/*
 * Genetic algorithm
 * Used for workout planification
 */

//First step, generate a population of random entities
//Seed is a function that must return a random entity
function generatePopulation(seed, fitness, populationSize, seedZero){
  var entityZero = seedZero();
  var population = [{ entity: entityZero, fit: fitness(entityZero) }];
  for(var i=1; i<populationSize; i++){
    var entity = seed();
    population.push({ entity: entity, fit: fitness(entity) }); //Store the entity and it fitness score
  }
  return population;
}

//Find and return the fitest entity of the population
function selectFitest(population){
  var max = Number.NEGATIVE_INFINITY;
  var fitest = null;
  for(var i=0; i<population.length; i++){
    if(population[i].fit >= max){
      fitest = population[i];
      max = population[i].fit;
    }
  }
  return fitest;
}

//Select a random entity of the population
function selectRandom(population){ return population[Math.floor(Math.random()*population.length)]; }

//Selection of the new population so that it's reduced to population size
function selection(population, populationSize){
  var newPopulation = [ selectFitest(population) ]; //Always keep the best entity found

  //Select randomly 2 entities and keep the fitest each time
  for(var i=0; i<populationSize-1; i++){
    var a1 = selectRandom(population);
    var a2 = selectRandom(population);
    newPopulation.push(a1.fit > a2.fit ? a1 : a2);
  }

  return newPopulation;
}

//Generate childrens that are a mix of 2 parent inside the population
//Crossover is a function that must return 2 children entities for 2 parent entities
function calcCrossOver(population, crossover, fitness, crossRatio){
  var newPopulation = [];
  for(var i=0; i<crossRatio*population.length; i++){
    //randomly select the 2 parents and generate the 2 children
    var a1 = selectRandom(population);
    var a2 = selectRandom(population);
    var crossed = crossover(a1.entity, a2.entity);
    newPopulation.push({ entity: crossed[0], fit: fitness(crossed[0])});
    newPopulation.push({ entity: crossed[1], fit: fitness(crossed[1])});
  }
  return newPopulation;
}

//Generate mutation inside the population
//mutate is a function that must return a child mutated entity for 1 parent entity
function calcMutations(population, mutate, fitness, mutationRatio){
  var newPopulation = [];
  for(var i=0; i<mutationRatio*population.length; i++){
    var a = selectRandom(population);
    var m = mutate(a.entity);
    newPopulation.push({ entity: m, fit: fitness(m) });
  }
  return newPopulation;
}

//Calc the new population for each loop
//Add the crossover and the mutations to the population
function calcNewPopulation(population, crossover, mutate, fitness, crossRatio, mutationRatio){
  var newpop1 = calcCrossOver(population, crossover, fitness, crossRatio);
  var newpop2 = calcMutations(population, mutate, fitness, mutationRatio);
  return population.concat(newpop1).concat(newpop2);
}

//Genetic algorithm function
//User must give a seed, mutate, crossover and fitness function
function evolve(seed, mutate, crossover, fitness, seedZero = seed){
  var populationSize = 200;
  var nbSteps = 100;
  var crossRatio = 0.5;
  var mutationRatio = 0.5;


  //Generate population, and on each loop, calculate new population and select it
  var population = generatePopulation(seed, fitness, populationSize, seedZero);
  //var previousFit = Number.NEGATIVE_INFINITY
  for(var i=0; i<nbSteps; i++){
    population = calcNewPopulation(population, crossover, mutate, fitness, crossRatio, mutationRatio);

    population = selection(population, populationSize);
    /*var fitest = selectFitest(population).fit;
    if(fitest > previousFit){
      console.log(i, ' : ', fitest);
      previousFit = fitest;
    }*/
  }

  var result = selectFitest(population); //Result is the fitest entity found
  if(result.fit == Number.NEGATIVE_INFINITY)
    console.error('WARNING /!\\ Plan Week not matching the constraints');
  else if(result.fit < 0)
    console.log('WARNING, Plan week found negative');

  //result.entity.fit = result.fit;

  return result.entity;
}

module.exports = {
  evolve,
};
