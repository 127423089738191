const WorkoutCategory = require('../workoutCategory');
const WorkoutItem = require('../workoutItem');



const warmup25 =  '2:00@55%ftp, 3:00@60%ftp, 5:00@65%ftp, 2:00@70%ftp, 2:00@80%ftp, 2:00@95%ftp, 1:00@100%ftp, 1:00@110%ftp, 2:00@45%ftp, 3*(8@350%ftp, 52@55%ftp), 2:00@50%ftp,';
const warmup40 =  '3:00@55%ftp, 6:00@60%ftp, 12:00@65%ftp, 6:00@70%ftp, 2:00@80%ftp, 2:00@95%ftp, 1:00@100%ftp, 1:00@110%ftp, 2:00@45%ftp, 3*(8@350%ftp, 52@55%ftp), 2:00@50%ftp,';
const warmup55 =  '4:00@55%ftp, 8:00@60%ftp, 20:00@65%ftp, 10:00@70%ftp, 2:00@80%ftp, 2:00@95%ftp, 1:00@100%ftp, 1:00@110%ftp, 2:00@45%ftp, 3*(8@350%ftp, 52@55%ftp), 2:00@50%ftp,';
const warmup70 =  '5:00@55%ftp, 10:00@60%ftp, 27:00@65%ftp, 15:00@70%ftp, 2:00@80%ftp, 2:00@95%ftp, 1:00@100%ftp, 1:00@110%ftp, 2:00@45%ftp, 3*(8@350%ftp, 52@55%ftp), 2:00@50%ftp,';
const warmup85 =  '5:00@55%ftp, 10:00@60%ftp, 42:00@65%ftp, 15:00@70%ftp, 2:00@80%ftp, 2:00@95%ftp, 1:00@100%ftp, 1:00@110%ftp, 2:00@45%ftp, 3*(8@350%ftp, 52@55%ftp), 2:00@50%ftp,';
const warmup100 = '5:00@55%ftp, 10:00@60%ftp, 57:00@65%ftp, 15:00@70%ftp, 2:00@80%ftp, 2:00@95%ftp, 1:00@100%ftp, 1:00@110%ftp, 2:00@45%ftp, 3*(8@350%ftp, 52@55%ftp), 2:00@50%ftp,';


const warmupsDuration = [25*60,40*60,55*60,70*60,85*60,100*60];
function lacticDescriptions(target, nb_intervals, intervals_duration, recovery, duration){
  return (index) => [
    { zone: 2, duration: warmupsDuration[index], target: '55%ftp-110%ftp', description: 'progressive_warmup_HIT' },
    { zone: 6, duration: duration, target: target, description: 'lactic', format: { nb_intervals: nb_intervals, intervals_duration: intervals_duration, recovery: recovery } },
    { zone: 1, duration: 10*60, target: '55%ftp', description: 'cooldown' },
  ];
}

var lactic = new WorkoutCategory(
  'lactic',
  [
    ...WorkoutItem.createWithWarmups([warmup25, warmup40, warmup55, warmup70, warmup85, warmup100],
      { name: 'lactic' }, 'anaerobic', { description: 'lactic', format: {intervals: '5*30s'} }, '5*(00:30@60%max, 4:30@50%ftp), 10:00@55%ftp', 0,
      lacticDescriptions('60%max', 5, '30s', '4min30', 5*5*60)),
    ...WorkoutItem.createWithWarmups([warmup25, warmup40, warmup55, warmup70, warmup85, warmup100],
      { name: 'lactic' }, 'anaerobic', { description: 'lactic', format: {intervals: '5*40s'} }, '5*(00:40@55%max, 4:20@50%ftp), 10:00@55%ftp', 0,
      lacticDescriptions('55%max', 5, '40s', '4min20', 5*5*60)),
    ...WorkoutItem.createWithWarmups([warmup25, warmup40, warmup55, warmup70, warmup85, warmup100],
      { name: 'lactic' }, 'anaerobic', { description: 'lactic', format: {intervals: '4*45s'} }, '4*(00:45@50%wbal, 5:30@50%ftp), 10:00@55%ftp', 0,
      lacticDescriptions('50%wbal', 4, '45s', '5min30', 5*5*60)),
    ...WorkoutItem.createWithWarmups([warmup25, warmup40, warmup55, warmup70, warmup85, warmup100],
      { name: 'lactic' }, 'anaerobic', { description: 'lactic', format: {intervals: '4*50s'} }, '4*(00:50@55%wbal, 5:25@50%ftp), 10:00@55%ftp', 0,
      lacticDescriptions('55%wbal', 4, '50s', '5min25', 5*5*60)),
    ...WorkoutItem.createWithWarmups([warmup25, warmup40, warmup55, warmup70, warmup85, warmup100],
      { name: 'lactic' }, 'anaerobic', { description: 'lactic', format: {intervals: '4*55s'} }, '4*(00:55@60%wbal, 5:20@50%ftp), 10:00@55%ftp', 0,
      lacticDescriptions('60%wbal', 4, '55s', '5min20', 5*5*60)),
    ...WorkoutItem.createWithWarmups([warmup25, warmup40, warmup55, warmup70, warmup85, warmup100],
      { name: 'lactic' }, 'anaerobic', { description: 'lactic', format: {intervals: '4*1mins'} }, '4*(01:00@65%wbal, 5:15@50%ftp), 10:00@55%ftp', 0,
      lacticDescriptions('65%wbal', 4, '1min', '5min15', 5*5*60)),
    ...WorkoutItem.createWithWarmups([warmup25, warmup40, warmup55, warmup70, warmup85, warmup100],
      { name: 'lactic' }, 'anaerobic', { description: 'lactic', format: {intervals: '4*1min15'} }, '4*(01:15@70%wbal, 5:00@50%ftp), 10:00@55%ftp', 0,
      lacticDescriptions('70%wbal', 4, '1min15', '5min', 5*5*60)),
  ]
);

module.exports = {
  lactic,
};
