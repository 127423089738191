<template>
  <div class="stats container" v-if="$store.getters.hasAdminAccess">
    <h2 class="title is-2">Stats</h2>

    <section class="section">
      <h3 class="title is-3">Actuellement inscrits:</h3>
      <div class="columns is-multiline is-mobile">
        <div class="column box mb-1">
          <h4 class="subtitle is-5">Inscrits</h4>
          <p class="title is-4">{{ usersStats.nb_users }}</p>
        </div>
        <div class="column box mb-1">
          <h4 class="subtitle is-5">Premium</h4>
          <p class="title is-4">{{ usersStats.nb_premiums }}</p>
        </div>
        <div class="column box mb-1">
          <h4 class="subtitle is-5">Standard</h4>
          <p class="title is-4">{{ usersStats.nb_standards }}</p>
        </div>
        <div class="column box mb-1">
          <h4 class="subtitle is-5">En Essai</h4>
          <p class="title is-4">{{ usersStats.nb_trials }}</p>
        </div>
        <div class="column box mb-1">
          <h4 class="subtitle is-5">Base</h4>
          <p class="title is-4">{{ usersStats.nb_bases }}</p>
        </div>
        <div class="column box mb-1">
          <h4 class="subtitle is-5">Parrainés</h4>
          <p class="title is-4">{{ usersStats.nb_referrals }}</p>
        </div>
      </div>
    </section>


    <section>
      <h2 class="title is-3">Codes utilisés</h2>
      <ul class="has-text-center">
        <li v-for="code in Object.keys(usedCodes)" :key="code">
          <b>{{ code }}</b> : {{ usedCodes[code] }}
        </li>
      </ul>
    </section>




    <section class="section" id="stats-charts">
      <h3 class="title is-3">Courbe des utilisateurs inscrits</h3>
      <div class="tooltip-row is-clipped">
        <div class="tooltip">
          <p class="inline-block">{{ tooltipDate.toLocaleDateString() }}</p>
        </div>
      </div>
      <StatsChart  ref="weightChart" id="nb-users-chart" v-if="nbUsersArray && nbUsersArray.length"
        :entries="nbUsersArray" :entries2="nbPaidsArray" :startDate="minDate" name=""
        elementListener="#stats-charts" :xAxis="false"
        :mouseMoveListener="event => { this.updateTooltip(event.mouse_x); }"/>

      <h3 class="title is-3">Courbe des inscrits par jours</h3>
      <div class="tooltip-row is-clipped">
        <div class="tooltip">
          <p class="inline-block">{{ tooltipDate.toLocaleDateString() }}</p>
        </div>
      </div>
      <StatsChart  ref="weightChart" id="nb-signups-chart" v-if="signupsArray && signupsArray.length"
        :entries="signupsArray" :startDate="minDate" name=""
        elementListener="#stats-charts" :xAxis="false"
        :mouseMoveListener="event => { this.updateTooltip(event.mouse_x); }"/>
    </section>





    <section class="section">
      <h3 class="title is-3">Utilisateurs connectés dans les dernières 24h: {{ lastDayUsers.length }}</h3>
      <ul class="has-text-left">
        <li v-for="user in lastDayUsers" :key="user.username">
          <a @click="loadUser(user)">{{ user.username }}</a>
          {{ user.email }}
          <i><b>{{ user.language }}</b></i>
          ( <b>{{ user.account_type }}; </b>{{ userHasPlan(user) }} )
          {{ user.completed_setup ? 'Setup fait; ' : '' }}
          {{ user.validated_mail ? 'Mail validé; ' : ''}}
          <span v-if="user.activities_count"> {{ user.activities_count }} activités</span>
          <span v-if="user.referral"><b> Parrainé</b></span>
        </li>
      </ul>
    </section>
    <section class="section">
      <h3 class="title is-3">Utilisateurs connectés dans les 7 derniers jours: {{ lastWeekUsers.length }}</h3>
      <ul class="has-text-left">
        <li v-for="user in lastWeekUsers" :key="user.username">
          <a @click="loadUser(user)">{{ user.username }}</a>
          {{ user.email }}
          <i>({{ user.last_login_date.toLocaleDateString() }})
          <b>{{ user.language }}</b></i> ( <b>{{ user.account_type }}; </b>{{ userHasPlan(user) }} )
          {{ user.completed_setup ? 'Setup fait; ' : '' }}
          {{ user.validated_mail ? 'Mail validé; ' : ''}}
          <span v-if="user.activities_count"> {{ user.activities_count }} activités</span>
          <span v-if="user.referral"><b> Parrainé</b></span>
        </li>
      </ul>
    </section>
    <section class="section">
      <h3 class="title is-3">Utilisateurs connectés dans les 30 derniers jours: {{ lastLogins.length }}</h3>
      <ul class="has-text-left">
        <li v-for="user in lastLogins" :key="user.username">
          <a @click="loadUser(user)">{{ user.username }}</a>
          {{ user.email }}
          <i>({{ user.last_login_date.toLocaleDateString() }})
            <b>{{ user.language }}</b></i>
            ( <b>{{ user.account_type }}; </b>{{ userHasPlan(user) }} )
            {{ user.completed_setup ? 'Setup fait; ' : '' }}
            {{ user.validated_mail ? 'Mail validé; ' : ''}}
            <span v-if="user.activities_count"> {{ user.activities_count }} activités</span>
            <span v-if="user.referral"><b> Parrainé</b></span>
        </li>
      </ul>
    </section>

    <section class="section">
      <h3 class="title is-3">Utilisateurs inscrits dans les 30 derniers jours: {{ lastSignups.length }}</h3>
      <h4 class="subtitle is-4">dont {{ usersSignedUpInLastDays(7).length }} cette semaine et {{ usersSignedUpInLastDays(1).length }} dans les dernières 24h.</h4>
      <ul class="has-text-left">
        <li v-for="user in lastSignups" :key="user.username">
          <a @click="loadUser(user)">{{ user.username }}</a>
          {{ user.email }} <i>({{ user.signup_date.toLocaleDateString() }})
            <b>{{ user.language }}</b></i>
            ( <b>{{ user.account_type }}; </b>{{ userHasPlan(user) }} )
            {{ user.completed_setup ? 'Setup fait; ' : '' }}
            {{ user.validated_mail ? 'Mail validé; ' : ''}}
            <span v-if="user.activities_count"> {{ user.activities_count }} activités</span>
            <span v-if="user.referral"><b> Parrainé</b></span>
            <span v-if="user.meta_infos"><i> {{ user.meta_infos }}</i></span>
        </li>
      </ul>
    </section>

    <section class="section">
      <h3 class="title is-3">Utilisateurs inscrits dans les 30 jours suivants: {{ afterLastSignups.length }}</h3>
      <ul class="has-text-left">
        <li v-for="user in afterLastSignups" :key="user.username">
          <a @click="loadUser(user)">{{ user.username }}</a>
          {{ user.email }} <i>({{ user.signup_date.toLocaleDateString() }})
            <b>{{ user.language }}</b></i>
            ( <b>{{ user.account_type }}; </b>{{ userHasPlan(user) }} )
            {{ user.completed_setup ? 'Setup fait; ' : '' }}
            {{ user.validated_mail ? 'Mail validé; ' : ''}}
            <span v-if="user.activities_count"> {{ user.activities_count }} activités</span>
            <span v-if="user.referral"><b> Parrainé</b></span>
            <span v-if="user.meta_infos"><i> {{ user.meta_infos }}</i></span>
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
import StatsChart from './StatsChart.vue';

export default{
  name: 'Stats',
  data(){
    return {
      usersStats: {
        nb_users: 0,
        nb_premiums: 0,
        nb_trials: 0,
        nb_bases: 0,
        nb_referrals: 0,
      },
      usedCodes: {},
      lastLogins: [],
      lastSignups: [],
      afterLastSignups: [],
      signupsStats: {},
      paidsStats: {},
      minDate: new Date(),
      nbUsersArray: [],
      nbPaidsArray: [],
      signupsArray: [],
      tooltipDate: new Date(),
    };
  },
  computed: {
    lastDayUsers(){ return this.usersLoggedInLastDays(1); },
    lastWeekUsers(){ return this.usersLoggedInLastDays(7); },
  },
  methods: {
    userHasPlan(user){
      const text = { 'NO_PLAN': 'Pas de plan', 'HAS_PLAN': 'Plan en cours', 'PAST_PLAN': 'A eu un plan', 'FUTURE_PLAN': 'Plan prévu', };
      return text[user.plan];
    },

    usersLoggedInLastDays(lastDays){
      var dateLowerLimit = new Date(Date.now() - lastDays * 24*3600*1000);
      var users = [];
      for(let user of this.lastLogins){
        if(user.last_login_date >= dateLowerLimit)
          users.push(user);
      }
      return users;
    },

    usersSignedUpInLastDays(lastDays){
      var dateLowerLimit = new Date(Date.now() - lastDays * 24*3600*1000);
      var users = [];
      for(let user of this.lastSignups){
        if(user.signup_date >= dateLowerLimit)
          users.push(user);
      }
      return users;
    },

    async getUsers(path, last_days, from_days = 0){
      let users = await this.get(path + '?last_days='+last_days+'&from_days='+from_days);
      for(let user of users){
        user.last_login_date = new Date(user.last_login_date);
        user.signup_date = new Date(user.signup_date);
      }
      return users;
    },

    async getSignupsStats(){
      let stats = await this.get('/users_signups_stats');
      let signups = stats.signups;
      let paids = stats.paids;

      this.signupsStats = {};
      this.paidsStats = {};
      this.minDate = new Date();
      for (const [key, value] of Object.entries(signups)){
        let date = new Date(key);
        this.signupsStats[date] = value;
        if(date < this.minDate)
          this.minDate = date;
      }

      for (const [key, value] of Object.entries(paids)){
        let date = new Date(key);
        this.paidsStats[date] = value;
      }


      this.nbUsersArray = [];
      this.signupsArray = [];
      this.nbPaidsArray = [];
      let nbUsers = 0;
      let nbPaids = 0;
      for(let date = new Date(this.minDate); date < Date.now(); date.setDate(date.getDate()+1)){
        this.signupsArray.push(this.signupsStats[date] || 0);
        nbUsers += this.signupsStats[date] || 0;
        this.nbUsersArray.push(nbUsers);

        nbPaids += this.paidsStats[date] || 0;
        this.nbPaidsArray.push(nbPaids);
      }

      console.log(this.nbPaidsArray)


    },


    async getStats(){
      try{
        this.usersStats = await this.get('/users_stats');

        await this.getSignupsStats();

        this.lastLogins = await this.getUsers('/last_logins', 30);
        this.lastLogins.sort((u1, u2) => u2.last_login_date - u1.last_login_date);

        this.lastSignups = await this.getUsers('/last_signups', 30);
        this.lastSignups.sort((u1, u2) => u2.signup_date - u1.signup_date);

        this.afterLastSignups = await this.getUsers('/last_signups', 30, 30);
        this.afterLastSignups.sort((u1, u2) => u2.signup_date - u1.signup_date);


        this.usedCodes = await this.get('/codes_used');
      }catch(err){
        console.error(err);
      }
    },

    loadUser(user){
      const user_id = (user._id == this.$store.getters.user._id) ? null : user._id;
      this.$store.dispatch('setLoadedUser', user_id).then(() => {
        this.$router.push('/dashboard');
      }).catch(err => {
        console.error(err);
      });
    },

    updateTooltip(mouse_x){
      var index = this.$refs.weightChart.indexOfPosition(mouse_x);
      this.tooltipDate = new Date(this.minDate);
      this.tooltipDate.setDate(this.minDate.getDate()+index);
      const width = document.getElementById('stats-charts').clientWidth;
      var translateValue = Math.max(Math.min(mouse_x, width-300), 50);
      for(let el of document.getElementsByClassName("tooltip")){
        el.style.transform = "translateX("+translateValue+"px)";
      }
    },
  },
  async mounted(){
    await this.getStats();
  },

  components: {
    StatsChart,
  },
};
</script>

<style lang="scss">
.tooltip{
  display: inline-block;
  float: left;
}

.progression-charts{
  padding:0;
  margin:auto;
  display: inline-block;
  width: 100%;
}

.verticalLine{
  stroke: grey;
  stroke-width: 1px;
}
.selectionRect{
  fill: grey;
  stroke: black;
  stroke-width: 1px;
}

.line {
  fill: none;
  stroke-width: 1px;
  stroke: black;
}

#nb-users-chart .line0 {
  stroke: blue;
}
#nb-users-chart .line1 {
  stroke: red;
}
</style>
