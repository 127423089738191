/*
 * Utility functions for planner
*/

const dayjs = require('dayjs');
dayjs.extend(require('dayjs/plugin/utc'));

const ObjectiveItem = require('./objectiveItem');
const TrainingRideItem = require('./trainingRideItem');
const ActivityItem = require('./activityItem');

//Return user available time of the week
function getUserAvailableTime(user, date, log=false){
   const week = dayjs.utc(date).startOf('week').format('YYYY-MM-DD');

   return user.training_plan_data.available_time_on_week[week] || user.training_plan_data;
}

//Return user available time for date
function getUserDateAvailableTime(user, date){
  return getUserAvailableTime(user, date, true).available_time_days[(date.day()+6)%7];
}

//Return available time for plan
function _getUserAvailableTimeForPlan(user, startDate, planLength){
  var date = dayjs.utc(startDate);
  var time = 0;
  var daysToWeekEnd = date.endOf('week').diff(date, 'day') + 1;
  while(planLength > daysToWeekEnd){ //If plan part end after the week end, have to split the time calculation for each week in case different week available time
    time += getUserAvailableTime(user, date).week_available_time * (daysToWeekEnd/7); //Time is week time * number of day to week end / 7
    //Update dates and length for next split
    date = date.add(daysToWeekEnd, 'day');
    planLength-= daysToWeekEnd;
    daysToWeekEnd = date.endOf('week').diff(date, 'day') + 1;
  }
  time += getUserAvailableTime(user, date).week_available_time * (planLength/7);
  return time;
}


//Return available time for plan
//Result is cached when it's the same parameters
function getUserAvailableTimeForPlan(user, startDate, planLength){
  if(user === getUserAvailableTimeForPlan.user && startDate == getUserAvailableTimeForPlan.startDate)
    return getUserAvailableTimeForPlan.time;

  getUserAvailableTimeForPlan.user = user;
  getUserAvailableTimeForPlan.startDate = startDate;
  getUserAvailableTimeForPlan.time = _getUserAvailableTimeForPlan(user, startDate, planLength);
  return getUserAvailableTimeForPlan.time;
}


function getUserDatesAvailableTimeForPlan(user, startDate, planLength){
  if(user === getUserDatesAvailableTimeForPlan.user && startDate == getUserDatesAvailableTimeForPlan.startDate)
    return getUserDatesAvailableTimeForPlan.times;

  getUserDatesAvailableTimeForPlan.user = user;
  getUserDatesAvailableTimeForPlan.startDate = startDate;
  var times = new Array(planLength);
  var date = dayjs.utc(startDate);
  for(var i=0; i<planLength; i++){
    times[i] = getUserDateAvailableTime(user, date);
    date = date.add(1, 'day');
  }
  getUserDatesAvailableTimeForPlan.times = times;
  return times;
}



//Return user training type day of date
function getUserDateTrainingType(user, date){
  const trainingTypes =  getUserAvailableTime(user, date, true).training_type_days;
  if(!trainingTypes)
    return 'outdoor';
  return trainingTypes[(date.day()+6)%7] || 'outdoor';
}

//Return user training types days for a set of days
function getUserDatesTrainingTypes(user, startDate, planLength){
  //Check if result is already cached and if it is return it
  if(user === getUserDatesTrainingTypes.user && startDate == getUserDatesTrainingTypes.startDate)
    return getUserDatesTrainingTypes.trainingTypes;

  //cache parameters for check
  getUserDatesTrainingTypes.user = user;
  getUserDatesTrainingTypes.startDate = startDate;

  let trainingTypes = new Array(planLength);
  let date = dayjs.utc(startDate);
  for(var i=0; i<planLength; i++){
    trainingTypes[i] = getUserDateTrainingType(user, date);
    date = date.add(1, 'day');
  }

  getUserDatesTrainingTypes.trainingTypes = trainingTypes; //cache result
  return trainingTypes;
}



//Utility, compare two value according to the comparator given
function compare(comp, v1, v2){
  switch(comp){
    case '>':
    case 'MORE':
      return v1 > v2;
    case '>=':
      return v1 >= v2;
    case '<':
    case 'LESS':
      return v1 < v2;
    case '<=':
      return v1 <= v2;
    case '=':
    case '==':
    case 'EQUAL':
      return v1 === v2;
  }

  console.trace();
  throw 'Unknown comparaison ' + comp;
}

//Transform user objectives and training rides array to a map (with dates as key) for faster direct access
function calcPlannedItemsMap(user){
  var items = {};
  for(let trainingRide of user.training_rides){
    items[trainingRide.date] = new TrainingRideItem(trainingRide);
  }
  /*for(let activity of user.activities){
    if(activity.intensity_done){
      if(items[activity.date] && items[activity.date].isActivity()){
        continue;
      }
      let activities = user.activities.filter(a => !!a.intensity_done && dayjs.utc(a.date).isSame(activity.date, 'date'));
      items[activity.date] = new ActivityItem(activities);
    }
  }*/
  for(let objective of user.objectives){
    items[objective.date] = new ObjectiveItem(objective);
  }
  return items;
}

 module.exports = {
   getUserAvailableTime,
   getUserDateAvailableTime,
   getUserAvailableTimeForPlan,
   getUserDatesAvailableTimeForPlan,
   getUserDateTrainingType,
   getUserDatesTrainingTypes,
   compare,
   calcPlannedItemsMap,
 };
