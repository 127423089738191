const WorkoutCategory = require('../workoutCategory');
const WorkoutItem = require('../workoutItem');


function openerDescription(){
  return [
    { zone: 3, duration: 15*60, target: '50%ftp-100%ftp', description: 'progressive_warmup', format: { duration: '15min' } },
    { zone: 7, duration: 10*60, target: '100%map-90%max', description: 'opener' },
    { zone: 1, duration: 5*60, target: '50%ftp', description: 'cooldown' },
  ];
}

var opener = new WorkoutCategory(
  'opener',
  [
    WorkoutItem.create({ name: 'opener', format: ['30min'] }, 'other', { description: 'opener', format: ['30min'] },
      '5:00@50%ftp, 5:00@60%ftp, 1:00@70%ftp, 1:00@80%ftp, 1:00@90%ftp, 2:00@100%ftp, 2:00@50%ftp, 1:00@100%map, 2:00@50%ftp, 3*(6@90%max, 54@50%ftp), 2:00@60%ftp, 5:00@50%ftp', 0,
      openerDescription()),
  ]
);

module.exports = {
  opener
};
