/*
 * Rules targeting workouts categories
 */
const Rule = require('./rule');



//Frequency Rule
//Specify the number of time the workout categories must appear in the week (either a specific number or a min/max)
class FrequencyRule extends Rule{
  constructor(categories, rule, frequency, priority = 'REQUIRED', multiplier = 1){
    super(priority, multiplier);
    this.categories = Array.isArray(categories) ? categories : [categories];
    this.rule = rule;
    this.frequency = frequency;

    if(!['MIN', 'MAX', 'EQUAL'].includes(rule)){ //rule can only be min max or equal
      console.trace();
      throw 'Unknown rule: ' + rule;
    }
  }

  check(user, plan, data){
    var nb = 0; //Find number of time a workout of the specified categories appears
    for(var i=0; i<plan.length; i++){
      var workoutCategory = Rule.workoutCategoryName(plan[i]);
      if(this.categories.includes(workoutCategory))
        nb++;
    }

    //And check if it match the rule
    if(this.rule === 'MIN')
      return nb >= this.frequency ? this.pointsOnSuccess() : this.pointsOnFail() * Math.abs(this.frequency-nb); //If frequency below, fail is bigger for bigger difference
    else if(this.rule === 'MAX')
      return nb <= this.frequency ? this.pointsOnSuccess() : this.pointsOnFail() * Math.abs(nb-this.frequency); //If frequency above, fail is bigger for bigger difference
    else if(this.rule === 'EQUAL')
      return nb == this.frequency ? this.pointsOnSuccess() : this.pointsOnFail() * Math.abs(nb-this.frequency); //Higher frequency difference = bigger failure

    return 0;
  }
}

function frequencyRule(category, rule, frequency, priority = 'REQUIRED', multiplier = 1){
  return new FrequencyRule(category, rule, frequency, priority, multiplier);
}


//Placement rule
//Specify that some workout categories need to be placed the day before or after some other workouts categories
class PlacementRule extends Rule{
  constructor(categories1, rule, categories2, priority = 'REQUIRED', multiplier = 1){
    super(priority, multiplier);
    this.categories1 = Array.isArray(categories1) ? categories1 : [categories1];
    this.rule = rule;
    this.categories2 = Array.isArray(categories2) ? categories2 : [categories2];

    if(!(rule === 'AFTER' || rule === 'BEFORE')){ //rule can only be before or after
      console.trace();
      throw 'Unknown rule: ' + rule;
    }
  }

  check(user, plan, data){
    var points = 0;

    var planCategories = [];
    //Need to check the last workout of last week and the first workout of the next week too.
    if(data.previousWorkout) planCategories.push(Rule.workoutCategoryName(data.previousWorkout));
    planCategories = planCategories.concat(plan.map(workout => Rule.workoutCategoryName(workout))); //Convert workout item array to a name array (easier and faster)
    if(data.nextWorkout) planCategories.push(Rule.workoutCategoryName(data.nextWorkout));

    for(var i=0; i<planCategories.length; i++){
      var category = planCategories[i];

      if(this.categories1.includes(category)){ //Workout specified in the first category found. Now need to check if the rule match.
        var otherCategory = null; //Will be the other category to check
        if(this.rule === 'AFTER' && i >= 1){ //depending of the rule
          otherCategory = planCategories[i-1];
        }else if(this.rule === 'BEFORE' && i < planCategories.length-1){
          otherCategory = planCategories[i+1];
        }

        if(otherCategory && this.categories2.includes(otherCategory)){ //Check if the category to check match
          points += this.pointsOnSuccess(); //Either a success
        }else{
          points += this.pointsOnFail(); // or a fail
        }
      }
    }
    return points;
  }

}

function placementRule(category, rule, categories, priority = 'REQUIRED', multiplier = 1){
  return new PlacementRule(category, rule, categories, priority, multiplier);
}


//Order rule
//Specify that some workout categories need to be placed before or after some other workouts categories in the week
class OrderRule extends Rule{
  constructor(category, rule, categories, priority = 'REQUIRED', multiplier = 1){
    super(priority, multiplier);
    this.category = category;
    this.rule = rule;
    this.categories = Array.isArray(categories) ? categories : [categories];

    if(!(rule === 'AFTER' || rule === 'BEFORE')){ //rule can only be before or after
      console.trace();
      throw 'Unknown rule: ' + rule;
    }
  }

  check(user, plan, data){
    for(var i=0; i<plan.length; i++){
      var workoutCategory = Rule.workoutCategoryName(plan[i]);
      if(workoutCategory == this.category){ //found a workout matching the first category. Now need to check the whole week to make sure the rule match
        for(var j=0; j<plan.length; j++){
          var cat = Rule.workoutCategoryName(plan[j]);
          if((this.rule === 'BEFORE' && j < i && this.categories.includes(cat))
            || (this.rule === 'AFTER' && j > i && this.categories.includes(cat))){ //Check if there is a fail
            return this.pointsOnFail();
          }
        }
        return this.pointsOnSuccess(); //If no fail found then it's a success
      }
    }

    return 0;
  }
}

function orderRule(category, rule, categories, priority = 'REQUIRED', multiplier = 1){
  return new OrderRule(category, rule, categories, priority, multiplier);
}



//Priority rule
//Put a priority for the specified categories above other categories:
//For [cat1, cat2, cat3], that mean that cat2 and 3 must not appear if there is no cat 1 during the week (and cat3 must not appear if no cat2)
class PriorityRule extends Rule{
  constructor(categories, priority = 'REQUIRED', multiplier = 1){
    super(priority, multiplier);

    for(var i=0; i<categories.length; i++) //Categories is an array (priority) of arrays (multiple categories for each priority group)
      categories[i] = Array.isArray(categories[i]) ? categories[i] : [categories[i]];

    this.categories = categories;

    if(!Array.isArray(categories)){
      console.trace();
      throw 'Priority Rule: categories must be an array';
    }
  }

  check(user, plan, data){
    var i=0;
    var firstFail = false;
    for(var categoryList of this.categories){
      //For every category list, try to find it in the plan
      var found = false;
      for(var item of plan){
        if(categoryList.includes(Rule.workoutCategoryName(item))){
          found = true;
          break;
        }
      }

      if(found && firstFail){ //If it found a workout below on the priority list while it already failed to find on higher, then it's a fail
        return this.pointsOnFail();
      }
      if(!found){
        firstFail = true;
      }
    }
    return this.pointsOnSuccess();
  }
}

function priorityRule(categories, priority = 'REQUIRED', multiplier = 1){
  return new PriorityRule(categories, priority, multiplier);
}


//Week position rule
//Specify in which day range the workout of the selected categories must appear
class WeekPositionRule extends Rule{
  constructor(categories, rangeStart, rangeEnd, priority = 'REQUIRED', multiplier = 1){
    super(priority, multiplier);
    this.categories = Array.isArray(categories) ? categories : [categories];
    this.rangeStart = rangeStart;
    this.rangeEnd = rangeEnd;

    if(rangeStart < 0 || rangeEnd > 6 || rangeStart > rangeEnd){ //rule can only be min max or equal
      console.trace();
      throw 'Week position rule error with range: ' + rangeStart + ',' + rangeEnd;
    }
  }

  check(user, plan, data){
    var rangeEnd = plan.length-(7-this.rangeEnd);
    var points = 0;
    var success = true;
    for(var i=0; i<plan.length; i++){
      if(i < this.rangeStart || i > rangeEnd){ //If day of the week not in range
        var workoutCategory = Rule.workoutCategoryName(plan[i]); //Check if workout isn't specified in categories list
        if(this.categories.includes(workoutCategory)){
          points += this.pointsOnFail(); //If it is then it's a fail (and fail points are added for each fail)
          success = false;
        }
      }
    }
    if(success)
      points += this.pointsOnSuccess();
    return points;
  }
}

function weekPositionRule(categories, rangeStart, rangeEnd, priority = 'REQUIRED', multiplier = 1){
  return new WeekPositionRule(categories, rangeStart, rangeEnd, priority, multiplier);
}



//Category level rule
//Specify that the workout of the specified categories must be of a certain level (used to simulate progression and making sure the user get a workout adapted to his level)
class CategoryLevelRule extends Rule{
  constructor(categories, level, priority = 'REQUIRED', multiplier = 1){
    super(priority, multiplier);
    this.categories = Array.isArray(categories) ? categories : [categories];
    this.level = level;
  }

  //Return the workout level needed based on the user level and the level modifier
  getWorkoutLevel(user){
    var userLevel = user.training_plan_data.training_level || 1;
    var compLevel = userLevel + this.level;
    compLevel = Math.max(Math.min(compLevel, 6.5), 1); //Level must be between 1 and 6.5
    return compLevel;
  }

  check(user, plan, data){
    var compLevel = this.getWorkoutLevel(user);
    var points = 0;
    //For each workout of the specified category, check if it's the wanted level
    for(let item of plan){
      if(item !== 'REST' && item.isWorkout() && this.categories.includes(Rule.workoutCategoryName(item))){
        if(item.getLevel())
          points += item.getLevel() === compLevel ? this.pointsOnSuccess() : this.pointsOnFail();
      }
    }
    return points;
  }
}

function categoryLevelRule(categories, level, priority = 'REQUIRED', multiplier = 1){
  return new CategoryLevelRule(categories, level, priority, multiplier);
}


//Longest workout for Category rule
//Specify that the longest workout must be of a specific category
class LongestWorkoutCategoryRule extends Rule{
  constructor(categories, priority = 'REQUIRED', multiplier = 1){
    super(priority, multiplier);
    this.categories = Array.isArray(categories) ? categories : [categories];
  }

  check(user, plan, data){
    //Find max duration of week
    var maxDuration = 0;
    for(let workout of plan){
      if(workout !== 'REST' && workout.isWorkout()){
        maxDuration = Math.max(maxDuration, workout.getDuration());
      }
    }
    //Check if all max duration are of the specified category
    for(let workout of plan){
      if(workout !== 'REST' && workout.isWorkout()
        && workout.getDuration() === maxDuration && !this.categories.includes(Rule.workoutCategoryName(workout))){
          return this.pointsOnFail();
      }
    }
    return this.pointsOnSuccess();
  }
}

function longestWorkoutCategoryRule(categories, priority = 'REQUIRED', multiplier = 1){
  return new LongestWorkoutCategoryRule(categories, priority, multiplier);
}



//Category target duration rule
//Specify the duration (min/max/equal) for workout of this category and level
class CategoryLevelTargetDuration extends Rule{
  constructor(categories, levels, rule, duration, priority = 'REQUIRED', multiplier = 1){
    super(priority, multiplier);
    this.categories = Array.isArray(categories) ? categories : [categories];
    this.levels = Array.isArray(levels) ? levels : [levels, levels];
    this.rule = rule;
    this.duration = duration;

    if(!['MIN', 'MAX', 'EQUAL'].includes(rule)){
      console.trace();
      throw 'Unknown rule: ' + rule;
    }
  }

  checkDuration(workout){
    switch(this.rule){
      case 'MIN': return workout.getDuration() >= this.duration*3600;
      case 'MAX': return workout.getDuration() <= this.duration*3600;
      case 'EQUAL': return workout.getDuration() === this.duration*3600;
    }
    return false;
  }

  check(user, plan, data){
    let points = 0;
    for(let workout of plan){
      if(this.categories.includes(Rule.workoutCategoryName(workout)) && workout.level >= this.levels[0] && workout.level <= this.levels[1]){
        points += this.checkDuration(workout) ? this.pointsOnSuccess() : this.pointsOnFail();
      }
    }
    return points;
  }
}

function categoryLevelTargetDuration(categories, levels, rule, duration, priority = 'REQUIRED', multiplier = 1){
  return new CategoryLevelTargetDuration(categories, levels, rule, duration, priority, multiplier);
}

//Set training ride category
//Specify which workout category the Training rides will have to emulate depending of the planned intensity
class TrainingRideCategory extends Rule{
  constructor(intensities, categories, priority = 'REQUIRED', multiplier = 1){
    super(priority, multiplier);
    this.intensities = Array.isArray(intensities) ? intensities : [intensities];
    this.categories = Array.isArray(categories) ? categories : [categories];
  }

  check(user, plan, data){
    let points = 0;
    for(let item of plan){ //Find training ride of the required intensity
      if(item !== 'REST' && item.isRide() && this.intensities.includes(item.getIntensity())){
        //And check if the training ride set category is the one needed
        points += this.categories.includes(item.categoryName()) ? this.pointsOnSuccess() : this.pointsOnFail();
      }
    }
    return points;
  }
}

function trainingRideCategory(intensities, categories, priority = 'REQUIRED', multiplier = 1){
  return new TrainingRideCategory(intensities, categories, priority, multiplier);
}


//Workout placement preference for day type
//Define preferences for workout categories for indoor/outdoor day type
class PlacementDayType extends Rule{
  constructor(categories, trainingType, priority = 'REQUIRED', multiplier = 1, failMultiplier = 1){
    super(priority, multiplier);
    this.categories = Array.isArray(categories) ? categories : [categories];
    this.trainingType = trainingType;
    this.failMultiplier = failMultiplier;
  }

  check(user, plan, data){
    let points = 0;
    let nb = 0; //Count nb occurence of workouts

    for(let i=0; i<plan.length; i++){
      //For every workouts, check if category matches this rule and if it does check training type of day matches rule training type and count points
      if(this.categories.includes(Rule.workoutCategoryName(plan[i]))){
        points += data.trainingTypeDays[i] == this.trainingType ? this.pointsOnSuccess() : this.pointsOnFail() * this.failMultiplier;
        nb++;
      }
    }

    if(!nb)
      return 0;


    return points/nb; //divide points by number of workouts
  }
}

function placementDayType(categories, trainingType, priority = 'REQUIRED', multiplier = 1){
  return new PlacementDayType(categories, trainingType, priority, multiplier, 1);
}

function placementDayTypePreference(categories, trainingType, priority = 'REQUIRED', multiplier = 1){
  return new PlacementDayType(categories, trainingType, priority, multiplier, 0);
}


class MaxTrainingDaysRule extends Rule{
  constructor(days, priority = 'REQUIRED', multiplier = 1){
    super(priority, multiplier);
    this.days = days;
  }

  check(user, plan, data){
    let nb = 0;   //Count number of training days
    for(let workout of plan)
      if(workout !== 'REST')
       nb++;

    if(nb <= this.days) //If below nb days success
      return this.pointsOnSuccess();

    return this.pointsOnFail() * (nb-this.days); //Else failure, amplify points by number of training days above nb days
  }
}

function maxTrainingDaysRule(days, priority = 'REQUIRED', multiplier = 1){
  return new MaxTrainingDaysRule(days, priority, multiplier);
}


module.exports = {
  placementRule,
  orderRule,
  frequencyRule,
  priorityRule,
  weekPositionRule,
  longestWorkoutCategoryRule,
  categoryLevelRule,
  trainingRideCategory,
  placementDayType,
  placementDayTypePreference,
  maxTrainingDaysRule,
};
