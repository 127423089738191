<template>
  <div class="mailsender box" v-if="$store.getters.hasAdminAccess">
    <div class="box">
      <h2 class="title is-2">Send contacts to SendGrid</h2>
      <b-button disabled @click.native="sendContactsToSendGrid()">Send contacts</b-button> <br/>
      <b-button @click.native="sendContactsNoSubToSendGrid()">Send contacts with no subscriptions</b-button>
    </div>
    <div class="box">
      <section class="section">
        <h2 class="title is-4">English</h2>
        <b-field label="Subject">
          <b-input type="text" v-model="englishSubject" @input.native="onEnglishChange"/>
        </b-field>
        <b-field label="Content">
          <prism-editor class="editor content" v-model="englishContent" :highlight="highlighter" @input="onEnglishChange" line-numbers/>
        </b-field>
      </section>
      <section class="section">
        <h2 class="title is-4">Français</h2>
        <b-field label="Sujet">
          <b-input type="text" v-model="frenchSubject" @input.native="onFrenchChange"/>
        </b-field>
        <b-field label="Contenu">
          <prism-editor class="editor content" v-model="frenchContent" :highlight="highlighter" @input="onFrenchChange" line-numbers/>
        </b-field>
      </section>
    </div>

    <div class="actions box">
      <p>Mail test: <b>{{ $store.getters.user.email }}</b></p>
      <b-button @click.native="test()">Test</b-button>
      <b-button @click.native="send()">Send</b-button>
      <b-button @click.native="reloadBaseTemplate()">Reload base template</b-button>
    </div>
    <div class="has-text-danger box" v-if="error">
      {{ error }}
    </div>
    <div class="has-text-success box" v-if="success">
      Success !
    </div>

    <div class="box">
      <iframe width="800" height="300"  :srcdoc="englishContent"/><br/>
      <iframe width="800" height="300"  :srcdoc="frenchContent"/>
    </div>

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false">
      <div class="box">
        <h1 class="title is-2"> Sending mails </h1>
        <section class="block">
          <b-icon icon="sync" size="is-large" custom-class="mdi-spin-reverse"/>
        </section>
      </div>
    </b-loading>
  </div>
</template>


<script>
import { PrismEditor } from 'vue-prism-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-markup';

import BaseTemplate from '!raw-loader!./mailTemplate.html';



export default{
  name: 'mailsender',
  data(){
    return {
      englishSubject: '',
      englishContent: '',
      frenchSubject: '',
      frenchContent: '',
      error: '',
      success: false,
      isLoading: false,
    };
  },

  methods: {
    highlighter(code){
      return highlight(code, languages.markup);
    },

    onEnglishChange(){
      localStorage.setItem('mail_sender_english_subject', this.englishSubject);
      localStorage.setItem('mail_sender_english', this.englishContent);
    },
    onFrenchChange(){
      localStorage.setItem('mail_sender_french_subject', this.frenchSubject);
      localStorage.setItem('mail_sender_french', this.frenchContent);
    },

    async sendContactsToSendGrid(){
      this.isLoading = true;
      try{
        await this.post('/send_contacts_to_sendgrid', {});
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Contacts successfully sent to SendGrid',
          position: 'is-bottom',
          type: 'is-success'
        });
      }catch(err){
        console.error(err);
        this.$buefy.toast.open({
          duration: 15000,
          message: 'Error: ' + err,
          position: 'is-bottom',
          type: 'is-success'
        });
      }
      this.isLoading = false;
    },

    async sendContactsNoSubToSendGrid(){
      this.isLoading = true;
      try{
        await this.post('/send_contacts_no_sub_to_sendgrid', {});
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Contacts successfully sent to SendGrid',
          position: 'is-bottom',
          type: 'is-success'
        });
      }catch(err){
        console.error(err);
        this.$buefy.toast.open({
          duration: 15000,
          message: 'Error: ' + err,
          position: 'is-bottom',
          type: 'is-success'
        });
      }
      this.isLoading = false;
    },



    async test(){
      this.error = '';
      this.success = false;
      this.isLoading = true;
      try{
        await this.post('/mail_sender_test', {
          english_subject: this.englishSubject,
          english_content: this.englishContent,
          french_subject: this.frenchSubject,
          french_content: this.frenchContent,
        });
        this.success = true;
      }catch(err){
        this.error = err;
      }
      this.isLoading = false;
    },

    async send(){
      this.error = '';
      this.success = false;
      if(window.confirm('Send mails to all users ?')){
        this.isLoading = true;
        try{
          await this.post('/mail_sender_send', {
            english_subject: this.englishSubject,
            english_content: this.englishContent,
            french_subject: this.frenchSubject,
            french_content: this.frenchContent,
          });
          this.success = true;
        }catch(err){
          this.error = err;
        }
        this.isLoading = false;
      }
    },

    reloadBaseTemplate(){
      if((!this.englishContent && !this.frenchContent) || window.confirm('This will overwrite your current contents. Continue ?')){
        this.englishContent = BaseTemplate;
        this.frenchContent = BaseTemplate;
      }
    },
  },
  mounted(){
    this.englishSubject = localStorage.getItem('mail_sender_english_subject') || '';
    this.englishContent = localStorage.getItem('mail_sender_english') || '';
    this.frenchSubject = localStorage.getItem('mail_sender_french_subject') || '';
    this.frenchContent = localStorage.getItem('mail_sender_french') || '';
  },
  components: {
    PrismEditor,
  }
}

</script>


<style lang="scss">
@import '~vue-prism-editor/dist/prismeditor.min.css';
@import '~prismjs/themes/prism-tomorrow.css';

.editor{
  background: #2d2d2d;
  color: #ccc;

  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
  height: 300px;
  overflow: auto;
}

.prism-editor__editor{
  color: #ccc;
}

.token.boolean,
.token.number,
.token.function,
.token.tag {
  color: #f08d49;
  background-color: transparent;
  font-size: 1em;
  display: contents;
}
</style>
