/*
 * Plan the training blocks for the season
 */
var dayjs = require('dayjs');
dayjs.extend(require('dayjs/plugin/utc'));
const TrainingBlock = require('./trainingBlock');
const util = require('../util');




function getObjectivesAfterDate(objectives, date){
  return objectives.filter(objective => objective.date > date);
}
function getObjectivesBeforeNextYearDate(objectives, date){
  const limit = dayjs.utc(date).add(1,'year').toDate();
  return objectives.filter(objective => objective.date <= limit);
}
function getRelevantObjectives(objectives, date){
  return getObjectivesBeforeNextYearDate(getObjectivesAfterDate(objectives, date), date);
}

function getAObjectives(objectives){
  return objectives.filter(objective => objective.priority === 'A');
}

//Blocks split used according to the number of weeks to plan
const blockSplits = [
  [{ type: 'TAPER', days: 0 }],
  [{ type: 'TAPER', days: 7 }],
  [{ type: 'BUILD_1', days: 7 }, { type: 'TAPER', days: 7 }],
  [{ type: 'BUILD_1', days: 14 }, { type: 'TAPER', days: 7 }],
  [{ type: 'BUILD_1', days: 21 }, { type: 'RECOVERY_BUILD_1', days: 7 }, { type: 'TAPER', days: 0 }],
  [{ type: 'BUILD_1', days: 21 }, { type: 'RECOVERY_BUILD_1', days: 7 }, { type: 'TAPER', days: 7 }],
  [{ type: 'BUILD_1', days: 21 }, { type: 'RECOVERY_BUILD_1', days: 7 }, { type: 'TAPER', days: 14 }],
  [{ type: 'BUILD_1', days: 21 }, { type: 'RECOVERY_BUILD_1', days: 7 }, { type: 'BUILD_2', days: 14 }, { type: 'TAPER', days: 7 }],
  [{ type: 'BASE_3', days: 14 }, { type: 'RECOVERY_BASE_3', days: 7 }, { type: 'BUILD_1', days: 14 }, { type: 'RECOVERY_BUILD_1', days: 7 },
    { type: 'BUILD_2', days: 7 }, { type: 'TAPER', days: 7 }],
  [{ type: 'BASE_3', days: 14 }, { type: 'RECOVERY_BASE_3', days: 7 }, { type: 'BUILD_1', days: 21 }, { type: 'RECOVERY_BUILD_1', days: 7 },
    { type: 'BUILD_2', days: 7 }, { type: 'TAPER', days: 7 }],
  [{ type: 'BASE_3', days: 21 }, { type: 'RECOVERY_BASE_3', days: 7 }, { type: 'BUILD_1', days: 21 }, { type: 'RECOVERY_BUILD_1', days: 7 },
    { type: 'BUILD_2', days: 7 }, { type: 'TAPER', days: 7 }],
  [{ type: 'BASE_2', days: 7 }, { type: 'RECOVERY_BASE_2', days: 7 }, { type: 'BASE_3', days: 14 },
   { type: 'RECOVERY_BASE_3', days: 7 }, { type: 'BUILD_1', days: 21 }, { type: 'RECOVERY_BUILD_1', days: 7 }, { type: 'BUILD_2', days: 7 },
   { type: 'TAPER', days: 7 }],
  [{ type: 'BASE_2', days: 7 }, { type: 'RECOVERY_BASE_2', days: 7 }, { type: 'BASE_3', days: 14 },
    { type: 'RECOVERY_BASE_3', days: 7 }, { type: 'BUILD_1', days: 21 }, { type: 'RECOVERY_BUILD_1', days: 7 }, { type: 'BUILD_2', days: 14 },
    { type: 'TAPER', days: 7 }],
  [{ type: 'BASE_2', days: 14 }, { type: 'RECOVERY_BASE_2', days: 7 }, { type: 'BASE_3', days: 21 },
    { type: 'RECOVERY_BASE_3', days: 7 }, { type: 'BUILD_1', days: 21 }, { type: 'RECOVERY_BUILD_1', days: 7 }, { type: 'BUILD_2', days: 7 },
    { type: 'TAPER', days: 7 }],
  [{ type: 'BASE_2', days: 14 }, { type: 'RECOVERY_BASE_2', days: 7 }, { type: 'BASE_3', days: 21 },
    { type: 'RECOVERY_BASE_3', days: 7 }, { type: 'BUILD_1', days: 21 }, { type: 'RECOVERY_BUILD_1', days: 7 }, { type: 'BUILD_2', days: 14 },
    { type: 'TAPER', days: 7 }],
  [{ type: 'BASE_2', days: 21 }, { type: 'RECOVERY_BASE_2', days: 7 },
    { type: 'BASE_3', days: 21 }, { type: 'RECOVERY_BASE_3', days: 7 }, { type: 'BUILD_1', days: 21 }, { type: 'RECOVERY_BUILD_1', days: 7 },
    { type: 'BUILD_2', days: 14 }, { type: 'TAPER', days: 7 }],
  [{ type: 'BASE_1', days: 7 }, { type: 'RECOVERY_BASE_1', days: 7 }, { type: 'BASE_2', days: 14 },
    { type: 'RECOVERY_BASE_2', days: 7 }, { type: 'BASE_3', days: 21 }, { type: 'RECOVERY_BASE_3', days: 7 }, { type: 'BUILD_1', days: 21 },
    { type: 'RECOVERY_BUILD_1', days: 7 }, { type: 'BUILD_2', days: 14 }, { type: 'TAPER', days: 7 }],
  [{ type: 'BASE_1', days: 7 }, { type: 'RECOVERY_BASE_1', days: 7 }, { type: 'BASE_2', days: 14 },
    { type: 'RECOVERY_BASE_2', days: 7 }, { type: 'BASE_3', days: 21 }, { type: 'RECOVERY_BASE_3', days: 7 }, { type: 'BUILD_1', days: 21 },
    { type: 'RECOVERY_BUILD_1', days: 7 }, { type: 'BUILD_2', days: 21 }, { type: 'TAPER', days: 7 }],
  [{ type: 'BASE_1', days: 7 }, { type: 'RECOVERY_BASE_1', days: 7 }, { type: 'BASE_2', days: 14 },
    { type: 'RECOVERY_BASE_2', days: 7 }, { type: 'BASE_3', days: 21 }, { type: 'RECOVERY_BASE_3', days: 7 }, { type: 'BUILD_1', days: 21 },
    { type: 'RECOVERY_BUILD_1', days: 7 }, { type: 'BUILD_2', days: 21 }, { type: 'TAPER', days: 14 }],
  [{ type: 'BASE_1', days: 7 }, { type: 'RECOVERY_BASE_1', days: 7 }, { type: 'BASE_2', days: 21 },
    { type: 'RECOVERY_BASE_2', days: 7 }, { type: 'BASE_3', days: 21 }, { type: 'RECOVERY_BASE_3', days: 7 }, { type: 'BUILD_1', days: 21 },
    { type: 'RECOVERY_BUILD_1', days: 7 }, { type: 'BUILD_2', days: 21 }, { type: 'TAPER', days: 14 }],
  [{ type: 'BASE_1', days: 14 }, { type: 'RECOVERY_BASE_1', days: 7 }, { type: 'BASE_2', days: 21 },
    { type: 'RECOVERY_BASE_2', days: 7 }, { type: 'BASE_3', days: 21 }, { type: 'RECOVERY_BASE_3', days: 7 }, { type: 'BUILD_1', days: 21 },
    { type: 'RECOVERY_BUILD_1', days: 7 }, { type: 'BUILD_2', days: 21 }, { type: 'TAPER', days: 14 }],
  [{ type: 'BASE_1', days: 21 }, { type: 'RECOVERY_BASE_1', days: 7 }, { type: 'BASE_2', days: 21 },
    { type: 'RECOVERY_BASE_2', days: 7 }, { type: 'BASE_3', days: 21 }, { type: 'RECOVERY_BASE_3', days: 7 }, { type: 'BUILD_1', days: 21 },
    { type: 'RECOVERY_BUILD_1', days: 7 }, { type: 'BUILD_2', days: 21 }, { type: 'TAPER', days: 14 }],
];

const baseBlockSplits = [
  [{ type: 'RECOVERY_BASE_1', days: 0 }],
  [{ type: 'RECOVERY_BASE_1', days: 7 }],
  [{ type: 'BASE_1', days: 7 }, { type: 'RECOVERY_BASE_1', days: 7 }],
  [{ type: 'BASE_1', days: 14 }, { type: 'RECOVERY_BASE_1', days: 7 }],
  [{ type: 'BASE_1', days: 21 }, { type: 'RECOVERY_BASE_1', days: 7 }],
  [{ type: 'BASE_1', days: 14 }, { type: 'RECOVERY_BASE_1', days: 7 }, { type: 'BASE_2', days: 7 }, { type: 'RECOVERY_BASE_2', days: 7 }],
  [{ type: 'BASE_1', days: 14 }, { type: 'RECOVERY_BASE_1', days: 7 }, { type: 'BASE_2', days: 14 }, { type: 'RECOVERY_BASE_2', days: 7 }],
  [{ type: 'BASE_1', days: 21 }, { type: 'RECOVERY_BASE_1', days: 7 }, { type: 'BASE_2', days: 14 }, { type: 'RECOVERY_BASE_2', days: 7 }],
  [{ type: 'BASE_1', days: 21 }, { type: 'RECOVERY_BASE_1', days: 7 }, { type: 'BASE_2', days: 21 }, { type: 'RECOVERY_BASE_2', days: 7 }],
  [{ type: 'BASE_1', days: 21 }, { type: 'RECOVERY_BASE_1', days: 7 }, { type: 'BASE_2', days: 14 }, { type: 'RECOVERY_BASE_2', days: 7 }, { type: 'BASE_3', days: 7 }, { type: 'RECOVERY_BASE_3', days: 7 }],
  [{ type: 'BASE_1', days: 21 }, { type: 'RECOVERY_BASE_1', days: 7 }, { type: 'BASE_2', days: 14 }, { type: 'RECOVERY_BASE_2', days: 7 }, { type: 'BASE_3', days: 14 }, { type: 'RECOVERY_BASE_3', days: 7 }],
  [{ type: 'BASE_1', days: 21 }, { type: 'RECOVERY_BASE_1', days: 7 }, { type: 'BASE_2', days: 21 }, { type: 'RECOVERY_BASE_2', days: 7 }, { type: 'BASE_3', days: 14 }, { type: 'RECOVERY_BASE_3', days: 7 }],
  [{ type: 'BASE_1', days: 21 }, { type: 'RECOVERY_BASE_1', days: 7 }, { type: 'BASE_2', days: 21 }, { type: 'RECOVERY_BASE_2', days: 7 }, { type: 'BASE_3', days: 21 }, { type: 'RECOVERY_BASE_3', days: 7 }],
];

//Check that each block split got the good amount of day
function checkBlockSplits(split){
  for(let i=0; i<split.length; i++){
    let days = 0;
    for(let block of split[i]){
      days += block.days;
    }
    if(days != i*7){
      console.error('Blocksplit wrong day number, got ', days, ' expected ', i*7);
      throw '';
    }
  }
}

checkBlockSplits(blockSplits);
checkBlockSplits(baseBlockSplits);


//Plan base blocks between two dates (if needed cycle base 1,2,3 -> 1,2,3)
function planBaseBlocksFromTo(startDate, endDate){
  var blocks = [];

  if(startDate >= endDate) //If endDate is after the start date, don't generate anything
    return blocks;

  if(startDate.day() == 0 || startDate.day() >= 5) //if start is friday or after (5,6,0) then start next week
    startDate = startDate.add(1, 'week').startOf('week');

  var startWeek = startDate.startOf('week');
  var nbDays = endDate.diff(startDate, 'day');
  var nbWeeks = endDate.diff(startWeek, 'week');

  if(nbWeeks >= baseBlockSplits.length){ //If the number of weeks between the start and end date is too high (above base block split length)
    var split = dayjs.utc(endDate).subtract(baseBlockSplits.length-2, 'week').startOf('week'); //Split the generation in 2 cycles and generate the training blocks for the 2 cycles
    return planBaseBlocksFromTo(startDate, split).concat(planBaseBlocksFromTo(split, endDate));
  }

  const splits = baseBlockSplits[nbWeeks];
  let start = startWeek;
  for(var i=0; i<splits.length; i++){
    let split = splits[i]
    let days = split.days;
    let end = start.add(days, 'day');

    let blockType = split.type;

    if(start < startDate) //For the first block : if start of the week is before the start date, the first block will start at the start date
      start = startDate;
    blocks.push(new TrainingBlock(blockType, start.toDate(), end.toDate()));
    start = end;
  }

  return blocks;
}


function findSplit(nbWeeks, previousBlock = null){
  if(!previousBlock || nbWeeks === 0)
    return blockSplits[nbWeeks]; //if no previous block defined, just return split for number of week

  for(let blockSplit in blockSplits){ //Find split that match length that can start with previous block for the number of weeks
    if(blockSplit.length > nbWeeks){
      if(blockSplit[blockSplit.length - nbWeeks].type === previousBlock.type){
        return blockSplit.slice(blockSplit.length - nbWeeks + 1);
      }
    }
  }

  //if no split that matches found return split for number of week (with a recovery week if needed)
  if(previousBlock.name === 'RECOVERY')
    return blockSplits[nbWeeks];
  else
    return [{ type: 'RECOVERY_BASE_1', days: 7 }].concat(blockSplits[nbWeeks-1]);
}

//Plan from a start date to the objective date
function planFromTo(startDate, objectiveDate, taper = true, startRestWeek = false, previousBlock = null){
  var blocks = [];

  if(startDate >= objectiveDate) //If objective is after the start date, don't generate anything
    return blocks;


  if(startRestWeek && objectiveDate.diff(startDate, 'week') >= 4){ //Add a rest week if it must start with a rest week and only if the next A objective is far enough
    var endDate = startDate;
    if(startDate.day() == 0 || startDate.day() >= 5) //if start is friday or after then rest week end the week after
      endDate = endDate.startOf('week').add(1, 'week');
    endDate = endDate.endOf('week').add(1, 'day').startOf('day');
    blocks.push(new TrainingBlock('RECOVERY_BUILD_2', startDate.toDate(), endDate.toDate()));
    startDate = endDate;
  }

  if(startDate.day() == 0 || startDate.day() >= 5) //if start is friday or after (5,6,0) then start next week
    startDate = startDate.add(1, 'week').startOf('week');

  var startWeek = startDate.startOf('week');
  var nbDays = objectiveDate.diff(startDate, 'day');
  var nbWeeks = objectiveDate.diff(startWeek, 'week');
  var daysLess = startDate.diff(startWeek, 'day');


  var objectiveDay = (objectiveDate.day()+6)%7; //Monday is 0 and sunday 6
  var taperDaysChange = objectiveDay; //Find number of day to add or remove from the taper so that it finish the same day than the objective
  if(taper)
    taperDaysChange++;
  if(objectiveDay >= 4){ //if objective is friday or after, remove some days and don't do a full week
    nbWeeks++;
    taperDaysChange-= 7;
  }


  if(nbWeeks >= blockSplits.length){ //If the number of weeks between the start date and the objective is too high (above block split length)
    if(previousBlock && previousBlock.name !== 'RECOVERY'){ //Start with a recovery week first if previous block wasn't one.
      let endDate = startDate.endOf('week').add(1, 'day').startOf('day');
      blocks.push(new TrainingBlock('RECOVERY_BASE_1', startDate.toDate(), endDate.toDate()));
      startDate = endDate;
      nbWeeks--;
    }
    var split = dayjs.utc(objectiveDate).subtract(blockSplits.length-2, 'week').startOf('week'); //Split the generation in 2 cycles and generate the training blocks for the 2 cycles
    return blocks.concat(planBaseBlocksFromTo(startDate, split).concat(planFromTo(split, objectiveDate, taper))); //Last cycle is full cycle. Previous cycles are base cycles
  }



  //Generate the training blocks according to the number of week
  const splits = findSplit(nbWeeks, previousBlock);
  let start = startWeek;
  for(var i=0; i<splits.length; i++){
    let split = splits[i]
    let days = split.days;
    let end = start.add(days, 'day');

    let blockType = split.type;
    if(split.type === 'TAPER'){
      if(!taper)
        blockType = 'RECOVERY_BUILD_2';
      end = end.add(taperDaysChange, 'day');
    }
    if(!taper && split.type === 'TAPER')
      blockType = 'RECOVERY_BUILD_2';

    if(start < startDate) //For the first block : if start of the week is before the start date, the first block will start at the start date
      start = startDate;
    blocks.push(new TrainingBlock(blockType, start.toDate(), end.toDate()));
    start = end;
  }

  return blocks;
}


function planWithoutObjectives(user, startDate){
  let blocks = [];
  const splits = blockSplits[blockSplits.length-1];
  let startWeek = startDate.startOf('week');
  let start = startWeek;
  for(var i=0; i<splits.length; i++){
    let split = splits[i]
    let days = split.days;
    let end = start.add(days, 'day');

    let blockType = split.type;

    if(start < startDate) //For the first block : if start of the week is before the start date, the first block will start at the start date
      start = startDate;
    blocks.push(new TrainingBlock(blockType, start.toDate(), end.toDate()));
    start = end;
  }

  return blocks;
}


//Plan the training blocks according to the user training plan data
function planTrainingBlocks(user){
  var objectives = getRelevantObjectives(user.objectives, user.training_plan_data.start_date);
  var aobjectives = getAObjectives(objectives); //Get the A objectives that are planned after the start date

  var blocks = [];
  var startDate = dayjs.utc(user.training_plan_data.start_date);

  if(startDate.day() === 0 || startDate.day() >= 5) //if start is friday or after then start next week instead
    startDate = startDate.startOf('week').add(1, 'week');
  var endDate = startDate.endOf('week').add(1, 'day').startOf('day');

  if(user.training_plan_data.preparation_week){ //Generate a preparation week if it's required by the user
    blocks.push(new TrainingBlock('PREPARATION', user.training_plan_data.start_date, endDate.toDate()));
    startDate = endDate;
    endDate = startDate.endOf('week').add(1, 'day').startOf('day');
  }

  if(user.training_plan_data.testing_week){ //Generate a testing week if it's required by the user
    blocks.push(new TrainingBlock('TESTING', startDate.toDate(), endDate.toDate()));
    startDate = endDate;
  }

  if(aobjectives.length === 0){
    blocks = blocks.concat(planWithoutObjectives(user, startDate));
  }else{

    //Plan the blocks between the start and the first A objectives and then between each next A objectives
    for(var i=0; i<aobjectives.length; i++){
      let objective = aobjectives[i];
      var objectiveDate = dayjs.utc(objective.date);
      blocks = blocks.concat(planFromTo(startDate, objectiveDate, true, i > 0));  //add a rest week after the first a objective

      startDate = dayjs.utc(objective.date).startOf('day').add(1, 'day');
    }
  }


  return blocks;
}

function blocksFromDate(user, date){
  let blocks = [];
  for(let block of user.training_blocks){
    if(block.end > date)
      blocks.push(block);
  }

  return blocks;
}



//Replan training block of user when A Objective date change (or is removed/added)
//Restart planning from last user training block (finish it) and try to continue from there
function replanTrainingBlocks(user){
  var startDate = dayjs.utc(new Date()).startOf('day');//dayjs.utc(user.training_plan_data.start_date);

  var objectives = getRelevantObjectives(user.objectives, startDate);
  var aobjectives = getAObjectives(objectives); //Get the A objectives that are planned after the start date

  console.log(aobjectives);


  var blocks = [];

  if(aobjectives.length === 0){
    return blocksFromDate(user, startDate.toDate());
  }


  let currentBlock = util.findTrainingBlockForDay(user, startDate);

  console.log(currentBlock);

  if(!currentBlock){
    console.log('no block')
    return planTrainingBlocks(user);
  }

  //blocks.push(currentBlock);
  startDate = dayjs.utc(currentBlock.end);

  if(currentBlock.name === 'PREPARATION' && user.training_plan_data.testing_week){ //Generate a testing week if it's required by the user and current block is still preparation
    let endDate = startDate.endOf('week').add(1, 'day').startOf('day');
    blocks.push(new TrainingBlock('TESTING', startDate.toDate(), endDate.toDate()));
    startDate = endDate;
    currentBlock = null; //ignore current block if its a preparation block
  }

  if(currentBlock && currentBlock.name === 'TESTING') //Ignore current block if its a testing block
    currentBlock = null;


  //Plan the blocks between the start and the first A objectives and then between each next A objectives
  for(var i=0; i<aobjectives.length; i++){
    let objective = aobjectives[i];
    var objectiveDate = dayjs.utc(objective.date);
    blocks = blocks.concat(planFromTo(startDate, objectiveDate, true, i > 0, currentBlock));  //add a rest week after the first a objective
    startDate = dayjs.utc(objective.date).startOf('day').add(1, 'day');
    currentBlock = null;
  }


  return blocks;
}

module.exports = {
  planTrainingBlocks,
  replanTrainingBlocks,
};
