/*
 * Training ride Item, similar to workout item, store a planned training ride and simulate data for workouts generation
 */

class TrainingRideItem{
  constructor(trainingRide){
    this.trainingRide = trainingRide;
    this.estimateData();
    this.category = null;
  }

  getIntensity(){ return this.trainingRide.planned_intensity; }

  loadMultiplier(){
    switch(this.trainingRide.planned_intensity){
      case 'easy': return 40;
      case 'endurance': return 60;
      case 'medium': return 70;
      case 'hard': return 80;
      case 'very_hard': return 90;
    }
    return 75;
  }

  estimateData(){ //Try to simulate data
    this.data = {
      load: this.getDuration()/3600 * this.loadMultiplier(), //Load simulation
      time_power_zones: this.estimateZones(),
    };
  }

  estimateZones(){ //Time in zone simulation
    var d = this.getDuration();
    switch(this.trainingRide.planned_intensity){
      case 'easy': return [0.9*d, 0.09*d, 0.01*d, 0, 0, 0, 0];
      case 'endurance': return [0.2*d, 0.68*d, 0.1*d, 0.01*d, 0, 0, 0.01*d];
      case 'medium': return [0.2*d, 0.2*d, 0.5*d, 0.07*d, 0.01*d, 0.01*d, 0.01*d];
      case 'hard': return [0.2*d, 0.2*d, 0.2*d, 0.3*d, 0.05*d, 0.025*d, 0.025*d];
      case 'very_hard': return [0.3*d, 0.1*d, 0.15*d, 0.2*d, 0.1*d, 0.1*d, 0.05*d];
    }
    return [0.2*d, 0.2*d, 0.2*d, 0.3*d, 0.05*d, 0.025*d, 0.025*d];
  }

  setCategory(category){ this.category = category; }
  getCategory(){ return this.category; }

  getData(user){
    return this.data;
  }

  getLoad(user){
    return this.getData(user).load;
  }

  getName(){ return this.trainingRide.name; }
  getDuration(){ return this.trainingRide.estimated_duration || 3600; }

  categoryName(){
    return this.category?.name || 'training_ride';
  }

  toWorkout(user, date){
    return null;
  }

  isRide(){ return true; }

  isWorkout(){ return false; }
  isObjective(){ return false; }
  isTrainingRide(){ return true; }
  isActivity(){ return false; }
  isRest(){ return false; }

  isSet(){ return true; }
}

module.exports = TrainingRideItem;
