const WorkoutCategory = require('../workoutCategory');
const WorkoutItem = require('../workoutItem');
const rules = require('../rules/rules');
const workouts = require('../workoutslib/workoutsLib');







module.exports = {
  categories: [ workouts.recovery, workouts.light, workouts.enduranceF, workouts.enduranceActive, workouts.enduranceSprint

  ],

  rules: [

    //Reprise 1

    rules.weekHoursTargetRule('TARGET', 75, '%', 'VERYHIGH', 5),

    rules.frequencyRule('enduranceF', 'MIN', 1, 'REQUIRED'),
    rules.frequencyRule('enduranceF', 'MIN', 2, 'MEDIUM'),
    rules.frequencyRule('enduranceActive', 'MIN', 1, 'REQUIRED'),
    rules.frequencyRule('enduranceSprint', 'MIN', 1, 'HIGH'),
    rules.frequencyRule('enduranceSprint', 'MAX', 1, 'REQUIRED'),
    rules.frequencyRule('recovery', 'MIN', 1, 'MEDIUM'),
    rules.frequencyRule(['enduranceF', 'enduranceActive', 'enduranceSprint'], 'MIN', 2, 'HIGH'),
    rules.frequencyRule(['enduranceF', 'enduranceActive', 'enduranceSprint'], 'MAX', 5, 'HIGH'),

    rules.placementRule('enduranceF', 'AFTER', ['REST', 'recovery',], 'HIGH'),
    rules.placementRule('enduranceActive', 'AFTER', 'enduranceF', 'MEDIUM'),
    rules.placementRule('enduranceSprint', 'AFTER', 'enduranceF', 'MEDIUM'),
    rules.placementRule('recovery', 'AFTER', ['enduranceActive', 'enduranceSprint'], 'HIGH'),

    rules.orderRule('enduranceF', 'BEFORE', ['recovery', 'enduranceActive', 'enduranceSprint'], 'REQUIRED'),

    rules.longestWorkoutDurationTargetRule('TARGET', 0.5, 18, '%', 'HIGH'),

    rules.weekZoneTargetRule(['z1', 'z2','z3'], 'TARGET', 80, '%', 'HIGH'),
    rules.weekZoneTargetRule('z4', 'TARGET', 10, '%', 'LOW'),
    rules.weekZoneTargetRule(['z5','z6', 'z7'], 'TARGET', 10, '%', 'LOW'),

    rules.trainingRideCategory(['medium', 'hard', 'very_hard'], 'enduranceSprint', 'REQUIRED'),
    rules.trainingRideCategory('endurance', ['enduranceF', 'enduranceActive'], 'REQUIRED'),
    rules.trainingRideCategory('easy', ['recovery', 'enduranceF', 'enduranceActive'], 'REQUIRED'),
    rules.trainingRideCategory('easy', 'recovery', 'VERYHIGH'),

  ],
};
