const WorkoutCategory = require('../workoutCategory');
const WorkoutItem = require('../workoutItem');

const warmup15 = '2:00@55%ftp, 2:00@60%ftp, 2:00@65%ftp, 2:00@70%ftp, 2:00@75%ftp, 3*(00:15@105%map, 00:45@55%ftp), 2:00@55%ftp,';
const warmup20 = '2:00@55%ftp, 2:00@60%ftp, 3:00@65%ftp, 3:00@70%ftp, 5:00@75%ftp, 3*(00:15@105%map, 00:45@55%ftp), 2:00@55%ftp,';
const warmup25 = '2:00@55%ftp, 3:00@60%ftp, 5:00@65%ftp, 5:00@70%ftp, 5:00@75%ftp, 3*(00:15@105%map, 00:45@55%ftp), 2:00@55%ftp,';
//const warmup30 = '2:00@55%ftp, 3:00@60%ftp, 10:00@65%ftp, 5:00@70%ftp, 5:00@75%ftp, 3*(00:15@105%map, 00:45@55%ftp), 2:00@55%ftp,';
const warmup35 = '2:00@55%ftp, 3:00@60%ftp, 10:00@65%ftp, 10:00@70%ftp, 5:00@75%ftp, 3*(00:15@105%map, 00:45@55%ftp), 2:00@55%ftp,';
const warmup40 = '2:00@55%ftp, 3:00@60%ftp, 10:00@65%ftp, 10:00@70%ftp, 10:00@75%ftp, 3*(00:15@105%map, 00:45@55%ftp), 2:00@55%ftp,';
//const warmup45 = '2:00@55%ftp, 3:00@60%ftp, 10:00@65%ftp, 15:00@70%ftp, 10:00@75%ftp, 3*(00:15@105%map, 00:45@55%ftp), 2:00@55%ftp,';
const warmup50 = '2:00@55%ftp, 8:00@60%ftp, 10:00@65%ftp, 15:00@70%ftp, 10:00@75%ftp, 3*(00:15@105%map, 00:45@55%ftp), 2:00@55%ftp,';
const warmup55 = '2:00@55%ftp, 8:00@60%ftp, 15:00@65%ftp, 15:00@70%ftp, 10:00@75%ftp, 3*(00:15@105%map, 00:45@55%ftp), 2:00@55%ftp,';
//const warmup60 = '5:00@55%ftp, 10:00@60%ftp, 15:00@65%ftp, 15:00@70%ftp, 10:00@75%ftp, 3*(00:15@105%map, 00:45@55%ftp), 2:00@55%ftp,';
const warmup65 = '5:00@55%ftp, 15:00@60%ftp, 15:00@65%ftp, 15:00@70%ftp, 10:00@75%ftp, 3*(00:15@105%map, 00:45@55%ftp), 2:00@55%ftp,';
const warmup70 = '5:00@55%ftp, 15:00@60%ftp, 20:00@65%ftp, 15:00@70%ftp, 10:00@75%ftp, 3*(00:15@105%map, 00:45@55%ftp), 2:00@55%ftp,';
//const warmup75 = '5:00@55%ftp, 15:00@60%ftp, 20:00@65%ftp, 18:00@70%ftp, 10:00@75%ftp, 3*(00:15@105%map, 01:15@55%ftp), 2:30@55%ftp,';
const warmup80 = '5:00@55%ftp, 20:00@60%ftp, 20:00@65%ftp, 18:00@70%ftp, 10:00@75%ftp, 3*(00:15@105%map, 01:15@55%ftp), 2:30@55%ftp,';
const warmup85 = '5:00@55%ftp, 20:00@60%ftp, 25:00@65%ftp, 18:00@70%ftp, 10:00@75%ftp, 3*(00:15@105%map, 01:15@55%ftp), 2:30@55%ftp,';
//const warmup90 = '5:00@55%ftp, 20:00@60%ftp, 20:00@65%ftp, 23:00@70%ftp, 15:00@75%ftp, 3*(00:15@105%map, 01:15@55%ftp), 2:30@55%ftp,';
const warmup95 = '5:00@55%ftp, 20:00@60%ftp, 20:00@65%ftp, 28:00@70%ftp, 15:00@75%ftp, 3*(00:15@105%map, 01:15@55%ftp), 2:30@55%ftp,';

//2 types of warmups duration list possible
const warmupsDurationsTypes = [
  [20*60,35*60,50*60,65*60,80*60,95*60],
  [25*60, 40*60, 55*60, 70*60, 85*60],
];

function vo2maxDescriptions(warmupsType, duration, target, intervals, recovery, cooldown){
  let warmupsDuration = warmupsDurationsTypes[warmupsType]; //choose list of warmup duration

  return (index) => [
    { zone: 2, duration: warmupsDuration[index], target: '55%ftp-75%ftp', description: 'progressive_warmup_HIT' },
    { zone: 6, duration: duration, target: target, description: 'vo2max', format: { intervals: intervals, recovery: recovery }},
    { zone: 1, duration: cooldown, target: '60%ftp', description: 'cooldown' },
  ];
}

var vo2max = new WorkoutCategory(
  'vo2max',
  [

  //Niveau 1

  //1h
  ...WorkoutItem.createWithWarmups([warmup20, warmup35, warmup50, warmup65, warmup80],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '4 * 3min'} },
    '4*(03:00@90%map, 02:00@55%ftp, 04:00@60%ftp), 4:00@60%ftp', 1, vo2maxDescriptions(0, 36*60, '90%map', '4*3min', '6min', 4*60)),
  ...WorkoutItem.createWithWarmups([warmup20, warmup35, warmup50, warmup65, warmup80, warmup95],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '5 * 2min'} }, '5*(02:00@90%map, 01:00@55%ftp, 03:00@60%ftp), 5:00@60%ftp,  5:00@55%ftp',1),
  ...WorkoutItem.createWithWarmups([warmup20, warmup35, warmup50, warmup65, warmup80, warmup95],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '3 * 4min'} }, '3*(04:00@90%map, 03:00@55%ftp, 03:00@60%ftp), 6:00@60%ftp, 4:00@55%ftp',1),
  ...WorkoutItem.createWithWarmups([warmup25, warmup40, warmup55, warmup70, warmup85],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '10 * 1min'} }, '10*(01:00@95%map, 02:00@55%ftp), 5:00@60%ftp',1),
  ...WorkoutItem.createWithWarmups([warmup25, warmup40, warmup55, warmup70, warmup85],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '7 * 1min30'} }, '7*(01:30@95%map, 03:00@55%ftp), 3:30@60%ftp',1),


  //Niveau 1.5

  //1h
  ...WorkoutItem.createWithWarmups([warmup20, warmup35, warmup50, warmup65, warmup80, warmup95],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '4 * 3min'} }, '4*(03:00@92%map, 02:00@55%ftp, 04:00@60%ftp), 4:00@60%ftp',1.5),
  ...WorkoutItem.createWithWarmups([warmup20, warmup35, warmup50, warmup65, warmup80, warmup95],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '5 * 2min'} }, '5*(02:00@92%map, 01:00@55%ftp, 03:00@60%ftp), 5:00@60%ftp,  5:00@55%ftp',1.5),
  ...WorkoutItem.createWithWarmups([warmup20, warmup35, warmup50, warmup65, warmup80, warmup95],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '3 * 4min'} }, '3*(04:00@92%map, 03:00@55%ftp, 03:00@60%ftp), 6:00@60%ftp, 4:00@55%ftp',1.5),
  ...WorkoutItem.createWithWarmups([warmup25, warmup40, warmup55, warmup70, warmup85],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '10 * 1min'} }, '10*(01:00@97%map, 02:00@55%ftp), 5:00@60%ftp',1.5),
  ...WorkoutItem.createWithWarmups([warmup25, warmup40, warmup55, warmup70, warmup85],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '7 * 1min30'} }, '7*(01:30@97%map, 03:00@55%ftp), 3:30@60%ftp',1.5),


  //Niveau 2

  ...WorkoutItem.createWithWarmups([warmup20, warmup35, warmup50, warmup65, warmup80, warmup95],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '4 * 3min'} }, '4*(03:00@94%map, 02:00@55%ftp, 04:00@60%ftp), 4:00@60%ftp',2),
  ...WorkoutItem.createWithWarmups([warmup20, warmup35, warmup50, warmup65, warmup80, warmup95],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '5 * 2min'} }, '5*(02:00@94%map, 01:00@55%ftp, 03:00@60%ftp), 5:00@60%ftp,  5:00@55%ftp',2),
  ...WorkoutItem.createWithWarmups([warmup20, warmup35, warmup50, warmup65, warmup80, warmup95],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '3 * 4min'} }, '3*(04:00@94%map, 03:00@55%ftp, 03:00@60%ftp), 6:00@60%ftp, 4:00@55%ftp',2),
  ...WorkoutItem.createWithWarmups([warmup25, warmup40, warmup55, warmup70, warmup85],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '10 * 1min'} }, '10*(01:00@99%map, 02:00@55%ftp), 5:00@60%ftp',2),
  ...WorkoutItem.createWithWarmups([warmup25, warmup40, warmup55, warmup70, warmup85],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '7 * 1min30'} }, '7*(01:30@99%map, 03:00@55%ftp), 3:30@60%ftp',2),


  //Niveau 2.5

  ...WorkoutItem.createWithWarmups([warmup20, warmup35, warmup50, warmup65, warmup80, warmup95],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '4 * 3min'} }, '4*(03:00@96%map, 02:00@55%ftp, 04:00@60%ftp), 4:00@60%ftp',2.5),
  ...WorkoutItem.createWithWarmups([warmup20, warmup35, warmup50, warmup65, warmup80, warmup95],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '5 * 2min'} }, '5*(02:00@96%map, 01:00@55%ftp, 03:00@60%ftp), 5:00@60%ftp,  5:00@55%ftp',2.5),
  ...WorkoutItem.createWithWarmups([warmup20, warmup35, warmup50, warmup65, warmup80, warmup95],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '3 * 4min'} }, '3*(04:00@96%map, 03:00@55%ftp, 03:00@60%ftp), 6:00@60%ftp, 4:00@55%ftp',2.5),
  ...WorkoutItem.createWithWarmups([warmup25, warmup40, warmup55, warmup70, warmup85],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '10 * 1min'} }, '10*(01:00@101%map, 02:00@55%ftp), 5:00@60%ftp',2.5),
  ...WorkoutItem.createWithWarmups([warmup25, warmup40, warmup55, warmup70, warmup85],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '7 * 1min30'} }, '7*(01:30@101%map, 03:00@55%ftp), 3:30@60%ftp',2.5),

  //Niveau 3

  ...WorkoutItem.createWithWarmups([warmup20, warmup35, warmup50, warmup65, warmup80, warmup95],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '4 * 3min'} }, '4*(03:00@98%map, 02:00@55%ftp, 04:00@60%ftp), 4:00@60%ftp',3),
  ...WorkoutItem.createWithWarmups([warmup20, warmup35, warmup50, warmup65, warmup80, warmup95],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '5 * 2min'} }, '5*(02:00@98%map, 01:00@55%ftp, 03:00@60%ftp), 5:00@60%ftp,  5:00@55%ftp',3),
  ...WorkoutItem.createWithWarmups([warmup20, warmup35, warmup50, warmup65, warmup80, warmup95],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '3 * 4min'} }, '3*(04:00@98%map, 03:00@55%ftp, 03:00@60%ftp), 6:00@60%ftp, 4:00@55%ftp',3),
  ...WorkoutItem.createWithWarmups([warmup25, warmup40, warmup55, warmup70, warmup85],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '10 * 1min'} }, '10*(01:00@103%map, 02:00@55%ftp), 5:00@60%ftp',3),
  ...WorkoutItem.createWithWarmups([warmup25, warmup40, warmup55, warmup70, warmup85],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '7 * 1min30'} }, '7*(01:30@103%map, 03:00@55%ftp), 4:00@60%ftp',3),


  //Niveau 3.5

  ...WorkoutItem.createWithWarmups([warmup20, warmup35, warmup50, warmup65, warmup80, warmup95],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '4 * 3min'} }, '4*(03:00@100%map, 02:00@55%ftp, 04:00@60%ftp), 4:00@60%ftp',3.5),
  ...WorkoutItem.createWithWarmups([warmup20, warmup35, warmup50, warmup65, warmup80, warmup95],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '5 * 2min'} }, '5*(02:00@100%map, 01:00@55%ftp, 03:00@60%ftp), 5:00@60%ftp,  5:00@55%ftp',3.5),
  ...WorkoutItem.createWithWarmups([warmup20, warmup35, warmup50, warmup65, warmup80, warmup95],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '3 * 4min'} }, '3*(04:00@100%map, 03:00@55%ftp, 03:00@60%ftp), 6:00@60%ftp, 4:00@55%ftp',3.5),
  ...WorkoutItem.createWithWarmups([warmup25, warmup40, warmup55, warmup70, warmup85],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '10 * 1min'} }, '10*(01:00@105%map, 02:00@55%ftp), 5:00@60%ftp',3.5),
  ...WorkoutItem.createWithWarmups([warmup25, warmup40, warmup55, warmup70, warmup85],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '7 * 1min30'} }, '7*(01:30@105%map, 03:00@55%ftp), 3:30@60%ftp',3.5),


  //Niveau 4

  ...WorkoutItem.createWithWarmups([warmup20, warmup35, warmup50, warmup65, warmup80, warmup95],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '4 * 3min'} }, '4*(03:00@102%map, 02:00@55%ftp, 04:00@60%ftp), 4:00@60%ftp',4),
  ...WorkoutItem.createWithWarmups([warmup20, warmup35, warmup50, warmup65, warmup80, warmup95],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '5 * 2min'} }, '5*(02:00@102%map, 01:00@55%ftp, 03:00@60%ftp), 5:00@60%ftp,  5:00@55%ftp',4),
  ...WorkoutItem.createWithWarmups([warmup20, warmup35, warmup50, warmup65, warmup80, warmup95],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '3 * 4min'} }, '3*(04:00@102%map, 03:00@55%ftp, 03:00@60%ftp), 6:00@60%ftp, 4:00@55%ftp',4),
  ...WorkoutItem.createWithWarmups([warmup25, warmup40, warmup55, warmup70, warmup85],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '10 * 1min'} }, '10*(01:00@107%map, 02:00@55%ftp), 5:00@60%ftp',4),
  ...WorkoutItem.createWithWarmups([warmup25, warmup40, warmup55, warmup70, warmup85],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '7 * 1min30'} }, '7*(01:30@107%map, 03:00@55%ftp), 3:30@60%ftp',4),

  //Niveau 4.5

  ...WorkoutItem.createWithWarmups([warmup20, warmup35, warmup50, warmup65, warmup80, warmup95],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '4 * 3min'} }, '4*(03:00@103%map, 02:00@55%ftp, 04:00@60%ftp), 4:00@60%ftp',4.5),
  ...WorkoutItem.createWithWarmups([warmup20, warmup35, warmup50, warmup65, warmup80, warmup95],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '5 * 2min'} }, '5*(02:00@103%map, 01:00@55%ftp, 03:00@60%ftp), 5:00@60%ftp,  5:00@55%ftp',4.5),
  ...WorkoutItem.createWithWarmups([warmup20, warmup35, warmup50, warmup65, warmup80, warmup95],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '3 * 4min'} }, '3*(04:00@103%map, 03:00@55%ftp, 03:00@60%ftp), 6:00@60%ftp, 4:00@55%ftp',4.5),
  ...WorkoutItem.createWithWarmups([warmup25, warmup40, warmup55, warmup70, warmup85],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '10 * 1min'} }, '10*(01:00@110%map, 02:00@55%ftp), 5:00@60%ftp',4.5),
  ...WorkoutItem.createWithWarmups([warmup25, warmup40, warmup55, warmup70, warmup85],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '7 * 1min30'} }, warmup25 + '7*(01:30@110%map, 03:00@55%ftp), 3:30@60%ftp',4.5),

  //Niveau 5

  ...WorkoutItem.createWithWarmups([warmup20, warmup35, warmup50, warmup65, warmup80, warmup95],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '4 * 3min'} }, '4*(03:00@104%map, 02:00@55%ftp, 04:00@60%ftp), 4:00@60%ftp',5),
  ...WorkoutItem.createWithWarmups([warmup20, warmup35, warmup50, warmup65, warmup80, warmup95],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '5 * 2min'} }, '5*(02:00@104%map, 01:00@55%ftp, 03:00@60%ftp), 5:00@60%ftp,  5:00@55%ftp',5),
  ...WorkoutItem.createWithWarmups([warmup20, warmup35, warmup50, warmup65, warmup80, warmup95],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '3 * 4min'} }, '3*(04:00@104%map, 03:00@55%ftp, 03:00@60%ftp), 6:00@60%ftp, 4:00@55%ftp',5),
  ...WorkoutItem.createWithWarmups([warmup25, warmup40, warmup55, warmup70, warmup85],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '10 * 1min'} }, '10*(01:00@112%map, 02:00@55%ftp), 5:00@60%ftp',5),
  ...WorkoutItem.createWithWarmups([warmup25, warmup40, warmup55, warmup70, warmup85],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '7 * 1min30'} }, warmup25 + '7*(01:30@112%map, 03:00@55%ftp), 3:30@60%ftp',5),

  //Niveau 5.5

  ...WorkoutItem.createWithWarmups([warmup20, warmup35, warmup50, warmup65, warmup80, warmup95],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '4 * 3min'} }, '4*(03:00@105%map, 02:00@55%ftp, 04:00@60%ftp), 4:00@60%ftp',5.5),
  ...WorkoutItem.createWithWarmups([warmup20, warmup35, warmup50, warmup65, warmup80, warmup95],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '5 * 2min'} }, '5*(02:00@105%map, 01:00@55%ftp, 03:00@60%ftp), 5:00@60%ftp,  5:00@55%ftp',5.5),
  ...WorkoutItem.createWithWarmups([warmup20, warmup35, warmup50, warmup65, warmup80, warmup95],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '3 * 4min'} }, '3*(04:00@105%map, 03:00@55%ftp, 03:00@60%ftp), 6:00@60%ftp, 4:00@55%ftp',5.5),
  ...WorkoutItem.createWithWarmups([warmup25, warmup40, warmup55, warmup70, warmup85],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '10 * 1min'} }, '10*(01:00@115%map, 02:00@55%ftp), 5:00@60%ftp',5.5),
  ...WorkoutItem.createWithWarmups([warmup25, warmup40, warmup55, warmup70, warmup85],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '7 * 1min30'} }, '7*(01:30@115%map, 03:00@55%ftp), 3:30@60%ftp',5.5),

  //Niveau 6

  ...WorkoutItem.createWithWarmups([warmup20, warmup35, warmup50, warmup65, warmup80, warmup95],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '4 * 3min'} }, '4*(03:00@106%map, 02:00@55%ftp, 04:00@60%ftp), 4:00@60%ftp',6),
  ...WorkoutItem.createWithWarmups([warmup20, warmup35, warmup50, warmup65, warmup80, warmup95],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '5 * 2min'} }, '5*(02:00@106%map, 01:00@55%ftp, 03:00@60%ftp), 5:00@60%ftp,  5:00@55%ftp',6),
  ...WorkoutItem.createWithWarmups([warmup20, warmup35, warmup50, warmup65, warmup80, warmup95],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '3 * 4min'} }, '3*(04:00@106%map, 03:00@55%ftp, 03:00@60%ftp), 6:00@60%ftp, 4:00@55%ftp',6),
  ...WorkoutItem.createWithWarmups([warmup25, warmup40, warmup55, warmup70, warmup85],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '10 * 1min'} },  '10*(01:00@120%map, 02:00@55%ftp), 5:00@60%ftp',6),
  ...WorkoutItem.createWithWarmups([warmup25, warmup40, warmup55, warmup70, warmup85],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '7 * 1min30'} }, '7*(01:30@120%map, 03:00@55%ftp), 3:30@60%ftp',6),


  //Level 6.5

  ...WorkoutItem.createWithWarmups([warmup20, warmup35, warmup50, warmup65, warmup80, warmup95],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '4 * 3min'} }, '4*(03:00@107%map, 02:00@55%ftp, 04:00@60%ftp), 4:00@60%ftp',6.5),
  ...WorkoutItem.createWithWarmups([warmup20, warmup35, warmup50, warmup65, warmup80, warmup95],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '5 * 2min'} }, '5*(02:00@107%map, 01:00@55%ftp, 03:00@60%ftp), 5:00@60%ftp,  5:00@55%ftp',6.5),
  ...WorkoutItem.createWithWarmups([warmup20, warmup35, warmup50, warmup65, warmup80, warmup95],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '3 * 4min'} }, '3*(04:00@107%map, 03:00@55%ftp, 03:00@60%ftp), 6:00@60%ftp, 4:00@55%ftp',6.5),
  ...WorkoutItem.createWithWarmups([warmup25, warmup40, warmup55, warmup70, warmup85],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '10 * 1min'} },  '10*(01:00@125%map, 02:00@55%ftp), 5:00@60%ftp',6.5),
  ...WorkoutItem.createWithWarmups([warmup25, warmup40, warmup55, warmup70, warmup85],
    'VO2max', 'vo2max', { description: 'vo2max', format: {intervals: '7 * 1min30'} }, '7*(01:30@125%map, 03:00@55%ftp), 3:30@60%ftp',6.5),

  ],
);

module.exports = {
  vo2max
};
