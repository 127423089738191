<script>
import DayChart from '@/helpers/DayChart.vue';

export default{
  name: 'StatsChart',
  extends: DayChart,
  props: {
    entries2: Array,
  },
  methods: {
    initialize(){
      DayChart.methods.initialize.call(this); //call super initialize
      this.data = [new Array(this.entries.length)];
      for(let i=0; i<this.entries.length; i++){
        let date = new Date(this.startDate.getTime()+this.ONEDAY*i);
        this.data[0][i] = { data: this.entries[i], date: date};
      }

      if(this.entries2 && this.entries2.length){
        this.data.push(new Array(this.entries2.length));
        for(let i=0; i<this.entries2.length; i++){
          let date = new Date(this.startDate.getTime()+this.ONEDAY*i);
          this.data[1][i] = { data: this.entries2[i], date: date};
        }
      }
    },

    createChart(){
      DayChart.methods.createChart.call(this);
      this.createChartElements();
    },

    createChartElements(){
      //Value to display
      this.textValue = this.svg.append("text")
        .attr("class", "text-value")
        .attr("fill", "#000")
        .attr("x", -this.margin.left/3)
        .attr("y", this.height/2)
        .attr("dy", "0.5em")
        .style("text-anchor", "middle")
        .text("0");
    },

    mouseMoveCall(event, mouse_x, mouse_y){
      if(this.data[0][this.indexOfPosition(mouse_x)])
        this.textValue.text(this.data[0][this.indexOfPosition(mouse_x)].data);
    },
  },
};
</script>
<style lang="scss" scoped>
.linechart{
  height: 100px;
}
</style>
