/*
 * I18n emulation used server side
 */

const english = require('./english/english.js');
const french = require('./french/french.js');


//Format prototype, replace variables inside string
String.prototype.format = String.prototype.format || function () {
  var str = this.toString();
  if(arguments.length){
    var t = typeof arguments[0];
    var key;
    var args = ("string" === t || "number" === t) ? Array.prototype.slice.call(arguments) : arguments[0];

    for (key in args){
      str = str.replace(new RegExp("\\{" + key + "\\}", "gi"), args[key]);
    }
  }

  return str;
};

const translations = {
  en: english,
  fr: french
};

function getTranslation(language){
  if(language === 'fr')
    return french;
  return english;
}

//Return text for tokens of language
function $t(language, token, format = {}){
  if(!token || token == '')
    return '';

  const translation = getTranslation(language);
  var path = token.split('.');
  var result = translation;
  for(var t of path){
    if(result)
      result = result[t];
  }


  if(result){ //Translation found return it
    if(typeof result === 'string')
      return result.format(format);
    return result;
  }

  if(translation != english) //No translation found, try to find an english one
    return $t('en', token);

  return token; //If it fail return the token
}

module.exports = $t;
