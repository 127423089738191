const WorkoutCategory = require('../workoutCategory');
const WorkoutItem = require('../workoutItem');
const rules = require('../rules/rules');
const workouts = require('../workoutslib/workoutsLib');

module.exports = {
  categories: [ workouts.opener ],
  rules: [
    rules.ifBaseAccountType(rules.maxTrainingDaysRule(3), 'REQUIRED', 100),

    rules.frequencyRule('REST', 'MIN', 1, 'REQUIRED'),

    rules.placementRule('opener', 'BEFORE', ['objectiveA', 'objectiveB', 'objectiveC'], 'REQUIRED'),
    rules.placementRule(['objectiveA', 'objectiveB'], 'AFTER', 'opener', 'REQUIRED'),

    rules.placementRule('objectiveC', 'AFTER', ['opener', 'REST', 'recovery', 'light'], 'HIGH'),
    rules.placementRule('objectiveC', 'AFTER', ['endurance', 'enduranceF', 'enduranceActive', 'enduranceSprint'], 'MEDIUM'),

    rules.placementRule(['objectiveA', 'objectiveB', 'objectiveC'], 'BEFORE', ['REST', 'recovery', 'light', 'endurance', 'enduranceF', 'enduranceActive'], 'VERYHIGH'),
    rules.placementRule(['objectiveA', 'objectiveB', 'objectiveC'], 'BEFORE', ['vo2max', 'threshold', 'thresholdForce', 'PMA', 'sprints', 'lactic', 'maxStrength', 'sst'], 'FORBIDDEN'),
    rules.placementRule(['vo2max', 'threshold', 'thresholdForce', 'PMA', 'sprints', 'lactic', 'maxStrength', 'sst'], 'AFTER', ['objectiveA', 'objectiveB', 'objectiveC'], 'FORBIDDEN'),

    rules.placementRule(['objectiveA', 'objectiveB', 'objectiveC'], 'BEFORE', ['tempo', 'tempoForce'], '!VERYHIGH'),
    rules.placementRule(['tempo', 'tempoForce'], 'AFTER', ['objectiveA', 'objectiveB', 'objectiveC'], '!VERYHIGH'),

    rules.placementRule('opener', 'AFTER', ['REST', 'recovery', 'light', 'endurance', 'enduranceF', 'enduranceActive', 'enduranceSprint'], 'HIGH'),

    rules.ruleForWorkout('BEFORE', 'opener', rules.forWorkoutDuration('<=', 2), 'HIGH'),
    rules.ruleForWorkout('BEFORE', 'opener', rules.forWorkoutDuration('<=', 1), 'MEDIUM'),

    rules.placementRule('REST', 'AFTER', 'REST', '!HIGH'),


    rules.placementDayTypePreference('REST', 'indoor', 'HIGH'),
    rules.placementDayTypePreference(['enduranceForce', 'tempo', 'tempoForce', 'threshold', 'thresholdForce'], 'indoor', 'HIGH'),
    rules.placementDayTypePreference(['PMA', 'vo2max'], 'indoor', 'VERYHIGH'),
    rules.placementDayType(['enduranceSprint', 'sprints', 'maxStrength'], 'outdoor', 'VERYHIGH', 2),
    rules.placementDayType('lactic', 'outdoor', 'VERYHIGH'),
    rules.placementDayType('enduranceF', 'outdoor', 'VERYHIGH'),
    rules.placementDayTypePreference(['enduranceActive'], 'outdoor', 'HIGH'),


    //SST home trainer rules
    rules.placementDayType('sst', 'outdoor', 'FORBIDDEN'),
    rules.placementDayTypePreference('sst', 'indoor', 'MEDIUM'),

    rules.workoutMaxIndoorDuration(1.5, 'REQUIRED', 10),

    rules.placementRule('sst', 'BEFORE', ['vo2max', 'threshold', 'thresholdForce', 'PMA', 'sprints', 'lactic', 'maxStrength', 'objectiveA', 'objectiveB', 'objectiveC'], '!VERYHIGH'),
    rules.placementRule('sst', 'BEFORE', ['tempo', 'tempoForce', 'sst'], '!HIGH'),
    rules.placementRule('sst', 'AFTER', ['vo2max', 'threshold', 'thresholdForce', 'PMA', 'sprints', 'lactic', 'maxStrength', 'objectiveA', 'objectiveB', 'objectiveC'], '!VERYHIGH'),
    rules.placementRule('sst', 'AFTER', ['tempo', 'tempoForce', 'sst'], '!HIGH'),

    rules.placementRule('sst', 'BEFORE', ['REST', 'recovery', 'light', 'enduranceF', 'enduranceActive'], 'HIGH'),
    rules.placementRule('sst', 'AFTER', ['REST', 'recovery', 'light', 'enduranceF', 'enduranceActive'], 'MEDIUM'),

    rules.frequencyRule('sst', 'MAX', 3, 'REQUIRED'),
    rules.frequencyRule('sst', 'MAX', 2, 'VERYHIGH'),
    rules.frequencyRule('sst', 'MAX', 1, 'HIGH'),
    rules.frequencyRule('sst', 'MAX', 0, 'MEDIUM'),

    rules.categoryLevelRule('sst', 0, 'REQUIRED'),


  ],
};
