const WorkoutCategory = require('../workoutCategory');
const WorkoutItem = require('../workoutItem');


function recoveryDescriptions(duration){
  return [
    { zone: 1, duration: duration, target: '50%ftp', description: 'recovery_workout'},
  ];
}

var recovery = new WorkoutCategory(
  'recovery',
  [
    WorkoutItem.create({ name: 'recovery', format: ['30min'] }, 'recovery', 'recovery', '5:00@45%ftp, 20:00@50%ftp,  5:00@45%ftp', 0, recoveryDescriptions(30*60)),
    WorkoutItem.create({ name: 'recovery', format: ['45min'] }, 'recovery', 'recovery', '5:00@45%ftp, 35:00@50%ftp,  5:00@45%ftp', 0, recoveryDescriptions(45*60)),
    WorkoutItem.create({ name: 'recovery', format: ['1h'] }, 'recovery', 'recovery', '5:00@45%ftp, 50:00@50%ftp,  5:00@45%ftp', 0, recoveryDescriptions(60*60)),
    WorkoutItem.create({ name: 'recovery', format: ['1h15'] }, 'recovery', 'recovery', '5:00@45%ftp, 65:00@50%ftp, 5:00@45%ftp', 0, recoveryDescriptions(75*60)),
    WorkoutItem.create({ name: 'recovery', format: ['1h30'] }, 'recovery', 'recovery', '5:00@45%ftp, 30:00@50%ftp, 20:00@55%ftp, 30:00@50%ftp, 5:00@45%ftp', 0, recoveryDescriptions(90*60)),
    WorkoutItem.create({ name: 'recovery', format: ['1h45'] }, 'recovery', 'recovery', '5:00@45%ftp, 45:00@50%ftp, 20:00@55%ftp, 30:00@50%ftp, 5:00@45%ftp', 0, recoveryDescriptions(105*60)),
    WorkoutItem.create({ name: 'recovery', format: ['2h'] }, 'recovery', 'recovery', '5:00@45%ftp, 45:00@50%ftp, 20:00@55%ftp, 45:00@50%ftp, 5:00@45%ftp', 0, recoveryDescriptions(120*60)),
  ],
);

function lightDescriptions(duration){
  return [
    { zone: 2, duration: duration, target: '50-60%ftp', description: 'light_endurance'},
  ];
}

var light = new WorkoutCategory(
  'light',
  [
    WorkoutItem.create({ name: 'light', format: ['30min'] }, 'recovery', 'light', '5:00@50%ftp, 10:00@55%ftp, 10:00@65%ftp, 5:00@50%ftp', 0, lightDescriptions(30*60)),
    WorkoutItem.create({ name: 'light', format: ['45min'] }, 'recovery', 'light', '5:00@55%ftp, 10:00@60%ftp, 25:00@65%ftp, 5:00@50%ftp', 0, lightDescriptions(45*60)),
    WorkoutItem.create({ name: 'light', format: ['1h00'] }, 'recovery', 'light', '5:00@55%ftp, 10:00@60%ftp, 40:00@65%ftp, 5:00@50%ftp', 0, lightDescriptions(60*60)),
    WorkoutItem.create({ name: 'light', format: ['1h15'] }, 'recovery', 'light', '5:00@55%ftp, 15:00@60%ftp, 50:00@65%ftp, 5:00@50%ftp', 0, lightDescriptions(75*60)),
    WorkoutItem.create({ name: 'light', format: ['1h30'] }, 'recovery', 'light', '5:00@55%ftp, 20:00@60%ftp, 60:00@65%ftp, 5:00@50%ftp', 0, lightDescriptions(90*60)),
    WorkoutItem.create({ name: 'light', format: ['1h45'] }, 'recovery', 'light', '5:00@50%ftp, 20:00@55%ftp, 1:15:00@60%ftp, 5:00@50%ftp', 0, lightDescriptions(105*60)),
    WorkoutItem.create({ name: 'light', format: ['2h00'] }, 'recovery', 'light', '15:00@50%ftp, 1:35:00@60%ftp, 10:00@50%ftp', 0, lightDescriptions(120*60)),
  ],
);

module.exports = {
  recovery,
  light
};
