/*
 * Rules to target something
 */
const Rule = require('./rule');
const plannerUtil = require('../plannerUtil');

//General Target rule class
//rule can be min, max, equal, or target (new rule type)
//target will give a percentage of point depending on how close the target is matched
//target unit can either be a percentage or a multiplier of the target  (100 % or 1* will target the same thing)
class TargetRule extends Rule{
  constructor(rule, target, targetUnit, priority = 'REQUIRED', multiplier = 1){
    super(priority, multiplier);
    this.rule = rule;
    this.target = target;
    this.targetUnit = targetUnit;

    if(!['MIN', 'MAX', 'EQUAL', 'TARGET'].includes(rule)){
      console.trace();
      throw 'Unknown rule: ' + rule;
    }
    if(!['%', 'percentage', '*', 'mul', ''].includes(targetUnit)){
      console.trace();
      throw 'Unknown targetUnit: ' + targetUnit;
    }
  }

  getValue(user, plan, data){ return 0; } //Method to override, return the value of the plan
  getTargetValue(user, plan, data){ return 0; } //Method to override, return the value that is used for the target

  getTarget(user, plan, data){
    //Return the value the plan should target: targetValue * target and targetunit
    var targetValue = this.getTargetValue(user, plan, data);
    switch(this.targetUnit){
      case '%':
      case 'percentage':
        return this.target/100 * targetValue;
      case '*':
      case 'mul':
        return this.target * targetValue;
    }
    return this.target;
  }

  check(user, plan, data){
    //Compare the value and the target
    var value = this.getValue(user, plan, data);
    var target = this.getTarget(user, plan, data);

    switch(this.rule){
      case 'MIN':
        return value >= target ? this.pointsOnSuccess() : this.pointsOnFail();
      case 'MAX':
        return value <= target ? this.pointsOnSuccess() : this.pointsOnFail();
      case 'EQUAL':
        return value == target ? this.pointsOnSuccess() : this.pointsOnFail();
      case 'TARGET':
        var diff = Math.abs(value-target)/(target||1);
        return Math.round((1-diff)*this.pointsOnSuccess() + diff*this.pointsOnFail());
    }

    return 0;
  }
}

//Rule to target weeks hours based on the user average week available time
class WeekHoursTargetRule extends TargetRule{
  constructor(rule, target, targetUnit = '%', priority = 'REQUIRED', multiplier = 1){
    super(rule, target, targetUnit, priority, multiplier);
  }

  getValue(user, plan, data){ //Value of the plan is the sum of hours of the workouts and objectives
    var hours = 0;
    for(var workout of plan)
      if(workout !== 'REST')
        hours += workout.getDuration();
    return hours;
  }

  getTargetValue(user, plan, data){
    return plannerUtil.getUserAvailableTimeForPlan(user, data.weekStartDate, plan.length);
  }
}

function weekHoursTargetRule(rule, target, targetUnit = '%', priority = 'REQUIRED', multiplier = 1){
  return new WeekHoursTargetRule(rule, target, targetUnit, priority, multiplier);
}

//Rule to target a specific load based on the hours of the week plan
class WeekLoadTargetRule extends TargetRule{
  constructor(rule, target, targetUnit = '*', priority = 'REQUIRED', multiplier = 1){
    super(rule, target, targetUnit, priority, multiplier);
  }

  getValue(user, plan, data){ //Value of the plan is the sum of the training load of the workouts and objectives
    var load = 0;
    for(var workout of plan)
      if(workout !== 'REST')
        load += workout.getLoad(user);
    return load;
  }

  getTargetValue(user, plan, data){ //Target value: number of hours of the plan (that will then be multiplied by the desired multiplier)
    var hours = 0;
    for(var workout of plan)
      if(workout !== 'REST')
        hours += workout.getDuration();
    return hours/3600;
  }
}

function weekLoadTargetRule(rule, target, targetUnit = '*', priority = 'REQUIRED', multiplier = 1){
  return new WeekLoadTargetRule(rule, target, targetUnit, priority, multiplier);
}


//Rules to target specific times in zones based on the plan week time
const zonesToNumber = { 'z1': 0, 'z2': 1, 'z3': 2, 'z4': 3, 'z5': 4, 'z6': 5, 'z7': 7 };
class WeekZoneTargetRule extends TargetRule{
  constructor(zones, rule, target, targetUnit = '%', priority = 'REQUIRED', multiplier = 1){
    super(rule, target, targetUnit, priority, multiplier);
    this.zones = [];

    //Store given zones as numbers
    for(var zone of Array.isArray(zones) ? zones : [zones]){
      if(zonesToNumber[zone] == undefined){
        console.trace();
        throw 'Unknown zone: ' + zone;
      }
      this.zones.push(zonesToNumber[zone]);
    }
  }

  getValue(user, plan, data){ //Value of the plan is the time spent on desired training zones
    var time = 0;
    for(var workout of plan){
      if(workout !== 'REST'){
        var tiz = workout.getData(user).time_power_zones;
        for(var zone of this.zones) //Only add time in zones for each desired zone
          time += tiz[zone] || 0;
      }
    }

    return time;
  }

  getTargetValue(user, plan, data){ //Target value is plan week time
    var time = 0;
    for(var workout of plan)
      if(workout !== 'REST')
        time += workout.getDuration();
    return time;
  }
}

function weekZoneTargetRule(zones, rule, target, targetUnit = '%', priority = 'REQUIRED', multiplier = 1){
  return new WeekZoneTargetRule(zones, rule, target, targetUnit, priority, multiplier);
}

//Rule to target a duration for the longest workout based on plan week time
class LongestWorkoutDurationTargetRule extends TargetRule{
  constructor(rule, baseHoursTarget, target, targetUnit = '%', priority = 'REQUIRED', multiplier = 1){
    super(rule, target, targetUnit, priority, multiplier);
    this.baseHoursTarget = baseHoursTarget;
  }

  getValue(user, plan, data){ //Value of the plan is the duration of the longest workout
    var duration = 0;
    for(var workout of plan){
      if(workout !== 'REST' && workout.isWorkout()){ //Objectives and training rides should be ignored
        duration = Math.max(workout.getDuration(), duration);
      }
    }
    return duration;
  }

  getTargetValue(user, plan, data){ //Target value is plan week time
    var time = 0;
    for(var workout of plan)
      if(workout !== 'REST')
        time += workout.getDuration();
    return time;
  }

  getTarget(user, plan, data){
    return super.getTarget(user, plan, data) + this.baseHoursTarget*3600;
  }
}

function longestWorkoutDurationTargetRule(rule, baseHoursTarget, target, targetUnit = '%', priority = 'REQUIRED', multiplier = 1){
  return new LongestWorkoutDurationTargetRule(rule, baseHoursTarget, target, targetUnit, priority, multiplier);
}



//Target rule that specify max duration of workouts for indoor workouts
class WorkoutMaxIndoorDuration extends Rule{
  constructor(hoursTarget, priority = 'REQUIRED', multiplier = 1){
    super(priority, multiplier);
    this.durationTarget = hoursTarget * 3600;
  }

  check(user, plan, data){
    let points = 0;
    for(let i=0; i<plan.length; i++){
      if(data.trainingTypeDays[i] === 'indoor'){
        let workout = plan[i];
        let duration = workout === 'REST' ? 0 : workout.getDuration();
        points += duration <= this.durationTarget ? this.pointsOnSuccess() : this.pointsOnFail();
      }
    }
    return points;
  }
}

function workoutMaxIndoorDuration(hoursTarget, priority = 'REQUIRED', multiplier = 1){
  return new WorkoutMaxIndoorDuration(hoursTarget, priority, multiplier);
}

module.exports = {
  weekHoursTargetRule,
  weekLoadTargetRule,
  weekZoneTargetRule,
  longestWorkoutDurationTargetRule,
  workoutMaxIndoorDuration,
};
