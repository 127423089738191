<template>
  <div class="codes-manager container" v-if="$store.getters.hasAdminAccess">
    <h2 class="title is-2">Codes</h2>

    <section>
      <b-field>
        <b-select label="Selectionner un compte pro" v-model="selectedBusinessUser">
          <option value="">Aucun</option>
          <template v-for="businessUser in businessUsersList">
            <option :key="businessUser._id" :value="businessUser">{{ businessUser.username }}</option>
          </template>
        </b-select>
      </b-field>
    </section>

    <section class="section">
      <h3 class="title is-3">Générer des codes <span v-if="selectedBusinessUser">pour {{ selectedBusinessUser.username }}</span></h3>
      <b-field grouped class="is-inline-block">
        <b-field label="nb codes">
          <b-input v-model.number="nbCodes" type="number" step="1"/>
        </b-field>
        <b-field label="Type">
          <b-select v-model="codesType">
            <option value="PREMIUM">Premium</option>
            <option value="STANDARD">Standard</option>
          </b-select>
        </b-field>
        <b-field label="nb jours">
          <b-input v-model.number="value" type="number" step="1"/>
        </b-field>
      </b-field><br/>
      <b-button @click.native="generate()">Générer</b-button>

    </section>

    <section>
      <h3 class="title is-3">Codes générés</h3>
      <div class="columns">
        <ul class="column has-text-left">
          <li v-for="code in generatedCodes" :key="code.code">
            <b>{{ code.code }}</b>
            {{ code.type }}
            {{ code.value }} jours
            {{ new Date(code.date_created).toLocaleDateString() }}
          </li>
        </ul>
        <ul class="column has-text-right">
          <li v-for="code in generatedCodes" :key="code.code">
            <b>{{ code.code }}</b>
          </li>
        </ul>
      </div>
    </section>

    <section>
      <h3 class="title is-3">Codes</h3>
      <div class="columns">
        <ul class="column has-text-left">
          <li v-for="code in codes" :key="code.code">
            <b>{{ code.code }}</b>
            {{ code.type }}
            {{ code.value }} jours
            {{ new Date(code.date_created).toLocaleDateString() }}
            <i v-if="code.used">
              Utilisé le {{ code.date_used }} par {{ code.by_username }} ( {{ code.by_email }} )
            </i>
          </li>
        </ul>
        <ul class="column has-text-right">
          <li v-for="code in codes" :key="code.code">
            <b>{{ code.code }}</b>
          </li>
        </ul>
      </div>
    </section>

    <b-table :data="codes" striped bordered narrowed hoverable>
      <b-table-column field="code" label="Code" v-slot="props" sortable>
        {{ props.row.code }}
      </b-table-column>
      <b-table-column field="type" label="Type" v-slot="props" sortable>
        {{ props.row.type }}
      </b-table-column>
      <b-table-column field="value" label="Valeur" v-slot="props" sortable>
        {{ props.row.value }}j
      </b-table-column>
      <b-table-column field="date_created" label="Créé le" v-slot="props" sortable>
        {{ new Date(props.row.date_created).toLocaleDateString() }}
      </b-table-column>
      <b-table-column field="used" label="Status" v-slot="props" sortable>
        {{ props.row.used ? 'Utilisé' : 'Non utilisé' }}
      </b-table-column>
      <b-table-column field="used" label="Utilisé par" v-slot="props" sortable>
        {{ props.row.used ? props.row.by_username : '' }}
      </b-table-column>
      <b-table-column field="used" label="Le" v-slot="props" sortable>
        {{ props.row.used ? new Date(props.row.date_used).toLocaleDateString() : '' }}
      </b-table-column>
    </b-table>
  </div>
</template>

<script>

export default{
  name: 'CodesManager',
  data(){
    return {
      codes: [],
      generatedCodes: [],
      nbCodes: 0,
      value: 30,
      codesType: 'PREMIUM',
      codesData: [],
      selectedBusinessUser: '',
      businessUsersList: [],
    };
  },
  methods: {
    generateCode(){
      const CODELENGTH = 8;
      const CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let code = '';
      for(var i=0; i < CODELENGTH; i++)
        code += CHARS.charAt(Math.floor(Math.random() * CHARS.length));
      return code;
    },

    async generate(){
      let codes = [];
      for(let i=0; i<this.nbCodes; i++){
        codes.push({ code: this.generateCode(), type: this.codesType, value: this.value, used: false, date_created: new Date(), owner: this.selectedBusinessUser?._id });
      }
      try{
        await this.post('/save_codes', { codes: codes });
        this.generatedCodes = this.generatedCodes.concat(codes);
        this.codes = this.codes.concat(codes);
      }catch(err){
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$t(err),
          position: 'is-bottom',
          type: 'is-danger'
        });
        console.error(err);
      }
    },

    async loadCodes(){
      try{
        let codes = await this.get('/get_codes' + (this.selectedBusinessUser ? '?owner='+this.selectedBusinessUser._id : ''));
        this.codes = codes;
        this.generatedCodes = [];
        for(let code of codes){
          if(new Date(code.date_created).toDateString() == new Date().toDateString())
            this.generatedCodes.push(code);
        }
      }catch(err){
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$t(err),
          position: 'is-bottom',
          type: 'is-danger'
        });
        console.error(err);
      }
    },


    async loadBusinessUsersList(){
      try{
        this.businessUsersList = (await this.get('/business_users_list')).business_users;
      }catch(err){
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$t(err),
          position: 'is-bottom',
          type: 'is-danger'
        });
        console.error(err);
      }
    }
  },
  async mounted(){
    await this.loadCodes();
    await this.loadBusinessUsersList();
  },

  watch: {
    async selectedBusinessUser(){
      await this.loadCodes();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
