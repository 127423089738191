const WorkoutCategory = require('../workoutCategory');
const WorkoutItem = require('../workoutItem');

const warmup15 = '2:00@55%ftp, 2:00@60%ftp, 2:00@65%ftp, 2:00@70%ftp, 2:00@75%ftp, 3*(15@105%map 45@55%ftp), 2:00@55%ftp,';
const warmup30 = '2:00@55%ftp, 3:00@60%ftp, 10:00@65%ftp, 5:00@70%ftp, 5:00@75%ftp, 3*(00:15@105%map, 00:45@55%ftp), 2:00@55%ftp,';
const warmup45 = '2:00@55%ftp, 3:00@60%ftp, 10:00@65%ftp, 15:00@70%ftp, 10:00@75%ftp, 3*(00:15@105%map, 00:45@55%ftp), 2:00@55%ftp,';
const warmup60 = '5:00@55%ftp, 10:00@60%ftp, 15:00@65%ftp, 15:00@70%ftp, 10:00@75%ftp, 3*(00:15@105%map, 00:45@55%ftp), 2:00@55%ftp,';
const warmup75 = '5:00@55%ftp, 15:00@60%ftp, 20:00@65%ftp, 18:00@70%ftp, 10:00@75%ftp, 3*(00:15@105%map, 01:15@55%ftp), 2:30@55%ftp,';
const warmup90 = '5:00@55%ftp, 20:00@60%ftp, 20:00@65%ftp, 23:00@70%ftp, 15:00@75%ftp, 3*(00:15@105%map, 01:15@55%ftp), 2:30@55%ftp,';


const warmupsDuration = [15*60,30*60,45*60,50*60,75*60,90*60];
function PMADescriptions(intervals, cooldown){
  let intervalsDescriptions = [];
  for(let interval of intervals){
    intervalsDescriptions.push({ zone: 6, duration: interval.duration, target: interval.target, description: 'short_MAP',
      format: { intervals: interval.intervals} });
    if(interval.cooldown)
      intervalsDescriptions.push({ zone: 1, duration: interval.cooldown, target: '55%ftp', description: 'recovery' });
  }

  return (index) => [
    { zone: 2, duration: warmupsDuration[index], target: '55%ftp-75%ftp', description: 'progressive_warmup_HIT' },
    ...intervalsDescriptions,
    { zone: 1, duration: cooldown, target: '55%ftp', description: 'cooldown' },
  ];
}

var pma = new WorkoutCategory(
  'PMA',
  [

  //Niveau 1

  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
    'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 8*(30/30)'} }, '8*(30@115%map 30@50%ftp), 06:00@55%ftp, 8*(30@115%map 30@50%ftp), 8:00@55%ftp', 1,
    PMADescriptions([{ duration: 8*60, target: '115%map', intervals:'8*(30/30)', cooldown: 6*60 }, { duration: 8*60, target: '115%map', intervals:'8*(30/30)' }], 8*60 )),

  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
    'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 12*(15/15)'} }, '12*(15@120%map 15@50%ftp), 06:00@55%ftp, 12*(15@120%map 15@50%ftp), 12:00@55%ftp', 1,
    PMADescriptions([{ duration: 12*30, target: '120%map', intervals:'12*(15/15)', cooldown: 6*60 }, { duration: 12*30, target: '120%map', intervals:'12*(15/15)' }], 12*60 )),

  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
    'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '8*(20/40) & 8*(30/30)'} }, '8*(20@120%map 40@50%ftp), 05:00@55%ftp, 8*(00:30@115%map 00:30@50%ftp), 9:00@55%ftp', 1,
    PMADescriptions([{ duration: 8*60, target: '120%map', intervals:'8*(20/40)', cooldown: 5*60 }, { duration: 8*60, target: '115%map', intervals:'8*(30/30)' }], 9*60 )),

  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
    'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '8*(15/30), 6*(40/20), 8*(15/30)'} }, '8*(15@120%map 30@50%ftp), 04:00@55%ftp, 6*(40@112%map 20@50%ftp), 04:00@55%ftp, 8*(15@120%map 30@50%ftp), 4:00@55%ftp', 1,
    PMADescriptions([{ duration: 8*45, target: '120%map', intervals:'8*(15/30)', cooldown: 4*60 }, { duration: 6*60, target: '112%map', intervals:'6*(40/20)', cooldown: 4*60 }, { duration: 8*45, target: '120%map', intervals:'8*(15/30)'}], 4*60 )),

  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
    'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 10*(20/40)'} }, '10*(20@120%map 40@55%ftp), 05:00@55%ftp, 10*(20@120%map 40@55%ftp), 5:00@55%ftp', 1,
    PMADescriptions([{ duration: 10*60, target: '120%map', intervals:'10*(20/40)', cooldown: 5*60 }, { duration: 10*60, target: '120%map', intervals:'10*(20/40)'}], 5*60 )),

  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
    'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 5*(45/30)'} }, '2*(5*(45@110%map 30@55%ftp), 06:00@55%ftp), 5:30@55%ftp', 1,
    PMADescriptions([{ duration: 5*75, target: '110%map', intervals:'5*(45/30)', cooldown: 6*60 }, { duration: 5*75, target: '110%map', intervals:'5*(45/30)'}], 11.5*60 )),

  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
    'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '6*(45/30) & 10*(30/30)'} }, '6*(45@110%map 30@55%ftp), 05:30@60%ftp, 10*(30@115%map 30@50%ftp) , 7:00@60%ftp', 1,
    PMADescriptions([{ duration: 6*75, target: '110%map', intervals:'6*(45/30)', cooldown: 5.5*60 }, { duration: 10*60, target: '115%map', intervals:'10*(30/30)'}], 7*60 )),


  //Niveau 1.5

  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
    'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 8*(30/30)'} }, '8*(30@117%map, 30@50%ftp), 06:00@55%ftp, 8*(30@117%map, 30@50%ftp), 8:00@55%ftp', 1.5,
    PMADescriptions([{ duration: 8*60, target: '117%map', intervals:'8*(30/30)', cooldown: 6*60 }, { duration: 8*60, target: '117%map', intervals:'8*(30/30)' }], 8*60 )),

  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
    'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 12*(15/15)'} }, '12*(15@122%map 00:15@50%ftp), 06:00@55%ftp, 12*(15@122%map 15@50%ftp), 12:00@55%ftp', 1.5,
    PMADescriptions([{ duration: 12*30, target: '122%map', intervals:'12*(15/15)', cooldown: 6*60 }, { duration: 12*30, target: '122%map', intervals:'12*(15/15)' }], 12*60 )),

  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
    'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '8*(20/40) & 8*(30/30)'} }, '8*(00:20@122%map 00:40@50%ftp), 05:00@55%ftp, 8*(00:30@117%map 00:30@50%ftp), 9:00@55%ftp', 1.5,
    PMADescriptions([{ duration: 8*60, target: '122%map', intervals:'8*(20/40)', cooldown: 5*60 }, { duration: 8*60, target: '117%map', intervals:'8*(30/30)' }], 9*60 )),

  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
    'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '8*(15/30), 6*(40/20), 8*(15/30)'} }, '8*(00:15@125%map 00:30@50%ftp), 04:00@55%ftp, 6*(00:40@114%map 00:20@50%ftp), 04:00@55%ftp, 8*(00:15@125%map 00:30@50%ftp),4:00@55%ftp', 1.5,
    PMADescriptions([{ duration: 8*45, target: '125%map', intervals:'8*(15/30)', cooldown: 4*60 }, { duration: 6*60, target: '114%map', intervals:'6*(40/20)', cooldown: 4*60 }, { duration: 8*45, target: '125%map', intervals:'8*(15/30)'}], 4*60 )),

  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
    'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 10*(20/40)'} }, '10*(20@122%map, 00:40@55%ftp), 05:00@55%ftp, 10*(00:20@122%map, 00:40@55%ftp), 5:00@55%ftp', 1.5,
    PMADescriptions([{ duration: 10*60, target: '122%map', intervals:'10*(20/40)', cooldown: 5*60 }, { duration: 10*60, target: '122%map', intervals:'10*(20/40)'}], 5*60 )),

  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
    'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 5*(45/30)'} }, '2*(5*(00:45@112%map, 00:30@55%ftp), 06:00@55%ftp), 5:30@55%ftp', 1.5,
    PMADescriptions([{ duration: 5*75, target: '112%map', intervals:'5*(45/30)', cooldown: 6*60 }, { duration: 5*75, target: '112%map', intervals:'5*(45/30)'}], 11.5*60 )),

  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
    'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '6*(45/30) & 10*(30/30)'} }, '6*(00:45@112%map, 00:30@55%ftp), 05:30@60%ftp, 10*(00:30@117%map, 00:30@55%ftp) , 7:00@60%ftp', 1.5,
    PMADescriptions([{ duration: 6*75, target: '112%map', intervals:'6*(45/30)', cooldown: 5.5*60 }, { duration: 10*60, target: '115%map', intervals:'10*(30/30)'}], 7*60 )),



  //Niveau 2

  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
    'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 8*(30/30)'} }, '8*(30@119%map, 30@50%ftp), 06:00@55%ftp, 8*(30@119%map, 30@50%ftp), 8:00@55%ftp',2),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
    'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 12*(15/15)'} }, '12*(15@124%map 00:15@50%ftp), 06:00@55%ftp, 12*(15@124%map 15@50%ftp), 12:00@55%ftp',),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
    'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '8*(20/40) & 8*(30/30)'} }, '8*(00:20@124%map 00:40@50%ftp), 05:00@55%ftp, 8*(00:30@119%map 00:30@50%ftp), 9:00@55%ftp',2),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
    'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '8*(15/30), 6*(40/20), 8*(15/30)'} }, '8*(00:15@127%map 00:30@50%ftp), 04:00@55%ftp, 6*(00:40@116%map 00:20@50%ftp), 04:00@55%ftp, 8*(00:15@127%map 00:30@50%ftp),4:00@55%ftp',2),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
    'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 10*(20/40)'} }, '10*(20@122%map, 00:40@55%ftp), 05:00@55%ftp, 10*(00:20@122%map, 00:40@55%ftp), 5:00@55%ftp',2),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
    'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 5*(45/30)'} }, '2*(5*(00:45@114%map, 00:30@55%ftp), 06:00@55%ftp), 5:30@55%ftp',2),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
    'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '6*(45/30) & 10*(30/30)'} }, '6*(00:45@114%map, 00:30@55%ftp), 05:30@60%ftp, 10*(00:30@119%map, 00:30@55%ftp) , 7:00@60%ftp',2),



  //Niveau 2.5

  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
    'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 8*(30/30)'} }, '8*(30@121%map, 30@50%ftp), 06:00@55%ftp, 8*(30@121%map, 30@50%ftp), 8:00@55%ftp',2.5),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
    'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 12*(15/15)'} }, '12*(15@126%map 00:15@50%ftp), 06:00@55%ftp, 12*(15@126%map 15@50%ftp), 12:00@55%ftp',2.5),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
    'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '8*(20/40) & 8*(30/30)'} }, '8*(20@126%map 40@50%ftp), 05:00@55%ftp, 8*(30@121%map 30@50%ftp), 9:00@55%ftp',2.5),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
    'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '8*(15/30), 6*(40/20), 8*(15/30)'} }, '8*(15@128%map 30@50%ftp), 04:00@55%ftp, 6*(40@118%map 20@50%ftp), 04:00@55%ftp, 8*(00:15@128%ftp 00:30@50%ftp),4:00@55%ftp',2.5),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
    'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 10*(20/40)'} }, '10*(20@126%map 40@55%ftp), 05:00@55%ftp, 10*(20@126%map 40@55%ftp), 5:00@55%ftp',2.5),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
    'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 5*(45/30)'} }, '2*(5*(45@116%map 30@55%ftp), 06:00@55%ftp), 5:30@55%ftp',2.5),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
    'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '6*(45/30) & 10*(30/30)'} }, '6*(45@116%map, 30@55%ftp), 05:30@60%ftp, 10*(30@121%map 30@55%ftp) , 7:00@60%ftp',2.5),


  //Niveau 3

  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 8*(30/30)'} }, '8*(30@123%map, 30@50%ftp), 06:00@55%ftp, 8*(30@123%map, 30@50%ftp), 8:00@55%ftp',3),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 12*(15/15)'} }, '12*(15@128%map 00:15@50%ftp), 06:00@55%ftp, 12*(15@128%map 15@50%ftp), 12:00@55%ftp',3),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '8*(20/40) & 8*(30/30)'} }, '8*(20@128%map 40@50%ftp), 05:00@55%ftp, 8*(30@123%map 30@50%ftp), 9:00@55%ftp',3),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '8*(15/30), 6*(40/20), 8*(15/30)'} }, '8*(15@128%map 30@50%ftp), 04:00@55%ftp, 6*(40@120%map 20@50%ftp), 04:00@55%ftp, 8*(00:15@128%ftp 00:30@50%ftp),4:00@55%ftp',3),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 10*(20/40)'} }, '10*(20@128%map 40@55%ftp), 05:00@55%ftp, 10*(20@128%map 40@55%ftp), 5:00@55%ftp',3),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 5*(45/30)'} }, '2*(5*(45@118%map 30@55%ftp), 06:00@55%ftp), 5:30@55%ftp',3),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '6*(45/30) & 10*(30/30)'} }, '6*(45@118%map, 30@55%ftp), 05:30@60%ftp, 10*(30@123%map 30@55%ftp) , 7:00@60%ftp',3),

  //Niveau 3.5

  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 8*(30/30)'} }, '8*(30@125%map, 30@50%ftp), 06:00@55%ftp, 8*(30@125%map, 30@50%ftp), 8:00@55%ftp',3.5),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 12*(15/15)'} }, '12*(15@130%map 00:15@50%ftp), 06:00@55%ftp, 12*(15@130%map 15@50%ftp), 12:00@55%ftp',3.5),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '8*(20/40) & 8*(30/30)'} }, '8*(20@130%map 40@50%ftp), 05:00@55%ftp, 8*(30@125%map 30@50%ftp), 9:00@55%ftp',3.5),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '8*(15/30), 6*(40/20), 8*(15/30)'} }, '8*(15@130%map 30@50%ftp), 04:00@55%ftp, 6*(40@122%map 20@50%ftp), 04:00@55%ftp, 8*(00:15@130%ftp 00:30@50%ftp),4:00@55%ftp',3.5),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 10*(20/40)'} }, '10*(20@130%map 40@55%ftp), 05:00@55%ftp, 10*(20@130%map 40@55%ftp), 5:00@55%ftp',3.5),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 5*(45/30)'} }, '2*(5*(45@120%map 30@55%ftp), 06:00@55%ftp), 5:30@55%ftp',3.5),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '6*(45/30) & 10*(30/30)'} }, '6*(45@120%map, 30@55%ftp), 05:30@60%ftp, 10*(30@125%map 30@55%ftp) , 7:00@60%ftp',3.5),


  //Niveau 4

  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 8*(30/30)'} }, '8*(30@127%map, 30@50%ftp), 06:00@55%ftp, 8*(30@127%map, 30@50%ftp), 8:00@55%ftp',4),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 12*(15/15)'} }, '12*(15@132%map 00:15@50%ftp), 06:00@55%ftp, 12*(15@132%map 15@50%ftp), 12:00@55%ftp',4),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '8*(20/40) & 8*(30/30)'} }, '8*(20@132%map 40@50%ftp), 05:00@55%ftp, 8*(30@129%map 30@50%ftp), 9:00@55%ftp',4),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '8*(15/30), 6*(40/20), 8*(15/30)'} }, '8*(15@132%map 30@50%ftp), 04:00@55%ftp, 6*(40@124%map 20@50%ftp), 04:00@55%ftp, 8*(00:15@132%ftp 00:30@50%ftp),4:00@55%ftp',4),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 10*(20/40)'} }, '10*(20@132%map 40@55%ftp), 05:00@55%ftp, 10*(20@132%map 40@55%ftp), 5:00@55%ftp',4),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 5*(45/30)'} }, '2*(5*(45@122%map 30@55%ftp), 06:00@55%ftp), 5:30@55%ftp',4),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '6*(45/30) & 10*(30/30)'} }, '6*(45@122%map, 30@55%ftp), 05:30@60%ftp, 10*(30@127%map 30@55%ftp) , 7:00@60%ftp',4),


  //Niveau 4.5

  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 8*(30/30)'} }, '8*(30@129%map, 30@50%ftp), 06:00@55%ftp, 8*(30@129%map, 30@50%ftp), 8:00@55%ftp',4.5),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 12*(15/15)'} }, '12*(15@134%map 00:15@50%ftp), 06:00@55%ftp, 12*(15@134%map 15@50%ftp), 12:00@55%ftp',4.5),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '8*(20/40) & 8*(30/30)'} }, '8*(20@134%map 40@50%ftp), 05:00@55%ftp, 8*(30@129%map 30@50%ftp), 9:00@55%ftp',4.5),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '8*(15/30), 6*(40/20), 8*(15/30)'} }, '8*(15@134%map 30@50%ftp), 04:00@55%ftp, 6*(40@126%map 20@50%ftp), 04:00@55%ftp, 8*(00:15@134%ftp 00:30@50%ftp),4:00@55%ftp',4.5),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 10*(20/40)'} }, '10*(20@134%map 40@55%ftp), 05:00@55%ftp, 10*(20@134%map 40@55%ftp), 5:00@55%ftp',4.5),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 5*(45/30)'} }, '2*(5*(45@124%map 30@55%ftp), 06:00@55%ftp), 5:30@55%ftp',4.5),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '6*(45/30) & 10*(30/30)'} }, '6*(45@124%map, 30@55%ftp), 05:30@60%ftp, 10*(30@129%map 30@55%ftp) , 7:00@60%ftp',4.5),

  //Niveau 5

  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 8*(30/30)'} }, '8*(30@131%map, 30@50%ftp), 06:00@55%ftp, 8*(30@131%map, 30@50%ftp), 8:00@55%ftp',5),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 12*(15/15)'} }, '12*(15@136%map 00:15@50%ftp), 06:00@55%ftp, 12*(15@136%map 15@50%ftp), 12:00@55%ftp',5),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '8*(20/40) & 8*(30/30)'} }, '8*(20@136%map 40@50%ftp), 05:00@55%ftp, 8*(30@131%map 30@50%ftp), 9:00@55%ftp',5),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '8*(15/30), 6*(40/20), 8*(15/30)'} }, '8*(15@136%map 30@50%ftp), 04:00@55%ftp, 6*(40@128%map 20@50%ftp), 04:00@55%ftp, 8*(00:15@136%ftp 00:30@50%ftp),4:00@55%ftp',5),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 10*(20/40)'} }, '10*(20@136%map 40@55%ftp), 05:00@55%ftp, 10*(20@136%map 40@55%ftp), 5:00@55%ftp',5),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 5*(45/30)'} }, '2*(5*(45@126%map 30@55%ftp), 06:00@55%ftp), 5:30@55%ftp',5),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '6*(45/30) & 10*(30/30)'} }, '6*(45@126%map, 30@55%ftp), 05:30@60%ftp, 10*(30@131%map 30@55%ftp) , 7:00@60%ftp',5),


  //Niveau 5.5

  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 8*(30/30)'} }, '8*(30@133%map, 30@50%ftp), 06:00@55%ftp, 8*(30@133%map, 30@50%ftp), 8:00@55%ftp',5.5),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 12*(15/15)'} }, '12*(15@138%map 00:15@50%ftp), 06:00@55%ftp, 12*(15@138%map 15@50%ftp), 12:00@55%ftp',5.5),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '8*(20/40) & 8*(30/30)'} }, '8*(20@138%map 40@50%ftp), 05:00@55%ftp, 8*(30@133%map 30@50%ftp), 9:00@55%ftp',5.5),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '8*(15/30), 6*(40/20), 8*(15/30)'} }, '8*(15@138%map 30@50%ftp), 04:00@55%ftp, 6*(40@130%map 20@50%ftp), 04:00@55%ftp, 8*(00:15@138%ftp 00:30@50%ftp),4:00@55%ftp',5.5),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 10*(20/40)'} }, '10*(20@138%map 40@55%ftp), 05:00@55%ftp, 10*(20@138%map 40@55%ftp), 5:00@55%ftp',5.5),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 5*(45/30)'} }, '2*(5*(45@128%map 30@55%ftp), 06:00@55%ftp), 5:30@55%ftp',5.5),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '6*(45/30) & 10*(30/30)'} }, '6*(45@128%map, 30@55%ftp), 05:30@60%ftp, 10*(30@133%map 30@55%ftp) , 7:00@60%ftp',5.5),

  //Niveau 6

  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 8*(30/30)'} }, '8*(30@135%map, 30@50%ftp), 06:00@55%ftp, 8*(30@135%map, 30@50%ftp), 8:00@55%ftp',6),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 12*(15/15)'} }, '12*(15@140%map 00:15@50%ftp), 06:00@55%ftp, 12*(15@140%map 15@50%ftp), 12:00@55%ftp',6),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '8*(20/40) & 8*(30/30)'} }, '8*(20@140%map 40@50%ftp), 05:00@55%ftp, 8*(30@135%map 30@50%ftp), 9:00@55%ftp',6),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '8*(15/30), 6*(40/20), 8*(15/30)'} }, '8*(15@140%map 30@50%ftp), 04:00@55%ftp, 6*(40@132%map 20@50%ftp), 04:00@55%ftp, 8*(00:15@140%ftp 00:30@50%ftp),4:00@55%ftp',6),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 10*(20/40)'} }, '10*(20@140%map 40@55%ftp), 05:00@55%ftp, 10*(20@140%map 40@55%ftp), 5:00@55%ftp',6),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 5*(45/30)'} }, '2*(5*(45@130%map 30@55%ftp), 06:00@55%ftp), 5:30@55%ftp',6),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '6*(45/30) & 10*(30/30)'} }, '6*(45@130%map, 30@55%ftp), 05:30@60%ftp, 10*(30@135%map 30@55%ftp) , 7:00@60%ftp',6),



  //Level 6.5

  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 8*(30/30)'} }, '8*(30@137%map, 30@50%ftp), 06:00@55%ftp, 8*(30@137%map, 30@50%ftp), 8:00@55%ftp',6.5),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 12*(15/15)'} }, '12*(15@142%map 00:15@50%ftp), 06:00@55%ftp, 12*(15@142%map 15@50%ftp), 12:00@55%ftp',6.5),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '8*(20/40) & 8*(30/30)'} }, '8*(20@142%map 40@50%ftp), 05:00@55%ftp, 8*(30@137%map 30@50%ftp), 9:00@55%ftp',6.5),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '8*(15/30), 6*(40/20), 8*(15/30)'} }, '8*(15@142%map 30@50%ftp), 04:00@55%ftp, 6*(40@135%map 20@50%ftp), 04:00@55%ftp, 8*(00:15@142%ftp 00:30@50%ftp),4:00@55%ftp',6.5),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 10*(20/40)'} }, '10*(20@142%map 40@55%ftp), 05:00@55%ftp, 10*(20@142%map 40@55%ftp), 5:00@55%ftp',6.5),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '2 * 5*(45/30)'} }, '2*(5*(45@132%map 30@55%ftp), 06:00@55%ftp), 5:30@55%ftp',6.5),
  ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90],
     'short_MAP', 'vo2max', { description: 'short_MAP', format: {intervals: '6*(45/30) & 10*(30/30)'} }, '6*(45@132%map, 30@55%ftp), 05:30@60%ftp, 10*(30@137%map 30@55%ftp) , 7:00@60%ftp',6.5),

  ],
);

module.exports = {
  pma
};
