/*
 * Workout item: Store a workout and useful infos for the algorithm generation
 */

const workoutTextParser = require('../../shared/workout/workoutTextParser');
const workoutUtil = require('../../shared/workout/workoutUtil');
const util = require('../util');
const ObjectID = require('bson').ObjectID;
const isEqual = require('fast-deep-equal');
const $t = require('../../shared/translations/i18n');


class WorkoutItem{
  constructor(workout, category = null, level = 0, set = false){
    this.workout = workout;
    this.workout.duration = workout.steps.reduce((total, step) => total + step.duration, 0);
    this.category = category;
    this.level = level;
    this.data = { load: 0, intensity: 0, np: 0 }; //Workout data is stored and recalculated only if a new user is generating a plan
    this.model = null;
    this.set = set;

    if(workout.tag && !['', 'recovery', 'endurance', 'tempo', 'threshold', 'vo2max', 'anaerobic', 'sprint', 'test', 'other'].includes(workout.tag))
      throw 'Wrong workout tag ' + workout.tag + ' for workout ' + workout.name;
  }

  calcNewData(user){
    var model = user.models.length > 0 ? user.models[user.models.length-1] : {};
    if(!util.modelEquals(this.model, model)){ //If not same user model, recalc data
      this.data = workoutUtil.calcData(this.workout, user.models[user.models.length-1], true);
      this.model = model;
    }
  }

  getData(user){
    this.calcNewData(user); //Recalc data if needed and then return it
    return this.data;
  }

  getName(){ return this.workout.name; }
  getLoad(user){ return this.getData(user).load; }

  getDuration(){ return this.workout.duration; }
  getLevel(){ return this.level; }

  categoryName(){
    return this.category.name;
  }

  //Utility function to create a workout item with a workout defined by text step
  static create(name, tag, description, textSteps, level = 0, stepsDescriptions = []){
    var nameFormat = {};
    if(typeof name !== 'string'){
      nameFormat = name.format || {};
      name = name.name;
    }

    var descriptionFormat = {};
    if(typeof description !== 'string'){
      descriptionFormat = description.format || {};
      description = description.description;
    }

    for(let stepDescription of stepsDescriptions){
      stepDescription.format = stepDescription.format || {};
    }


    return new WorkoutItem({ name: name, nameFormat: nameFormat, tag: tag, description: description, descriptionFormat: descriptionFormat,
       sport: 'cycling', steps: workoutTextParser.parseSteps(textSteps), steps_descriptions: stepsDescriptions }, null, level);
  }


  //Create multiple main workouts with different warmups and cooldowns (for different workouts length)
  static createWithWarmupsCooldowns(warmups, cooldowns, name, tag, description, textSteps, level = 0, stepsDescriptions = (index) => []){
    if(warmups.length !== cooldowns.length)
      throw 'WorkoutItem createWithWarmupsCooldowns,  warmups and cooldowns arrays must have same length';

    let workouts = [];
    for(let i=0; i<warmups.length; i++){
      let warmup = warmups[i];
      let cooldown = cooldowns[i];
      workouts.push(WorkoutItem.create(name, tag, description, warmup + textSteps + cooldown, level, stepsDescriptions(i)));
    }
    return workouts;
  }
  //Create multiple main workouts with different warmups (for different workouts length)
  static createWithWarmups(warmups, name, tag, description, textSteps, level = 0, stepsDescriptions = (index) => []){
    return WorkoutItem.createWithWarmupsCooldowns(warmups, Array(warmups.length).fill(''), name, tag, description, textSteps, level, stepsDescriptions);
  }
  //Create multiple main workouts with different cooldowns (for different workouts length)
  static createWithCooldowns(cooldowns, name, tag, description, textSteps, level = 0, stepsDescriptions = (index) => []){
    return WorkoutItem.createWithWarmupsCooldowns(Array(cooldowns.length).fill(''), cooldowns, name, tag, description, textSteps, level, stepsDescriptions);
  }

  //Check if the name is a translation and translate it if it's the case (if not return default name)
  translate(user, path, format, name){
    let t = $t(user.settings.language, path, format);
    if(t === path)
      return name;
    return t;
  }

  //Check if the name is a translation key and translate it if it's the case
  translatedName(user){
    return this.translate(user, 'workouts_lib.name.'+this.workout.name, this.workout.nameFormat, this.workout.name)
  }

  //Check if the description is a translation key and translate it if it's the case
  translatedDescription(user){
    return this.translate(user, 'workouts_lib.description.'+this.workout.description, this.workout.descriptionFormat, this.workout.description);
  }

  //Translate all descriptions in steps descriptions data
  translatedStepsDescriptions(user){
    const path = 'workouts_lib.steps_descriptions.';
    let stepsDescriptions = [];
    for(let stepDescription of this.workout.steps_descriptions){
      stepsDescriptions.push({
        zone: stepDescription.zone,
        duration: stepDescription.duration,
        target: stepDescription.target,
        description: this.translate(user, path+stepDescription.description, stepDescription.format, stepDescription.description),
      });
    }
    return stepsDescriptions;
  }

  //Generate workout data for the workout
  toWorkoutData(user){
    var data = user.models.length > 0 ? workoutUtil.calcData(this.workout, user.models[user.models.length-1]) : {};
    data.category = this.categoryName();
    return data;
  }

  //Convert the item to a workout to plan for the user
  toWorkout(user, date){
    return {
      id:  new ObjectID(),
      name: this.translatedName(user),
      date: date,
      description: this.translatedDescription(user),
      steps_descriptions: this.translatedStepsDescriptions(user),
      sport: this.workout.sport || 'cycling',
      duration: this.workout.duration,
      state: '',
      steps: this.workout.steps,
      tag: this.workout.tag,
      generated: true,
      data: this.toWorkoutData(user),
    }
  }

  toWorkoutModel(user){
    var model = {
      owner: -1, //id of owner
      access: 'public',
      name: this.translatedName(user),
      duration: this.workout.duration,
      tag: this.workout.tag,
      description: this.translatedDescription(user),
      steps_descriptions: this.translatedStepsDescriptions(user),
      sport: this.workout.sport || 'cycling',
      steps: this.workout.steps,
    };

    if(this.level)
      model.data = { level: this.level };

    return model;
  }

  toString(){
    return this.workout.name + '  duration: ' + this.getDuration();
  }

  isRide(){ return false; }

  isWorkout(){ return true; }
  isObjective(){ return false; }
  isTrainingRide(){ return false; }
  isActivity(){ return false; }
  isRest(){ return false; }
  isSet(){ return this.set; }
}

module.exports = WorkoutItem;
