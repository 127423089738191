const WorkoutCategory = require('../workoutCategory');
const WorkoutItem = require('../workoutItem');

const warmup1 = '2:00@55%ftp, 2:00@60%ftp, 2:00@65%ftp, 2:00@70%ftp, 2:00@75%ftp, 2:00@80%ftp, 1:00@90%ftp, 1:00@100%ftp, 1:00@110%ftp, 10:00@60%ftp, ';
const warmup2 = '1:00@55%ftp, 1:00@60%ftp, 1:00@65%ftp, 1:00@70%ftp, 1:00@75%ftp, 1:00@80%ftp, 1:00@90%ftp, 1:00@100%ftp, 1:00@110%ftp, 50@60%ftp, 10@90%max, 10:00@60%ftp, ';


function test20minStepsDescriptions(d1){
  return [
    { zone: 2, duration: 15*60, target: '55%ftp-110%ftp', description: 'progressive_warmup', format: { duration: '15min' } },
    { zone: 2, duration: 10*60, target: '65%ftp', description: 'light_endurance' },
    { zone: 5, duration: 20*60, target: '100%Wbal', description: '20min_test' },
    { zone: 2, duration: d1, target: '65%ftp', description: 'light_endurance' },
    { zone: 1, duration: 9*60, target: '55%ftp', description: 'cooldown' },
  ];
}

var test20min = new WorkoutCategory(
  '20minTests',
  [
    WorkoutItem.create('20min', 'test', '20min', warmup1 + '20:00@100%Wbal lap, 60@30%ftp, 5:00@65%ftp, 9:00@55%ftp', 0, test20minStepsDescriptions(5*60)),
    WorkoutItem.create('20min', 'test', '20min', warmup1 + '20:00@100%Wbal lap, 60@30%ftp, 35:00@65%ftp, 9:00@55%ftp', 0, test20minStepsDescriptions(35*60)),
    WorkoutItem.create('20min', 'test', '20min', warmup1 + '20:00@100%Wbal lap, 60@30%ftp, 1:05:00@65%ftp, 9:00@55%ftp', 0, test20minStepsDescriptions(65*60)),
    WorkoutItem.create('20min', 'test', '20min', warmup1 + '20:00@100%Wbal lap, 60@30%ftp, 1:35:00@65%ftp, 9:00@55%ftp', 0, test20minStepsDescriptions(95*60)),
    WorkoutItem.create('20min', 'test', '20min', warmup1 + '20:00@100%Wbal lap, 60@30%ftp, 2:05:00@65%ftp, 9:00@55%ftp', 0, test20minStepsDescriptions(125*60)),
  ]
);



function test5minStepsDescriptions(d1){
  return [
    { zone: 2, duration: 15*60, target: '55%ftp-110%ftp', description: 'progressive_warmup', format: { duration: '15min' } },
    { zone: 2, duration: 10*60, target: '65%ftp', description: 'light_endurance' },
    { zone: 5, duration: 5*60, target: '100%Wbal', description: '5min_test' },
    { zone: 2, duration: d1, target: '65%ftp', description: 'light_endurance' },
    { zone: 1, duration: 9*60, target: '55%ftp', description: 'cooldown' },
  ];
}

var test5min = new WorkoutCategory(
  '5minTests',
  [
    WorkoutItem.create('5min', 'test', '5min', warmup1 + '5:00@100%Wbal lap, 60@30%ftp, 5:00@65%ftp, 9:00@55%ftp', 0, test5minStepsDescriptions(5*60)),
    WorkoutItem.create('5min', 'test', '5min', warmup1 + '5:00@100%Wbal lap, 60@30%ftp, 20:00@65%ftp, 9:00@55%ftp', 0, test5minStepsDescriptions(20*60)),
    WorkoutItem.create('5min', 'test', '5min', warmup1 + '5:00@100%Wbal lap, 60@30%ftp, 50:00@65%ftp, 9:00@55%ftp', 0, test5minStepsDescriptions(50*60)),
    WorkoutItem.create('5min', 'test', '5min', warmup1 + '5:00@100%Wbal lap, 60@30%ftp, 1:20:00@65%ftp, 9:00@55%ftp', 0, test5minStepsDescriptions(80*60)),
    WorkoutItem.create('5min', 'test', '5min', warmup1 + '5:00@100%Wbal lap, 60@30%ftp, 1:50:00@65%ftp, 9:00@55%ftp', 0, test5minStepsDescriptions(110*60)),
    WorkoutItem.create('5min', 'test', '5min', warmup1 + '5:00@100%Wbal lap, 60@30%ftp, 2:20:00@65%ftp, 9:00@55%ftp', 0, test5minStepsDescriptions(140*60)),
  ]
);

function test1minStepsDescriptions(d1){
  return [
    { zone: 2, duration: 10*60, target: '55%ftp-110%ftp', description: 'progressive_warmup', format: { duration: '10min' } },
    { zone: 2, duration: 10*60, target: '65%ftp', description: 'light_endurance' },
    { zone: 7, duration: 60, target: '100%Wbal', description: '1min_test' },
    d1 ? { zone: 2, duration: d1, target: '65%ftp', description: 'light_endurance' } : null, //if no endurance duration, no step
    { zone: 1, duration: 8*60, target: '55%ftp', description: 'cooldown' },
  ].filter(e => e); //remove null element if any
}

var test1min = new WorkoutCategory(
  '1minTests',
  [
    WorkoutItem.create('1min', 'test', '1min', warmup2 + '1:00@100%Wbal, 60@30%ftp, 8:00@55%ftp', 0, test1minStepsDescriptions(0)),
    WorkoutItem.create('1min', 'test', '1min', warmup2 + '1:00@100%Wbal, 60@30%ftp, 15:00@65%ftp, 8:00@55%ftp', 0, test1minStepsDescriptions(15*60)),
    WorkoutItem.create('1min', 'test', '1min', warmup2 + '1:00@100%Wbal, 60@30%ftp, 30:00@65%ftp, 8:00@55%ftp', 0, test1minStepsDescriptions(30*60)),
    WorkoutItem.create('1min', 'test', '1min', warmup2 + '1:00@100%Wbal, 60@30%ftp, 60:00@65%ftp, 8:00@55%ftp', 0, test1minStepsDescriptions(60*60)),
    WorkoutItem.create('1min', 'test', '1min', warmup2 + '1:00@100%Wbal, 60@30%ftp, 1:30:00@65%ftp, 8:00@55%ftp', 0, test1minStepsDescriptions(90*60)),
    WorkoutItem.create('1min', 'test', '1min', warmup2 + '1:00@100%Wbal, 60@30%ftp, 2:00:00@65%ftp, 8:00@55%ftp', 0, test1minStepsDescriptions(120*60)),
    WorkoutItem.create('1min', 'test', '1min', warmup2 + '1:00@100%Wbal, 60@30%ftp, 2:30:00@65%ftp, 8:00@55%ftp', 0, test1minStepsDescriptions(150*60)),
  ]
);

var testSprint = new WorkoutCategory(
  'sprintTests',
  [
    WorkoutItem.create('sprintsTest', 'test', 'sprintsTest', warmup2 + '2*(10@90%ftp, 30@100%max, 20@30%ftp, 9:00@57%ftp), 5:00@55%ftp'),
    WorkoutItem.create('sprintsTest', 'test', 'sprintsTest', warmup2 + '2*(10@90%ftp, 30@100%max, 20@30%ftp, 9:00@57%ftp), 10:00@65%ftp, 10:00@55%ftp'),
    WorkoutItem.create('sprintsTest', 'test', 'sprintsTest', warmup2 + '2*(10@90%ftp, 30@100%max, 20@30%ftp, 9:00@57%ftp), 40:00@65%ftp, 10:00@55%ftp'),
    WorkoutItem.create('sprintsTest', 'test', 'sprintsTest', warmup2 + '2*(10@90%ftp, 30@100%max, 20@30%ftp, 9:00@57%ftp), 1:10:00@65%ftp, 10:00@55%ftp'),
    WorkoutItem.create('sprintsTest', 'test', 'sprintsTest', warmup2 + '2*(10@90%ftp, 30@100%max, 20@30%ftp, 9:00@57%ftp), 1:40:00@65%ftp, 10:00@55%ftp'),
    WorkoutItem.create('sprintsTest', 'test', 'sprintsTest', warmup2 + '2*(10@90%ftp, 30@100%max, 20@30%ftp, 9:00@57%ftp), 2:10:00@65%ftp, 10:00@55%ftp'),
  ]
);

var vLamax = new WorkoutCategory(
  'VLamax',
  [ WorkoutItem.create('VLamax test', 'test', '', '10:00@70%ftp, 5:00@100%ftp, 5:00@60%ftp, 5*(10@100%max 9:50@60%ftp), 10:00@60%ftp')]
);

module.exports = {
  test20min,
  test5min,
  test1min,
  testSprint,
  //vLamax,
};
