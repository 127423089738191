/*
 * Workout category
 * Contains workouts item that can be picked by the genetic algorithm
 */
const WorkoutItem = require('./workoutItem');

class WorkoutCategory{
  constructor(name, workouts){
    this.name = name;
    this.workouts = workouts;

    for(var workout of this.workouts)
      workout.category = this; //Set category of each workout to this (link to category needed)
  }
}

module.exports = WorkoutCategory;
