const WorkoutCategory = require('../workoutCategory');
const WorkoutItem = require('../workoutItem');


function enduranceFDescriptions(duration){
  return [
    { zone: 1, duration: 10*60, target: '55%ftp-60%ftp', description: 'progressive_warmup', format: { duration: '10min' } },
    { zone: 2, duration: duration, target: '67%ftp', description: 'enduranceF' },
    { zone: 1, duration: 5*60, target: '50%ftp', description: 'cooldown' },
  ];
}

var enduranceF = new WorkoutCategory(
  'enduranceF',
  [
    WorkoutItem.create({ name: 'fundamental_endurance', format: ['1h'] }, 'endurance', 'fundamental_endurance',
      '5:00@55%ftp, 5:00@60%ftp, 45:00@67%ftp,  5:00@50%ftp', 0, enduranceFDescriptions(45*60)),
    WorkoutItem.create({ name: 'fundamental_endurance', format: ['1h15'] }, 'endurance', 'fundamental_endurance',
     '5:00@55%ftp, 5:00@60%ftp, 60:00@67%ftp, 5:00@50%ftp', 0, enduranceFDescriptions(60*60)),
    WorkoutItem.create({ name: 'fundamental_endurance', format: ['1h30'] }, 'endurance', 'fundamental_endurance',
     '5:00@55%ftp, 5:00@60%ftp, 1:15:00@67%ftp, 5:00@50%ftp', 0, enduranceFDescriptions(75*60)),
    WorkoutItem.create({ name: 'fundamental_endurance', format: ['1h45'] }, 'endurance', 'fundamental_endurance',
     '5:00@55%ftp, 5:00@60%ftp, 1:30:00@67%ftp, 5:00@50%ftp', 0, enduranceFDescriptions(90*60)),
    WorkoutItem.create({ name: 'fundamental_endurance', format: ['2h'] }, 'endurance', 'fundamental_endurance',
     '5:00@55%ftp, 5:00@60%ftp, 1:45:00@67%ftp, 5:00@50%ftp', 0, enduranceFDescriptions(105*60)),
    WorkoutItem.create({ name: 'fundamental_endurance', format: ['2h15'] }, 'endurance', 'fundamental_endurance',
     '5:00@55%ftp, 5:00@60%ftp, 2:00:00@67%ftp, 5:00@50%ftp', 0, enduranceFDescriptions(120*60)),
    WorkoutItem.create({ name: 'fundamental_endurance', format: ['2h30'] }, 'endurance', 'fundamental_endurance',
     '5:00@55%ftp, 5:00@60%ftp, 2:15:00@67%ftp, 5:00@50%ftp', 0, enduranceFDescriptions(135*60)),
    WorkoutItem.create({ name: 'fundamental_endurance', format: ['2h45'] }, 'endurance', 'fundamental_endurance',
     '5:00@55%ftp, 5:00@60%ftp, 2:30:00@67%ftp, 5:00@50%ftp', 0, enduranceFDescriptions(150*60)),
    WorkoutItem.create({ name: 'fundamental_endurance', format: ['3h'] }, 'endurance', 'fundamental_endurance',
     '5:00@55%ftp, 5:00@60%ftp, 2:45:00@67%ftp, 5:00@50%ftp', 0, enduranceFDescriptions(165*60)),
    WorkoutItem.create({ name: 'fundamental_endurance', format: ['3h15'] }, 'endurance', 'fundamental_endurance',
     '5:00@55%ftp, 5:00@60%ftp, 3:00:00@67%ftp, 5:00@50%ftp', 0, enduranceFDescriptions(180*60)),
    WorkoutItem.create({ name: 'fundamental_endurance', format: ['3h30'] }, 'endurance', 'fundamental_endurance',
     '5:00@55%ftp, 5:00@60%ftp, 3:15:00@67%ftp, 5:00@50%ftp', 0, enduranceFDescriptions(195*60)),
    WorkoutItem.create({ name: 'fundamental_endurance', format: ['4h'] }, 'endurance', 'fundamental_endurance',
     '5:00@55%ftp, 5:00@60%ftp, 3:45:00@67%ftp, 5:00@50%ftp', 0, enduranceFDescriptions(225*60)),
    WorkoutItem.create({ name: 'fundamental_endurance', format: ['4h30'] }, 'endurance', 'fundamental_endurance',
     '5:00@55%ftp, 5:00@60%ftp, 4:15:00@67%ftp, 5:00@50%ftp', 0, enduranceFDescriptions(255*60)),
    WorkoutItem.create({ name: 'fundamental_endurance', format: ['5h'] }, 'endurance', 'fundamental_endurance',
     '5:00@55%ftp, 5:00@60%ftp, 4:45:00@67%ftp, 5:00@50%ftp', 0, enduranceFDescriptions(285*60)),
    WorkoutItem.create({ name: 'fundamental_endurance', format: ['5h30'] }, 'endurance', 'fundamental_endurance',
     '5:00@55%ftp, 5:00@60%ftp, 5:15:00@67%ftp, 5:00@50%ftp', 0, enduranceFDescriptions(315*60)),
    WorkoutItem.create({ name: 'fundamental_endurance', format: ['6h'] }, 'endurance', 'fundamental_endurance',
     '5:00@55%ftp, 5:00@60%ftp, 5:45:00@67%ftp, 5:00@50%ftp', 0, enduranceFDescriptions(345*60)),
  ],
);



function enduranceActiveDescriptions(warmup, duration, cooldown){
  return [
    { zone: 1, duration: warmup, target: '55%ftp-65%ftp', description: 'progressive_warmup', format: { duration: (warmup/60)+'min' } },
    { zone: 2, duration: duration, target: '60%ftp-72%ftp', description: 'active_endurance' },
    { zone: 1, duration: cooldown, target: '50%ftp', description: 'cooldown' },
  ];
}

var enduranceActive = new WorkoutCategory(
  'enduranceActive',
  [
    WorkoutItem.create({ name: 'active_endurance', format: ['1h'] }, 'endurance', 'active_endurance',
      '5:00@55%ftp, 5:00@60%ftp, 5:00@65%ftp, 2*(15:00@72%ftp, 5:00@57%ftp), 5:00@50%ftp', 0, enduranceActiveDescriptions(15*60, 40*60, 5*60)),
    WorkoutItem.create({ name: 'active_endurance', format: ['1h15'] }, 'endurance', 'active_endurance',
      '5:00@55%ftp, 5:00@60%ftp, 5:00@65%ftp, 3*(15:00@72%ftp, 3:20@57%ftp), 5:00@50%ftp', 0, enduranceActiveDescriptions(15*60, 55*60, 5*60)),
    WorkoutItem.create({ name: 'active_endurance', format: ['1h30'] }, 'endurance', 'active_endurance',
      '5:00@55%ftp, 10:00@60%ftp, 10:00@65%ftp, 4*(12:00@72%ftp, 3:00@57%ftp), 5:00@50%ftp', 0, enduranceActiveDescriptions(25*60, 60*60, 5*60)),
    WorkoutItem.create({ name: 'active_endurance', format: ['1h45'] }, 'endurance', 'active_endurance',
      '5:00@55%ftp, 10:00@60%ftp, 10:00@65%ftp, 3*(20:00@72%ftp, 5:00@57%ftp), 5:00@50%ftp', 0, enduranceActiveDescriptions(25*60, 75*60, 5*60)),
    WorkoutItem.create({ name: 'active_endurance', format: ['2h'] }, 'endurance', 'active_endurance',
      '5:00@55%ftp, 10:00@60%ftp, 10:00@65%ftp, 3*(25:00@72%ftp, 5:00@57%ftp), 5:00@50%ftp', 0, enduranceActiveDescriptions(25*60, 90*60, 5*60)),
    WorkoutItem.create({ name: 'active_endurance', format: ['2h15'] }, 'endurance', 'active_endurance',
      '5:00@55%ftp, 15:00@60%ftp, 20:00@65%ftp, 3*(25:00@72%ftp, 5:00@57%ftp), 5:00@50%ftp', 0, enduranceActiveDescriptions(40*60, 90*60, 5*60)),
    WorkoutItem.create({ name: 'active_endurance', format: ['2h30'] }, 'endurance', 'active_endurance',
      '5:00@55%ftp, 10:00@60%ftp, 10:00@65%ftp, 4*(25:00@72%ftp, 5:00@57%ftp), 5:00@50%ftp', 0, enduranceActiveDescriptions(25*60, 120*60, 5*60)),
    WorkoutItem.create({ name: 'active_endurance', format: ['2h45'] }, 'endurance', 'active_endurance',
      '5:00@55%ftp, 15:00@60%ftp, 20:00@65%ftp, 4*(25:00@72%ftp, 5:00@57%ftp), 5:00@50%ftp', 0, enduranceActiveDescriptions(40*60, 120*60, 5*60)),
    WorkoutItem.create({ name: 'active_endurance', format: ['3h'] }, 'endurance', 'active_endurance',
      '5:00@55%ftp, 20:00@60%ftp, 20:00@65%ftp, 4*(25:00@72%ftp, 5:00@57%ftp), 10:00@60%ftp, 5:00@50%ftp', 0, enduranceActiveDescriptions(45*60, 130*60, 5*60)),
    WorkoutItem.create({ name: 'active_endurance', format: ['3h15'] }, 'endurance', 'active_endurance',
      '5:00@55%ftp, 20:00@60%ftp, 20:00@65%ftp, 3*(35:00@72%ftp, 10:00@57%ftp), 10:00@60%ftp, 5:00@50%ftp', 0, enduranceActiveDescriptions(45*60, 145*60, 5*60)),
    WorkoutItem.create({ name: 'active_endurance', format: ['3h30'] }, 'endurance', 'active_endurance',
      '5:00@55%ftp, 20:00@60%ftp, 20:00@65%ftp, 3*(40:00@72%ftp, 10:00@57%ftp), 10:00@60%ftp, 5:00@50%ftp', 0, enduranceActiveDescriptions(45*60, 160*60, 5*60)),
    WorkoutItem.create({ name: 'active_endurance', format: ['3h45'] }, 'endurance', 'active_endurance',
      '5:00@55%ftp, 20:00@60%ftp, 20:00@65%ftp, 3*(45:00@70%ftp, 10:00@57%ftp), 10:00@60%ftp, 5:00@50%ftp', 0, enduranceActiveDescriptions(45*60, 175*60, 5*60)),
    WorkoutItem.create({ name: 'active_endurance', format: ['4h'] }, 'endurance', 'active_endurance',
      '5:00@55%ftp, 20:00@60%ftp, 20:00@65%ftp, 6*(25:00@70%ftp, 5:00@57%ftp), 10:00@60%ftp, 5:00@50%ftp', 0, enduranceActiveDescriptions(45*60, 190*60, 5*60)),
    WorkoutItem.create({ name: 'active_endurance', format: ['4h15'] }, 'endurance', 'active_endurance',
      '5:00@55%ftp, 15:00@60%ftp, 20:00@65%ftp, 5*(35:00@70%ftp, 5:00@57%ftp), 10:00@60%ftp, 5:00@50%ftp', 0, enduranceActiveDescriptions(40*60, 210*60, 5*60)),
    WorkoutItem.create({ name: 'active_endurance', format: ['4h30'] }, 'endurance', 'active_endurance',
      '5:00@55%ftp, 20:00@60%ftp, 20:00@65%ftp, 7*(25:00@70%ftp, 5:00@57%ftp), 10:00@60%ftp, 5:00@50%ftp', 0, enduranceActiveDescriptions(45*60, 220*60, 5*60)),
    WorkoutItem.create({ name: 'active_endurance', format: ['5h'] }, 'endurance', 'active_endurance',
      '5:00@55%ftp, 15:00@60%ftp, 20:00@65%ftp, 4*(55:00@70%ftp, 5:00@57%ftp), 15:00@60%ftp, 5:00@50%ftp', 0, enduranceActiveDescriptions(40*60, 255*60, 5*60)),
    WorkoutItem.create({ name: 'active_endurance', format: ['5h30'] }, 'endurance', 'active_endurance',
      '5:00@55%ftp, 15:00@60%ftp, 20:00@65%ftp, 5*(45:00@70%ftp, 7:30@57%ftp), 17:30@60%ftp, 10:00@50%ftp', 0, enduranceActiveDescriptions(40*60, 280*60, 10*60)),
    WorkoutItem.create({ name: 'active_endurance', format: ['6h'] }, 'endurance', 'active_endurance',
      '5:00@55%ftp, 15:00@60%ftp, 20:00@65%ftp, 5*(55:00@70%ftp, 5:00@57%ftp), 10:00@60%ftp, 10:00@50%ftp', 0, enduranceActiveDescriptions(40*60, 310*60, 10*60)),


    // WorkoutItem.create({ name: 'active_endurance', format: ['2h00'] }, 'endurance', 'active_endurance', '5:00@50%ftp, 10:00@55%ftp, 10:00@60%ftp, 3*(10:00@65%ftp, 15:00@75%ftp, 5:00@55%ftp), 5:00@50%ftp'),
    // WorkoutItem.create({ name: 'active_endurance', format: ['2h15'] }, 'endurance', 'active_endurance', '5:00@50%ftp, 5:00@55%ftp, 20:00@60%ftp, 2*(25:00@75%ftp, 25:00@60%ftp), 5:00@50%ftp'),
    // WorkoutItem.create({ name: 'active_endurance', format: ['2h30'] }, 'endurance', 'active_endurance', '5:00@50%ftp, 15:00@55%ftp, 20:00@60%ftp, 2*(25:00@75%ftp, 25:00@60%ftp), 10:00@50%ftp'),
    // WorkoutItem.create({ name: 'active_endurance', format: ['2h45'] }, 'endurance', 'active_endurance', '5:00@50%ftp, 10:00@55%ftp, 20:00@60%ftp, 3*(15:00@65%ftp, 25:00@72%ftp), 10:00@50%ftp'),
    // WorkoutItem.create({ name: 'active_endurance', format: ['3h00'] }, 'endurance', 'active_endurance', '5:00@50%ftp, 20:00@55%ftp, 3*(25:00@60%ftp, 25:00@72%ftp), 5:00@50%ftp'),
    // WorkoutItem.create({ name: 'active_endurance', format: ['3h15'] }, 'endurance', 'active_endurance', '5:00@50%ftp, 20:00@55%ftp, 3*(30:00@60%ftp, 25:00@72%ftp), 5:00@50%ftp'),
    // WorkoutItem.create({ name: 'active_endurance', format: ['3h30'] }, 'endurance', 'active_endurance', '5:00@50%ftp, 15:00@55%ftp, 20:00@60%ftp, 3*(25:00@65%ftp, 30:00@72%ftp), 5:00@50%ftp'),
    // WorkoutItem.create({ name: 'active_endurance', format: ['3h45'] }, 'endurance', 'active_endurance', '5:00@50%ftp, 15:00@55%ftp, 20:00@60%ftp, 3*(25:00@65%ftp, 30:00@72%ftp, 5:00@55%ftp), 5:00@50%ftp'),
    // WorkoutItem.create({ name: 'active_endurance', format: ['4h00'] }, 'endurance', 'active_endurance', '5:00@50%ftp, 10:00@55%ftp, 20:00@60%ftp, 4*(15:00@65%ftp, 30:00@72%ftp, 5:00@55%ftp), 5:00@50%ftp'),
    // WorkoutItem.create({ name: 'active_endurance', format: ['4h15'] }, 'endurance', 'active_endurance', '5:00@50%ftp, 10:00@55%ftp, 15:00@60%ftp, 4*(20:00@65%ftp, 30:00@72%ftp, 5:00@55%ftp), 5:00@50%ftp'),
    // WorkoutItem.create({ name: 'active_endurance', format: ['4h00'] }, 'endurance', 'active_endurance', '5:00@50%ftp, 10:00@55%ftp, 20:00@60%ftp, 4*(15:00@65%ftp, 30:00@72%ftp, 5:00@55%ftp), 5:00@50%ftp'),
    // WorkoutItem.create({ name: 'active_endurance', format: ['4h30'] }, 'endurance', 'active_endurance', '5:00@50%ftp, 10:00@55%ftp, 15:00@60%ftp, 4*(20:00@65%ftp, 30:00@72%ftp, 7:30@55%ftp), 5:00@55%ftp, 5:00@50%ftp'),
    // WorkoutItem.create({ name: 'active_endurance', format: ['4h45'] }, 'endurance', 'active_endurance', '5:00@50%ftp, 10:00@55%ftp, 10:00@60%ftp, 5*(20:00@65%ftp, 25:00@72%ftp, 5:00@55%ftp), 5:00@55%ftp, 5:00@50%ftp'),
    // WorkoutItem.create({ name: 'active_endurance', format: ['5h00'] }, 'endurance', 'active_endurance', '5:00@50%ftp, 20:00@55%ftp, 25:00@60%ftp, 8*(10:00@65%ftp, 15:00@72%ftp, 5:00@55%ftp), 5:00@55%ftp, 5:00@50%ftp'),
    // WorkoutItem.create({ name: 'active_endurance', format: ['5h30'] }, 'endurance', 'active_endurance', '5:00@50%ftp, 20:00@55%ftp, 25:00@60%ftp, 6*(25:00@65%ftp, 15:00@72%ftp, 5:00@55%ftp), 5:00@55%ftp, 5:00@50%ftp'),
    // WorkoutItem.create({ name: 'active_endurance', format: ['6h00'] }, 'endurance', 'active_endurance', '5:00@50%ftp, 20:00@55%ftp, 25:00@60%ftp, 5*(35:00@65%ftp, 20:00@72%ftp, 5:00@55%ftp), 5:00@55%ftp, 5:00@50%ftp'),
  ],
);


function enduranceSprintsDescriptions(duration, sprints, sprintsDuration, lightEndurance){
  return [
    { zone: 2, duration: duration, target: '55%ftp-65%ftp', description: 'endurance_ramp' },
    { zone: 7, duration: sprintsDuration, target: '55%ftp-65%ftp', description: 'endurance_sprints', format: { sprints: sprints } },
    { zone: 2, duration: lightEndurance, target: '60%ftp', description: 'light_endurance' },
    { zone: 1, duration: 5*60, target: '50%ftp', description: 'cooldown' },
  ];
}


var enduranceSprint = new WorkoutCategory(
  'enduranceSprint',
  [
    WorkoutItem.create({ name: 'endurance_sprints', format: ['1h00'] }, 'endurance', { description: 'endurance_sprints', format: {sprints: '5'} },
      '5:00@50%ftp, 5:00@55%ftp,  5:00@60%ftp,  5:00@65%ftp,  5:00@60%ftp !lap,  5* (7@90%max ontarget, 4:53@55%ftp !lap), 5:00@60%ftp,  5:00@50%ftp', 0,
      enduranceSprintsDescriptions(25*60, 5, 25*60, 5*60)),
    WorkoutItem.create({ name: 'endurance_sprints', format: ['1h15'] }, 'endurance', { description: 'endurance_sprints', format: {sprints: '6'} },
      '5:00@50%ftp, 5:00@55%ftp,  10:00@60%ftp, 10:00@65%ftp, 5:00@60%ftp !lap,  6* (7@90%max ontarget, 4:53@55%ftp !lap), 5:00@60%ftp,  5:00@50%ftp', 0,
      enduranceSprintsDescriptions(35*60, 6, 30*60, 5*60)),
    WorkoutItem.create({ name: 'endurance_sprints', format: ['1h30'] }, 'endurance', { description: 'endurance_sprints', format: {sprints: '8'} },
      '5:00@50%ftp, 10:00@55%ftp, 10:00@60%ftp, 10:00@65%ftp, 5:00@60%ftp !lap,  8* (7@90%max ontarget, 4:53@55%ftp !lap), 5:00@60%ftp,  5:00@50%ftp', 0,
      enduranceSprintsDescriptions(40*60, 8, 40*60, 5*60)),
    WorkoutItem.create({ name: 'endurance_sprints', format: ['1h45'] }, 'endurance', { description: 'endurance_sprints', format: {sprints: '9'} },
      '5:00@50%ftp, 10:00@55%ftp, 15:00@60%ftp, 10:00@65%ftp, 10:00@60%ftp !lap, 9* (7@90%max ontarget, 4:53@55%ftp !lap), 5:00@60%ftp,  5:00@50%ftp', 0,
      enduranceSprintsDescriptions(50*60, 9, 45*60, 5*60)),
    WorkoutItem.create({ name: 'endurance_sprints', format: ['2h00'] }, 'endurance', { description: 'endurance_sprints', format: {sprints: '9'} },
      '5:00@50%ftp, 10:00@55%ftp, 30:00@60%ftp, 10:00@65%ftp, 10:00@60%ftp !lap, 9* (7@90%max ontarget, 4:53@55%ftp !lap), 5:00@60%ftp,  5:00@50%ftp', 0,
      enduranceSprintsDescriptions(65*60, 9, 45*60, 5*60)),
    WorkoutItem.create({ name: 'endurance_sprints', format: ['2h15'] }, 'endurance', { description: 'endurance_sprints', format: {sprints: '9'} },
      '5:00@50%ftp, 20:00@55%ftp, 30:00@60%ftp, 15:00@65%ftp, 10:00@60%ftp !lap, 9* (7@90%max ontarget, 4:53@55%ftp !lap), 5:00@60%ftp,  5:00@50%ftp', 0,
      enduranceSprintsDescriptions(80*60, 9, 45*60, 5*60)),
    WorkoutItem.create({ name: 'endurance_sprints', format: ['2h30'] }, 'endurance', { description: 'endurance_sprints', format: {sprints: '10'} },
      '5:00@50%ftp, 20:00@55%ftp, 35:00@60%ftp, 15:00@65%ftp, 10:00@60%ftp !lap, 10*(7@90%max ontarget, 4:53@55%ftp !lap), 10:00@60%ftp, 5:00@50%ftp', 0,
      enduranceSprintsDescriptions(85*60, 10, 50*60, 10*60)),
    WorkoutItem.create({ name: 'endurance_sprints', format: ['2h45'] }, 'endurance', { description: 'endurance_sprints', format: {sprints: '10'} },
      '5:00@50%ftp, 20:00@55%ftp, 50:00@60%ftp, 15:00@65%ftp, 10:00@60%ftp !lap, 10*(7@90%max ontarget, 4:53@55%ftp !lap), 10:00@60%ftp, 5:00@50%ftp', 0,
      enduranceSprintsDescriptions(100*60, 10, 50*60, 10*60)),
    WorkoutItem.create({ name: 'endurance_sprints', format: ['3h00'] }, 'endurance', { description: 'endurance_sprints', format: {sprints: '10'} },
      '5:00@50%ftp, 20:00@55%ftp, 50:00@60%ftp, 30:00@65%ftp, 10:00@60%ftp !lap, 10*(7@90%max ontarget, 4:53@55%ftp !lap), 10:00@60%ftp, 5:00@50%ftp', 0,
      enduranceSprintsDescriptions(115*60, 10, 50*60, 10*60)),
    WorkoutItem.create({ name: 'endurance_sprints', format: ['3h15'] }, 'endurance', { description: 'endurance_sprints', format: {sprints: '10'} },
      '5:00@50%ftp, 20:00@55%ftp, 50:00@60%ftp, 45:00@65%ftp, 10:00@60%ftp !lap, 10*(7@90%max ontarget, 4:53@55%ftp !lap), 10:00@60%ftp, 5:00@50%ftp', 0,
      enduranceSprintsDescriptions(130*60, 10, 50*60, 10*60)),
    WorkoutItem.create({ name: 'endurance_sprints', format: ['3h30'] }, 'endurance', { description: 'endurance_sprints', format: {sprints: '10'} },
      '5:00@50%ftp, 20:00@55%ftp, 65:00@60%ftp, 45:00@65%ftp, 10:00@60%ftp !lap, 10*(7@90%max ontarget, 4:53@55%ftp !lap), 10:00@60%ftp, 5:00@50%ftp', 0,
      enduranceSprintsDescriptions(145*60, 10, 50*60, 10*60)),
    WorkoutItem.create({ name: 'endurance_sprints', format: ['4h00'] }, 'endurance', { description: 'endurance_sprints', format: {sprints: '15'} },
      '5:00@50%ftp, 25:00@55%ftp, 60:00@60%ftp, 45:00@65%ftp, 10:00@60%ftp !lap, 15*(7@90%max ontarget, 4:53@55%ftp !lap), 15:00@60%ftp, 5:00@50%ftp', 0,
      enduranceSprintsDescriptions(145*60, 10, 75*60, 15*60)),
  ],
);


function enduranceForceDescriptions(warmup, duration, intervals, recovery, cooldown){
  return [
    { zone: 2, duration: warmup, target: '50%ftp-70%ftp', description: 'progressive_warmup', format: { duration: (warmup/60)+'min' } },
    { zone: 2, duration: duration, target: '75%ftp', description: 'endurance_strength', format: { intervals: intervals, recovery: recovery } },
    { zone: 1, duration: cooldown, target: '60%ftp', description: 'cooldown' },
  ];
}

var enduranceForce = new WorkoutCategory(
  'enduranceForce',
  [
    WorkoutItem.create({ name: 'endurance_strength', format: ['1h'] }, 'endurance', 'endurance_strength',
      '5:00@55%ftp, 5:00@60%ftp, 10:00@65%ftp, 6:00@70%ftp, 4*(4:00@75%ftp60rpm, 2:00@65%ftp95rpm), 10:00@60%ftp', 0,
      enduranceForceDescriptions(26*60, 24*60, '4x4min', '2min', 10*60)),
    WorkoutItem.create({ name: 'endurance_strength', format: ['1h'] }, 'endurance', 'endurance_strength',
      '5:00@55%ftp, 10:00@65%ftp, 4:00@70%ftp, 5*(5:00@70%ftp60rpm, 2:00@65%ftp95rpm), 6:00@60%ftp', 0,
      enduranceForceDescriptions(19*60, 35*60, '5x5min', '2min', 6*60)),
    WorkoutItem.create({ name: 'endurance_strength', format: ['1h'] }, 'endurance', 'endurance_strength',
      '5:00@55%ftp, 10:00@65%ftp, 10:00@70%ftp, 6*(3:00@70%ftp60rpm, 1:30@65%ftp95rpm), 8:00@60%ftp', 0,
      enduranceForceDescriptions(25*60, 27*60, '6x3min', '1min30', 8*60)),

    WorkoutItem.create({ name: 'endurance_strength', format: ['1h15'] }, 'endurance', 'endurance_strength',
      '5:00@55%ftp, 5:00@60%ftp, 5:00@70%ftp, 7:00@65%ftp, 8*(4:00@75%ftp60rpm, 2:00@65%ftp95rpm), 5:00@60%ftp', 0,
      enduranceForceDescriptions(22*60, 48*60, '8x4min', '2min', 5*60)),
    WorkoutItem.create({ name: 'endurance_strength', format: ['1h15'] }, 'endurance', 'endurance_strength',
      '5:00@55%ftp, 5:00@60%ftp, 5:00@70%ftp, 10:00@65%ftp, 6*(5:00@75%ftp60rpm, 2:30@65%ftp95rpm), 5:00@60%ftp', 0,
      enduranceForceDescriptions(25*60, 45*60, '6x5min', '2min30', 5*60)),
    WorkoutItem.create({ name: 'endurance_strength', format: ['1h15'] }, 'endurance', 'endurance_strength',
      '5:00@55%ftp, 5:00@60%ftp, 10:00@65%ftp, 10:00@70%ftp, 8*(3:00@70%ftp60rpm, 1:30@65%ftp95rpm), 9:00@60%ftp', 0,
      enduranceForceDescriptions(30*60, 36*60, '8x3min', '1min30', 9*60)),

    WorkoutItem.create({ name: 'endurance_strength', format: ['1h30'] }, 'endurance', 'endurance_strength',
      '5:00@55%ftp, 5:00@60%ftp, 5:00@70%ftp, 10:00@65%ftp, 10*(4:00@75%ftp60rpm, 2:00@65%ftp95rpm), 5:00@60%ftp', 0,
      enduranceForceDescriptions(25*60, 60*60, '10x4min', '2min', 5*60)),
    WorkoutItem.create({ name: 'endurance_strength', format: ['1h30'] }, 'endurance', 'endurance_strength',
      '5:00@55%ftp, 5:00@60%ftp, 5:00@70%ftp, 10:00@65%ftp, 8*(5:00@75%ftp60rpm, 2:30@65%ftp95rpm), 5:00@60%ftp', 0,
      enduranceForceDescriptions(25*60, 60*60, '8x5min', '2min30', 5*60)),
    WorkoutItem.create({ name: 'endurance_strength', format: ['1h30'] }, 'endurance', 'endurance_strength',
      '5:00@55%ftp, 5:00@60%ftp, 6:00@70%ftp, 10:00@65%ftp, 12*(3:00@75%ftp60rpm, 1:30@65%ftp95rpm), 10:00@60%ftp', 0,
      enduranceForceDescriptions(26*60, 54*60, '12x3min', '1min30', 10*60)),

    WorkoutItem.create({ name: 'endurance_strength', format: ['1h45'] }, 'endurance', 'endurance_strength',
      '5:00@55%ftp, 5:00@60%ftp, 5:00@70%ftp, 10:00@65%ftp, 12*(4:00@75%ftp60rpm, 2:00@65%ftp95rpm), 8:00@60%ftp', 0,
      enduranceForceDescriptions(25*60, 72*60, '12x4min', '2min', 8*60)),
    WorkoutItem.create({ name: 'endurance_strength', format: ['1h45'] }, 'endurance', 'endurance_strength',
      '5:00@55%ftp, 5:00@60%ftp, 5:00@70%ftp, 10:00@65%ftp, 10*(5:00@75%ftp60rpm, 2:30@65%ftp95rpm), 5:00@60%ftp', 0,
      enduranceForceDescriptions(25*60, 75*60, '10x5min', '2min30', 5*60)),
    WorkoutItem.create({ name: 'endurance_strength', format: ['1h45'] }, 'endurance', 'endurance_strength',
      '5:00@55%ftp, 5:00@60%ftp, 6:00@70%ftp, 9:30@65%ftp, 15*(3:00@75%ftp60rpm, 1:30@65%ftp95rpm), 12:00@60%ftp', 0,
      enduranceForceDescriptions(25.5*60, 67.5*60, '15x3min', '1min30', 12*60)),

    WorkoutItem.create({ name: 'endurance_strength', format: ['2h'] }, 'endurance', 'endurance_strength',
      '5:00@55%ftp, 5:00@60%ftp, 5:00@65%ftp, 7:00@70%ftp, 10:00@65%ftp, 6*(4:00@75%ftp60rpm, 2:00@65%ftp95rpm), 6:00@60%ftp, 6*(4:00@75%ftp60rpm, 2:00@65%ftp95rpm), 10:00@60%ftp', 0,
      enduranceForceDescriptions(32*60, 78*60, '12x4min', '2min', 10*60)),
    WorkoutItem.create({ name: 'endurance_strength', format: ['2h'] }, 'endurance', 'endurance_strength',
      '5:00@55%ftp, 5:00@60%ftp, 5:00@65%ftp, 6:00@70%ftp, 10:00@65%ftp, 5*(5:00@75%ftp60rpm, 2:30@65%ftp95rpm), 6:00@60%ftp, 5*(5:00@75%ftp60rpm, 2:30@65%ftp95rpm), 8:00@60%ftp', 0,
      enduranceForceDescriptions(31*60, 81*60, '10x5min', '2min30', 8*60)),
    WorkoutItem.create({ name: 'endurance_strength', format: ['2h'] }, 'endurance', 'endurance_strength',
      '5:00@55%ftp, 5:00@60%ftp, 5:00@65%ftp, 7:00@70%ftp, 10:00@65%ftp, 8*(3:00@75%ftp60rpm, 1:30@65%ftp95rpm), 6:00@60%ftp, 8*(3:00@75%ftp60rpm, 1:30@65%ftp95rpm), 10:00@60%ftp', 0,
      enduranceForceDescriptions(32*60, 78*60, '16x3min', '1min30', 10*60)),

    WorkoutItem.create({ name: 'endurance_strength', format: ['2h30'] }, 'endurance', 'endurance_strength',
      '5:00@55%ftp, 5:00@60%ftp, 5:00@65%ftp, 7:00@70%ftp, 10:00@65%ftp, 8*(4:00@75%ftp60rpm, 2:00@65%ftp95rpm), 12:00@60%ftp, 8*(4:00@75%ftp60rpm, 2:00@65%ftp95rpm), 10:00@60%ftp', 0,
      enduranceForceDescriptions(32*60, 108*60, '16x4min', '2min', 10*60)),
    WorkoutItem.create({ name: 'endurance_strength', format: ['2h30'] }, 'endurance', 'endurance_strength',
      '5:00@55%ftp, 5:00@60%ftp, 5:00@65%ftp, 6:00@70%ftp, 10:00@65%ftp, 7*(5:00@75%ftp60rpm, 2:30@65%ftp95rpm), 6:00@60%ftp, 7*(5:00@75%ftp60rpm, 2:30@65%ftp95rpm), 8:00@60%ftp', 0,
      enduranceForceDescriptions(31*60, 111*60, '14x5min', '2min30', 8*60)),
    WorkoutItem.create({ name: 'endurance_strength', format: ['2h30'] }, 'endurance', 'endurance_strength',
      '5:00@55%ftp, 5:00@60%ftp, 5:00@65%ftp, 7:00@70%ftp, 10:00@65%ftp, 12*(3:00@75%ftp60rpm, 1:30@65%ftp95rpm), 9:00@60%ftp, 10*(3:00@75%ftp60rpm, 1:30@65%ftp95rpm), 10:00@60%ftp', 0,
      enduranceForceDescriptions(32*60, 108*60, '22x3min', '1min30', 10*60)),
  ],
);



module.exports = {
  enduranceF,
  enduranceActive,
  enduranceSprint,
  enduranceForce
};
