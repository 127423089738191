/*
 * Activity Item, will be considered the same than a training ride item but contain a rated activity instead
 */

const clonedeep = require('lodash.clonedeep');

//Intensity to keep when combining 2 activities is the highest one
function combinedIntensity(i1, i2){
  const intensities = ['easy', 'endurance', 'medium', 'hard', 'very_hard'];
  if(intensities.indexOf(i1) > intensities.indexOf(i2))
    return i1;
  return i2;
}

//Combine activities into one
function combineActivities(activities){
  if(activities.length === 1)
    return activities[0];

  //sort by duration to get the longest activity as the first one to combine
  activities.sort((a1, a2) => a1.duration < a2.duration);
  let activity = clonedeep(activities[0]);

  //For each other activity, combine the duration, intensity and load  (TODO: do other paramters too)
  for(let i=1; i<activities.length; i++){
    let a = activities[i];
    activity.duration += a.duration;
    activity.intensity_done = combinedIntensity(activity.intensity_done, a.intensity_done);
    if(activity.data.load && a.data.load)
      activity.data.load += a.data.load;
  }

  return activity;
}

class ActivityItem{
  constructor(activities){
    this.activity = combineActivities(activities);
    this.estimateData();
    this.category = null;
  }

  getIntensity(){ return this.activity.intensity_done; }

  loadMultiplier(){
    switch(this.activity.intensity_done){
      case 'easy': return 40;
      case 'endurance': return 60;
      case 'medium': return 70;
      case 'hard': return 80;
      case 'very_hard': return 90;
    }
    return 75;
  }

  estimateData(){
    if(this.activity.data.load && (this.activity.data.time_power_zones || this.activity.data.time_hr_zones)){
      this.data = this.activity.data;
      if(!this.activity.data.time_power_zones) //if no power zones, use hr zones instead
        this.data.time_power_zones = this.activity.data.time_hr_zones;
    }else{ //Try to simulate data if no data
      this.data = {
        load: this.getDuration()/3600 * this.loadMultiplier(), //Load simulation
        time_power_zones: this.estimateZones(),
      };
    }
  }

  estimateZones(){ //Time in zone simulation
    var d = this.getDuration();
    switch(this.activity.intensity_done){
      case 'easy': return [0.9*d, 0.09*d, 0.01*d, 0, 0, 0, 0];
      case 'endurance': return [0.2*d, 0.68*d, 0.1*d, 0.01*d, 0, 0, 0.01*d];
      case 'medium': return [0.2*d, 0.2*d, 0.5*d, 0.07*d, 0.01*d, 0.01*d, 0.01*d];
      case 'hard': return [0.2*d, 0.2*d, 0.2*d, 0.3*d, 0.05*d, 0.025*d, 0.025*d];
      case 'very_hard': return [0.3*d, 0.1*d, 0.15*d, 0.2*d, 0.1*d, 0.1*d, 0.05*d];
    }
    return [0.2*d, 0.2*d, 0.2*d, 0.3*d, 0.05*d, 0.025*d, 0.025*d];
  }

  setCategory(category){ this.category = category; }
  getCategory(){ return this.category; }

  getData(user){
    return this.data;
  }

  getLoad(user){
    return this.getData(user).load;
  }

  getName(){ return this.activity.name; }
  getDuration(){ return this.activity.duration || 3600; }

  categoryName(){
    return this.category?.name || 'activity';
  }

  toWorkout(user, date){
    return null;
  }

  isRide(){ return true; }

  isWorkout(){ return false; }
  isObjective(){ return false; }
  isTrainingRide(){ return false; }
  isActivity(){ return true; }
  isRest(){ return false; }

  isSet(){ return true; }
}

module.exports = ActivityItem;
