/*
 * Workout planner: Generate all the workouts for each training week
 */

var dayjs = require('dayjs');
dayjs.extend(require('dayjs/plugin/utc'));
const genetic = require('./genetic');
const WorkoutItem = require('./workoutItem');
const ObjectiveItem = require('./objectiveItem');
const TrainingRideItem = require('./trainingRideItem');
const ActivityItem = require('./activityItem');
const generalRules = require('./blocktypes/generalRules');
const plannerUtil = require('./plannerUtil');
const util = require('../util');
const otherWorkouts = require('./workoutslib/otherWorkouts');
const clonedeep = require('lodash.clonedeep');



//Block Type class to contains the workouts category and rules of each block
class BlockType{
  constructor({categories, rules}){
    this.categories = (categories || []).concat(generalRules.categories).concat({ workouts: ['REST'] }); //Add the general category and rules to each block
    this.rules = generalRules.rules.concat(rules || []);
  }
}

//Load each block type with their key
const blockTypes = {
  'TESTING': new BlockType(require('./blocktypes/testingBlock')),
  'PREPARATION': new BlockType(require('./blocktypes/preparationBlock')),
  'BASE_1': new BlockType(require('./blocktypes/baseBlock1')),
  'BASE_2': new BlockType(require('./blocktypes/baseBlock2')),
  'BASE_3': new BlockType(require('./blocktypes/baseBlock3')),
  'BUILD_1': new BlockType(require('./blocktypes/buildBlock1')),
  'BUILD_2': new BlockType(require('./blocktypes/buildBlock2')),
  'RECOVERY_BASE_1': new BlockType(require('./blocktypes/recoveryBaseBlock')),
  'RECOVERY_BASE_2': new BlockType(require('./blocktypes/recoveryBaseBlock')),
  'RECOVERY_BASE_3': new BlockType(require('./blocktypes/recoveryBaseBlock')),
  'RECOVERY_BUILD_1': new BlockType(require('./blocktypes/recoveryBuildBlock')),
  'RECOVERY_BUILD_2': new BlockType(require('./blocktypes/recoveryBuildBlock')),
  'TAPER': new BlockType(require('./blocktypes/taperBlock')),
};



//Plan workouts for each blocks
function planWorkouts(user, blocks){
  var plannedItemsMap = plannerUtil.calcPlannedItemsMap(user);
  var plan = [];
  for(var block of blocks){
    var previousWorkout = plan.length > 0 ? plan[plan.length-1] : null; //Last workout of the previous block
    plan = plan.concat(planForBlock(user, block, plannedItemsMap, previousWorkout));
  }
  return plan;
}



//Replan workouts for a week
function planWorkoutsForWeek(user, block, weekDate){
  weekDate = dayjs.utc(Math.max(block.start, weekDate));
  var endWeekDate = dayjs.utc(Math.min(weekDate.endOf('week'), block.end));
  var plannedItemsMap = plannerUtil.calcPlannedItemsMap(user);

  var weeks = block.nbWeeks();
  var days = block.nbDays();
  var weeksLengths = block.weeksLengths();
  var weeksStarts = block.weeksStarts();
  var plan = [];

  const today = dayjs.utc();

  //Replan workouts for the whole block but every workouts that are not from the week to regenerate are set still
  for(var week=0; week<weeks; week++){
    var weekLength = weeksLengths[week];
    var weekStart = dayjs.utc(weeksStarts[week]);
    var weekEnd = weekStart.add(weekLength-1, 'day');
    var previousWorkout = findItemForDay(user, weekStart.add(-1, 'day')); //Find last workout item of the previous week
    var nextWorkout = findItemForDay(user, weekEnd.add(1, 'day')); //Find first workout item of the next week

    var weekPlan = new Array(weekLength);
    var date = weekStart;
    for(var i = 0; i < weekPlan.length; i++){
      if(!weekDate.isSame(date, 'week') || date.isBefore(today, 'day') || (date.isSame(today, 'day'))){
        weekPlan[i] = findItemForDay(user, date); //If day is not from week to regenerate (or is before current day or is today), then set the workout
        weekPlan[i].set = true;
        if(date.isSame(today, 'day') && weekPlan[i] === 'REST') //If today is a REST day, allow change
          weekPlan[i] = null;
      }
      date = date.add(1, 'day');
    }

    plan = plan.concat(planWeek(user, block, week, weeksStarts[week], weekLength, plannedItemsMap, previousWorkout, nextWorkout, weekPlan));
  }

  return plan.slice(weekDate.diff(block.start, 'day'), endWeekDate.diff(block.start, 'day')+1); //Return only the workouts of the week
}


//Find an already existing workout item for the day
//Either a generated workout or an objective (and REST if none)
//Mainly used during regeneration to get last previous workout before the regenerated week and the next one after.
function findItemForDay(user, day){
  var workout = user.workouts.find(workout => workout.data && workout.data.category && dayjs.utc(workout.date).isSame(day, 'date'));
  if(workout)
    return new WorkoutItem(workout, { name: workout.data.category }); //TODO: find the category object ?

  var objective = user.objectives.find(objective => dayjs.utc(objective.date).isSame(day, 'date'));
  if(objective)
    return new ObjectiveItem(objective);


  var activities = user.activities.filter(activity => !!activity.intensity_done && dayjs.utc(activity.date).isSame(day, 'date'));
  if(activities && activities.length){
    return new ActivityItem(activities);
  }

  var trainingRide = user.training_rides.find(ride => dayjs.utc(ride.date).isSame(day, 'date'));
  if(trainingRide){
    return new TrainingRideItem(trainingRide);
  }


  return 'REST';
}

//Plan workouts for a block
function planForBlock(user, block, plannedItemsMap, previousWorkout){
  var weeks = block.nbWeeks();
  var days = block.nbDays();
  var weeksLengths = block.weeksLengths();
  var weeksStarts = block.weeksStarts();
  var plan = [];
  //Plan workouts for each week of the block
  for(var week=0; week<weeks; week++){
    if(plan.length > 0) //For first week, the last workout of the previous block is send. For the weeks after, look for the last current generated workout
      previousWorkout = plan[plan.length-1];
    plan = plan.concat(planWeek(user, block, week, weeksStarts[week], weeksLengths[week], plannedItemsMap));
  }
  return plan;
}




//Plan workout for a week
function planWeek(user, block, week, startDate, weekLength, plannedItemsMap, previousWorkout, nextWorkout = null, plan = new Array(weekLength)){
  week = block.getWeekNumberForWeek(week); //Recovery weeks get a different week number based on their number. And shorter blocks can also have different week number (the last week of a 2 week block should be week 3)


  var blockType = blockTypes[block.type];

  if(!blockType){ //Error if block type is not defined
    console.error('Unknown block type: ' + block.type);
    console.error(weekLength);
    return (new Array(weekLength)).fill('REST'); //Return only rest day
  }
  const availableTime = plannerUtil.getUserAvailableTime(user, startDate);
  var maxTime = availableTime.max_week_available_time;
  var timePerDay = availableTime.available_time_days;

  //Every day where the user can't train is a rest day.
  for(var i=0; i<plan.length; i++){
    if(plan[i] && plan[i] !== 'REST' && plan[i].isSet()) //If a set item is already defined, no change
      continue;

    if(timePerDay[(startDate.getDay()+6+i)%7] === 0){  //Modulo 7 to get a day number between 0 and 6. Add 6 to start day so that monday become 0 and sunday become 6
      plan[i] = 'REST';
    }

    let objectiveNextDay = plannedItemsMap[dayjs.utc(startDate).add(i+1, 'day').toDate()];
    if(objectiveNextDay && objectiveNextDay.priority === 'A'){ //If there is an objective A next day, then it's an opener
      plan[i] = clonedeep(otherWorkouts.opener.workouts[0]); //clone opener and set it as a set workout
      plan[i].set = true;
    }
    //If objective during the week, add it to the plan
    let plannedItem = plannedItemsMap[dayjs.utc(startDate).add(i, 'day').toDate()];
    if(plannedItem){
      plan[i] = plannedItem;
    }

  }

  var endDate = dayjs.utc(startDate).add(weekLength, 'day').toDate();


  //Data that may be used by some rules for the workouts planning
  var data = {
    weekStartDate: startDate,
    startDay: (startDate.getDay()+6)%7, //Add 6 to start day so that monday become 0 and sunday become 6
    week: week,
    weekLength: weekLength,
    plannedItemsMap: plannedItemsMap,
    previousWorkout: previousWorkout,
    nextWorkout: plannedItemsMap[endDate] ? plannedItemsMap[endDate] : nextWorkout,
    trainingTypeDays: plannerUtil.getUserDatesTrainingTypes(user, startDate, weekLength),
  };
  console.log(block.type, ' week ', week+1, ' user: ', user.username);

  return fillDaysGenetic(user, plan, blockType, data); //Genetic algorithm call to find the best possible workout planning for the week
}



/************************************/
/* Genetic algorithm to fill days   */
/************************************/


//Check that the generated plan is fully generated (no empty days)
function checkPlanFull(plan){
  for(var i=0; i<plan.length; i++)
    if(!plan[i])
      return false;
  return true;
}


//Check if the generated plan is valid
function checkPlan(user, plan, blockType, data){
  //Check that the workouts fit the user available time (day by day and max week time)
  var totalDuration = 0;
  var totalDurationSetWorkouts = 0;
  var availableTime = plannerUtil.getUserAvailableTime(user, data.weekStartDate);
  var currentDay = dayjs.utc(data.weekStartDate);
  var currentWeek = currentDay.startOf('week');
  var dayCount = 0;
  const daysAvailableTime = plannerUtil.getUserDatesAvailableTimeForPlan(user, data.weekStartDate, plan.length);


  for(var i=0; i<plan.length; i++){

    var newCurrentWeek = currentDay.startOf('week');
    if(newCurrentWeek > currentWeek){ //If it's next week. Test week available time
      if(totalDurationSetWorkouts > availableTime.max_week_available_time*(dayCount/7)){
        if(totalDuration !== totalDurationSetWorkouts)
          return false;
      }else if(totalDuration > availableTime.max_week_available_time*(dayCount/7)){
        return false;
      }

      //Reset all durations values
      availableTime = plannerUtil.getUserAvailableTime(user, currentDay); //Load new available time for new week
      totalDuration = 0;
      totalDurationSetWorkouts = 0;
      dayCount = 0;
      currentWeek = newCurrentWeek; //Current week is now the new current week
    }


    var workout = plan[i];
    if(workout && workout !== 'REST' && workout.isWorkout()){
      if(workout.isSet()){
        totalDurationSetWorkouts += workout.getDuration();
      }else{ //Only check duration of day if it's not a set workout
        if(workout.getDuration() > daysAvailableTime[i] + 60) //Allow workout to be 60sec longer max than day available time for convenience
          return false;
      }

      totalDuration += workout.getDuration();
    }
    currentDay = currentDay.add(1, 'day');
    dayCount++;
  }


  if(totalDurationSetWorkouts > availableTime.max_week_available_time*(dayCount/7)) //If total duration of set workouts exceed max week time
    return totalDuration === totalDurationSetWorkouts; //Then total duration must be equal to total duration of set workouts (no more workouts)
  return totalDuration <= availableTime.max_week_available_time*(dayCount/7);
}

//Function that evaluate the generated plan value (the function the algorithm optimize)
function fitness(user, plan, blockType, data){
  var value = 0;
  for(var rule of blockType.rules){
    value += rule.check(user, plan, data); //Check every rule of the block type and add every points
    if(isNaN(value)){
      console.error(rule);
      throw '';
    }
  }
  return value;
}

//Return a random element of an array
function sample(array){
  return array[Math.floor(Math.random()*array.length)];
}

//Pick a random workout category
function pickWorkoutCategory(blockType){
  return sample(blockType.categories);
}

//Pick a random workout
function pickWorkout(blockType){
  return sample(pickWorkoutCategory(blockType).workouts); //Pick a workout category then a random workout inside the category
}

//Check if day is set and not a training ride
function isDayFullySet(plan, i){
  if(plan[i] && plan[i] !== 'REST' && plan[i].isRide())
    return false;
  return plan[i];
}

//Fill day with a workout (or set a category to a training ride if one is planned for this day)
function fillDay(plan, i, blockType){
  if(plan[i] && plan[i] !== 'REST' && plan[i].isRide()){ //Else if day is a planned training ride, set a workout category
    plan[i].setCategory(pickWorkoutCategory(blockType));
  }else{
    plan[i] = pickWorkout(blockType);
  }
}

//This will call the genetic algorithm
function fillDaysGenetic(user, plan, blockType, data){
  if(checkPlanFull(plan))
    return plan; //If plan already full, return it


  //Seed function: create a random plan
  let seed = () => {
    var newPlan = [...plan]; //copy of the original plan data (contain rest and objective day that are already set)
    for(var i=0; i<newPlan.length; i++){
      //if(!newPlan[i])
      //  newPlan[i] = pickWorkout(blockType);
      if(!newPlan[i])
        fillDay(newPlan, i, blockType); //For each day that aren't already filled (by a rest day or objective), pick a random workout
    }
    return newPlan;
  };

  //Seed a plan with day filled with rest day
  //Useful if all generated plan are don't fit checkplan hours requirements (can happen i a (near) empty week)
  let seedZero = () => {
    var newPlan = [...plan]; //copy of the original plan data (contain rest and objective day that are already set)
    for(var i=0; i<newPlan.length; i++){
      if(!newPlan[i]){
        newPlan[i] = 'REST';
      }
    }
    return newPlan;
  }

  //Mutate the plan
  let mutate = function(newPlan){
    newPlan = [...newPlan]; //Clone the parent plan
    var i = 0;
    do{
      i = Math.floor(Math.random()*newPlan.length); //TODO: optimize pick if several set day in plan ?
    }while(isDayFullySet(plan, i)); //Pick a random day that isn't a fully set day
    fillDay(newPlan, i, blockType);
    return newPlan;
  };

  //Create two child plan from two parent plan
  let crossover = function(mother, father){
    var cross = Math.floor(Math.random()*mother.length); //Select a random cross point
    var son = father.slice(0, cross).concat(mother.slice(cross)); //First child: father workouts before cross and mother workout after
    var daughter = mother.slice(0, cross).concat(father.slice(cross)); //Second child: opposite
    return [son, daughter];
  };

  //Fitness function to evaluate plan
  let fit = function(newPlan){
    if(!checkPlan(user, newPlan, blockType, data))
      return Number.NEGATIVE_INFINITY; //If plan non valid, return negative infinity
    return fitness(user, newPlan, blockType, data); //Else return fitness evaluation
  };

  var result = genetic.evolve(seed, mutate, crossover, fit, seedZero);

  return result;
}

module.exports = {
  BlockType,
  planWorkouts,
  planForBlock,
  planWorkoutsForWeek,
  fillDaysGenetic,
  fitness,
};
