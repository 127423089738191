module.exports = {
  ...require('./testWorkouts'),
  ...require('./recoveryWorkouts'),
  ...require('./enduranceWorkouts'),
  ...require('./tempoWorkouts'),
  ...require('./thresholdWorkouts'),
  ...require('./PMAWorkouts'),
  ...require('./vo2maxWorkouts'),
  ...require('./anaerobicWorkouts'),
  ...require('./sprintWorkouts'),
  ...require('./otherWorkouts'),
  ...require('./hometrainerWorkouts'),
};
