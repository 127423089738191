/*
 * Base Rule Class. All rules will inherit this class
 * Only handle priority and points calculation on success or fail (with potentially a multiplier)
 */

class Rule{
  constructor(priority = 'REQUIRED', multiplier = 1){
    this.priority = priority;
    this.multiplier = multiplier;

    if(!['LOW', 'MEDIUM', 'HIGH', 'VERYHIGH', '!LOW', '!MEDIUM', '!HIGH', '!VERYHIGH', 'FORBIDDEN', 'REQUIRED'].includes(priority)){ //Throw error if not valid priority
      console.trace();
      throw 'Unknown priority: '+priority;
    }
  }

  _pointsOnSuccess(){ //Points received on success
    switch(this.priority){
      case 'VERYHIGH': return 500;
      case 'HIGH': return 100;
      case 'MEDIUM': return 50;
      case 'LOW': return 25;
      case 'REQUIRED': return 0;
      case 'FORBIDDEN': return -100000000; //Forbiden = not allowed to success
      case '!VERYHIGH': return -100; //On inverted priority, give same points as points on fail for success
      case '!HIGH': return -25;
      case '!MEDIUM': return -1;
      case '!LOW': return 0;
    }

    console.error('Wrong rule priority : ' + this.priority);
    return 0;
  }

  pointsOnSuccess(){
    return this._pointsOnSuccess() * this.multiplier;
  }

  _pointsOnFail(){
    switch(this.priority){
      case 'VERYHIGH': return -100;
      case 'HIGH': return -25;
      case 'MEDIUM': return -1;
      case 'LOW': return 0;
      case 'REQUIRED': return -100000000; //Not allowed to fail so lot of negative points given
      case 'FORBIDDEN': return 0;
      case '!VERYHIGH': return 500; //On inverted priority, give same points as points on success for fail
      case '!HIGH': return 100;
      case '!MEDIUM': return 50;
      case '!LOW': return 25;
    }

    console.error('Wrong rule priority : ' + this.priority);
    return 0;
  }

  pointsOnFail(){
    return this._pointsOnFail() * this.multiplier;
  }

  //Method that inhering rules need to implement. Return a number of points (success or fail) depending of the plan
  check(user, plan, data){
    return 0;
  }


  static workoutCategoryName(workout){
    return (!workout || workout === 'REST') ? 'REST' : workout.categoryName();
  }


  //Debug help function, log only once for this object
  logOnce(log){
    if(!this.logged){
      this.logged = true;
      console.log(log);
    }
  }

}

module.exports = Rule;
