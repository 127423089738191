const WorkoutCategory = require('../workoutCategory');
const WorkoutItem = require('../workoutItem');
const rules = require('../rules/rules');
const workouts = require('../workoutslib/workoutsLib');







module.exports = {
    categories: [ workouts.recovery, workouts.enduranceF, workouts.enduranceSprint, workouts.tempo, workouts.pma, workouts.threshold, workouts.sprints, workouts.lactic

    ],

    rules: [
      rules.placementRule('enduranceSprint', 'AFTER', ['enduranceForce', 'enduranceActive', 'enduranceF'], 'LOW'),
      rules.placementRule(['tempo', 'tempoForce'], 'AFTER', ['tempo', 'tempoForce'], '!VERYHIGH', 2),
      rules.placementRule('threshold', 'AFTER', 'tempoForce', '!VERYHIGH', 2),
      rules.placementRule(['tempo', 'tempoForce'], 'AFTER', ['threshold', 'thresholdForce'], '!VERYHIGH', 2),
      rules.placementRule('tempoForce', 'AFTER', 'PMA', 'LOW'),

      rules.placementRule(['vo2max', 'PMA', 'tempo', 'tempoForce', 'threshold', 'thresholdForce', 'lactic', 'sprints', 'maxStrength'], 'BEFORE', ['enduranceF', 'enduranceActive', 'enduranceSprint', 'REST', 'recovery'], 'VERYHIGH'),
      rules.placementRule(['vo2max', 'PMA', 'tempo', 'tempoForce', 'threshold', 'thresholdForce', 'lactic', 'sprints', 'maxStrength'], 'BEFORE', ['enduranceF', 'recovery', 'REST'], 'HIGH'),

      rules.placementRule(['vo2max', 'PMA', 'lactic', 'sprints', 'maxStrength', 'threshold', 'thresholdForce'], 'AFTER', ['REST', 'enduranceF', 'recovery', 'enduranceSprint'], 'VERYHIGH', 3),
      rules.placementRule(['vo2max', 'PMA', 'lactic', 'sprints', 'maxStrength', 'threshold', 'thresholdForce'], 'AFTER', ['REST', 'enduranceF', 'recovery'], 'HIGH'),
      rules.placementRule(['vo2max', 'PMA', 'lactic', 'sprints', 'maxStrength'], 'AFTER', ['REST', 'enduranceF', 'recovery'], 'VERYHIGH'),
      rules.placementRule(['vo2max', 'PMA', 'lactic', 'sprints', 'maxStrength'], 'AFTER', ['REST', 'recovery'], 'HIGH'),

      rules.placementRule('recovery', 'AFTER', ['vo2max', 'PMA', 'lactic', 'sprints', 'maxStrength', 'threshold', 'thresholdForce'], 'HIGH'),
      rules.placementRule('recovery', 'BEFORE', ['vo2max', 'PMA', 'lactic', 'sprints', 'maxStrength', 'threshold', 'thresholdForce'], 'HIGH'),

      rules.placementRule(['lactic', 'sprints', 'maxStrength'], 'BEFORE', ['enduranceF', 'recovery', 'REST'], 'VERYHIGH', 2),

      rules.placementRule('tempo', 'BEFORE', ['vo2max', 'PMA', 'lactic'], '!VERYHIGH', 3),
      rules.placementRule('tempoForce', 'BEFORE', ['vo2max', 'PMA', 'lactic'], '!VERYHIGH', 3),

      rules.placementRule(['vo2max', 'PMA', 'threshold', 'thresholdForce', 'lactic', 'sprints', 'maxStrength'], 'BEFORE', 'objectiveC', 'FORBIDDEN'),
      rules.placementRule(['vo2max', 'PMA', 'threshold', 'thresholdForce', 'lactic', 'sprints', 'maxStrength'], 'BEFORE', 'opener', 'FORBIDDEN'),

      rules.placementRule(['vo2max', 'PMA', 'lactic', 'sprints', 'maxStrength', 'threshold', 'thresholdForce'], 'AFTER', ['vo2max', 'PMA', 'lactic', 'sprints', 'maxStrength', 'threshold', 'thresholdForce'], '!VERYHIGH', 2),
      rules.placementRule(['vo2max', 'PMA', 'lactic', 'sprints', 'maxStrength', 'threshold', 'thresholdForce'], 'BEFORE', ['vo2max', 'PMA', 'lactic', 'sprints', 'maxStrength', 'threshold', 'thresholdForce'], '!VERYHIGH', 2),

      //rules.orderRule('enduranceF', 'AFTER', ['REST', 'enduranceActive', 'enduranceSprint'], 'HIGH'),
      //rules.orderRule(['vo2max', 'PMA'], 'AFTER', ['enduranceActive', 'enduranceF','recovery'], 'HIGH'),


      rules.trainingRideCategory(['hard', 'very_hard'], ['vo2max', 'PMA', 'threshold', 'thresholdForce', 'lactic', 'sprints', 'maxStrength'], 'REQUIRED'),
      rules.trainingRideCategory('hard', ['threshold', 'thresholdForce'], 'HIGH'),
      rules.trainingRideCategory('very_hard', ['vo2max', 'PMA', 'lactic'], 'HIGH'),
      rules.trainingRideCategory('medium', ['tempo', 'tempoForce', 'vo2max', 'PMA', 'threshold', 'thresholdForce', 'lactic', 'sprints', 'maxStrength'], 'REQUIRED'),
      rules.trainingRideCategory('medium', ['tempo', 'tempoForce'], 'VERYHIGH'),
      rules.trainingRideCategory('medium', ['sprints', 'maxStrength'], 'MEDIUM'),
      rules.trainingRideCategory('endurance', ['enduranceF', 'enduranceSprint', 'enduranceActive'], 'REQUIRED'),
      rules.trainingRideCategory('easy', ['recovery', 'enduranceF', 'enduranceSprint', 'enduranceActive'], 'REQUIRED'),
      rules.trainingRideCategory('easy', 'recovery', 'VERYHIGH'),

      rules.ifObjectivesNumber('>=', 1, 'ABC', rules.frequencyRule(['tempo', 'tempoForce', 'vo2max', 'PMA', 'threshold', 'thresholdForce', 'lactic', 'sprints', 'maxStrength'], 'MAX', 2, 'REQUIRED', 2)),
      rules.ifObjectivesNumber('>=', 2, 'ABC', rules.frequencyRule(['tempo', 'tempoForce', 'vo2max', 'PMA', 'threshold', 'thresholdForce', 'lactic', 'sprints', 'maxStrength'], 'MAX', 1, 'REQUIRED', 2)),
      rules.ifObjectivesNumber('>=', 3, 'ABC', rules.frequencyRule(['tempo', 'tempoForce', 'vo2max', 'PMA', 'threshold', 'thresholdForce', 'lactic', 'sprints', 'maxStrength'], 'MAX', 0, 'REQUIRED', 2)),

      rules.ifObjectivesNumber('>=', 1, 'ABC', rules.frequencyRule(['threshold', 'thresholdForce'], 'MAX', 0, 'VERYHIGH')),
      rules.ifObjectivesNumber('>=', 1, 'ABC', rules.frequencyRule(['lactic', 'sprints', 'maxStrength'], 'MAX', 0, 'MEDIUM')),
      rules.ifObjectivesNumber('>=', 1, 'ABC', rules.frequencyRule(['tempo', 'tempoForce'], 'MAX', 0, 'LOW')),

      rules.ifObjectivesNumber('>=', 2, 'ABC', rules.frequencyRule(['lactic', 'sprints', 'maxStrength'], 'MAX', 0, 'VERYHIGH')),
      rules.ifObjectivesNumber('>=', 2, 'ABC', rules.frequencyRule(['tempo', 'tempoForce'], 'MAX', 0, 'MEDIUM')),


      rules.frequencyRule('tempo', 'MAX', 1, 'REQUIRED'),
      rules.frequencyRule('tempoForce', 'MAX', 1, 'REQUIRED'),
      rules.frequencyRule('threshold', 'MAX', 1, 'REQUIRED'),
      rules.frequencyRule('PMA', 'MAX', 1, 'REQUIRED'),
      rules.frequencyRule('lactic', 'MAX', 1, 'REQUIRED'),
      rules.frequencyRule('sprints', 'MAX', 1, 'REQUIRED'),
      rules.frequencyRule('enduranceF', 'MAX', 1, 'REQUIRED'),
      rules.frequencyRule('enduranceSprint', 'MAX', 1, 'REQUIRED'),
      rules.frequencyRule('recovery', 'MAX', 1, 'REQUIRED'),

      rules.frequencyRule('enduranceF', 'MIN', 1, 'REQUIRED'),
      rules.frequencyRule('enduranceSprint', 'MIN', 1, 'MEDIUM'),
      rules.frequencyRule('recovery', 'MIN', 1, 'LOW'),

      rules.ifProfileType('climber', [
        rules.frequencyRule(['lactic', 'sprints'], 'MAX', 0, 'REQUIRED'),

        rules.frequencyRule('threshold', 'MIN', 1, 'VERYHIGH'),
        rules.frequencyRule('tempoForce', 'MIN', 1, 'HIGH'),

        rules.forWeek(1, [
          rules.frequencyRule('tempo', 'MIN', 1, 'VERYHIGH', 2),

          rules.frequencyRule('PMA', 'MAX', 0, 'REQUIRED'),
        ]),
        rules.forWeek(2, [
        ]),
      ]),

      rules.ifProfileType('puncher', [
        rules.frequencyRule(['tempoForce', 'sprints'], 'MAX', 0, 'REQUIRED'),

        rules.frequencyRule('threshold', 'MIN', 1, 'VERYHIGH'),
        rules.frequencyRule('lactic', 'MIN', 1, 'HIGH'),

        rules.forWeek(1, [
          rules.frequencyRule('tempo', 'MIN', 1, 'VERYHIGH', 2),

          rules.frequencyRule('PMA', 'MAX', 0, 'REQUIRED'),
        ]),
        rules.forWeek(2, [
        ]),
      ]),

      rules.ifProfileType('rouleur', [
        rules.frequencyRule(['lactic', 'sprints'], 'MAX', 0, 'REQUIRED'),

        rules.frequencyRule('PMA', 'MIN', 1, 'VERYHIGH'),
        rules.frequencyRule('tempoForce', 'MIN', 1, 'HIGH'),

        rules.forWeek(1, [
          rules.frequencyRule('tempo', 'MIN', 1, 'VERYHIGH', 2),

          rules.frequencyRule('threshold', 'MAX', 0, 'REQUIRED'),
        ]),
        rules.forWeek(2, [
        ]),
      ]),

      rules.ifProfileType('sprinter', [
        rules.frequencyRule(['tempoForce', 'sprints'], 'MAX', 0, 'REQUIRED'),

        rules.frequencyRule('PMA', 'MIN', 1, 'VERYHIGH'),
        rules.frequencyRule('threshold', 'MIN', 1, 'HIGH'),

        rules.forWeek(1, [
          rules.frequencyRule('tempo', 'MIN', 1, 'VERYHIGH', 2),

          rules.frequencyRule('sprints', 'MAX', 0, 'REQUIRED'),
        ]),
        rules.forWeek(2, [
        ]),
      ]),

      rules.forWeek(2, [
        rules.frequencyRule(['tempoForce', 'sprints', 'tempo', 'threshold', 'lactic'], 'MAX', 0, 'REQUIRED'),
        rules.frequencyRule('PMA', 'MIN', 1, 'VERYHIGH'),
        rules.ifObjectivesNumber('>=', 2, 'ABC', rules.frequencyRule('PMA', 'MAX', 0, 'REQUIRED')),
      ]),

      rules.ifProfileType('grandfondo', [
        rules.frequencyRule(['lactic', 'sprints'], 'MAX', 0, 'REQUIRED'),

        rules.frequencyRule('tempoForce', 'MIN', 1, 'VERYHIGH'),
        rules.frequencyRule('PMA', 'MIN', 1, 'HIGH'),

        rules.forWeek(1, [
          rules.frequencyRule('tempo', 'MIN', 1, 'VERYHIGH', 2),

          rules.frequencyRule('threshold', 'MAX', 0, 'REQUIRED'),
        ]),
        rules.forWeek(2, [
        ]),
      ]),



      //Taper1
      rules.forWeek(1, [
        rules.ifObjectivesNumber('=', 0, 'AB', rules.weekHoursTargetRule('TARGET', 80, '%', 'VERYHIGH', 5)),
        rules.ifObjectivesNumber('>=', 1, 'AB', rules.weekHoursTargetRule('TARGET', 70, '%', 'VERYHIGH', 5)),

        rules.longestWorkoutDurationTargetRule('TARGET', 1, 20, '%', 'HIGH'),
        rules.weekZoneTargetRule(['z1', 'z2', 'z3'], 'TARGET', 70, '%', 'HIGH'),
        rules.weekZoneTargetRule('z4', 'TARGET', 20, '%', 'HIGH'),
        rules.weekZoneTargetRule(['z5','z6', 'z7'], 'TARGET', 10, '%', 'HIGH'),

        rules.categoryLevelRule('tempo', 1, 'REQUIRED'),
        rules.categoryLevelRule('tempoForce', 1, 'REQUIRED'),
        rules.categoryLevelRule('threshold', 1, 'REQUIRED'),
        rules.categoryLevelRule('PMA', 1, 'REQUIRED'),

      ]),


      //Taper2
      rules.forWeek(2, [
        rules.weekHoursTargetRule('TARGET', 50, '%', 'VERYHIGH', 5),

        rules.longestWorkoutDurationTargetRule('TARGET', 1, 15, '%', 'HIGH'),

        rules.weekZoneTargetRule(['z1','z2', 'z3'], 'TARGET', 75, '%', 'HIGH'),
        rules.weekZoneTargetRule('z4', 'TARGET', 15, '%', 'HIGH'),
        rules.weekZoneTargetRule(['z5','z6', 'z7'], 'TARGET', 10, '%', 'HIGH'),

        rules.categoryLevelRule('tempo', -2, 'REQUIRED'),
        rules.categoryLevelRule('tempoForce', -2, 'REQUIRED'),
        rules.categoryLevelRule('threshold', -2, 'REQUIRED'),
        rules.categoryLevelRule('PMA', -2, 'REQUIRED'),


      ]),

    ],
};
