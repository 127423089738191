const WorkoutCategory = require('../workoutCategory');
const WorkoutItem = require('../workoutItem');
const rules = require('../rules/rules');
const workouts = require('../workoutslib/workoutsLib');







module.exports = {
  categories: [ workouts.enduranceF, workouts.enduranceActive, workouts.enduranceSprint,
     workouts.enduranceForce, workouts.tempoForce, workouts.tempo, workouts.vo2max, workouts.pma, workouts.sst,
  ],

  rules: [

    rules.frequencyRule('enduranceF', 'MIN', 1, 'REQUIRED'),
    rules.frequencyRule('tempo', 'EQUAL', 1, 'REQUIRED'),
    rules.frequencyRule('tempoForce', 'MIN', 1, 'VERYHIGH'),
    rules.frequencyRule('tempoForce', 'MAX', 1, 'REQUIRED'),
    rules.frequencyRule('enduranceSprint', 'MIN', 1, 'HIGH'),
    rules.frequencyRule('enduranceSprint', 'MAX', 1, 'REQUIRED'),
    rules.frequencyRule('enduranceActive', 'MIN', 1, 'HIGH'),
    rules.frequencyRule('enduranceF', 'MIN', 2, 'MEDIUM'),

    rules.frequencyRule('sst', 'MAX', 1, 'REQUIRED'),

    rules.placementRule('enduranceSprint', 'AFTER', ['enduranceForce', 'enduranceActive', 'enduranceF'], 'MEDIUM'),
    rules.placementRule('tempo', 'AFTER', 'tempoForce', '!VERYHIGH', 2),
    rules.placementRule('tempoForce', 'AFTER', 'tempo', '!VERYHIGH', 2),
    rules.placementRule(['vo2max', 'PMA', 'tempo', 'tempoForce'], 'BEFORE', ['enduranceF', 'enduranceActive', 'enduranceSprint' ], 'HIGH'),
    rules.placementRule(['enduranceF', 'enduranceActive', 'enduranceSprint' ], 'AFTER', ['vo2max', 'PMA', 'tempo', 'tempoForce'], 'HIGH'),
    rules.placementRule('tempo', 'BEFORE', ['vo2max', 'PMA'], '!VERYHIGH', 3),
    rules.placementRule('tempoForce', 'BEFORE', ['vo2max', 'PMA'], '!VERYHIGH', 3),
    rules.placementRule(['vo2max', 'PMA'], 'BEFORE', 'objectiveC', 'FORBIDDEN'),
    rules.placementRule('tempoForce', 'AFTER', ['vo2max', 'PMA'], 'MEDIUM'),

    rules.ifObjectivesNumber('>=', 1, 'ABC', rules.frequencyRule('tempoForce', 'MAX', 0, 'REQUIRED', 10)),
    rules.ifObjectivesNumber('>=', 2, 'ABC', rules.frequencyRule('vo2max', 'MAX', 0, 'REQUIRED', 10)),
    rules.ifObjectivesNumber('>=', 2, 'ABC', rules.frequencyRule('PMA', 'MAX', 0, 'REQUIRED', 10)),
    rules.ifObjectivesNumber('>=', 3, 'ABC', rules.frequencyRule('tempo', 'MAX', 0, 'REQUIRED', 10)),


    rules.trainingRideCategory(['hard', 'very_hard'], ['PMA', 'vo2max'], 'REQUIRED'),
    rules.trainingRideCategory('medium', ['tempo', 'tempoForce'], 'REQUIRED'),
    rules.trainingRideCategory('medium', 'tempo', 'LOW'),
    rules.trainingRideCategory('endurance', ['enduranceSprint', 'enduranceForce', 'enduranceActive', 'enduranceF'], 'REQUIRED'),
    rules.trainingRideCategory('easy', 'enduranceF', 'REQUIRED'),



    //Semaine 1
    rules.forWeek(1, [
      rules.weekHoursTargetRule('TARGET', 105, '%', 'VERYHIGH', 5),

      rules.frequencyRule('vo2max', 'MAX', 0, 'REQUIRED'),
      rules.frequencyRule('PMA', 'MAX', 0, 'REQUIRED'),

      rules.longestWorkoutDurationTargetRule('TARGET', 1, 15, '%', 'HIGH'),

      rules.categoryLevelRule('tempo', 0, 'REQUIRED'),
      rules.categoryLevelRule('tempoForce', 0, 'REQUIRED'),

      rules.weekZoneTargetRule(['z1', 'z2', 'z3'], 'TARGET', 80, '%', 'HIGH'),
      rules.weekZoneTargetRule('z4', 'TARGET', 10, '%', 'LOW'),
      rules.weekZoneTargetRule(['z5','z6', 'z7'], 'TARGET', 10, '%', 'LOW'),
    ]),

    //Semaine 2
    rules.forWeek(2, [
      rules.weekHoursTargetRule('TARGET', 120, '%', 'VERYHIGH', 5),

      rules.frequencyRule('PMA', 'EQUAL', 1, 'REQUIRED'),
      rules.frequencyRule('vo2max', 'MAX', 0, 'REQUIRED'),

      rules.frequencyRule('sst', 'MAX', 0, 'REQUIRED'),


      rules.categoryLevelRule('tempo', 0.5, 'REQUIRED'),
      rules.categoryLevelRule('tempoForce', 0.5, 'REQUIRED'),
      rules.categoryLevelRule('PMA', 0.5, 'REQUIRED'),

      rules.longestWorkoutDurationTargetRule('TARGET', 1, 20, '%', 'HIGH'),

      rules.weekZoneTargetRule(['z1', 'z2','z3'], 'TARGET', 75, '%', 'HIGH'),
      rules.weekZoneTargetRule('z4', 'TARGET', 15, '%', 'LOW'),
      rules.weekZoneTargetRule(['z5','z6', 'z7'], 'TARGET', 10, '%', 'HIGH'),
    ]),


    //Semaine 3
    rules.forWeek(3, [
      rules.weekHoursTargetRule('TARGET', 135, '%', 'VERYHIGH', 5),

      rules.frequencyRule('vo2max', 'EQUAL', 1, 'REQUIRED'),
      rules.frequencyRule('PMA', 'MAX', 0, 'REQUIRED'),

      rules.frequencyRule('sst', 'MAX', 0, 'REQUIRED'),


      rules.categoryLevelRule('tempo', 1, 'REQUIRED'),
      rules.categoryLevelRule('tempoForce', 1, 'REQUIRED'),

      rules.longestWorkoutDurationTargetRule('TARGET', 1, 20, '%', 'HIGH'),

      rules.weekZoneTargetRule(['z1', 'z2', 'z3'], 'TARGET', 70, '%', 'HIGH'),
      rules.weekZoneTargetRule('z4', 'TARGET', 20, '%', 'HIGH'),
      rules.weekZoneTargetRule(['z5','z6', 'z7'], 'TARGET', 10, '%', 'HIGH'),
    ]),

    rules.categoryLevelRule('vo2max', 0, 'REQUIRED'),

  ],
};
