const WorkoutCategory = require('../workoutCategory');
const WorkoutItem = require('../workoutItem');

var warmup15 = '2:00@55%ftp, 2:00@60%ftp, 2:00@65%ftp, 2:00@70%ftp, 2:00@75%ftp, 3*(15@105%map 45@55%ftp), 2:00@55%ftp,';
var warmup30 = '5:00@55%ftp, 5:00@60%ftp, 5:00@65%ftp, 3:00@75%ftp, 1:00@85%ftp, 1:00@90%ftp, 1:00@105%ftp, 1:00@45%ftp, 2*(15@105%map 45@50%ftp), 2:00@50%ftp, 2*(7@110%map 53@50%ftp), 2:00@50%ftp';
var warmup45 = '5:00@55%ftp, 5:00@60%ftp, 20:00@65%ftp, 3:00@75%ftp, 1:00@85%ftp, 1:00@90%ftp, 1:00@105%ftp, 1:00@45%ftp, 2*(15@105%map 45@50%ftp), 2:00@50%ftp, 2*(7@110%map 53@50%ftp), 2:00@50%ftp';
var warmup60 = '5:00@55%ftp, 5:00@60%ftp, 35:00@65%ftp, 3:00@75%ftp, 1:00@85%ftp, 1:00@90%ftp, 1:00@105%ftp, 1:00@45%ftp, 2*(15@105%map 45@50%ftp), 2:00@50%ftp, 2*(7@110%map 53@50%ftp), 2:00@50%ftp';
var warmup75 = '5:00@55%ftp, 5:00@60%ftp, 50:00@65%ftp, 3:00@75%ftp, 1:00@85%ftp, 1:00@90%ftp, 1:00@105%ftp, 1:00@45%ftp, 2*(15@105%map 45@50%ftp), 2:00@50%ftp, 2*(7@110%map 53@50%ftp), 2:00@50%ftp';
var warmup90 = '5:00@55%ftp, 5:00@60%ftp, 65:00@65%ftp, 3:00@75%ftp, 1:00@85%ftp, 1:00@90%ftp, 1:00@105%ftp, 1:00@45%ftp, 2*(15@105%map 45@50%ftp), 2:00@50%ftp, 2*(7@110%map 53@50%ftp), 2:00@50%ftp';
var warmup105 = '5:00@55%ftp, 5:00@60%ftp, 80:00@65%ftp, 3:00@75%ftp, 1:00@85%ftp, 1:00@90%ftp, 1:00@105%ftp, 1:00@45%ftp, 2*(15@105%map 45@50%ftp), 2:00@50%ftp, 2*(7@110%map 53@50%ftp), 2:00@50%ftp';


const warmupsDuration = [15*60,30*60,45*60,60*60,75*60,90*60, 105*60];
function sprintsDescriptions(sprintDuration){
  return (index) => [
    { zone: 2, duration: warmupsDuration[index], target: '55%ftp-110%ftp', description: 'progressive_warmup_HIT' },
    { zone: 7, duration: 30*60, target: '100%max', description: 'sprints', format: { nb_sprints: 6, sprints_duration: sprintDuration } },
    { zone: 1, duration: 15*60, target: '55%ftp', description: 'cooldown' },
  ];
}
var sprints = new WorkoutCategory(
  'sprints',
  [
    ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90, warmup105],
      'Sprint 12s', 'sprint', '', '6*(12@100%max, 4:48@55%ftp), 15:00@60%ftp', 0, sprintsDescriptions('12s')),
    ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90, warmup105],
      'Sprint 15s', 'sprint', '', '6*(15@100%max, 4:45@55%ftp), 15:00@60%ftp', 0, sprintsDescriptions('15s')),
    ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90, warmup105],
      'Sprint 20s', 'sprint', '', '6*(20@100%max, 4:40@55%ftp), 15:00@60%ftp', 0, sprintsDescriptions('20s')),
  ]
);


function maxStrengthDescriptions(duration, nbSprints, sprintDuration, cooldown){
  return (index) => [
    { zone: 2, duration: warmupsDuration[index], target: '55%ftp-110%ftp', description: 'progressive_warmup_HIT' },
    { zone: 7, duration: duration, target: '100%max', description: 'max_strength', format: { nb_sprints: nbSprints, sprints_duration: sprintDuration } },
    { zone: 1, duration: cooldown, target: '55%ftp', description: 'cooldown' },
  ];
}
var maxStrength = new WorkoutCategory(
  'maxStrength',
  [
    ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90, warmup105],
      { name: 'max_strength' }, 'sprint', { description: 'max_strength', format: { nbSprints: 6, sprintDuration: '15s' } }, '6*(15@100%max, 4:45@55%ftp), 15:00@55%ftp', 0,
      maxStrengthDescriptions(30*60, 6, '15s', 15*60)),
    ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90, warmup105],
      { name: 'max_strength' }, 'sprint', { description: 'max_strength', format: { nbSprints: 6, sprintDuration: '20s' } }, '6*(20@100%max, 4:40@55%ftp), 15:00@55%ftp', 0,
      maxStrengthDescriptions(30*60, 6, '20s', 15*60)),
    ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90, warmup105],
      { name: 'max_strength' }, 'sprint', { description: 'max_strength', format: { nbSprints: 6, sprintDuration: '10s' } }, '6*(10@100%max, 4:50@55%ftp), 15:00@55%ftp', 0,
      maxStrengthDescriptions(30*60, 6, '10s', 15*60)),
    ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90, warmup105],
      { name: 'max_strength' }, 'sprint', { description: 'max_strength', format: { nbSprints: 8, sprintDuration: '8s' } }, '8*(8@100%max, 3:52@55%ftp), 13:00@55%ftp', 0,
      maxStrengthDescriptions(8*4*60, 8, '8s', 13*60)),
    ...WorkoutItem.createWithWarmups([warmup15, warmup30, warmup45, warmup60, warmup75, warmup90, warmup105],
      { name: 'max_strength' }, 'sprint', { description: 'max_strength', format: { nbSprints: 10, sprintDuration: '8s' } }, '10*(8@100%max, 3:52@55%ftp), 5:00@55%ftp', 0,
      maxStrengthDescriptions(40*60, 10, '8s', 5*60)),
  ]
);

module.exports = {
  sprints,
  maxStrength,
};
