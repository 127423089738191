/*
 * Objective Item, similar to workout item, store a planned objective and simulate data for workouts generation
 */

class ObjectiveItem{
  constructor(objective){
    this.objective = objective;
    this.estimateData();
  }

  estimateData(){ //Try to simulate data
    this.data = {
      load: this.getDuration()/3600 * 90, //Load simulation
      time_power_zones: this.estimateZones(),
    };
  }

  estimateZones(){ //Time in zone simulation
    var d = this.getDuration();
    return [0.3*d, 0.1*d, 0.15*d, 0.2*d, 0.1*d, 0.1*d, 0.05*d];
  }

  getData(user){
    return this.data;
  }

  getLoad(user){
    return this.getData(user).load;
  }

  getName(){ return this.objective.name; }
  getDuration(){ return this.objective.estimated_duration || 3600; }

  categoryName(){
    return 'objective'+this.objective.priority;
  }

  priority(){ return this.objective.priority; }


  toWorkout(user, date){
    return null;
  }

  isRide(){ return false; }
  
  isWorkout(){ return false; }
  isObjective(){ return true; }
  isTrainingRide(){ return false; }
  isActivity(){ return false; }
  isRest(){ return false; }

  isSet(){ return true; }
}

module.exports = ObjectiveItem;
