const WorkoutCategory = require('../workoutCategory');
const WorkoutItem = require('../workoutItem');

const warmup15 =  '2:00@55%ftp, 2:00@60%ftp, 2:00@65%ftp, 2:00@70%ftp, 2:00@75%ftp, 3*(15@105%map 45@55%ftp), 2:00@55%ftp,';
const warmup20 =  '2:00@50%ftp, 2:00@60%ftp, 3:00@65%ftp, 2:00@70%ftp, 2:00@80%ftp, 2:00@95%ftp, 1:00@100%ftp, 1:00@110%ftp, 1:00@45%ftp, 3*(8@350%ftp, 52@55%ftp), 1:00@50%ftp,';
const warmup25 =  '2:00@55%ftp, 3:00@60%ftp, 5:00@65%ftp, 2:00@70%ftp, 2:00@80%ftp, 2:00@95%ftp, 1:00@100%ftp, 1:00@110%ftp, 2:00@45%ftp, 3*(8@350%ftp, 52@55%ftp), 2:00@50%ftp,';
const warmup30 =  '2:00@55%ftp, 8:00@60%ftp, 5:00@65%ftp, 2:00@70%ftp, 2:00@80%ftp, 2:00@95%ftp, 1:00@100%ftp, 1:00@110%ftp, 2:00@45%ftp, 3*(8@350%ftp, 52@55%ftp), 2:00@50%ftp,';


var sst = new WorkoutCategory(
'sst',
[
  // SST 45min
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '4*4min'} }, warmup20 + '4*(4:00@82%ftp, 1:30@60%ftp), 3:00@55%ftp', 1),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '6*2min30'} }, warmup20 + '6*(2:30@82%ftp, 1:00@60%ftp), 4:00@55%ftp', 1),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '8*2min'} }, warmup20 + '8*(2:00@82%ftp, 45@60%ftp), 3:00@55%ftp', 1),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '10*1min30'} }, warmup20 + '10*(1:30@82%ftp, 30@60%ftp), 5:00@55%ftp', 1),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '5*3min'} }, warmup20 + '5*(3:00@82%ftp, 1:00@60%ftp), 5:00@55%ftp', 1),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '4*3min30'} }, warmup20 + '4*(3:30@82%ftp, 1:30@60%ftp), 5:00@55%ftp', 1),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '2*7min'} }, warmup20 + '2*(7:00@82%ftp, 3:00@60%ftp), 5:00@55%ftp', 1),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '3*5min'} }, warmup20 + '3*(5:00@82%ftp, 1:30@60%ftp), 5:30@55%ftp', 1),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '3*6min'} }, warmup20 + '3*(6:00@82%ftp, 1:30@60%ftp), 2:30@55%ftp', 1),

  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '4*4min'} }, warmup20 + '4*(4:00@83%ftp, 1:30@61%ftp), 3:00@55%ftp', 2),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '6*2min30'} }, warmup20 + '6*(2:30@83%ftp, 1:00@61%ftp), 4:00@55%ftp', 2),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '8*2min'} }, warmup20 + '8*(2:00@83%ftp, 45@61%ftp), 3:00@55%ftp', 2),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '10*1min30'} }, warmup20 + '10*(1:30@83%ftp, 30@61%ftp), 5:00@55%ftp', 2),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '5*3min'} }, warmup20 + '5*(3:00@83%ftp, 1:00@61%ftp), 5:00@55%ftp', 2),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '4*3min30'} }, warmup20 + '4*(3:30@83%ftp, 1:30@61%ftp), 5:00@55%ftp', 2),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '2*7min'} }, warmup20 + '2*(7:00@83%ftp, 3:00@61%ftp), 5:00@55%ftp', 2),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '3*5min'} }, warmup20 + '3*(5:00@83%ftp, 1:30@61%ftp), 5:30@55%ftp', 2),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '3*6min'} }, warmup20 + '3*(6:00@83%ftp, 1:30@61%ftp), 2:30@55%ftp', 2),

  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '4*4min'} }, warmup20 + '4*(4:00@84%ftp, 1:30@62%ftp), 3:00@55%ftp', 3),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '6*2min30'} }, warmup20 + '6*(2:30@84%ftp, 1:00@62%ftp), 4:00@55%ftp', 3),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '8*2min'} }, warmup20 + '8*(2:00@84%ftp, 45@62%ftp), 3:00@55%ftp', 3),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '10*1min30'} }, warmup20 + '10*(1:30@84%ftp, 30@62%ftp), 5:00@55%ftp', 3),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '5*3min'} }, warmup20 + '5*(3:00@84%ftp, 1:00@62%ftp), 5:00@55%ftp', 3),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '4*3min30'} }, warmup20 + '4*(3:30@84%ftp, 1:30@62%ftp), 5:00@55%ftp', 3),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '2*7min'} }, warmup20 + '2*(7:00@84%ftp, 3:00@62%ftp), 5:00@55%ftp', 3),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '3*5min'} }, warmup20 + '3*(5:00@84%ftp, 1:30@62%ftp), 5:30@55%ftp', 3),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '3*6min'} }, warmup20 + '3*(6:00@84%ftp, 1:30@62%ftp), 2:30@55%ftp', 3),

  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '4*4min'} }, warmup20 + '4*(4:00@85%ftp, 1:30@63%ftp), 3:00@55%ftp', 4),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '6*2min30'} }, warmup20 + '6*(2:30@85%ftp, 1:00@63%ftp), 4:00@55%ftp', 4),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '8*2min'} }, warmup20 + '8*(2:00@85%ftp, 45@63%ftp), 3:00@55%ftp', 4),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '10*1min30'} }, warmup20 + '10*(1:30@85%ftp, 30@63%ftp), 5:00@55%ftp', 4),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '5*3min'} }, warmup20 + '5*(3:00@85%ftp, 1:00@63%ftp), 5:00@55%ftp', 4),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '4*3min30'} }, warmup20 + '4*(3:30@85%ftp, 1:30@63%ftp), 5:00@55%ftp', 4),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '2*7min'} }, warmup20 + '2*(7:00@85%ftp, 3:00@63%ftp), 5:00@55%ftp', 4),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '3*5min'} }, warmup20 + '3*(5:00@85%ftp, 1:30@63%ftp), 5:30@55%ftp', 4),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '3*6min'} }, warmup20 + '3*(6:00@85%ftp, 1:30@63%ftp), 2:30@55%ftp', 4),

  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '4*4min'} }, warmup20 + '4*(4:00@86%ftp, 1:30@64%ftp), 3:00@55%ftp', 5),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '6*2min30'} }, warmup20 + '6*(2:30@86%ftp, 1:00@64%ftp), 4:00@55%ftp', 5),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '8*2min'} }, warmup20 + '8*(2:00@86%ftp, 45@64%ftp), 3:00@55%ftp', 5),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '10*1min30'} }, warmup20 + '10*(1:30@86%ftp, 30@64%ftp), 5:00@55%ftp', 5),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '5*3min'} }, warmup20 + '5*(3:00@86%ftp, 1:00@64%ftp), 5:00@55%ftp', 5),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '4*3min30'} }, warmup20 + '4*(3:30@86%ftp, 1:30@64%ftp), 5:00@55%ftp', 5),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '2*7min'} }, warmup20 + '2*(7:00@86%ftp, 3:00@64%ftp), 5:00@55%ftp', 5),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '3*5min'} }, warmup20 + '3*(5:00@86%ftp, 1:30@64%ftp), 5:30@55%ftp', 5),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '3*6min'} }, warmup20 + '3*(6:00@86%ftp, 1:30@64%ftp), 2:30@55%ftp', 5),

  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '4*4min'} }, warmup20 + '4*(4:00@86%ftp, 1:30@65%ftp), 3:00@55%ftp', 6),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '6*2min30'} }, warmup20 + '6*(2:30@86%ftp, 1:00@65%ftp), 4:00@55%ftp', 6),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '8*2min'} }, warmup20 + '8*(2:00@86%ftp, 45@65%ftp), 3:00@55%ftp', 6),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '10*1min30'} }, warmup20 + '10*(1:30@86%ftp, 30@65%ftp), 5:00@55%ftp', 6),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '5*3min'} }, warmup20 + '5*(3:00@86%ftp, 1:00@65%ftp), 5:00@55%ftp', 6),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '4*3min30'} }, warmup20 + '4*(3:30@86%ftp, 1:30@65%ftp), 5:00@55%ftp', 6),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '2*7min'} }, warmup20 + '2*(7:00@86%ftp, 3:00@65%ftp), 5:00@55%ftp', 6),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '3*5min'} }, warmup20 + '3*(5:00@86%ftp, 1:30@65%ftp), 5:30@55%ftp', 6),
  WorkoutItem.create({ name: 'sst', format: ['45min'] }, 'tempo', { description: 'sst', format: {intervals: '3*6min'} }, warmup20 + '3*(6:00@86%ftp, 1:30@65%ftp), 2:30@55%ftp', 6),


  // SST 1h
  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '5*5min'} }, warmup25 + '6*(4:00@82%ftp, 1:30@60%ftp), 2:00@55%ftp', 1),
  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '4*5min'} }, warmup25 + '9*(2:30@82%ftp, 1:00@60%ftp), 3:30@55%ftp', 1),
  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '4*5min'} }, warmup25 + '11*(2:00@82%ftp, 45@60%ftp), 4:45@55%ftp', 1),
  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '4*5min'} }, warmup25 + '15*(1:30@82%ftp, 30@60%ftp), 5:00@55%ftp', 1),
  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '4*5min'} }, warmup25 + '8*(3:00@82%ftp, 1:00@60%ftp), 3:00@55%ftp', 1),
  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '4*5min'} }, warmup25 + '6*(3:30@82%ftp, 1:30@60%ftp), 5:00@55%ftp', 1),
  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '4*5min'} }, warmup25 + '3*(7:00@82%ftp, 3:00@60%ftp), 5:00@55%ftp', 1),
  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '4*5min'} }, warmup25 + '5*(5:00@82%ftp, 1:30@60%ftp), 2:30@55%ftp', 1),
  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '4*5min'} }, warmup25 + '4*(6:00@82%ftp, 1:30@60%ftp), 5:00@55%ftp', 1),

  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '5*5min'} }, warmup25 + '6*(4:00@83%ftp, 1:30@61%ftp), 2:00@55%ftp', 2),
  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '4*5min'} }, warmup25 + '9*(2:30@83%ftp, 1:00@61%ftp), 3:30@55%ftp', 2),
  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '4*5min'} }, warmup25 + '11*(2:00@83%ftp, 45@61%ftp), 4:45@55%ftp', 2),
  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '4*5min'} }, warmup25 + '15*(1:30@83%ftp, 30@61%ftp), 5:00@55%ftp', 2),
  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '4*5min'} }, warmup25 + '8*(3:00@83%ftp, 1:00@61%ftp), 3:00@55%ftp', 2),
  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '4*5min'} }, warmup25 + '6*(3:30@83%ftp, 1:30@61%ftp), 5:00@55%ftp', 2),
  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '4*5min'} }, warmup25 + '5*(5:00@83%ftp, 1:30@61%ftp), 2:30@55%ftp', 2),
  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '4*5min'} }, warmup25 + '4*(6:00@83%ftp, 1:30@61%ftp), 5:00@55%ftp', 2),

  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '5*5min'} }, warmup25 + '6*(4:00@84%ftp, 1:30@62%ftp), 2:00@55%ftp', 3),
  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '4*5min'} }, warmup25 + '9*(2:30@84%ftp, 1:00@62%ftp), 3:30@55%ftp', 3),
  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '4*5min'} }, warmup25 + '11*(2:00@84%ftp, 45@62%ftp), 4:45@55%ftp', 3),
  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '4*5min'} }, warmup25 + '15*(1:30@84%ftp, 30@62%ftp), 5:00@55%ftp', 3),
  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '4*5min'} }, warmup25 + '8*(3:00@84%ftp, 1:00@62%ftp), 3:00@55%ftp', 3),
  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '4*5min'} }, warmup25 + '6*(3:30@84%ftp, 1:30@62%ftp), 5:00@55%ftp', 3),
  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '4*5min'} }, warmup25 + '5*(5:00@84%ftp, 1:30@62%ftp), 2:30@55%ftp', 3),
  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '4*5min'} }, warmup25 + '4*(6:00@84%ftp, 1:30@62%ftp), 5:00@55%ftp', 3),


  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '5*5min'} }, warmup25 + '6*(4:00@85%ftp, 1:30@63%ftp), 2:00@55%ftp', 4),
  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '4*5min'} }, warmup25 + '9*(2:30@85%ftp, 1:00@63%ftp), 3:30@55%ftp', 4),
  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '4*5min'} }, warmup25 + '11*(2:00@85%ftp, 45@63%ftp), 4:45@55%ftp', 4),
  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '4*5min'} }, warmup25 + '15*(1:30@85%ftp, 30@63%ftp), 5:00@55%ftp', 4),
  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '4*5min'} }, warmup25 + '8*(3:00@85%ftp, 1:00@63%ftp), 3:00@55%ftp', 4),
  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '4*5min'} }, warmup25 + '6*(3:30@85%ftp, 1:30@63%ftp), 5:00@55%ftp', 4),
  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '4*5min'} }, warmup25 + '5*(5:00@85%ftp, 1:30@63%ftp), 2:30@55%ftp', 4),
  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '4*5min'} }, warmup25 + '4*(6:00@85%ftp, 1:30@63%ftp), 5:00@55%ftp', 4),

  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '5*5min'} }, warmup25 + '6*(4:00@86%ftp, 1:30@64%ftp), 2:00@55%ftp', 5),
  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '4*5min'} }, warmup25 + '9*(2:30@86%ftp, 1:00@64%ftp), 3:30@55%ftp', 5),
  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '4*5min'} }, warmup25 + '11*(2:00@86%ftp, 45@64%ftp), 4:45@55%ftp', 5),
  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '4*5min'} }, warmup25 + '15*(1:30@86%ftp, 30@64%ftp), 5:00@55%ftp', 5),
  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '4*5min'} }, warmup25 + '8*(3:00@86%ftp, 1:00@64%ftp), 3:00@55%ftp', 5),
  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '4*5min'} }, warmup25 + '6*(3:30@86%ftp, 1:30@64%ftp), 5:00@55%ftp', 5),
  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '4*5min'} }, warmup25 + '5*(5:00@86%ftp, 1:30@64%ftp), 2:30@55%ftp', 5),
  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '4*5min'} }, warmup25 + '4*(6:00@86%ftp, 1:30@64%ftp), 5:00@55%ftp', 5),

  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '5*5min'} }, warmup25 + '6*(4:00@88%ftp, 1:30@64%ftp), 2:00@55%ftp', 6),
  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '4*5min'} }, warmup25 + '9*(2:30@88%ftp, 1:00@64%ftp), 3:30@55%ftp', 6),
  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '4*5min'} }, warmup25 + '11*(2:00@88%ftp, 45@64%ftp), 4:45@55%ftp', 6),
  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '4*5min'} }, warmup25 + '15*(1:30@88%ftp, 30@64%ftp), 5:00@55%ftp', 6),
  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '4*5min'} }, warmup25 + '8*(3:00@88%ftp, 1:00@64%ftp), 3:00@55%ftp', 6),
  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '4*5min'} }, warmup25 + '6*(3:30@88%ftp, 1:30@64%ftp), 5:00@55%ftp', 6),
  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '4*5min'} }, warmup25 + '5*(5:00@88%ftp, 1:30@64%ftp), 2:30@55%ftp', 6),
  WorkoutItem.create({ name: 'sst', format: ['1h'] }, 'tempo', { description: 'sst', format: {intervals: '4*5min'} }, warmup25 + '4*(6:00@88%ftp, 1:30@64%ftp), 5:00@55%ftp', 6),

  // SST 1h15
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '8*4min'} }, warmup25 + '8*(4:00@78%ftp, 1:30@60%ftp), 6:00@55%ftp', 1),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '12*2min30'} }, warmup25 + '12*(2:30@78%ftp, 1:00@60%ftp), 8:00@55%ftp', 1),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '15*2min'} }, warmup25 + '15*(2:00@78%ftp, 45@60%ftp), 8:45@55%ftp', 1),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '19*1min30'} }, warmup30 + '19*(1:30@78%ftp, 30@60%ftp), 7:00@55%ftp', 1),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '10*3min'} }, warmup30 + '10*(3:00@78%ftp, 1:00@60%ftp), 5:00@55%ftp', 1),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '8*3min30'} }, warmup30 + '8*(3:30@78%ftp, 1:30@60%ftp), 5:00@55%ftp', 1),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '4*7min'} }, warmup30 + '4*(7:00@78%ftp, 3:00@60%ftp), 5:00@55%ftp', 1),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '6*5min'} }, warmup30 + '6*(5:00@78%ftp, 1:30@60%ftp), 6:00@55%ftp', 1),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '5*6min'} }, warmup30 + '5*(6:00@78%ftp, 1:30@60%ftp), 7:30@55%ftp', 1),

  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '8*4min'} }, warmup25 + '8*(4:00@80%ftp, 1:30@60%ftp), 6:00@55%ftp', 2),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '12*2min30'} }, warmup25 + '12*(2:30@80%ftp, 1:00@60%ftp), 8:00@55%ftp', 2),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '15*2min'} }, warmup25 + '15*(2:00@80%ftp, 45@60%ftp), 8:45@55%ftp', 2),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '19*1min30'} }, warmup30 + '19*(1:30@80%ftp, 30@60%ftp), 7:00@55%ftp', 2),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '10*3min'} }, warmup30 + '10*(3:00@80%ftp, 1:00@60%ftp), 5:00@55%ftp', 2),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '8*3min30'} }, warmup30 + '8*(3:30@80%ftp, 1:30@60%ftp), 5:00@55%ftp', 2),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '4*7min'} }, warmup30 + '4*(7:00@80%ftp, 3:00@60%ftp), 5:00@55%ftp', 2),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '6*5min'} }, warmup30 + '6*(5:00@80%ftp, 1:30@60%ftp), 6:00@55%ftp', 2),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '5*6min'} }, warmup30 + '5*(6:00@80%ftp, 1:30@60%ftp), 7:30@55%ftp', 2),

  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '8*4min'} }, warmup25 + '8*(4:00@82%ftp, 1:30@60%ftp), 6:00@55%ftp', 3),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '12*2min30'} }, warmup25 + '12*(2:30@82%ftp, 1:00@60%ftp), 8:00@55%ftp', 3),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '15*2min'} }, warmup25 + '15*(2:00@82%ftp, 45@60%ftp), 8:45@55%ftp', 3),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '19*1min30'} }, warmup30 + '19*(1:30@82%ftp, 30@60%ftp), 7:00@55%ftp', 3),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '10*3min'} }, warmup30 + '10*(3:00@82%ftp, 1:00@60%ftp), 5:00@55%ftp', 3),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '8*3min30'} }, warmup30 + '8*(3:30@82%ftp, 1:30@60%ftp), 5:00@55%ftp', 3),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '4*7min'} }, warmup30 + '4*(7:00@82%ftp, 3:00@60%ftp), 5:00@55%ftp', 3),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '6*5min'} }, warmup30 + '6*(5:00@82%ftp, 1:30@60%ftp), 6:00@55%ftp', 3),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '5*6min'} }, warmup30 + '5*(6:00@82%ftp, 1:30@60%ftp), 7:30@55%ftp', 3),

  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '8*4min'} }, warmup25 + '8*(4:00@84%ftp, 1:30@60%ftp), 6:00@55%ftp', 4),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '12*2min30'} }, warmup25 + '12*(2:30@84%ftp, 1:00@60%ftp), 8:00@55%ftp', 4),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '15*2min'} }, warmup25 + '15*(2:00@84%ftp, 45@60%ftp), 8:45@55%ftp', 4),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '19*1min30'} }, warmup30 + '19*(1:30@84%ftp, 30@60%ftp), 7:00@55%ftp', 4),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '10*3min'} }, warmup30 + '10*(3:00@84%ftp, 1:00@60%ftp), 5:00@55%ftp', 4),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '8*3min30'} }, warmup30 + '8*(3:30@84%ftp, 1:30@60%ftp), 5:00@55%ftp', 4),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '4*7min'} }, warmup30 + '4*(7:00@84%ftp, 3:00@60%ftp), 5:00@55%ftp', 4),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '6*5min'} }, warmup30 + '6*(5:00@84%ftp, 1:30@60%ftp), 6:00@55%ftp', 4),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '5*6min'} }, warmup30 + '5*(6:00@84%ftp, 1:30@60%ftp), 7:30@55%ftp', 4),

  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '8*4min'} }, warmup25 + '8*(4:00@86%ftp, 1:30@60%ftp), 6:00@55%ftp', 5),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '12*2min30'} }, warmup25 + '12*(2:30@86%ftp, 1:00@60%ftp), 8:00@55%ftp', 5),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '15*2min'} }, warmup25 + '15*(2:00@86%ftp, 45@60%ftp), 8:45@55%ftp', 5),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '19*1min30'} }, warmup30 + '19*(1:30@86%ftp, 30@60%ftp), 7:00@55%ftp', 5),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '10*3min'} }, warmup30 + '10*(3:00@86%ftp, 1:00@60%ftp), 5:00@55%ftp', 5),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '8*3min30'} }, warmup30 + '8*(3:30@86%ftp, 1:30@60%ftp), 5:00@55%ftp', 5),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '4*7min'} }, warmup30 + '4*(7:00@86%ftp, 3:00@60%ftp), 5:00@55%ftp', 5),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '6*5min'} }, warmup30 + '6*(5:00@86%ftp, 1:30@60%ftp), 6:00@55%ftp', 5),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '5*6min'} }, warmup30 + '5*(6:00@86%ftp, 1:30@60%ftp), 7:30@55%ftp', 5),

  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '8*4min'} }, warmup25 + '8*(4:00@88%ftp, 1:30@60%ftp), 6:00@55%ftp', 6),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '12*2min30'} }, warmup25 + '12*(2:30@88%ftp, 1:00@60%ftp), 8:00@55%ftp', 6),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '15*2min'} }, warmup25 + '15*(2:00@88%ftp, 45@60%ftp), 8:45@55%ftp', 6),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '19*1min30'} }, warmup30 + '19*(1:30@88%ftp, 30@60%ftp), 7:00@55%ftp', 6),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '10*3min'} }, warmup30 + '10*(3:00@88%ftp, 1:00@60%ftp), 5:00@55%ftp', 6),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '8*3min30'} }, warmup30 + '8*(3:30@88%ftp, 1:30@60%ftp), 5:00@55%ftp', 6),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '4*7min'} }, warmup30 + '4*(7:00@88%ftp, 3:00@60%ftp), 5:00@55%ftp', 6),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '6*5min'} }, warmup30 + '6*(5:00@88%ftp, 1:30@60%ftp), 6:00@55%ftp', 6),
  WorkoutItem.create({ name: 'sst', format: ['1h15'] }, 'tempo', { description: 'sst', format: {intervals: '5*6min'} }, warmup30 + '5*(6:00@88%ftp, 1:30@60%ftp), 7:30@55%ftp', 6),


  // SST 1h30
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '10*4min'} }, warmup30 + '10*(4:00@78%ftp, 1:30@55%ftp), 5:00@55%ftp', 1),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '15*2min30'} }, warmup30 + '15*(2:30@78%ftp, 1:00@55%ftp), 8:00@55%ftp', 1),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '25*1min30'} }, warmup30 + '25*(1:30@78%ftp, 30@55%ftp), 10:00@55%ftp', 1),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '14*3min'} }, warmup30 + '14*(3:00@78%ftp, 1:00@55%ftp), 4:00@55%ftp', 1),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '11*3min30'} }, warmup30 + '11*(3:30@78%ftp, 1:30@55%ftp), 5:00@55%ftp', 1),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '5*7min'} }, warmup30 + '5*(7:00@76%ftp, 3:00@55%ftp), 5:00@55%ftp', 1),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '8*5min'} }, warmup30 + '8*(5:00@76%ftp, 1:30@55%ftp), 8:00@55%ftp', 1),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '15*2min'} }, warmup30 + '15*(2:00@78%ftp, 45@55%ftp), 3:45@55%ftp', 1),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '7*6min'} }, warmup30 + '7*(6:00@76%ftp, 1:30@55%ftp), 7:30@55%ftp', 1),

  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '10*4min'} }, warmup30 + '10*(4:00@80%ftp, 1:30@55%ftp), 5:00@55%ftp', 2),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '15*2min30'} }, warmup30 + '15*(2:30@80%ftp, 1:00@55%ftp), 8:00@55%ftp', 2),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '25*1min30'} }, warmup30 + '25*(1:30@80%ftp, 30@55%ftp), 10:00@55%ftp', 2),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '14*3min'} }, warmup30 + '14*(3:00@80%ftp, 1:00@55%ftp), 4:00@55%ftp', 2),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '11*3min30'} }, warmup30 + '11*(3:30@80%ftp, 1:30@55%ftp), 5:00@55%ftp', 2),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '5*7min'} }, warmup30 + '5*(7:00@78%ftp, 3:00@55%ftp), 5:00@55%ftp', 2),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '8*5min'} }, warmup30 + '8*(5:00@78%ftp, 1:30@55%ftp), 8:00@55%ftp', 2),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '15*2min'} }, warmup30 + '15*(2:00@80%ftp, 45@55%ftp), 3:45@55%ftp', 2),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '7*6min'} }, warmup30 + '7*(6:00@78%ftp, 1:30@55%ftp), 7:30@55%ftp', 2),

  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '10*4min'} }, warmup30 + '10*(4:00@82%ftp, 1:30@55%ftp), 5:00@55%ftp', 3),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '15*2min30'} }, warmup30 + '15*(2:30@82%ftp, 1:00@55%ftp), 8:00@55%ftp', 3),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '25*1min30'} }, warmup30 + '25*(1:30@82%ftp, 30@55%ftp), 10:00@55%ftp', 3),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '14*3min'} }, warmup30 + '14*(3:00@82%ftp, 1:00@55%ftp), 4:00@55%ftp', 3),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '11*3min30'} }, warmup30 + '11*(3:30@82%ftp, 1:30@55%ftp), 5:00@55%ftp', 3),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '5*7min'} }, warmup30 + '5*(7:00@80%ftp, 3:00@55%ftp), 5:00@55%ftp', 3),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '8*5min'} }, warmup30 + '8*(5:00@80%ftp, 1:30@55%ftp), 8:00@55%ftp', 3),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '15*2min'} }, warmup30 + '15*(2:00@82%ftp, 45@55%ftp), 3:45@55%ftp', 3),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '7*6min'} }, warmup30 + '7*(6:00@80%ftp, 1:30@55%ftp), 7:30@55%ftp', 3),

  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '10*4min'} }, warmup30 + '10*(4:00@84%ftp, 1:30@55%ftp), 5:00@55%ftp', 4),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '15*2min30'} }, warmup30 + '15*(2:30@84%ftp, 1:00@55%ftp), 8:00@55%ftp', 4),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '25*1min30'} }, warmup30 + '25*(1:30@84%ftp, 30@55%ftp), 10:00@55%ftp', 4),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '14*3min'} }, warmup30 + '14*(3:00@84%ftp, 1:00@55%ftp), 4:00@55%ftp', 4),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '11*3min30'} }, warmup30 + '11*(3:30@84%ftp, 1:30@55%ftp), 5:00@55%ftp', 4),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '5*7min'} }, warmup30 + '5*(7:00@82%ftp, 3:00@55%ftp), 5:00@55%ftp', 4),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '8*5min'} }, warmup30 + '8*(5:00@82%ftp, 1:30@55%ftp), 8:00@55%ftp', 4),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '15*2min'} }, warmup30 + '15*(2:00@84%ftp, 45@55%ftp), 3:45@55%ftp', 4),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '7*6min'} }, warmup30 + '7*(6:00@82%ftp, 1:30@55%ftp), 7:30@55%ftp', 4),

  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '10*4min'} }, warmup30 + '10*(4:00@86%ftp, 1:30@55%ftp), 5:00@55%ftp', 5),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '15*2min30'} }, warmup30 + '15*(2:30@86%ftp, 1:00@55%ftp), 8:00@55%ftp', 5),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '25*1min30'} }, warmup30 + '25*(1:30@86%ftp, 30@55%ftp), 10:00@55%ftp', 5),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '14*3min'} }, warmup30 + '14*(3:00@86%ftp, 1:00@55%ftp), 4:00@55%ftp', 5),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '11*3min30'} }, warmup30 + '11*(3:30@86%ftp, 1:30@55%ftp), 5:00@55%ftp', 5),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '5*7min'} }, warmup30 + '5*(7:00@84%ftp, 3:00@55%ftp), 5:00@55%ftp', 5),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '8*5min'} }, warmup30 + '8*(5:00@84%ftp, 1:30@55%ftp), 8:00@55%ftp', 5),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '15*2min'} }, warmup30 + '15*(2:00@86%ftp, 45@55%ftp), 3:45@55%ftp', 5),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '7*6min'} }, warmup30 + '7*(6:00@84%ftp, 1:30@55%ftp), 7:30@55%ftp', 5),

  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '10*4min'} }, warmup30 + '10*(4:00@88%ftp, 1:30@55%ftp), 5:00@55%ftp', 6),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '15*2min30'} }, warmup30 + '15*(2:30@88%ftp, 1:00@55%ftp), 8:00@55%ftp', 6),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '25*1min30'} }, warmup30 + '25*(1:30@88%ftp, 30@55%ftp), 10:00@55%ftp', 6),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '14*3min'} }, warmup30 + '14*(3:00@88%ftp, 1:00@55%ftp), 4:00@55%ftp', 6),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '11*3min30'} }, warmup30 + '11*(3:30@88%ftp, 1:30@55%ftp), 5:00@55%ftp', 6),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '5*7min'} }, warmup30 + '5*(7:00@86%ftp, 3:00@55%ftp), 5:00@55%ftp', 6),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '8*5min'} }, warmup30 + '8*(5:00@86%ftp, 1:30@55%ftp), 8:00@55%ftp', 6),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '15*2min'} }, warmup30 + '15*(2:00@88%ftp, 45@55%ftp), 3:45@55%ftp', 6),
  WorkoutItem.create({ name: 'sst', format: ['1h30'] }, 'tempo', { description: 'sst', format: {intervals: '7*6min'} }, warmup30 + '7*(6:00@86%ftp, 1:30@55%ftp), 7:30@55%ftp', 6),

]);

module.exports = {
  sst,
};
