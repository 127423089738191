const WorkoutCategory = require('../workoutCategory');
const WorkoutItem = require('../workoutItem');


const warmup1 = '1:00@55%ftp, 1:00@60%ftp, 1:00@65%ftp, 1:00@70%ftp, 1:00@75%ftp, 2:00@80%ftp, 1:00@90%ftp, 1:00@100%ftp, 1:00@110%ftp, '; //10min
const warmup2 = '2:00@55%ftp, 2:00@60%ftp, 2:00@65%ftp, 3:00@70%ftp, 1:00@75%ftp, 1:00@80%ftp, 1:00@90%ftp, 2:00@100%ftp, 1:00@110%ftp, '; //15min
const warmup3 = '2:00@55%ftp, 3:00@60%ftp, 10:00@65%ftp, 15:00@70%ftp, 10:00@75%ftp, 3*(00:10@105%map, 00:50@55%ftp), 2:00@55%ftp,'; //45min
const warmup4 = '5:00@55%ftp, 15:00@60%ftp, 20:00@65%ftp, 15:00@70%ftp, 10:00@75%ftp, 5*(00:15@105%map, 00:45@55%ftp), 5:00@55%ftp,'; //75min
const warmup5 = '5:00@55%ftp, 25:00@60%ftp, 30:00@65%ftp, 25:00@70%ftp, 10:00@75%ftp, 5*(00:15@105%map, 00:45@55%ftp), 5:00@55%ftp,'; //105min

const warmupDescriptions = [
  null,
  { zone: 2, duration: 10*60, target: '55%ftp-110%ftp', description: 'progressive_warmup' },
  { zone: 2, duration: 15*60, target: '55%ftp-110%ftp', description: 'progressive_warmup' },
  { zone: 2, duration: 45*60, target: '55%ftp-110%ftp', description: 'progressive_warmup_HIT' },
  { zone: 2, duration: 75*60, target: '55%ftp-110%ftp', description: 'progressive_warmup_HIT' },
  { zone: 2, duration: 105*60, target: '55%ftp-110%ftp', description: 'progressive_warmup_HIT' },
];

function thresholdDescriptions(warmup, duration, target, intervals, recovery, endurance, cooldown){
  return [
    warmupDescriptions[warmup],
    { zone: 4, duration: duration, target: target, description: 'threshold', format: { intervals: intervals, recovery: recovery } },
    endurance ? { zone: 2, duration: endurance, target: '65%ftp', description: 'light_endurance' } : null, //if no endurance duration, no step
    { zone: 1, duration: cooldown, target: '60%ftp', description: 'cooldown' },
  ].filter(e => e); //remove null element if any
}

var threshold = new WorkoutCategory(
  'threshold',
  [

    // Seuil 1h
    WorkoutItem.create({ name: 'threshold', format: ['1h'] }, 'threshold', { description: 'threshold', format: {intervals: '2*10min'} },
      warmup2 + '2*(5:00@55%ftp, 10:00@95%ftp), 5:00@65%ftp, 10:00@60%ftp', 1, thresholdDescriptions(2, 30*60, '95%ftp', '2*10min', '5min', 5*60, 10*60)),
    WorkoutItem.create({ name: 'threshold', format: ['1h'] }, 'threshold', { description: 'threshold', format: {intervals: '4*6min'} },
      warmup2 + '4*(2:00@55%ftp, 6:00@95%ftp), 3:00@65%ftp, 10:00@60%ftp', 1, thresholdDescriptions(2, 32*60, '95%ftp', '4*6min', '2min', 3*60, 10*60)),

    WorkoutItem.create({ name: 'threshold', format: ['1h'] }, 'threshold', { description: 'threshold', format: {intervals: '2*12min'} },
      warmup2 + '2*(6:00@55%ftp, 12:30@100%ftp), 3:00@65%ftp, 5:00@60%ftp', 1.5, thresholdDescriptions(2, 32*60, '100%ftp', '2*12min', '6min', 3*60, 5*60)),
    WorkoutItem.create({ name: 'threshold', format: ['1h'] }, 'threshold', { description: 'threshold', format: {intervals: '5*5min'} },
      warmup2 + '5*(3:00@55%ftp, 5:00@100%ftp), 5:00@60%ftp', 1.5, thresholdDescriptions(2, 35*60, '100%ftp', '5*5min', '3min', 0, 5*60)),

    WorkoutItem.create({ name: 'threshold', format: ['1h'] }, 'threshold', { description: 'threshold', format: {intervals: '2*15min'} },
      warmup1 + '2*(7:00@55%ftp, 15:00@100%ftp), 6:00@60%ftp', 2, thresholdDescriptions(1, 44*60, '100%ftp', '2*15min', '7min', 0, 6*60)),
    WorkoutItem.create({ name: 'threshold', format: ['1h'] }, 'threshold', { description: 'threshold', format: {intervals: '3*10min'} },
      warmup1 + '3*(5:00@55%ftp, 10:00@100%ftp), 5:00@60%ftp', 2, thresholdDescriptions(1, 45*60, '100%ftp', '3*10min', '5min', 0, 5*60)),

    WorkoutItem.create({ name: 'threshold', format: ['1h'] }, 'threshold', { description: 'threshold', format: {intervals: '3*10min'} },
      warmup1 + '3*(5:00@55%ftp, 10:00@105%ftp), 5:00@60%ftp', 2.5, thresholdDescriptions(1, 45*60, '105%ftp', '3*10min', '5min', 0, 5*60)),
    WorkoutItem.create({ name: 'threshold', format: ['1h'] }, 'threshold', { description: 'threshold', format: {intervals: '3*12min'} },
      warmup1 + '3*(3:00@55%ftp, 12:00@100%ftp), 5:00@60%ftp', 2.5, thresholdDescriptions(1, 45*60, '100%ftp', '3*12min', '3min', 0, 5*60)),

    WorkoutItem.create({ name: 'threshold', format: ['1h'] }, 'threshold', { description: 'threshold', format: {intervals: '4*10min'} },
      warmup1 + '4*(1:20@55%ftp, 10:00@100%ftp), 5:00@60%ftp', 3, thresholdDescriptions(1, 45*60, '100%ftp', '4*10min', '1min', 0, 5*60)),

    WorkoutItem.create({ name: 'threshold', format: ['1h'] }, 'threshold', { description: 'threshold', format: {intervals: '2*20min'} },
      warmup1 + '1:00@55%ftp, 20:00@100%ftp, 4:00@55%ftp, 20:00@100%ftp, 5:00@60%ftp', 3.5, thresholdDescriptions(1, 45*60, '100%ftp', '2*20min', '4min', 0, 5*60)),

    WorkoutItem.create({ name: 'threshold', format: ['1h'] }, 'threshold', { description: 'threshold', format: {intervals: '2*22min'} },
      warmup1 + '2*(1:00@55%ftp, 22:00@95%ftp), 4:00@60%ftp', 4, thresholdDescriptions(1, 45*60, '95%ftp', '2*22min', '1min', 0, 5*60)),

    WorkoutItem.create({ name: 'threshold', format: ['1h'] }, 'threshold', { description: 'threshold', format: {intervals: '2*22min'} },
      warmup1 + '2*(1:00@55%ftp, 22:00@100%ftp), 4:00@60%ftp', 4.5, thresholdDescriptions(1, 45*60, '100%ftp', '2*22min', '1min', 0, 5*60)),

    WorkoutItem.create({ name: 'threshold', format: ['1h'] }, 'threshold', { description: 'threshold', format: {intervals: '44min'} },
      warmup1 + '1:00@55%ftp, 44:00@95%ftp, 5:00@60%ftp', 5, thresholdDescriptions(1, 44*60, '95%ftp', '44min', '', 0, 5*60)),

    WorkoutItem.create({ name: 'threshold', format: ['1h'] }, 'threshold', { description: 'threshold', format: {intervals: '44min'} },
      warmup1 + '1:00@55%ftp, 44:00@100%ftp, 5:00@60%ftp', 5.5, thresholdDescriptions(1, 44*60, '100%ftp', '44min', '', 0, 5*60)),

    WorkoutItem.create({ name: 'threshold', format: ['1h'] }, 'threshold', { description: 'threshold', format: {intervals: '44min'} },
      warmup1 + '1:00@55%ftp, 44:00@102%ftp, 5:00@60%ftp', 6, thresholdDescriptions(1, 44*60, '102%ftp', '44min', '', 0, 5*60)),

    WorkoutItem.create({ name: 'threshold', format: ['1h'] }, 'threshold', { description: 'threshold', format: {intervals: '44min'} },
      warmup1 + '1:00@55%ftp, 44:00@104%ftp, 5:00@60%ftp', 6.5, thresholdDescriptions(1, 44*60, '104%ftp', '44min', '', 0, 5*60)),

    //Seuil 1h30
    WorkoutItem.create({ name: 'threshold', format: ['1h30'] }, 'threshold', { description: 'threshold', format: {intervals: '2*10min'} },
      warmup2 + '2*(5:00@55%ftp, 10:00@95%ftp), 35:00@65%ftp, 10:00@60%ftp', 1, thresholdDescriptions(2, 30*60, '95%ftp', '2*10min', '5min', 35*60, 10*60)),
    WorkoutItem.create({ name: 'threshold', format: ['1h30'] }, 'threshold', { description: 'threshold', format: {intervals: '4*5min'} },
      warmup2 + '4*(3:00@55%ftp, 5:00@100%ftp), 33:00@65%ftp, 10:00@60%ftp', 1, thresholdDescriptions(2, 32*60, '100%ftp', '4*5min', '3min', 33*60, 10*60)),

    WorkoutItem.create({ name: 'threshold', format: ['1h30'] }, 'threshold', { description: 'threshold', format: {intervals: '2*12min'} },
      warmup2 + '2*(6:00@55%ftp, 12:30@100%ftp), 28:00@65%ftp, 10:00@60%ftp', 1.5, thresholdDescriptions(2, 32*60, '100%ftp', '2*12min', '6min', 28*60, 10*60)),
    WorkoutItem.create({ name: 'threshold', format: ['1h30'] }, 'threshold', { description: 'threshold', format: {intervals: '5*5min'} },
      warmup2 + '5*(3:00@55%ftp, 5:00@100%ftp), 25:00@65%ftp, 10:00@60%ftp', 1.5, thresholdDescriptions(2, 35*60, '100%ftp', '5*5min', '3min', 25*60, 10*60)),

    WorkoutItem.create({ name: 'threshold', format: ['1h30'] }, 'threshold', { description: 'threshold', format: {intervals: '2*15min'} },
      warmup2 + '2*(7:00@55%ftp, 15:00@100%ftp), 21:00@65%ftp, 10:00@60%ftp', 2, thresholdDescriptions(2, 44*60, '100%ftp', '2*15min', '7min', 21*60, 10*60)),
    WorkoutItem.create({ name: 'threshold', format: ['1h30'] }, 'threshold', { description: 'threshold', format: {intervals: '3*10min'} },
      warmup2 + '3*(5:00@55%ftp, 10:00@100%ftp), 20:00@65%ftp, 10:00@60%ftp', 2, thresholdDescriptions(2, 45*60, '100%ftp', '3*10min', '5min', 20*60, 10*60)),

    WorkoutItem.create({ name: 'threshold', format: ['1h30'] }, 'threshold', { description: 'threshold', format: {intervals: '3*10min'} },
      warmup2 + '3*(5:00@55%ftp, 10:00@105%ftp), 20:00@65%ftp, 10:00@60%ftp', 2.5, thresholdDescriptions(2, 45*60, '105%ftp', '3*10min', '5min', 20*60, 10*60)),
    WorkoutItem.create({ name: 'threshold', format: ['1h30'] }, 'threshold', { description: 'threshold', format: {intervals: '3*12min'} },
      warmup2 + '3*(6:00@55%ftp, 12:00@100%ftp), 11:00@65%ftp, 10:00@60%ftp', 2.5, thresholdDescriptions(2, 54*60, '100%ftp', '3*12min', '5min', 11*60, 10*60)),

    WorkoutItem.create({ name: 'threshold', format: ['1h30'] }, 'threshold', { description: 'threshold', format: {intervals: '4*10min'} },
      warmup2 + '4*(5:00@55%ftp, 10:00@100%ftp), 5:00@65%ftp, 10:00@60%ftp', 3, thresholdDescriptions(2, 60*60, '100%ftp', '4*10min', '5min', 5*60, 10*60)),
    WorkoutItem.create({ name: 'threshold', format: ['1h30'] }, 'threshold', { description: 'threshold', format: {intervals: '2*20min'} },
      warmup2 + '2*(10:00@55%ftp, 20:00@100%ftp), 5:00@65%ftp, 10:00@60%ftp', 3, thresholdDescriptions(2, 60*60, '100%ftp', '2*20min', '10min', 5*60, 10*60)),

    WorkoutItem.create({ name: 'threshold', format: ['1h30'] }, 'threshold', { description: 'threshold', format: {intervals: '4*11min'} },
      warmup2 + '4*(5:00@55%ftp, 11:00@100%ftp), 11:00@60%ftp', 3.5, thresholdDescriptions(2, 64*60, '100%ftp', '4*11min', '5min', 0, 11*60)),
    WorkoutItem.create({ name: 'threshold', format: ['1h30'] }, 'threshold', { description: 'threshold', format: {intervals: '2*22min'} },
      warmup2 + '2*(10:00@55%ftp, 22:00@100%ftp), 11:00@60%ftp', 3.5, thresholdDescriptions(2, 64*60, '100%ftp', '2*22min', '10min', 0, 11*60)),

    WorkoutItem.create({ name: 'threshold', format: ['1h30'] }, 'threshold', { description: 'threshold', format: {intervals: '2*25min'} },
      warmup2 + '5:00@55%ftp, 25:00@100%ftp, 10:00@55%ftp, 25:00@100%ftp, 10:00@60%ftp', 4, thresholdDescriptions(2, 65*60, '100%ftp', '2*25min', '10min', 0, 10*60)),

    WorkoutItem.create({ name: 'threshold', format: ['1h30'] }, 'threshold', { description: 'threshold', format: {intervals: '25 & 30min'} },
      warmup2 + '2:00@55%ftp, 25:00@100%ftp, 8:00@55%ftp, 30:00@100%ftp, 10:00@60%ftp', 4.5, thresholdDescriptions(2, 65*60, '100%ftp', '25 & 30min', '8min', 0, 10*60)),

    WorkoutItem.create({ name: 'threshold', format: ['1h30'] }, 'threshold', { description: 'threshold', format: {intervals: '2*30min'} },
      warmup2 + '1:00@55%ftp, 30:00@98%ftp, 4:00@55%ftp, 30:00@98%ftp, 10:00@60%ftp', 5, thresholdDescriptions(2, 65*60, '98%ftp', '2*30min', '4min', 0, 10*60)),

    WorkoutItem.create({ name: 'threshold', format: ['1h30'] }, 'threshold', { description: 'threshold', format: {intervals: '2*31min'} },
      warmup2 + '1:00@55%ftp, 31:00@100%ftp, 2:00@55%ftp, 31:00@100%ftp, 10:00@60%ftp', 5.5, thresholdDescriptions(2, 65*60, '100%ftp', '2*31min', '2min', 0, 10*60)),

    WorkoutItem.create({ name: 'threshold', format: ['1h30'] }, 'threshold', { description: 'threshold', format: {intervals: '1h'} },
      warmup2 + '5:00@55%ftp, 60:00@97%ftp, 10:00@60%ftp', 6, thresholdDescriptions(2, 60*60, '97%ftp', '1h', '', 0, 10*60)),

    WorkoutItem.create({ name: 'threshold', format: ['1h30'] }, 'threshold', { description: 'threshold', format: {intervals: '1h'} },
      warmup2 + '5:00@55%ftp, 60:00@100%ftp, 10:00@60%ftp', 6.5, thresholdDescriptions(2, 60*60, '100%ftp', '1h', '', 0, 10*60)),


    //Seuil 2h

    WorkoutItem.create({ name: 'threshold', format: ['2h'] }, 'threshold', { description: 'threshold', format: {intervals: '3*10min'} },
      warmup2 + '3*(5:00@55%ftp, 10:00@105%ftp), 50:00@65%ftp, 10:00@60%ftp', 2.5, thresholdDescriptions(2, 45*60, '105%ftp', '3*10min', '5min', 50*60, 10*60)),
    WorkoutItem.create({ name: 'threshold', format: ['2h'] }, 'threshold', { description: 'threshold', format: {intervals: '3*12min'} },
      warmup2 + '3*(6:00@55%ftp, 12:00@100%ftp), 41:00@65%ftp, 10:00@60%ftp', 2.5, thresholdDescriptions(2, 54*60, '100%ftp', '3*12min', '5min', 41*60, 10*60)),

    WorkoutItem.create({ name: 'threshold', format: ['2h'] }, 'threshold', { description: 'threshold', format: {intervals: '4*10min'} },
      warmup2 + '4*(5:00@55%ftp, 10:00@100%ftp), 35:00@65%ftp, 10:00@60%ftp', 3, thresholdDescriptions(2, 60*60, '100%ftp', '4*10min', '5min', 35*60, 10*60)),
    WorkoutItem.create({ name: 'threshold', format: ['2h'] }, 'threshold', { description: 'threshold', format: {intervals: '2*20min'} },
      warmup2 + '2*(10:00@55%ftp, 20:00@100%ftp), 35:00@65%ftp, 10:00@60%ftp', 3, thresholdDescriptions(2, 60*60, '100%ftp', '2*20min', '10min', 35*60, 10*60)),

    WorkoutItem.create({ name: 'threshold', format: ['2h'] }, 'threshold', { description: 'threshold', format: {intervals: '4*11min'} },
      warmup2 + '4*(5:00@55%ftp, 11:00@100%ftp), 31:00@65%ftp, 10:00@60%ftp', 3.5, thresholdDescriptions(2, 64*60, '100%ftp', '4*11min', '5min', 31*60, 10*60)),
    WorkoutItem.create({ name: 'threshold', format: ['2h'] }, 'threshold', { description: 'threshold', format: {intervals: '2*22min'} },
      warmup2 + '2*(10:00@55%ftp, 22:00@100%ftp), 31:00@65%ftp, 10:00@60%ftp', 3.5, thresholdDescriptions(2, 64*60, '100%ftp', '2*22min', '10min', 31*60, 10*60)),

    WorkoutItem.create({ name: 'threshold', format: ['2h'] }, 'threshold', { description: 'threshold', format: {intervals: '2*25min'} },
      warmup2 + '5:00@55%ftp, 25:00@100%ftp, 10:00@55%ftp, 25:00@100%ftp, 30:00@65%ftp, 10:00@60%ftp', 4, thresholdDescriptions(2, 65*60, '100%ftp', '2*25min', '10min', 30*60, 10*60)),

    WorkoutItem.create({ name: 'threshold', format: ['2h'] }, 'threshold', { description: 'threshold', format: {intervals: '25 & 30min'} },
      warmup2 + '5:00@55%ftp, 25:00@100%ftp, 10:00@55%ftp, 30:00@100%ftp, 25:00@65%ftp, 10:00@60%ftp', 4.5, thresholdDescriptions(2, 70*60, '100%ftp', '25 & 30min', '10min', 25*60, 10*60)),

    WorkoutItem.create({ name: 'threshold', format: ['2h'] }, 'threshold', { description: 'threshold', format: {intervals: '2*30min'} },
      warmup2 + '5:00@55%ftp, 30:00@100%ftp, 10:00@55%ftp, 30:00@100%ftp, 20:00@65%ftp, 10:00@60%ftp', 5, thresholdDescriptions(2, 75*60, '100%ftp', '2*30min', '10min', 20*60, 10*60)),
    WorkoutItem.create({ name: 'threshold', format: ['2h'] }, 'threshold', { description: 'threshold', format: {intervals: '3*20min'} },
      warmup2 + '3*(10:00@55%ftp, 20:00@100%ftp), 5:00@65%ftp, 10:00@60%ftp', 5, thresholdDescriptions(2, 90*60, '100%ftp', '3*20min', '10min', 5*60, 10*60)),

    WorkoutItem.create({ name: 'threshold', format: ['2h'] }, 'threshold', { description: 'threshold', format: {intervals: '2*35min'} },
      warmup2 + '5:00@55%ftp, 35:00@100%ftp, 10:00@55%ftp, 35:00@100%ftp, 10:00@65%ftp, 10:00@60%ftp', 5.5, thresholdDescriptions(2, 85*60, '100%ftp', '2*35min', '10min', 10*60, 10*60)),

    WorkoutItem.create({ name: 'threshold', format: ['2h'] }, 'threshold', { description: 'threshold', format: {intervals: '2*40min'} },
      warmup2 + '5:00@55%ftp, 40:00@100%ftp, 10:00@55%ftp, 40:00@100%ftp, 10:00@60%ftp', 6, thresholdDescriptions(2, 95*60, '100%ftp', '2*40min', '10min', 0, 10*60)),

    WorkoutItem.create({ name: 'threshold', format: ['2h'] }, 'threshold', { description: 'threshold', format: {intervals: '2*42min'} },
      warmup2 + '1:00@55%ftp, 42:00@100%ftp, 10:00@55%ftp, 42:00@100%ftp, 10:00@60%ftp', 6.5, thresholdDescriptions(2, 95*60, '100%ftp', '2*42min', '10min', 0, 10*60)),


    //Seuil 2h30

    WorkoutItem.create({ name: 'threshold', format: ['2h30'] }, 'threshold', { description: 'threshold', format: {intervals: '2*30min'} },
      warmup2 + '5:00@55%ftp, 30:00@100%ftp, 10:00@55%ftp, 30:00@100%ftp, 50:00@65%ftp, 10:00@60%ftp', 5, thresholdDescriptions(2, 75*60, '100%ftp', '2*30min', '10min', 50*60, 10*60)),
    WorkoutItem.create({ name: 'threshold', format: ['2h30'] }, 'threshold', { description: 'threshold', format: {intervals: '3*20min'} },
      warmup2 + '3*(10:00@55%ftp, 20:00@100%ftp), 35:00@65%ftp, 10:00@60%ftp', 5, thresholdDescriptions(2, 90*60, '100%ftp', '3*20min', '10min', 35*60, 10*60)),

    WorkoutItem.create({ name: 'threshold', format: ['2h30'] }, 'threshold', { description: 'threshold', format: {intervals: '2*35min'} },
      warmup2 + '5:00@55%ftp, 35:00@100%ftp, 10:00@55%ftp, 35:00@100%ftp, 40:00@65%ftp, 10:00@60%ftp', 5.5, thresholdDescriptions(2, 85*60, '100%ftp', '2*35min', '10min', 40*60, 10*60)),

    WorkoutItem.create({ name: 'threshold', format: ['2h30'] }, 'threshold', { description: 'threshold', format: {intervals: '2*40min'} },
      warmup2 + '5:00@55%ftp, 40:00@100%ftp, 15:00@55%ftp, 40:00@100%ftp, 25:00@65%ftp, 10:00@60%ftp', 6, thresholdDescriptions(2, 100*60, '100%ftp', '2*40min', '15min', 25*60, 10*60)),
    WorkoutItem.create({ name: 'threshold', format: ['2h30'] }, 'threshold', { description: 'threshold', format: {intervals: '4*20min'} },
      warmup2 + '4*(10:00@55%ftp, 20:00@100%ftp), 5:00@65%ftp, 10:00@60%ftp', 6, thresholdDescriptions(2, 120*60, '100%ftp', '4*20min', '10min', 5*60, 10*60)),

    WorkoutItem.create({ name: 'threshold', format: ['2h30'] }, 'threshold', { description: 'threshold', format: {intervals: '2*45min'} },
      warmup2 + '5:00@55%ftp, 45:00@100%ftp, 20:00@55%ftp, 45:00@100%ftp, 10:00@65%ftp, 10:00@60%ftp', 6.5, thresholdDescriptions(2, 115*60, '100%ftp', '2*45min', '20min', 10*60, 10*60)),


    //Seuil 3h
    WorkoutItem.create({ name: 'threshold', format: ['3h00'] }, 'threshold', { description: 'threshold', format: {intervals: '2*40min'} },
      warmup2 + '5:00@55%ftp, 40:00@100%ftp, 15:00@55%ftp, 40:00@100%ftp, 55:00@65%ftp, 10:00@60%ftp', 6, thresholdDescriptions(2, 100*60, '100%ftp', '2*40min', '15min', 55*60, 10*60)),
    WorkoutItem.create({ name: 'threshold', format: ['3h00'] }, 'threshold', { description: 'threshold', format: {intervals: '4*20min'} },
      warmup2 + '4*(10:00@55%ftp, 20:00@100%ftp), 35:00@65%ftp, 10:00@60%ftp', 6, thresholdDescriptions(2, 120*60, '100%ftp', '4*20min', '10min', 35*60, 10*60)),

    WorkoutItem.create({ name: 'threshold', format: ['3h00'] }, 'threshold', { description: 'threshold', format: {intervals: '2*45min'} },
      warmup2 + '5:00@55%ftp, 45:00@100%ftp, 20:00@55%ftp, 45:00@100%ftp, 40:00@65%ftp, 10:00@60%ftp', 6.5, thresholdDescriptions(2, 115*60, '100%ftp', '2*45min', '20min', 40*60, 10*60)),
  ]
);



function thresholdForceDescriptions(warmup, duration, target, intervals, recovery, endurance, cooldown){
  return [
    warmupDescriptions[warmup],
    { zone: 4, duration: duration, target: target, description: 'threshold_strength', format: { intervals: intervals, recovery: recovery } },
    endurance ? { zone: 2, duration: endurance, target: '65%ftp', description: 'light_endurance' } : null, //if no endurance duration, no step
    { zone: 1, duration: cooldown, target: '60%ftp', description: 'cooldown' },
  ].filter(e => e); //remove null element if any
}


var thresholdForce = new WorkoutCategory(
  'thresholdForce',
  [
    // Seuil force 1h
    WorkoutItem.create({ name: 'threshold_strength', format: ['1h'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '5*4min'} },
      warmup2 + '5*(4:00@95%ftp60rpm, 2:00@55%ftp), 5:00@65%ftp, 10:00@60%ftp', 1, thresholdForceDescriptions(2, 30*60, '95%ftp', '5*4min', '2min', 5*60, 10*60)),
    WorkoutItem.create({ name: 'threshold_strength', format: ['1h'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '10*2min'} },
      warmup2 + '10*(2:00@95%ftp60rpm, 1:00@55%ftp), 5:00@65%ftp, 10:00@60%ftp', 1, thresholdForceDescriptions(2, 30*60, '95%ftp', '10*2min', '1min', 5*60, 10*60)),

    WorkoutItem.create({ name: 'threshold_strength', format: ['1h'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '6*3min'} },
      warmup2 + '6*(3:00@100%ftp60rpm, 2:00@55%ftp), 5:00@65%ftp, 10:00@60%ftp', 1.5, thresholdForceDescriptions(2, 30*60, '100%ftp', '6*3min', '2min', 5*60, 10*60)),
    WorkoutItem.create({ name: 'threshold_strength', format: ['1h'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '4*6min'} },
      warmup2 + '4*(6:00@95%ftp60rpm, 2:00@55%ftp), 3:00@65%ftp, 10:00@60%ftp', 1.5, thresholdForceDescriptions(2, 32*60, '95%ftp', '4*6min', '2min', 3*60, 10*60)),

    WorkoutItem.create({ name: 'threshold_strength', format: ['1h'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '7*3min'} },
      warmup2 + '7*(3:00@100%ftp60rpm, 2:00@55%ftp), 5:00@65%ftp, 5:00@60%ftp', 2, thresholdForceDescriptions(2, 35*60, '100%ftp', '7*3min', '2min', 5*60, 5*60)),
    WorkoutItem.create({ name: 'threshold_strength', format: ['1h'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '5*5min'} },
      warmup2 + '5*(5:00@100%ftp60rpm, 2:00@55%ftp), 3:00@65%ftp, 7:00@60%ftp', 2, thresholdForceDescriptions(2, 35*60, '100%ftp', '5*5min', '2min', 3*60, 7*60)),

    WorkoutItem.create({ name: 'threshold_strength', format: ['1h'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '8*3min'} },
      warmup2 + '8*(3:00@100%ftp60rpm, 2:00@55%ftp), 5:00@60%ftp', 2.5, thresholdForceDescriptions(2, 40*60, '100%ftp', '8*3min', '2min', 0, 5*60)),
    WorkoutItem.create({ name: 'threshold_strength', format: ['1h'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '6*5min'} },
      warmup2 + '6*(5:00@100%ftp60rpm, 1:30@55%ftp), 6:00@60%ftp', 2.5, thresholdForceDescriptions(2, 39*60, '100%ftp', '6*5min', '1min30', 0, 6*60)),

    WorkoutItem.create({ name: 'threshold_strength', format: ['1h'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '7*4min'} },
      warmup2 + '7*(4:00@100%ftp60rpm, 1:30@55%ftp), 6:30@60%ftp', 3, thresholdForceDescriptions(2, 38.5*60, '100%ftp', '7*4min', '1min30', 0, 6.5*60)),
    WorkoutItem.create({ name: 'threshold_strength', format: ['1h'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '5*6min'} },
      warmup2 + '5*(6:00@100%ftp60rpm, 2:00@55%ftp), 5:00@60%ftp', 3, thresholdForceDescriptions(2, 40*60, '100%ftp', '5*6min', '2min', 0, 5*60)),

    WorkoutItem.create({ name: 'threshold_strength', format: ['1h'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '7*4min'} },
      warmup2 + '7*(4:00@102%ftp60rpm, 1:45@55%ftp), 4:45@60%ftp', 3.5, thresholdForceDescriptions(2, 40.25*60, '102%ftp', '7*4min', '1min45', 0, 4.75*60)),

    WorkoutItem.create({ name: 'threshold_strength', format: ['1h'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '6*5min'} },
      warmup2 + '6*(5:00@100%ftp60rpm, 1:45@55%ftp), 4:30@60%ftp', 4, thresholdForceDescriptions(2, 40.5*60, '100%ftp', '6*5min', '1min45', 0, 4.5*60)),

    WorkoutItem.create({ name: 'threshold_strength', format: ['1h'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '7*4min30'} },
      warmup2 + '7*(4:30@100%ftp60rpm, 1:30@55%ftp), 3:00@60%ftp', 4.5, thresholdForceDescriptions(2, 42*60, '100%ftp', '7*4min30', '1min30', 0, 3*60)),

    WorkoutItem.create({ name: 'threshold_strength', format: ['1h'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '4*7min'} },
      warmup2 + '4*(7:00@102%ftp60rpm, 3:00@55%ftp), 5:00@60%ftp', 5, thresholdForceDescriptions(2, 40*60, '102%ftp', '4*7min', '3min', 0, 5*60)),

    WorkoutItem.create({ name: 'threshold_strength', format: ['1h'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '8*3min'} },
      warmup2 + '8*(3:00@105%ftp60rpm, 2:00@55%ftp), 5:00@60%ftp', 5.5, thresholdForceDescriptions(2, 40*60, '105%ftp', '8*3min', '2min', 0, 5*60)),

    WorkoutItem.create({ name: 'threshold_strength', format: ['1h'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '6*5min'} },
      warmup2 + '6*(5:00@105%ftp60rpm, 1:30@55%ftp), 6:00@60%ftp', 6, thresholdForceDescriptions(2, 40.5*60, '105%ftp', '6*5min', '1min45', 0, 4.5*60)),

    WorkoutItem.create({ name: 'threshold_strength', format: ['1h'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '7*4min'} },
      warmup2 + '7*(4:00@105%ftp60rpm, 1:30@55%ftp), 6:30@60%ftp', 6.5, thresholdForceDescriptions(2, 40.25*60, '105%ftp', '7*4min', '1min45', 0, 4.75*60)),

    WorkoutItem.create({ name: 'threshold_strength', format: ['1h'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '4*7min'} },
      warmup2 + '4*(7:00@105%ftp60rpm, 3:00@55%ftp), 5:00@60%ftp', 7, thresholdForceDescriptions(2, 40*60, '105%ftp', '4*7min', '3min', 0, 5*60)),

    //Seuil force 1h30
    WorkoutItem.create({ name: 'threshold_strength', format: ['1h30'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '5*4min'} },
      warmup3 + '5*(4:00@95%ftp60rpm, 2:00@55%ftp), 5:00@65%ftp, 10:00@60%ftp', 1, thresholdForceDescriptions(3, 30*60, '95%ftp', '5*4min', '2min', 5*60, 10*60)),
    WorkoutItem.create({ name: 'threshold_strength', format: ['1h30'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '10*2min'} },
      warmup3 + '10*(2:00@95%ftp60rpm, 1:00@55%ftp), 5:00@65%ftp, 10:00@60%ftp', 1, thresholdForceDescriptions(3, 30*60, '95%ftp', '10*2min', '1min', 5*60, 10*60)),

    WorkoutItem.create({ name: 'threshold_strength', format: ['1h30'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '6*3min'} },
      warmup3 + '6*(3:00@100%ftp60rpm, 2:00@55%ftp), 5:00@65%ftp, 10:00@60%ftp', 1.5, thresholdForceDescriptions(3, 30*60, '100%ftp', '6*3min', '2min', 5*60, 10*60)),
    WorkoutItem.create({ name: 'threshold_strength', format: ['1h30'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '4*6min'} },
      warmup3 + '4*(6:00@95%ftp60rpm, 2:00@55%ftp), 3:00@65%ftp, 10:00@60%ftp', 1.5, thresholdForceDescriptions(3, 32*60, '95%ftp', '4*6min', '2min', 3*60, 10*60)),

    WorkoutItem.create({ name: 'threshold_strength', format: ['1h30'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '7*3min'} },
      warmup3 + '7*(3:00@100%ftp60rpm, 2:00@55%ftp), 5:00@65%ftp, 5:00@60%ftp', 2, thresholdForceDescriptions(3, 35*60, '100%ftp', '7*3min', '2min', 5*60, 5*60)),
    WorkoutItem.create({ name: 'threshold_strength', format: ['1h30'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '5*5min'} },
      warmup3 + '5*(5:00@100%ftp60rpm, 2:00@55%ftp), 3:00@65%ftp, 7:00@60%ftp', 2, thresholdForceDescriptions(3, 35*60, '100%ftp', '5*5min', '2min', 3*60, 7*60)),

    WorkoutItem.create({ name: 'threshold_strength', format: ['1h30'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '8*3min'} },
      warmup3 + '8*(3:00@100%ftp60rpm, 2:00@55%ftp), 5:00@60%ftp', 2.5, thresholdForceDescriptions(3, 40*60, '100%ftp', '8*3min', '2min', 0, 5*60)),
    WorkoutItem.create({ name: 'threshold_strength', format: ['1h30'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '6*5min'} },
      warmup3 + '6*(5:00@100%ftp60rpm, 1:30@55%ftp), 6:00@60%ftp', 2.5, thresholdForceDescriptions(3, 39*60, '100%ftp', '6*5min', '1min30', 0, 6*60)),

    WorkoutItem.create({ name: 'threshold_strength', format: ['1h30'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '7*4min'} },
      warmup3 + '7*(4:00@100%ftp60rpm, 1:30@55%ftp), 6:30@60%ftp', 3),
    WorkoutItem.create({ name: 'threshold_strength', format: ['1h30'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '5*6min'} }, warmup3 + '5*(6:00@100%ftp60rpm, 2:00@55%ftp), 5:00@60%ftp', 3),

    WorkoutItem.create({ name: 'threshold_strength', format: ['1h30'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '7*4min'} }, warmup3 + '7*(4:00@102%ftp60rpm, 1:45@55%ftp), 4:45@60%ftp', 3.5),

    WorkoutItem.create({ name: 'threshold_strength', format: ['1h30'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '6*5min'} }, warmup3 + '6*(5:00@100%ftp60rpm, 1:45@55%ftp), 4:30@60%ftp', 4),
    WorkoutItem.create({ name: 'threshold_strength', format: ['1h30'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '7*4min30'} }, warmup3 + '7*(4:30@100%ftp60rpm, 1:30@55%ftp), 3:00@60%ftp', 4.5),

    WorkoutItem.create({ name: 'threshold_strength', format: ['1h30'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '4*7min'} }, warmup3 + '4*(7:00@102%ftp60rpm, 3:00@55%ftp), 5:00@60%ftp', 5),

    WorkoutItem.create({ name: 'threshold_strength', format: ['1h30'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '8*3min'} }, warmup3 + '8*(3:00@105%ftp60rpm, 2:00@55%ftp), 5:00@60%ftp', 5.5),

    WorkoutItem.create({ name: 'threshold_strength', format: ['1h30'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '6*5min'} }, warmup3 + '6*(5:00@103%ftp60rpm, 1:30@55%ftp), 6:00@60%ftp', 6),

    WorkoutItem.create({ name: 'threshold_strength', format: ['1h30'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '7*4min'} }, warmup3 + '7*(4:00@103%ftp60rpm, 1:30@55%ftp), 6:30@60%ftp', 6.5),

    WorkoutItem.create({ name: 'threshold_strength', format: ['1h30'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '4*7min'} }, warmup3 + '4*(7:00@105%ftp60rpm, 3:00@55%ftp), 5:00@60%ftp', 7),


    //Seuil force 2h
    WorkoutItem.create({ name: 'threshold_strength', format: ['2h00'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '5*4min'} }, warmup4 + '5*(4:00@95%ftp60rpm, 2:00@55%ftp), 5:00@65%ftp, 10:00@60%ftp', 1),
    WorkoutItem.create({ name: 'threshold_strength', format: ['2h00'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '10*2min'} }, warmup4 + '10*(2:00@95%ftp60rpm, 1:00@55%ftp), 5:00@65%ftp, 10:00@60%ftp', 1),

    WorkoutItem.create({ name: 'threshold_strength', format: ['2h00'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '6*3min'} }, warmup4 + '6*(3:00@100%ftp60rpm, 2:00@55%ftp), 5:00@65%ftp, 10:00@60%ftp', 1.5),
    WorkoutItem.create({ name: 'threshold_strength', format: ['2h00'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '4*6min'} }, warmup4 + '4*(6:00@95%ftp60rpm, 2:00@55%ftp), 3:00@65%ftp, 10:00@60%ftp', 1.5),

    WorkoutItem.create({ name: 'threshold_strength', format: ['2h00'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '7*3min'} }, warmup4 + '7*(3:00@100%ftp60rpm, 2:00@55%ftp), 5:00@65%ftp, 5:00@60%ftp', 2),
    WorkoutItem.create({ name: 'threshold_strength', format: ['2h00'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '5*5min'} }, warmup4 + '5*(5:00@100%ftp60rpm, 2:00@55%ftp), 3:00@65%ftp, 7:00@60%ftp', 2),

    WorkoutItem.create({ name: 'threshold_strength', format: ['2h00'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '8*3min'} }, warmup4 + '8*(3:00@100%ftp60rpm, 2:00@55%ftp), 5:00@60%ftp', 2.5),
    WorkoutItem.create({ name: 'threshold_strength', format: ['2h00'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '6*5min'} }, warmup4 + '6*(5:00@100%ftp60rpm, 1:30@55%ftp), 6:00@60%ftp', 2.5),

    WorkoutItem.create({ name: 'threshold_strength', format: ['2h00'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '7*4min'} }, warmup4 + '7*(4:00@100%ftp60rpm, 1:30@55%ftp), 6:30@60%ftp', 3),
    WorkoutItem.create({ name: 'threshold_strength', format: ['2h00'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '5*6min'} }, warmup4 + '5*(6:00@100%ftp60rpm, 2:00@55%ftp), 5:00@60%ftp', 3),

    WorkoutItem.create({ name: 'threshold_strength', format: ['2h00'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '7*4min'} }, warmup4 + '7*(4:00@102%ftp60rpm, 1:45@55%ftp), 4:45@60%ftp', 3.5),

    WorkoutItem.create({ name: 'threshold_strength', format: ['2h00'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '6*5min'} }, warmup4 + '6*(5:00@100%ftp60rpm, 1:45@55%ftp), 4:30@60%ftp', 4),
    WorkoutItem.create({ name: 'threshold_strength', format: ['2h00'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '7*4min30'} }, warmup4 + '7*(4:30@100%ftp60rpm, 1:30@55%ftp), 3:00@60%ftp', 4.5),

    WorkoutItem.create({ name: 'threshold_strength', format: ['2h00'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '4*7min'} }, warmup4 + '4*(7:00@102%ftp60rpm, 3:00@55%ftp), 5:00@60%ftp', 5),

    WorkoutItem.create({ name: 'threshold_strength', format: ['2h00'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '8*3min'} }, warmup4 + '8*(3:00@105%ftp60rpm, 2:00@55%ftp), 5:00@60%ftp', 5.5),

    WorkoutItem.create({ name: 'threshold_strength', format: ['2h00'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '6*5min'} }, warmup4 + '6*(5:00@103%ftp60rpm, 1:30@55%ftp), 6:00@60%ftp', 6),

    WorkoutItem.create({ name: 'threshold_strength', format: ['2h00'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '7*4min'} }, warmup4 + '7*(4:00@103%ftp60rpm, 1:30@55%ftp), 6:30@60%ftp', 6.5),

    WorkoutItem.create({ name: 'threshold_strength', format: ['2h00'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '4*7min'} }, warmup4 + '4*(7:00@105%ftp60rpm, 3:00@55%ftp), 5:00@60%ftp', 7),


    //Seuil force 2h30
    WorkoutItem.create({ name: 'threshold_strength', format: ['2h30'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '5*4min'} }, warmup5 + '5*(4:00@95%ftp60rpm, 2:00@55%ftp), 5:00@65%ftp, 10:00@60%ftp', 1),
    WorkoutItem.create({ name: 'threshold_strength', format: ['2h30'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '10*2min'} }, warmup5 + '10*(2:00@95%ftp60rpm, 1:00@55%ftp), 5:00@65%ftp, 10:00@60%ftp', 1),

    WorkoutItem.create({ name: 'threshold_strength', format: ['2h30'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '6*3min'} }, warmup5 + '6*(3:00@100%ftp60rpm, 2:00@55%ftp), 5:00@65%ftp, 10:00@60%ftp', 1.5),
    WorkoutItem.create({ name: 'threshold_strength', format: ['2h30'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '4*6min'} }, warmup5 + '4*(6:00@95%ftp60rpm, 2:00@55%ftp), 3:00@65%ftp, 10:00@60%ftp', 1.5),

    WorkoutItem.create({ name: 'threshold_strength', format: ['2h30'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '7*3min'} }, warmup5 + '7*(3:00@100%ftp60rpm, 2:00@55%ftp), 5:00@65%ftp, 5:00@60%ftp', 2),
    WorkoutItem.create({ name: 'threshold_strength', format: ['2h30'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '5*5min'} }, warmup5 + '5*(5:00@100%ftp60rpm, 2:00@55%ftp), 3:00@65%ftp, 7:00@60%ftp', 2),

    WorkoutItem.create({ name: 'threshold_strength', format: ['2h30'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '8*3min'} }, warmup5 + '8*(3:00@100%ftp60rpm, 2:00@55%ftp), 5:00@60%ftp', 2.5),
    WorkoutItem.create({ name: 'threshold_strength', format: ['2h30'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '6*5min'} }, warmup5 + '6*(5:00@100%ftp60rpm, 1:30@55%ftp), 6:00@60%ftp', 2.5),

    WorkoutItem.create({ name: 'threshold_strength', format: ['2h30'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '7*4min'} }, warmup5 + '7*(4:00@100%ftp60rpm, 1:30@55%ftp), 6:30@60%ftp', 3),
    WorkoutItem.create({ name: 'threshold_strength', format: ['2h30'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '5*6min'} }, warmup5 + '5*(6:00@100%ftp60rpm, 2:00@55%ftp), 5:00@60%ftp', 3),

    WorkoutItem.create({ name: 'threshold_strength', format: ['2h30'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '7*4min'} }, warmup5 + '7*(4:00@102%ftp60rpm, 1:45@55%ftp), 4:45@60%ftp', 3.5),

    WorkoutItem.create({ name: 'threshold_strength', format: ['2h30'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '6*5min'} }, warmup5 + '6*(5:00@100%ftp60rpm, 1:45@55%ftp), 4:30@60%ftp', 4),
    WorkoutItem.create({ name: 'threshold_strength', format: ['2h30'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '7*4min30'} }, warmup5 + '7*(4:30@100%ftp60rpm, 1:30@55%ftp), 3:00@60%ftp', 4.5),

    WorkoutItem.create({ name: 'threshold_strength', format: ['2h30'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '4*7min'} }, warmup5 + '4*(7:00@102%ftp60rpm, 3:00@55%ftp), 5:00@60%ftp', 5),

    WorkoutItem.create({ name: 'threshold_strength', format: ['2h30'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '8*3min'} }, warmup5 + '8*(3:00@105%ftp60rpm, 2:00@55%ftp), 5:00@60%ftp', 5.5),

    WorkoutItem.create({ name: 'threshold_strength', format: ['2h30'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '6*5min'} }, warmup5 + '6*(5:00@103%ftp60rpm, 1:30@55%ftp), 6:00@60%ftp', 6),

    WorkoutItem.create({ name: 'threshold_strength', format: ['2h30'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '7*4min'} }, warmup5 + '7*(4:00@103%ftp60rpm, 1:30@55%ftp), 6:30@60%ftp', 6.5),

    WorkoutItem.create({ name: 'threshold_strength', format: ['2h30'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '4*7min'} }, warmup5 + '4*(7:00@105%ftp60rpm, 3:00@55%ftp), 5:00@60%ftp', 7),


    //Seuil force 3h
    WorkoutItem.create({ name: 'threshold_strength', format: ['3h00'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '5*4min'} }, warmup5 + '5*(4:00@95%ftp60rpm, 2:00@55%ftp), 25:00@65%ftp, 15:00@60%ftp', 1),
    WorkoutItem.create({ name: 'threshold_strength', format: ['3h00'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '10*2min'} }, warmup5 + '10*(2:00@95%ftp60rpm, 1:00@55%ftp), 25:00@65%ftp, 15:00@60%ftp', 1),

    WorkoutItem.create({ name: 'threshold_strength', format: ['3h00'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '6*3min'} }, warmup5 + '6*(3:00@100%ftp60rpm, 2:00@55%ftp), 25:00@65%ftp, 15:00@60%ftp', 1.5),
    WorkoutItem.create({ name: 'threshold_strength', format: ['3h00'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '4*6min'} }, warmup5 + '4*(6:00@95%ftp60rpm, 2:00@55%ftp), 33:00@65%ftp, 10:00@60%ftp', 1.5),

    WorkoutItem.create({ name: 'threshold_strength', format: ['3h00'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '7*3min'} }, warmup5 + '7*(3:00@100%ftp60rpm, 2:00@55%ftp), 25:00@65%ftp, 15:00@60%ftp', 2),
    WorkoutItem.create({ name: 'threshold_strength', format: ['3h00'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '5*5min'} }, warmup5 + '5*(5:00@100%ftp60rpm, 2:00@55%ftp), 33:00@65%ftp, 7:00@60%ftp', 2),

    WorkoutItem.create({ name: 'threshold_strength', format: ['3h00'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '8*3min'} }, warmup5 + '8*(3:00@100%ftp60rpm, 2:00@55%ftp), 35:00@60%ftp', 2.5),
    WorkoutItem.create({ name: 'threshold_strength', format: ['3h00'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '6*5min'} }, warmup5 + '6*(5:00@100%ftp60rpm, 1:30@55%ftp), 36:00@60%ftp', 2.5),

    WorkoutItem.create({ name: 'threshold_strength', format: ['3h00'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '7*4min'} }, warmup5 + '7*(4:00@100%ftp60rpm, 1:30@55%ftp), 36:30@60%ftp', 3),
    WorkoutItem.create({ name: 'threshold_strength', format: ['3h00'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '5*6min'} }, warmup5 + '5*(6:00@100%ftp60rpm, 2:00@55%ftp), 35:00@60%ftp', 3),

    WorkoutItem.create({ name: 'threshold_strength', format: ['3h00'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '7*4min'} }, warmup5 + '7*(4:00@102%ftp60rpm, 1:45@55%ftp), 34:45@60%ftp', 3.5),

    WorkoutItem.create({ name: 'threshold_strength', format: ['3h00'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '6*5min'} }, warmup5 + '6*(5:00@100%ftp60rpm, 1:45@55%ftp), 34:30@60%ftp', 4),
    WorkoutItem.create({ name: 'threshold_strength', format: ['3h00'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '7*4min30'} }, warmup5 + '7*(4:30@100%ftp60rpm, 1:30@55%ftp), 33:00@60%ftp', 4.5),

    WorkoutItem.create({ name: 'threshold_strength', format: ['3h00'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '4*7min'} }, warmup5 + '4*(7:00@102%ftp60rpm, 3:00@55%ftp), 35:00@60%ftp', 5),

    WorkoutItem.create({ name: 'threshold_strength', format: ['3h00'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '8*3min'} }, warmup5 + '8*(3:00@105%ftp60rpm, 2:00@55%ftp), 35:00@60%ftp', 5.5),

    WorkoutItem.create({ name: 'threshold_strength', format: ['3h00'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '6*5min'} }, warmup5 + '6*(5:00@103%ftp60rpm, 1:30@55%ftp), 36:00@60%ftp', 6),

    WorkoutItem.create({ name: 'threshold_strength', format: ['3h00'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '7*4min'} }, warmup5 + '7*(4:00@103%ftp60rpm, 1:30@55%ftp), 36:30@60%ftp', 6.5),

    WorkoutItem.create({ name: 'threshold_strength', format: ['3h00'] }, 'threshold', { description: 'threshold_strength', format: {intervals: '4*7min'} }, warmup5 + '4*(7:00@105%ftp60rpm, 3:00@55%ftp), 35:00@60%ftp', 7),
  ]
);

module.exports = {
  threshold,
  thresholdForce,
};
