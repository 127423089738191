const WorkoutCategory = require('../workoutCategory');
const rules = require('../rules/rules');
const WorkoutItem = require('../workoutItem');
const workouts = require('../workoutslib/workoutsLib');





module.exports = {
  categories: [ workouts.enduranceF, workouts.enduranceActive, workouts.enduranceSprint, workouts.enduranceForce, workouts.sst],

  rules: [

    rules.frequencyRule('enduranceF', 'MIN', 1, 'REQUIRED'),
    rules.frequencyRule('enduranceF', 'MIN', 2, 'HIGH'),
    rules.frequencyRule('enduranceActive', 'MIN', 1, 'VERYHIGH'),
    rules.frequencyRule('enduranceSprint', 'MIN', 1, 'VERYHIGH'),
    rules.frequencyRule('enduranceSprint', 'MAX', 1, 'REQUIRED'),
    rules.frequencyRule('enduranceForce', 'MIN', 1, 'MEDIUM'),
    rules.frequencyRule(['enduranceF', 'enduranceActive', 'enduranceSprint'], 'MIN', 3, 'HIGH'),
    rules.frequencyRule(['enduranceF', 'enduranceActive', 'enduranceSprint'], 'MAX', 5, 'MEDIUM'),

    rules.placementRule('enduranceF', 'AFTER', 'REST', 'HIGH'),
    rules.placementRule('enduranceSprint', 'AFTER', ['enduranceF', 'enduranceActive'], 'HIGH'),
    rules.placementRule('enduranceForce', 'BEFORE', ['enduranceF', 'enduranceActive'], 'HIGH'),



    rules.trainingRideCategory(['hard', 'very_hard', 'medium'], ['enduranceSprint', 'enduranceForce', 'enduranceActive'], 'REQUIRED'),
    rules.trainingRideCategory(['hard', 'very_hard'], ['enduranceSprint'], 'VERYHIGH'),
    rules.trainingRideCategory('medium', ['enduranceForce', 'enduranceActive'], 'VERYHIGH'),
    rules.trainingRideCategory('endurance', ['enduranceSprint', 'enduranceForce', 'enduranceActive', 'enduranceF'], 'REQUIRED'),
    rules.trainingRideCategory('endurance', 'enduranceF', 'HIGH'),
    rules.trainingRideCategory('easy', 'enduranceF', 'REQUIRED'),

    rules.frequencyRule('sst', 'MAX', 3, 'VERYHIGH'),


    //Semaine 1
    rules.forWeek(1, [
      rules.weekHoursTargetRule('TARGET', 90, '%', 'VERYHIGH', 5),

      rules.frequencyRule('enduranceForce', 'MAX', 1, 'REQUIRED'),

      rules.longestWorkoutDurationTargetRule('TARGET', 1, 15, '%', 'HIGH'),
    ]),

    //Semaine 2
    rules.forWeek(2, [
      rules.weekHoursTargetRule('TARGET', 105, '%', 'VERYHIGH', 5),

      rules.frequencyRule('enduranceForce', 'MIN', 1, 'HIGH'),
      rules.frequencyRule('enduranceForce', 'MIN', 2, 'MEDIUM'),
      rules.frequencyRule('enduranceForce', 'MAX', 2, 'REQUIRED'),

      rules.longestWorkoutDurationTargetRule('TARGET', 1, 20, '%', 'HIGH'),
    ]),

    //Semaine 3
    rules.forWeek(3, [
      rules.weekHoursTargetRule('TARGET', 120, '%', 'VERYHIGH', 5),

      rules.frequencyRule('enduranceForce', 'MIN', 1, 'REQUIRED'),
      rules.frequencyRule('enduranceForce', 'MIN', 2, 'MEDIUM'),
      rules.frequencyRule('enduranceForce', 'MAX', 2, 'REQUIRED'),

      rules.longestWorkoutDurationTargetRule('TARGET', 1, 20, '%', 'HIGH'),
    ]),

  ],
};
