/*
 * Utility functions
 */
const dayjs = require('dayjs');
dayjs.extend(require('dayjs/plugin/utc'));

//Last measure before date
function getLastMeasure(measures, date = new Date()){
  var m = measures.filter(measures => { return measures.date <= date; });
  return m.length > 0 ? m[m.length-1] : null;
}

//Last model before date
function getLastModel(models, date = new Date()){
  var m = models.filter(model => { return model.date <= date; });
  return m.length > 0 ? m[m.length-1] : null;
}

//Get set model if defined (filled with last model data)
function getSetModel(user, date = new Date()){
  let model = getLastModel(user.models, date);
  let setModel = user.set_model;
  if(!setModel)
    return model;

  let currentModel = Object.assign({}, model); //clone last model
  for(const [key, value] of Object.entries(setModel)){ //And set values of set model that aren't 0 or null
    if(value)
      currentModel[key] = value;
  }

  if(currentModel.map && currentModel.threshold_power && currentModel.map > currentModel.threshold_power){ //If MAP is defined
    currentModel.anaerobic_capacity = (currentModel.map-currentModel.threshold_power)*300; //Recalculate anaerobic capacity (diff between map and ftp over 5 minutes)
  }

  return currentModel;
}

function findWorkoutById(workouts, id){
  for(var i=0; i<workouts.length; i++){
    if(workouts[i].id == id)
      return workouts[i];
  }
  return null;
}

function findById(items, id){
  for(var i=0; i<items.length; i++){
    if(items[i].id == id)
      return items[i];
  }
  return null;
}

function getActivityFromSerial(activities, serial_number){
  for(var i=0; i<activities.length; i++){
    if(activities[i].serial_number === serial_number)
      return activities[i];
  }
  return null;
}

function serialExist(activities, serial_number){
  if(getActivityFromSerial(activities, serial_number))
    return true;
  return false;
}

//Get the position to insert an item with a date so that the array stay sorted
function insertPosition(array, item){
  var t = item.date.getTime();
  for(var i=0; i<array.length; i++){
    if(new Date(array[i].date).getTime() >= t)
      return i;
  }
  return array.length;
}

//Find items from same day than the date in an array
function findByDay(array, date){
  var datejs = dayjs.utc(date);
  return array.filter(item => datejs.isSame(dayjs.utc(item.date), 'date'));
}

function findWorkoutsByDay(user, date){
  return findByDay(user.workouts, date);
}

function findActivitiesByDay(user, date){
  return findByDay(user.activities, date);
}

function findTrainingBlockForDay(user, date){
  for(let trainingBlock of user.training_blocks){
    if(trainingBlock.start <= date && trainingBlock.end > date)
      return trainingBlock;
  }
  return null;
}

function arrayEquals(a1, a2){
  return Array.isArray(a1) &&
    Array.isArray(a2) &&
    a1.length === a2.length &&
    a1.every((val, index) => val === a2[index]);
}

function modelEquals(m1, m2){
  if(!m1 || !m2){
    return m1 === m2; //return true only if two null models
  }
  return m1.threshold_power === m2.threshold_power && m1.max_power === m2.max_power && m1.anaerobic_capacity === m2.anaerobic_capacity &&
    m1.threshold_heart_rate === m2.threshold_heart_rate && m1.max_heart_rate === m2.max_heart_rate && m1.resting_heart_rate === m2.resting_heart_rate;
}


module.exports = {
  getLastMeasure,
  getLastModel,
  getSetModel,
  findWorkoutById,
  findById,
  getActivityFromSerial,
  serialExist,
  insertPosition,
  findByDay,
  findWorkoutsByDay,
  findActivitiesByDay,
  findTrainingBlockForDay,
  arrayEquals,
  modelEquals,
};
