/*
 * Target workouts and apply specific rules
 */
const Rule = require('./rule');
const plannerUtil = require('../plannerUtil');
const compare = plannerUtil.compare;

class RuleForWorkout extends Rule{
  constructor(targetRule, categories, rule, priority = 'REQUIRED', multiplier = 1){
    super(priority, multiplier);
    this.targetRule = targetRule;
    this.categories =  Array.isArray(categories) ? categories : [categories];
    this.rule = rule;

    if(!(targetRule === 'THIS' || targetRule === 'AFTER' || targetRule === 'BEFORE')){ //rule can only be before or after
      console.trace();
      throw 'Unknown rule: ' + targetRule;
    }
  }


  check(user, plan, data){
    var points = 0;

    var planCategories = [];
    if(data.previousWorkout) planCategories.push(data.previousWorkout);
    planCategories = planCategories.concat(plan);
    if(data.nextWorkout) planCategories.push(data.nextWorkout);

    for(var i=0; i<planCategories.length; i++){
      var category = Rule.workoutCategoryName(planCategories[i]);



      if(this.categories.includes(category)){ //Workout specified in the first category found. Now need to check if the rule match.
        var targetWorkout = null; //Will be the other category to check
        if(this.targertRule === 'THIS'){
          targetWorkout = planCategories[i];
        }else if(this.targetRule === 'AFTER' && i < planCategories.length-1){ //depending of the rule
          targetWorkout = planCategories[i+1];
        }else if(this.targetRule === 'BEFORE' && i >= 1){
          targetWorkout = planCategories[i-1];
        }

        if(targetWorkout){ //If target workout found
          if(this.rule(targetWorkout)) //check rule and apply points
            points += this.pointsOnSuccess();
          else
            points += this.pointsOnFail();
        }
      }
    }
    return points;
  }

}

function ruleForWorkout(targetRule, categories, rule = (w) => true, priority = 'REQUIRED', multiplier = 1){
  return new RuleForWorkout(targetRule, categories, rule, priority, multiplier);
}

function forWorkoutDuration(comp, time){
  const seconds = time*3600;
  return (workout) => {
    const duration = workout === 'REST' ? 0 : workout.getDuration();
    return compare(comp, duration, seconds);
  }
}





module.exports = {
  ruleForWorkout,
  forWorkoutDuration,
};
