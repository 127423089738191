<template>
  <div id="block_editor" class="box" v-if="$store.getters.hasAdminAccess">
    <div>
      <prism-editor class="editor" v-model="code" :highlight="highlighter" @input="onChange" line-numbers/>
    </div>

    <div class="error box" v-if="error">
      {{ error }}
    </div>

    <div class="actions box">
      <b-field grouped group-multiline>
        <b-field label=".">
          <b-button @click.native="load()">Générer les semaines</b-button>
        </b-field>
        <b-field label="Nombre de génération">
          <b-input type="number" v-model.number="nbPlans"/>
        </b-field>
        <b-field label="Semaine">
          <b-select v-model.number="week">
            <option value="0">1</option>
            <option value="1">2</option>
            <option value="2">3</option>
          </b-select>
        </b-field>
        <b-field label="Niveau">
          <b-select v-model.number="level">
            <option value="0">Aléatoire</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
          </b-select>
        </b-field>

        <b-field label="Profil">
          <b-select v-model="profile">
            <option value="">Aléatoire</option>
            <option value="climber">Grimpeur</option>
            <option value="puncher">Puncheur</option>
            <option value="rouleur">Rouleur</option>
            <option value="sprinter">Sprinteur</option>
            <option value="grandfondo">Cyclosportif</option>
          </b-select>
        </b-field>

        <b-field label="NB Indoor ?">
          <b-select v-model.number="indoorDays">
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
          </b-select>
        </b-field>

        <b-field label="Nb objectifs">
          <b-input type="number" min="0" max="7" v-model.number="nbObjectives"/>
        </b-field>

        <b-field label="Nb Training rides">
          <b-input type="number" min="0" max="7" v-model.number="nbTrainingRides"/>
        </b-field>


        <b-field label="Load">
          <b-select v-model="selectedBlockCode">
            <option v-for="block in Object.keys(blocksCodes)" :key="block" :value="block">{{ block }}</option>
          </b-select>
          <b-button @click.native="loadSelectedBlockCode()">Load</b-button>
        </b-field>

        <b-field label=".">
          <b-button @click.native="copyCode()">Copy code</b-button>
        </b-field>
      </b-field>


      <div class="columns is-multiline is-mobile">
        <b-field label="Objectif" v-for="(n,i) in nbObjectives" :key="i" grouped class="box column is-narrow">

          <b-select v-model.number="objectives[i].day">
            <option value="0">Lundi</option>
            <option value="1">Mardi</option>
            <option value="2">Mercredi</option>
            <option value="3">Jeudi</option>
            <option value="4">Vendredi</option>
            <option value="5">Samedi</option>
            <option value="6">Dimanche</option>
          </b-select>
          <DurationPicker v-model="objectives[i].duration"/>
          <b-select v-model="objectives[i].priority">
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="C">C</option>
          </b-select>
        </b-field>
      </div>


      <div class="columns is-multiline is-mobile">
        <b-field label="Training rides" v-for="(n,i) in nbTrainingRides" :key="i" grouped class="box column is-narrow">
          <b-select v-model.number="trainingRides[i].day">
            <option value="0">Lundi</option>
            <option value="1">Mardi</option>
            <option value="2">Mercredi</option>
            <option value="3">Jeudi</option>
            <option value="4">Vendredi</option>
            <option value="5">Samedi</option>
            <option value="6">Dimanche</option>
          </b-select>
          <DurationPicker v-model="trainingRides[i].estimated_duration"/>
          <b-select v-model="trainingRides[i].planned_intensity">
            <option value="easy">Easy</option>
            <option value="endurance">Endurance</option>
            <option value="medium">Medium</option>
            <option value="hard">Hard</option>
            <option value="very_hard">Very hard</option>
          </b-select>
        </b-field>
      </div>


    </div>

    <div class="results box">
      <div class="columns">
        <div class="column"><h2 class="title is-4">Lundi</h2></div>
        <div class="column"><h2 class="title is-4">Mardi</h2></div>
        <div class="column"><h2 class="title is-4">Mercredi</h2></div>
        <div class="column"><h2 class="title is-4">Jeudi</h2></div>
        <div class="column"><h2 class="title is-4">Vendredi</h2></div>
        <div class="column"><h2 class="title is-4">Samedi</h2></div>
        <div class="column"><h2 class="title is-4">Dimanche</h2></div>
        <div class="column"><h2 class="title is-4">Temps</h2></div>
        <div class="column"><h2 class="title is-4">Stats</h2></div>
      </div>
      <div v-for="(plan, i) in weeks" :key="i" class="columns">
        <div v-for="(workout, j) in plan" :key="j" class="column">
          <WorkoutBox v-if="workout !== 'REST' && workout.isWorkout" :workout="workout" :showValidation="false"/>
          <ObjectiveBox v-if="workout !== 'REST' && workout.isObjective" :objective="workout"/>
          <TrainingRideBox v-if="workout !== 'REST' && workout.isTrainingRide" :trainingRide="workout"/>
          <p> {{ displayTime(plan.data.available_time_days[j]) }}</p>
          <p> {{ plan.data.training_type_days[j] }} </p>
        </div>
        <div class="column">
          <p>Temps moyen: {{ displayTime(plan.data.week_available_time) }}</p>
          <p>Temps max: {{ displayTime(plan.data.max_week_available_time) }}</p>
          <p> <span v-for="(time, k) in plan.data.available_time_days" :key="k"> {{ displayTime(time) }}</span></p>
          <p>Niveau: {{ plan.data.training_level }}</p>
          <p>Profil: {{ plan.stats.profile }}</p>
          <p v-if="plan.fit"><b>Fit: {{ plan.fit }}</b></p>
        </div>
        <div class="column">
          <p>Temps: {{ utimeToString(plan.stats.plannedDuration )}}</p>
          <p>{{ plan.stats.plannedLoad }} TSS</p>
          <p v-for="(time, z) in plan.stats.plannedZones" :key="z">
            {{ 'Z' + (z+1) + ': ' + upreciseTimeToString(time) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PrismEditor } from 'vue-prism-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import WorkoutBox from '@/views/calendar/WorkoutBox.vue';
import ObjectiveBox from '@/views/calendar/ObjectiveBox.vue';
import TrainingRideBox from '@/views/calendar/TrainingRideBox.vue';
import DurationPicker from '@/components/DurationPicker.vue';

const generateWeek = require('./generateWeek');


export default{
  name: 'block_editor',
  data: function(){
    return {
      code: this.loadCode(),
      weeks: [],
      error: '',
      nbPlans: 1,
      week: 0,
      level: 0,
      profile: '',
      indoorDays: 0,
      nbObjectives: 0,
      objectives: new Array(7).fill().map(o => ({ day: 0, duration: 3600, priority: 'A' })),
      nbTrainingRides: 0,
      trainingRides: new Array(7).fill().map(t => ({ day: 0, estimated_duration: 3600, planned_intensity: 'medium'})),
      blocksCodes: {},
      selectedBlockCode: '',
    };
  },
  computed: {
    fullUser(){
      var user = Object.assign({}, this.$store.getters.user);
      user.models = this.$store.getters.models;
      return user;
    },
  },
  methods: {
    highlighter(code){
      return highlight(code, languages.js);
    },

    onChange(){
      localStorage.setItem('block_editor_code', this.code);
    },

    loadCode(){
      return localStorage.getItem('block_editor_code') || '{\n    categories: [],\n\n    rules: [\n\n    ], \n}';
    },

    load(){
      this.weeks = [];
      this.addPlan(0);
    },

    addPlan(i){
      try{
        this.weeks.push(generateWeek(this.fullUser, this.code, this.week, this.level, this.profile,
           this.objectives.slice(0, this.nbObjectives), this.trainingRides.slice(0, this.nbTrainingRides), this.indoorDays));
        if(i+1<this.nbPlans){
          setTimeout(() => this.addPlan(i+1), 10);
        }
      }catch(error){
        this.error = error;
        console.error(error);
      }
    },

    displayTime(time){
      return Math.floor(time/3600) + ':' + this.$pad(Math.floor(time/60)%60, 2);
    },


    loadSelectedBlockCode(){
      if(!this.code || window.confirm('Loading this block code will overwrite your current code. Continue ?')){
        this.code = this.blocksCodes[this.selectedBlockCode];
      }
    },

    async loadBlocksCodes(){
      //Load every block code as raw text
      this.blocksCodes = {
        'preparation': (await import('!raw-loader!@/../api/planner/blocktypes/preparationBlock.js')).default,
        'testing': (await import('!raw-loader!@/../api/planner/blocktypes/testingBlock.js')).default,
        'base1': (await import('!raw-loader!@/../api/planner/blocktypes/baseBlock1.js')).default,
        'base2': (await import('!raw-loader!@/../api/planner/blocktypes/baseBlock2.js')).default,
        'base3': (await import('!raw-loader!@/../api/planner/blocktypes/baseBlock3.js')).default,
        'build1': (await import('!raw-loader!@/../api/planner/blocktypes/buildBlock1.js')).default,
        'build2': (await import('!raw-loader!@/../api/planner/blocktypes/buildBlock2.js')).default,
        'recoveryBase': (await import('!raw-loader!@/../api/planner/blocktypes/recoveryBaseBlock.js')).default,
        'recoveryBuild': (await import('!raw-loader!@/../api/planner/blocktypes/recoveryBuildBlock.js')).default,
        'taper': (await import('!raw-loader!@/../api/planner/blocktypes/taperBlock.js')).default,
      };

      for(let block of Object.keys(this.blocksCodes)){
        this.blocksCodes[block] = this.blocksCodes[block].split('module.exports = ')[1].split('};')[0]+'}'; //Remove imports and module.exports of loaded code
      }
    },

    copyCode(){
      var dummy = document.createElement("textarea");
      document.body.appendChild(dummy);
      dummy.value = this.code;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
    },
  },

  mounted(){
    this.loadBlocksCodes();
  },

  components: {
    PrismEditor,
    WorkoutBox,
    ObjectiveBox,
    TrainingRideBox,
    DurationPicker,
  },

};
</script>

<style lang="scss">
@import '~vue-prism-editor/dist/prismeditor.min.css';
@import '~prismjs/themes/prism-tomorrow.css';

.editor{
  background: #2d2d2d;
  color: #ccc;

  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
  height: 600px;
  overflow: auto;
}

.prism-editor__editor{
  color: #ccc;
}

.token.boolean,
.token.number,
.token.function {
  color: #f08d49;
  background-color: transparent;
  font-size: 1em;
  display: contents;
}

</style>
