const WorkoutCategory = require('../workoutCategory');
const WorkoutItem = require('../workoutItem');

const warmup1 = '1:00@55%ftp, 1:00@60%ftp, 1:00@65%ftp, 1:00@70%ftp, 1:00@75%ftp, 2:00@80%ftp, 1:00@90%ftp, 1:00@100%ftp, 1:00@110%ftp, ';
const warmup2 = '2:00@55%ftp, 2:00@60%ftp, 2:00@65%ftp, 3:00@70%ftp, 1:00@75%ftp, 2:00@80%ftp, 1:00@90%ftp, 1:00@100%ftp, 1:00@110%ftp, ';
const warmupDurations = [0, 10*60, 15*60];

function tempoDescriptions(warmup, duration, target, intervals, recovery, endurance, cooldown){
  let wduration = warmupDurations[warmup];
  return [
    { zone: 2, duration: wduration, target: '55%ftp-110%ftp', description: 'progressive_warmup' },
    { zone: 3, duration: duration, target: target, description: 'tempo', format: { intervals: intervals, recovery: recovery } },
    endurance ? { zone: 2, duration: endurance, target: '65%ftp', description: 'light_endurance' } : null, //if no endurance duration, no step
    { zone: 1, duration: cooldown, target: '60%ftp', description: 'cooldown' },
  ].filter(e => e); //remove null element if any
}


var tempo = new WorkoutCategory(
  'tempo',
  [
    // Tempo 1h
    WorkoutItem.create({ name: 'tempo', format: ['1h'] }, 'tempo', { description: 'tempo', format: {intervals: '4*6min'} },
      warmup1 + '4*(4:00@55%ftp, 6:00@85%ftp), 10:00@60%ftp', 1, tempoDescriptions(1, 40*60, '85%ftp', '4*6min', '4min', 0, 10*60)),
    WorkoutItem.create({ name: 'tempo', format: ['1h'] }, 'tempo', { description: 'tempo', format: {intervals: '4*5min'} },
      warmup1 + '4*(5:00@55%ftp, 5:00@87%ftp), 10:00@60%ftp', 1, tempoDescriptions(1, 40*60, '87%ftp', '4*5min', '5min', 0, 10*60)),

    WorkoutItem.create({ name: 'tempo', format: ['1h'] }, 'tempo', { description: 'tempo', format: {intervals: '3*10min'} },
      warmup1 + '3*(5:00@55%ftp, 10:00@85%ftp), 5:00@60%ftp', 1.5, tempoDescriptions(1, 45*60, '85%ftp', '3*10min', '5min', 0, 5*60)),
    WorkoutItem.create({ name: 'tempo', format: ['1h'] }, 'tempo', { description: 'tempo', format: {intervals: '5*5min'} },
      warmup1 + '5*(4:00@55%ftp, 5:00@87%ftp), 5:00@60%ftp', 1.5, tempoDescriptions(1, 45*60, '87%ftp', '5*5min', '4min', 0, 5*60)),

    WorkoutItem.create({ name: 'tempo', format: ['1h'] }, 'tempo', { description: 'tempo', format: {intervals: '3*10min'} },
      warmup1 + '3*(5:00@55%ftp, 10:00@87%ftp), 5:00@60%ftp', 2, tempoDescriptions(1, 45*60, '87%ftp', '3*10min', '5min', 0, 5*60)),
    WorkoutItem.create({ name: 'tempo', format: ['1h'] }, 'tempo', { description: 'tempo', format: {intervals: '4*7min'} },
      warmup1 + '4*(4:00@55%ftp, 7:15@87%ftp), 5:00@60%ftp', 2, tempoDescriptions(1, 45*60, '85%ftp', '4*7min', '4min', 0, 5*60)),

    WorkoutItem.create({ name: 'tempo', format: ['1h'] }, 'tempo', { description: 'tempo', format: {intervals: '3*12min'} },
      warmup1 + '3*(3:00@55%ftp, 12:00@87%ftp), 5:00@60%ftp', 2.5, tempoDescriptions(1, 45*60, '87%ftp', '3*12min', '3min', 0, 5*60)),

    WorkoutItem.create({ name: 'tempo', format: ['1h'] }, 'tempo', { description: 'tempo', format: {intervals: '2*20min'} },
      warmup1 + '20:00@87%ftp, 5:00@55%ftp, 20:00@87%ftp, 5:00@60%ftp', 3, tempoDescriptions(1, 45*60, '87%ftp', '2*20min', '5min', 0, 5*60)),

    WorkoutItem.create({ name: 'tempo', format: ['1h'] }, 'tempo', { description: 'tempo', format: {intervals: '2*20min'} },
      warmup1 + '20:00@90%ftp, 5:00@55%ftp, 20:00@90%ftp, 5:00@60%ftp', 3.5, tempoDescriptions(1, 45*60, '90%ftp', '2*20min', '5min', 0, 5*60)),

    WorkoutItem.create({ name: 'tempo', format: ['1h'] }, 'tempo', { description: 'tempo', format: {intervals: '2*22min'} },
      warmup1 + '22:00@85%ftp, 1:00@55%ftp, 22:00@85%ftp, 5:00@60%ftp', 4, tempoDescriptions(1, 45*60, '85%ftp', '2*22min', '1min', 0, 5*60)),

    WorkoutItem.create({ name: 'tempo', format: ['1h'] }, 'tempo', { description: 'tempo', format: {intervals: '45min'} },
      warmup1 + '45:00@85%ftp, 5:00@60%ftp', 4.5, tempoDescriptions(1, 45*60, '85%ftp', '45min', '', 0, 5*60)),

    WorkoutItem.create({ name: 'tempo', format: ['1h'] }, 'tempo', { description: 'tempo', format: {intervals: '45min'} },
      warmup1 + '45:00@87%ftp, 5:00@60%ftp', 5, tempoDescriptions(1, 45*60, '87%ftp', '45min', '', 0, 5*60)),

    WorkoutItem.create({ name: 'tempo', format: ['1h'] }, 'tempo', { description: 'tempo', format: {intervals: '45min'} },
      warmup1 + '45:00@90%ftp, 5:00@60%ftp', 5.5, tempoDescriptions(1, 45*60, '85%ftp', '45min', '', 0, 5*60)),

    //Tempo 1h30
    WorkoutItem.create({ name: 'tempo', format: ['1h30'] }, 'tempo', { description: 'tempo', format: {intervals: '3*10min'} },
      warmup2 + '3*(5:00@55%ftp, 10:00@85%ftp), 20:00@65%ftp, 10:00@60%ftp', 1, tempoDescriptions(2, 45*60, '85%ftp', '3*10min', '5min', 20*60, 10*60)),
    WorkoutItem.create({ name: 'tempo', format: ['1h30'] }, 'tempo', { description: 'tempo', format: {intervals: '3*8min'} },
      warmup2 + '3*(5:00@55%ftp, 8:00@87%ftp), 26:00@65%ftp, 10:00@60%ftp', 1, tempoDescriptions(2, 39*60, '87%ftp', '3*8min', '5min', 26*60, 10*60)),

    WorkoutItem.create({ name: 'tempo', format: ['1h30'] }, 'tempo', { description: 'tempo', format: {intervals: '3*12min'} },
      warmup2 + '3*(5:00@55%ftp, 12:00@85%ftp), 14:00@65%ftp, 10:00@60%ftp', 1.5, tempoDescriptions(2, 51*60, '85%ftp', '3*12min', '5min', 14*60, 10*60)),
    WorkoutItem.create({ name: 'tempo', format: ['1h30'] }, 'tempo', { description: 'tempo', format: {intervals: '6*6min'} },
      warmup2 + '6*(4:00@55%ftp, 6:00@85%ftp), 5:00@65%ftp, 10:00@60%ftp', 1.5, tempoDescriptions(2, 60*60, '85%ftp', '6*6min', '4min', 5*60, 10*60)),

    WorkoutItem.create({ name: 'tempo', format: ['1h30'] }, 'tempo', { description: 'tempo', format: {intervals: '3*15min'} },
      warmup2 + '3*(5:00@55%ftp, 15:00@85%ftp), 5:00@65%ftp, 10:00@60%ftp', 2, tempoDescriptions(2, 60*60, '85%ftp', '3*15min', '5min', 5*60, 10*60)),
    WorkoutItem.create({ name: 'tempo', format: ['1h30'] }, 'tempo', { description: 'tempo', format: {intervals: '5*8min'} },
      warmup2 + '5*(5:00@55%ftp, 8:00@87%ftp), 10:00@60%ftp', 2, tempoDescriptions(2, 65*60, '87%ftp', '5*8min', '5min', 0, 10*60)),

    WorkoutItem.create({ name: 'tempo', format: ['1h30'] }, 'tempo', { description: 'tempo', format: {intervals: '4*11min'} },
      warmup2 + '4*(5:00@55%ftp, 11:15@85%ftp), 10:00@60%ftp', 2.5, tempoDescriptions(2, 65*60, '85%ftp', '4*11min', '5min', 0, 10*60)),

    WorkoutItem.create({ name: 'tempo', format: ['1h30'] }, 'tempo', { description: 'tempo', format: {intervals: '3*16min'} },
      warmup2 + '3*(6:00@55%ftp, 16:00@85%ftp), 9:00@60%ftp', 3, tempoDescriptions(2, 66*60, '85%ftp', '3*16min', '6min', 0, 9*60)),

    WorkoutItem.create({ name: 'tempo', format: ['1h30'] }, 'tempo', { description: 'tempo', format: {intervals: '3*16min'} },
      warmup2 + '3*(6:00@55%ftp, 16:00@87%ftp), 9:00@60%ftp', 3.5, tempoDescriptions(2, 66*60, '87%ftp', '3*16min', '6min', 0, 9*60)),

    WorkoutItem.create({ name: 'tempo', format: ['1h30'] }, 'tempo', { description: 'tempo', format: {intervals: '2*30min'} },
      warmup2 + '30:00@87%ftp, 5:00@55%ftp, 30:00@87%ftp, 10:00@60%ftp', 4, tempoDescriptions(2, 65*60, '87%ftp', '2*30min', '5min', 0, 10*60)),

    WorkoutItem.create({ name: 'tempo', format: ['1h30'] }, 'tempo', { description: 'tempo', format: {intervals: '2*30min'} },
      warmup2 + '30:00@90%ftp, 5:00@55%ftp, 30:00@90%ftp, 10:00@60%ftp', 4.5, tempoDescriptions(2, 65*60, '90%ftp', '5*8min', '5min', 0, 10*60)),

    WorkoutItem.create({ name: 'tempo', format: ['1h30'] }, 'tempo', { description: 'tempo', format: {intervals: '1h'} },
      warmup2 + '5:00@55%ftp, 1:00:00@85%ftp, 10:00@60%ftp', 5, tempoDescriptions(2, 60*60, '85%ftp', '1h', '', 0, 10*60)),

    WorkoutItem.create({ name: 'tempo', format: ['1h30'] }, 'tempo', { description: 'tempo', format: {intervals: '1h'} },
      warmup2 + '5:00@55%ftp, 1:00:00@87%ftp, 10:00@60%ftp', 5.5, tempoDescriptions(2, 60*60, '87%ftp', '1h', '', 0, 10*60)),

    WorkoutItem.create({ name: 'tempo', format: ['1h30'] }, 'tempo', { description: 'tempo', format: {intervals: '1h'} },
      warmup2 + '5:00@55%ftp, 1:00:00@90%ftp, 10:00@60%ftp', 6, tempoDescriptions(2, 60*60, '90%ftp', '1h', '', 0, 10*60)),

    WorkoutItem.create({ name: 'tempo', format: ['1h30'] }, 'tempo', { description: 'tempo', format: {intervals: '1h05'} },
      warmup2 + '1:05:00@90%ftp, 10:00@60%ftp', 6.5, tempoDescriptions(2, 65*60, '90%ftp', '1h05', '', 0, 10*60)),


    //Tempo 2h

    WorkoutItem.create({ name: 'tempo', format: ['2h'] }, 'tempo', { description: 'tempo', format: {intervals: '3*10min'} },
      warmup2 + '3*(5:00@55%ftp, 10:00@85%ftp), 50:00@65%ftp, 10:00@60%ftp', 1, tempoDescriptions(2, 45*60, '85%ftp', '3*10min', '5min', 50*60, 10*60)),
    WorkoutItem.create({ name: 'tempo', format: ['2h'] }, 'tempo', { description: 'tempo', format: {intervals: '3*8min'} },
      warmup2 + '3*(5:00@55%ftp, 8:00@87%ftp), 56:00@65%ftp, 10:00@60%ftp', 1, tempoDescriptions(2, 39*60, '87%ftp', '3*8min', '5min', 56*60, 10*60)),

    WorkoutItem.create({ name: 'tempo', format: ['2h'] }, 'tempo', { description: 'tempo', format: {intervals: '3*12min'} },
      warmup2 + '3*(5:00@55%ftp, 12:00@85%ftp), 44:00@65%ftp, 10:00@60%ftp', 1.5, tempoDescriptions(2, 51*60, '85%ftp', '3*12min', '5min', 44*60, 10*60)),
    WorkoutItem.create({ name: 'tempo', format: ['2h'] }, 'tempo', { description: 'tempo', format: {intervals: '6*6min'} },
      warmup2 + '6*(4:00@55%ftp, 6:00@85%ftp), 35:00@65%ftp, 10:00@60%ftp', 1.5, tempoDescriptions(2, 60*60, '85%ftp', '6*6min', '4min', 35*60, 10*60)),

    WorkoutItem.create({ name: 'tempo', format: ['2h'] }, 'tempo', { description: 'tempo', format: {intervals: '3*15min'} },
      warmup2 + '3*(5:00@55%ftp, 15:00@85%ftp), 35:00@65%ftp, 10:00@60%ftp', 2, tempoDescriptions(2, 60*60, '85%ftp', '3*15min', '5min', 35*60, 10*60)),
    WorkoutItem.create({ name: 'tempo', format: ['2h'] }, 'tempo', { description: 'tempo', format: {intervals: '5*8min'} },
      warmup2 + '5*(5:00@55%ftp, 8:00@87%ftp), 30:00@65%ftp, 10:00@60%ftp', 2, tempoDescriptions(2, 65*60, '85%ftp', '5*8min', '5min', 30*60, 10*60)),

    WorkoutItem.create({ name: 'tempo', format: ['2h'] }, 'tempo', { description: 'tempo', format: {intervals: '4*12min'} },
      warmup2 + '4*(5:00@55%ftp, 12:30@85%ftp), 25:00@65%ftp, 10:00@60%ftp', 2.5, tempoDescriptions(2, 70*60, '85%ftp', '4*12min', '5min', 25*60, 10*60)),

    WorkoutItem.create({ name: 'tempo', format: ['2h'] }, 'tempo', { description: 'tempo', format: {intervals: '3*20min'} },
      warmup2 + '3*(10:00@55%ftp, 20:00@85%ftp), 5:00@65%ftp, 10:00@60%ftp', 3, tempoDescriptions(2, 90*60, '85%ftp', '3*20min', '10min', 5*60, 10*60)),

    WorkoutItem.create({ name: 'tempo', format: ['2h'] }, 'tempo', { description: 'tempo', format: {intervals: '3*24min'} },
      warmup2 + '3*(8:00@55%ftp, 24:00@85%ftp), 9:00@60%ftp', 3.5, tempoDescriptions(2, 96*60, '85%ftp', '3*24min', '8min', 0, 9*60)),

    WorkoutItem.create({ name: 'tempo', format: ['2h'] }, 'tempo', { description: 'tempo', format: {intervals: '3*25min'} },
      warmup2 + '3*(7:00@55%ftp, 25:00@87%ftp), 9:00@60%ftp', 4, tempoDescriptions(2, 96*60, '87%ftp', '3*25min', '7min', 0, 9*60)),

    WorkoutItem.create({ name: 'tempo', format: ['2h'] }, 'tempo', { description: 'tempo', format: {intervals: '20min & 2*30min'} },
      warmup2 + '20:00@87%ftp, 2*(8:00@55%ftp, 30:00@87%ftp), 9:00@60%ftp', 4.5, tempoDescriptions(2, 96*60, '87%ftp', '20min & 2*30min', '8min', 0, 9*60)),

    WorkoutItem.create({ name: 'tempo', format: ['2h'] }, 'tempo', { description: 'tempo', format: {intervals: '40min & 45min'} },
      warmup2 + '40:00@87%ftp, 10:00@55%ftp, 45:00@87%ftp, 10:00@60%ftp', 5, tempoDescriptions(2, 95*60, '87%ftp', '40min & 45min', '10min', 0, 10*60)),

    WorkoutItem.create({ name: 'tempo', format: ['2h'] }, 'tempo', { description: 'tempo', format: {intervals: '40 & 45min'} },
      warmup2 + '40:00@90%ftp, 10:00@55%ftp, 45:00@90%ftp, 10:00@60%ftp', 5.5, tempoDescriptions(2, 95*60, '90%ftp', '40min & 45min', '10min', 0, 10*60)),

    WorkoutItem.create({ name: 'tempo', format: ['2h'] }, 'tempo', { description: 'tempo', format: {intervals: '1h20'} },
      warmup2 + '5:00@55%ftp, 1:20:00@85%ftp, 10:00@65%ftp, 10:00@60%ftp', 6, tempoDescriptions(2, 80*60, '85%ftp', '1h20', '', 10*60, 10*60)),

    WorkoutItem.create({ name: 'tempo', format: ['2h'] }, 'tempo', { description: 'tempo', format: {intervals: '1h35'} },
      warmup2 + '1:35:00@85%ftp, 10:00@60%ftp', 6.5, tempoDescriptions(2, 95*60, '85%ftp', '1h35', '', 0, 10*60)),


    //Tempo 2h30

    WorkoutItem.create({ name: 'tempo', format: ['2h30'] }, 'tempo', { description: 'tempo', format: {intervals: '4*12min'} },
      warmup2 + '4*(5:00@55%ftp, 12:30@85%ftp), 55:00@65%ftp, 10:00@60%ftp', 2.5, tempoDescriptions(2, 70*60, '85%ftp', '4*12min', '5min', 55*60, 10*60)),

    WorkoutItem.create({ name: 'tempo', format: ['2h30'] }, 'tempo', { description: 'tempo', format: {intervals: '3*20min'} },
      warmup2 + '3*(10:00@55%ftp, 20:00@85%ftp), 35:00@65%ftp, 10:00@60%ftp', 3, tempoDescriptions(2, 90*60, '85%ftp', '3*20min', '10min', 35*60, 10*60)),

    WorkoutItem.create({ name: 'tempo', format: ['2h30'] }, 'tempo', { description: 'tempo', format: {intervals: '3*25min'} },
      warmup2 + '3*(10:00@55%ftp, 25:00@85%ftp), 20:00@65%ftp, 10:00@60%ftp', 3.5, tempoDescriptions(2, 105*60, '85%ftp', '3*25min', '10min', 20*60, 10*60)),

    WorkoutItem.create({ name: 'tempo', format: ['2h30'] }, 'tempo', { description: 'tempo', format: {intervals: '4*20min'} },
      warmup2 + '4*(10:00@55%ftp, 20:00@85%ftp), 5:00@65%ftp, 10:00@60%ftp', 4, tempoDescriptions(2, 120*60, '85%ftp', '4*20min', '10min', 5*60, 10*60)),

    WorkoutItem.create({ name: 'tempo', format: ['2h30'] }, 'tempo', { description: 'tempo', format: {intervals: '3*30min'} },
      warmup2 + '3*(10:00@55%ftp, 30:00@85%ftp), 5:00@65%ftp, 10:00@60%ftp', 4.5, tempoDescriptions(2, 120*60, '85%ftp', '3*30min', '10min', 5*60, 10*60)),

    WorkoutItem.create({ name: 'tempo', format: ['2h30'] }, 'tempo', { description: 'tempo', format: {intervals: '3*30min'} },
      warmup2 + '3*(10:00@55%ftp, 30:00@87%ftp), 5:00@65%ftp, 10:00@60%ftp', 5, tempoDescriptions(2, 120*60, '87%ftp', '3*30min', '10min', 5*60, 10*60)),

    WorkoutItem.create({ name: 'tempo', format: ['2h30'] }, 'tempo', { description: 'tempo', format: {intervals: '3*30min'} },
      warmup2 + '3*(10:00@55%ftp, 30:00@90%ftp), 5:00@65%ftp, 10:00@60%ftp', 5.5, tempoDescriptions(2, 120*60, '90%ftp', '3*30min', '10min', 5*60, 10*60)),

    WorkoutItem.create({ name: 'tempo', format: ['2h30'] }, 'tempo', { description: 'tempo', format: {intervals: '2*55min'} },
      warmup2 + '2*(8:00@55%ftp, 55:00@87%ftp), 9:00@60%ftp', 6, tempoDescriptions(2, 126*60, '87%ftp', '3*55min', '8min', 0, 9*60)),

    WorkoutItem.create({ name: 'tempo', format: ['2h30'] }, 'tempo', { description: 'tempo', format: {intervals: '2*55min'} },
      warmup2 + '2*(8:00@55%ftp, 55:00@90%ftp), 9:00@60%ftp', 6.5, tempoDescriptions(2, 126*60, '90%ftp', '3*55min', '8min', 0, 9*60)),

    //Tempo 3h

    WorkoutItem.create({ name: 'tempo', format: ['3h'] }, 'tempo', { description: 'tempo', format: {intervals: '3*25min'} },
      warmup2 + '3*(10:00@55%ftp, 25:00@85%ftp), 50:00@65%ftp, 10:00@60%ftp', 3.5, tempoDescriptions(2, 105*60, '85%ftp', '3*25min', '10min', 50*60, 10*60)),

    WorkoutItem.create({ name: 'tempo', format: ['3h'] }, 'tempo', { description: 'tempo', format: {intervals: '4*20min'} },
      warmup2 + '4*(10:00@55%ftp, 20:00@85%ftp), 35:00@65%ftp, 10:00@60%ftp', 4, tempoDescriptions(2, 120*60, '85%ftp', '4*20min', '10min', 35*60, 10*60)),

    WorkoutItem.create({ name: 'tempo', format: ['3h'] }, 'tempo', { description: 'tempo', format: {intervals: '3*30min'} },
      warmup2 + '3*(10:00@55%ftp, 30:00@85%ftp), 35:00@65%ftp, 10:00@60%ftp', 4.5, tempoDescriptions(2, 120*60, '85%ftp', '3*30min', '10min', 35*60, 10*60)),

    WorkoutItem.create({ name: 'tempo', format: ['3h'] }, 'tempo', { description: 'tempo', format: {intervals: '4*25min'} },
      warmup2 + '4*(10:00@55%ftp, 25:00@85%ftp), 15:00@65%ftp, 10:00@60%ftp', 5, tempoDescriptions(2, 140*60, '85%ftp', '4*25min', '10min', 15*60, 10*60)),

    WorkoutItem.create({ name: 'tempo', format: ['3h'] }, 'tempo', { description: 'tempo', format: {intervals: '2*55min'} },
      warmup2 + '2*(15:00@55%ftp, 55:00@85%ftp), 15:00@65%ftp, 10:00@60%ftp', 5.5, tempoDescriptions(2, 140*60, '85%ftp', '2*55min', '15min', 15*60, 10*60)),

    WorkoutItem.create({ name: 'tempo', format: ['3h'] }, 'tempo', { description: 'tempo', format: {intervals: '2*60min'} },
      warmup2 + '2*(15:00@55%ftp, 60:00@87%ftp), 5:00@65%ftp, 10:00@60%ftp', 6, tempoDescriptions(2, 150*60, '87%ftp', '2*60min', '15min', 5*60, 10*60)),

    WorkoutItem.create({ name: 'tempo', format: ['3h'] }, 'tempo', { description: 'tempo', format: {intervals: '2*60min'} },
      warmup2 + '2*(15:00@55%ftp, 60:00@90%ftp), 5:00@65%ftp, 10:00@60%ftp', 6.5, tempoDescriptions(2, 150*60, '90%ftp', '2*60min', '15min', 5*60, 10*60)),

    //Tempo 3h30

    WorkoutItem.create({ name: 'tempo', format: ['3h30'] }, 'tempo', { description: 'tempo', format: {intervals: '4*25min'} },
      warmup2 + '4*(10:00@55%ftp, 25:00@85%ftp), 45:00@65%ftp, 10:00@60%ftp', 5, tempoDescriptions(2, 140*60, '85%ftp', '4*25min', '10min', 45*60, 10*60)),

    WorkoutItem.create({ name: 'tempo', format: ['3h30'] }, 'tempo', { description: 'tempo', format: {intervals: '2*55min'} },
      warmup2 + '2*(15:00@55%ftp, 55:00@85%ftp), 45:00@65%ftp, 10:00@60%ftp', 5.5, tempoDescriptions(2, 140*60, '85%ftp', '2*55min', '15min', 45*60, 10*60)),

    WorkoutItem.create({ name: 'tempo', format: ['3h30'] }, 'tempo', { description: 'tempo', format: {intervals: '2*60min'} },
      warmup2 + '2*(15:00@55%ftp, 60:00@87%ftp), 35:00@65%ftp, 10:00@60%ftp', 6, tempoDescriptions(2, 150*60, '87%ftp', '2*60min', '15min', 35*60, 10*60)),

    WorkoutItem.create({ name: 'tempo', format: ['3h30'] }, 'tempo', { description: 'tempo', format: {intervals: '3*50min'} },
      warmup2 + '3*(10:00@55%ftp, 50:00@87%ftp), 5:00@65%ftp, 10:00@60%ftp', 6.5, tempoDescriptions(2, 180*60, '87%ftp', '3*50min', '10min', 5*60, 10*60)),

    //Tempo 4h

    WorkoutItem.create({ name: 'tempo', format: ['4h00'] }, 'tempo', { description: 'tempo', format: {intervals: '4*40min'} },
      warmup2 + '4*(10:00@55%ftp, 40:00@85%ftp), 15:00@65%ftp, 10:00@60%ftp', 6.5, tempoDescriptions(2, 200*60, '85%ftp', '4*40min', '10min', 15*60, 10*60)),

  ]
);


function tempoForceDescriptions(warmup, duration, target, intervals, recovery, endurance, cooldown){
  let wduration = warmupDurations[warmup];
  return [
    { zone: 2, duration: wduration, target: '55%ftp-110%ftp', description: 'progressive_warmup' },
    { zone: 3, duration: duration, target: target, description: 'tempo_strength', format: { intervals: intervals, recovery: recovery } },
    endurance ? { zone: 2, duration: endurance, target: '65%ftp', description: 'light_endurance' } : null, //if no endurance duration, no step
    { zone: 1, duration: cooldown, target: '60%ftp', description: 'cooldown' },
  ].filter(e => e); //remove null element if any
}

var tempoForce = new WorkoutCategory(
'tempoForce',
[

  // Tempo force 1h
  WorkoutItem.create({ name: 'tempo_strength', format: ['1h'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '4*5min'} },
    warmup1 + '4*(5:00@55%ftp, 5:00@85%ftp60rpm), 10:00@60%ftp', 1, tempoForceDescriptions(1, 40*60, '85%ftp', '4*5min', '5min', 0, 10*60)),
  WorkoutItem.create({ name: 'tempo_strength', format: ['1h'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '4*4min'} },
    warmup2 + '4*(5:00@55%ftp, 4:00@87%ftp60rpm), 9:00@60%ftp', 1, tempoForceDescriptions(2, 36*60, '87%ftp', '4*4min', '5min', 0, 9*60)),

  WorkoutItem.create({ name: 'tempo_strength', format: ['1h'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '4*6min'} },
    warmup1 + '4*(4:00@55%ftp, 6:00@85%ftp60rpm), 10:00@60%ftp', 1.5, tempoForceDescriptions(1, 40*60, '85%ftp', '4*6min', '4min', 0, 10*60)),
  WorkoutItem.create({ name: 'tempo_strength', format: ['1h'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '4*5min'} },
    warmup1 + '4*(5:00@55%ftp, 5:00@87%ftp60rpm), 10:00@60%ftp', 1.5, tempoForceDescriptions(1, 40*60, '87%ftp', '4*5min', '5min', 0, 10*60)),

  WorkoutItem.create({ name: 'tempo_strength', format: ['1h'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '3*10min'} },
    warmup1 + '3*(5:00@55%ftp, 10:00@85%ftp60rpm), 5:00@60%ftp', 2, tempoForceDescriptions(1, 45*60, '85%ftp', '3*10min', '5min', 0, 5*60)),
  WorkoutItem.create({ name: 'tempo_strength', format: ['1h'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '5*5min'} },
    warmup1 + '5*(4:00@55%ftp, 5:00@87%ftp60rpm), 5:00@60%ftp', 2, tempoForceDescriptions(1, 45*60, '87%ftp', '5*5min', '4min', 0, 5*60)),

  WorkoutItem.create({ name: 'tempo_strength', format: ['1h'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '3*10min'} },
    warmup1 + '3*(5:00@55%ftp, 10:00@87%ftp60rpm), 5:00@60%ftp', 2.5, tempoForceDescriptions(1, 45*60, '87%ftp', '3*10min', '5min', 0, 5*60)),
  WorkoutItem.create({ name: 'tempo_strength', format: ['1h'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '4*7min'} },
    warmup1 + '4*(4:00@55%ftp, 7:15@87%ftp60rpm), 5:00@60%ftp', 2.5, tempoForceDescriptions(1, 45*60, '87%ftp', '4*7min', '4min', 0, 5*60)),

  WorkoutItem.create({ name: 'tempo_strength', format: ['1h'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '3*12min'} },
    warmup1 + '3*(3:00@55%ftp, 12:00@87%ftp60rpm), 5:00@60%ftp', 3, tempoForceDescriptions(1, 45*60, '87%ftp', '3*12min', '3min', 0, 5*60)),

  WorkoutItem.create({ name: 'tempo_strength', format: ['1h'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '2*20min'} },
    warmup1 + '20:00@87%ftp60rpm, 5:00@55%ftp, 20:00@87%ftp60rpm, 5:00@60%ftp', 3.5, tempoForceDescriptions(1, 45*60, '87%ftp', '2*20min', '5min', 0, 5*60)),

  WorkoutItem.create({ name: 'tempo_strength', format: ['1h'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '2*20min'} },
    warmup1 + '20:00@90%ftp60rpm, 5:00@55%ftp, 20:00@90%ftp60rpm, 5:00@60%ftp', 4, tempoForceDescriptions(1, 45*60, '90%ftp', '2*20min', '5min', 0, 5*60)),

  WorkoutItem.create({ name: 'tempo_strength', format: ['1h'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '2*22min'} },
    warmup1 + '22:00@85%ftp60rpm, 1:00@55%ftp, 22:00@85%ftp60rpm, 5:00@60%ftp', 4.5, tempoForceDescriptions(1, 45*60, '85%ftp', '2*22min', '1min', 0, 5*60)),

  WorkoutItem.create({ name: 'tempo_strength', format: ['1h'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '45min'} },
    warmup1 + '45:00@85%ftp60rpm, 5:00@60%ftp', 5, tempoForceDescriptions(1, 45*60, '85%ftp', '45min', '', 0, 5*60)),

  WorkoutItem.create({ name: 'tempo_strength', format: ['1h'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '45min'} },
    warmup1 + '45:00@87%ftp60rpm, 5:00@60%ftp', 5.5, tempoForceDescriptions(1, 45*60, '87%ftp', '45min', '', 0, 5*60)),

  WorkoutItem.create({ name: 'tempo_strength', format: ['1h'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '45min'} },
    warmup1 + '45:00@90%ftp60rpm, 5:00@60%ftp', 6, tempoForceDescriptions(1, 45*60, '90%ftp', '45min', '', 0, 5*60)),

  //Tempo force 1h30
  WorkoutItem.create({ name: 'tempo_strength', format: ['1h'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '4*5min'} },
    warmup2 + '4*(5:00@55%ftp, 5:00@85%ftp60rpm), 25:00@65%ftp, 10:00@60%ftp', 1, tempoForceDescriptions(2, 40*60, '85%ftp', '4*5min', '5min', 25*60, 10*60)),
  WorkoutItem.create({ name: 'tempo_strength', format: ['1h'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '4*4min'} },
    warmup2 + '4*(5:00@55%ftp, 4:00@87%ftp60rpm), 29:00@65%ftp, 10:00@60%ftp', 1, tempoForceDescriptions(2, 36*60, '87%ftp', '4*4min', '5min', 29*60, 10*60)),

  WorkoutItem.create({ name: 'tempo_strength', format: ['1h30'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '3*10min'} },
    warmup2 + '3*(5:00@55%ftp, 10:00@85%ftp60rpm), 20:00@65%ftp, 10:00@60%ftp', 1.5, tempoForceDescriptions(2, 45*60, '85%ftp', '3*10min', '5min', 20*60, 10*60)),
  WorkoutItem.create({ name: 'tempo_strength', format: ['1h30'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '3*8min'} },
    warmup2 + '3*(5:00@55%ftp, 8:00@87%ftp60rpm), 26:00@65%ftp, 10:00@60%ftp', 1.5, tempoForceDescriptions(2, 39*60, '87%ftp', '3*8min', '5min', 26*60, 10*60)),

  WorkoutItem.create({ name: 'tempo_strength', format: ['1h30'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '3*12min'} },
    warmup2 + '3*(5:00@55%ftp, 12:00@85%ftp60rpm), 14:00@65%ftp, 10:00@60%ftp', 2, tempoForceDescriptions(2, 51*60, '85%ftp', '3*12min', '5min', 14*60, 10*60)),
  WorkoutItem.create({ name: 'tempo_strength', format: ['1h30'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '6*6min'} },
    warmup2 + '6*(4:00@55%ftp, 6:00@87%ftp60rpm), 5:00@65%ftp, 10:00@60%ftp', 2, tempoForceDescriptions(2, 60*60, '87%ftp', '6*6min', '4min', 5*60, 10*60)),

  WorkoutItem.create({ name: 'tempo_strength', format: ['1h30'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '3*15min'} },
    warmup2 + '3*(5:00@55%ftp, 15:00@85%ftp60rpm), 5:00@65%ftp, 10:00@60%ftp', 2.5, tempoForceDescriptions(2, 60*60, '85%ftp', '3*15min', '5min', 5*60, 10*60)),
  WorkoutItem.create({ name: 'tempo_strength', format: ['1h30'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '5*8min'} },
    warmup2 + '5*(5:00@55%ftp, 8:00@87%ftp60rpm), 10:00@60%ftp', 2.5, tempoForceDescriptions(2, 65*60, '87%ftp', '5*8min', '5min', 0, 10*60)),

  WorkoutItem.create({ name: 'tempo_strength', format: ['1h30'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '4*11min'} },
    warmup2 + '4*(5:00@55%ftp, 11:15@85%ftp60rpm), 10:00@60%ftp', 3, tempoForceDescriptions(2, 65*60, '85%ftp', '4*11min', '5min', 0, 10*60)),

  WorkoutItem.create({ name: 'tempo_strength', format: ['1h30'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '3*16min'} },
    warmup2 + '3*(6:00@55%ftp, 16:00@87%ftp60rpm), 9:00@60%ftp', 3.5, tempoForceDescriptions(2, 66*60, '87%ftp', '3*16min', '6min', 0, 9*60)),

  WorkoutItem.create({ name: 'tempo_strength', format: ['1h30'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '3*18min'} },
    warmup2 + '3*(4:00@55%ftp, 18:00@87%ftp60rpm), 9:00@60%ftp', 4, tempoForceDescriptions(2, 66*60, '87%ftp', '3*18min', '4min', 0, 9*60)),

  WorkoutItem.create({ name: 'tempo_strength', format: ['1h30'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '2*30min'} },
    warmup2 + '30:00@87%ftp60rpm, 5:00@55%ftp, 30:00@87%ftp60rpm, 10:00@60%ftp', 4.5, tempoForceDescriptions(2, 65*60, '87%ftp', '2*30min', '5min', 0, 10*60)),

  WorkoutItem.create({ name: 'tempo_strength', format: ['1h30'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '2*30min'} },
    warmup2 + '30:00@90%ftp60rpm, 5:00@55%ftp, 30:00@90%ftp60rpm, 10:00@60%ftp', 5, tempoForceDescriptions(2, 65*60, '90%ftp', '2*30min', '5min', 0, 10*60)),

  WorkoutItem.create({ name: 'tempo_strength', format: ['1h30'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '1h'} },
    warmup2 + '5:00@55%ftp, 1:00:00@85%ftp60rpm, 10:00@60%ftp', 5.5, tempoForceDescriptions(2, 60*60, '85%ftp', '1h', '', 0, 10*60)),

  WorkoutItem.create({ name: 'tempo_strength', format: ['1h30'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '1h'} },
    warmup2 + '5:00@55%ftp, 1:00:00@87%ftp60rpm, 10:00@60%ftp', 6, tempoForceDescriptions(2, 60*60, '87%ftp', '1h', '', 0, 10*60)),

  WorkoutItem.create({ name: 'tempo_strength', format: ['1h30'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '1h'} },
    warmup2 + '5:00@55%ftp, 1:00:00@90%ftp60rpm, 10:00@60%ftp', 6.5, tempoForceDescriptions(2, 60*60, '90%ftp', '1h', '', 0, 10*60)),

  //Tempo force 2h

  WorkoutItem.create({ name: 'tempo_strength', format: ['2h'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '3*10min'} },
    warmup2 + '3*(5:00@55%ftp, 10:00@85%ftp60rpm), 50:00@65%ftp, 10:00@60%ftp', 1.5, tempoForceDescriptions(2, 45*60, '85%ftp', '3*10min', '5min', 50*60, 10*60)),
  WorkoutItem.create({ name: 'tempo_strength', format: ['2h'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '3*8min'} },
    warmup2 + '3*(5:00@55%ftp, 8:00@87%ftp60rpm), 56:00@65%ftp, 10:00@60%ftp', 1.5, tempoForceDescriptions(2, 39*60, '87%ftp', '3*8min', '5min', 56*60, 10*60)),

  WorkoutItem.create({ name: 'tempo_strength', format: ['2h'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '3*12min'} },
    warmup2 + '3*(5:00@55%ftp, 12:00@85%ftp60rpm), 44:00@65%ftp, 10:00@60%ftp', 2, tempoForceDescriptions(2, 51*60, '85%ftp', '3*12min', '5min', 44*60, 10*60)),
  WorkoutItem.create({ name: 'tempo_strength', format: ['2h'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '6*6min'} },
    warmup2 + '6*(4:00@55%ftp, 6:00@87%ftp60rpm), 35:00@65%ftp, 10:00@60%ftp', 2, tempoForceDescriptions(2, 60*60, '87%ftp', '6*6min', '4min', 35*60, 10*60)),

  WorkoutItem.create({ name: 'tempo_strength', format: ['2h'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '3*15min'} },
    warmup2 + '3*(5:00@55%ftp, 15:00@85%ftp60rpm), 35:00@65%ftp, 10:00@60%ftp', 2.5, tempoForceDescriptions(2, 60*60, '85%ftp', '3*15min', '5min', 35*60, 10*60)),
  WorkoutItem.create({ name: 'tempo_strength', format: ['2h'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '5*8min'} },
    warmup2 + '5*(5:00@55%ftp, 8:00@87%ftp60rpm), 30:00@65%ftp, 10:00@60%ftp', 2.5, tempoForceDescriptions(2, 65*60, '87%ftp', '5*8min', '5min', 30*60, 10*60)),

  WorkoutItem.create({ name: 'tempo_strength', format: ['2h'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '4*12min'} },
    warmup2 + '4*(5:00@55%ftp, 12:30@85%ftp60rpm), 25:00@65%ftp, 10:00@60%ftp', 3, tempoForceDescriptions(2, 70*60, '85%ftp', '4*12min', '5min', 25*60, 10*60)),

  WorkoutItem.create({ name: 'tempo_strength', format: ['2h'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '3*20min'} },
    warmup2 + '3*(10:00@55%ftp, 20:00@85%ftp60rpm), 5:00@65%ftp, 10:00@60%ftp', 3.5, tempoForceDescriptions(2, 90*60, '85%ftp', '3*20min', '10min', 5*60, 10*60)),

  WorkoutItem.create({ name: 'tempo_strength', format: ['2h'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '3*24min'} },
    warmup2 + '3*(8:00@55%ftp, 24:00@85%ftp60rpm), 9:00@60%ftp', 4, tempoForceDescriptions(2, 96*60, '85%ftp', '3*24min', '8min', 0, 9*60)),

  WorkoutItem.create({ name: 'tempo_strength', format: ['2h'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '3*25min'} },
    warmup2 + '3*(7:00@55%ftp, 25:00@87%ftp60rpm), 9:00@60%ftp', 4.5, tempoForceDescriptions(2, 96*60, '87%ftp', '3*25min', '7min', 0, 9*60)),

  WorkoutItem.create({ name: 'tempo_strength', format: ['2h'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '20min & 2*30min'} },
    warmup2 + '20:00@87%ftp60rpm, 2*(8:00@55%ftp, 30:00@87%ftp60rpm), 9:00@60%ftp', 5, tempoForceDescriptions(2, 96*60, '85=7%ftp', '20min & 2*30min', '8min', 0, 9*60)),

  WorkoutItem.create({ name: 'tempo_strength', format: ['2h'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '40 & 45min'} },
    warmup2 + '40:00@87%ftp60rpm, 10:00@55%ftp, 45:00@87%ftp60rpm, 10:00@60%ftp', 5.5, tempoForceDescriptions(2, 95*60, '87%ftp', '40 & 45min', '10min', 0, 10*60)),

  WorkoutItem.create({ name: 'tempo_strength', format: ['2h'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '40 & 45min'} },
    warmup2 + '40:00@90%ftp60rpm, 10:00@55%ftp, 45:00@90%ftp60rpm, 10:00@60%ftp', 6, tempoForceDescriptions(2, 95*60, '90%ftp', '40 & 45min', '10min', 0, 10*60)),

  WorkoutItem.create({ name: 'tempo_strength', format: ['2h'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '1h20'} },
    warmup2 + '5:00@55%ftp, 1:20:00@85%ftp60rpm, 10:00@65%ftp, 10:00@60%ftp', 6.5, tempoForceDescriptions(2, 80*60, '87%ftp', '1h20', '', 10*60, 10*60)),


  //Tempo force 2h30

  WorkoutItem.create({ name: 'tempo_strength', format: ['2h30'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '4*12min'} },
    warmup2 + '4*(5:00@55%ftp, 12:30@85%ftp60rpm), 55:00@65%ftp, 10:00@60%ftp', 3, tempoForceDescriptions(2, 70*60, '85%ftp', '4*12min', '5min', 55*60, 10*60)),

  WorkoutItem.create({ name: 'tempo_strength', format: ['2h30'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '3*20min'} },
    warmup2 + '3*(10:00@55%ftp, 20:00@85%ftp60rpm), 35:00@65%ftp, 10:00@60%ftp', 3.5, tempoForceDescriptions(2, 90*60, '85%ftp', '3*20min', '10min', 35*60, 10*60)),

  WorkoutItem.create({ name: 'tempo_strength', format: ['2h30'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '3*25min'} },
    warmup2 + '3*(10:00@55%ftp, 25:00@85%ftp60rpm), 20:00@65%ftp, 10:00@60%ftp', 4, tempoForceDescriptions(2, 105*60, '85%ftp', '3*25min', '10min', 20*60, 10*60)),

  WorkoutItem.create({ name: 'tempo_strength', format: ['2h30'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '4*20min'} },
    warmup2 + '4*(10:00@55%ftp, 20:00@85%ftp60rpm), 5:00@65%ftp, 10:00@60%ftp', 4.5, tempoForceDescriptions(2, 120*60, '85%ftp', '4*20min', '10min', 5*60, 10*60)),

  WorkoutItem.create({ name: 'tempo_strength', format: ['2h30'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '3*30min'} },
    warmup2 + '3*(10:00@55%ftp, 30:00@85%ftp60rpm), 5:00@65%ftp, 10:00@60%ftp', 5, tempoForceDescriptions(2, 120*60, '85%ftp', '3*30min', '10min', 5*60, 10*60)),

  WorkoutItem.create({ name: 'tempo_strength', format: ['2h30'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '3*30min'} },
    warmup2 + '3*(10:00@55%ftp, 30:00@87%ftp60rpm), 5:00@65%ftp, 10:00@60%ftp', 5.5, tempoForceDescriptions(2, 120*60, '87%ftp', '3*30min', '10min', 5*60, 10*60)),

  WorkoutItem.create({ name: 'tempo_strength', format: ['2h30'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '3*30min'} },
    warmup2 + '3*(10:00@55%ftp, 30:00@90%ftp60rpm), 5:00@65%ftp, 10:00@60%ftp', 6, tempoForceDescriptions(2, 120*60, '90%ftp', '3*30min', '10min', 5*60, 10*60)),

  WorkoutItem.create({ name: 'tempo_strength', format: ['2h30'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '2*55min'} },
    warmup2 + '2*(8:00@55%ftp, 55:00@87%ftp60rpm), 9:00@60%ftp', 6.5, tempoForceDescriptions(2, 126*60, '87%ftp', '2*55min', '8min', 0, 9*60)),

  //Tempo force 3h

  WorkoutItem.create({ name: 'tempo_strength', format: ['3h'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '3*25min'} },
    warmup2 + '3*(10:00@55%ftp, 25:00@85%ftp60rpm), 50:00@65%ftp, 10:00@60%ftp', 4, tempoForceDescriptions(2, 105*60, '85%ftp', '3*25min', '10min', 50*60, 10*60)),

  WorkoutItem.create({ name: 'tempo_strength', format: ['3h'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '4*20min'} },
    warmup2 + '4*(10:00@55%ftp, 20:00@85%ftp60rpm), 35:00@65%ftp, 10:00@60%ftp', 4.5, tempoForceDescriptions(2, 120*60, '85%ftp', '4*20min', '10min', 35*60, 10*60)),

  WorkoutItem.create({ name: 'tempo_strength', format: ['3h'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '3*30min'} },
    warmup2 + '3*(10:00@55%ftp, 30:00@85%ftp60rpm), 35:00@65%ftp, 10:00@60%ftp', 5, tempoForceDescriptions(2, 120*60, '85%ftp', '3*30min', '10min', 35*60, 10*60)),

  WorkoutItem.create({ name: 'tempo_strength', format: ['3h'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '4*25min'} },
    warmup2 + '4*(10:00@55%ftp, 25:00@85%ftp60rpm), 15:00@65%ftp, 10:00@60%ftp', 5.5, tempoForceDescriptions(2, 140*60, '85%ftp', '4*25min', '10min', 15*60, 10*60)),

  WorkoutItem.create({ name: 'tempo_strength', format: ['3h'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '2*55min'} },
    warmup2 + '2*(15:00@55%ftp, 55:00@85%ftp60rpm), 15:00@65%ftp, 10:00@60%ftp', 6, tempoForceDescriptions(2, 140*60, '85%ftp', '2*55min', '15min', 15*60, 10*60)),

  WorkoutItem.create({ name: 'tempo_strength', format: ['3h'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '2*60min'} },
    warmup2 + '2*(15:00@55%ftp, 60:00@87%ftp60rpm), 5:00@65%ftp, 10:00@60%ftp', 6.5, tempoForceDescriptions(2, 150*60, '85%ftp', '2*60min', '15min', 5*60, 10*60)),

  //Tempo force 3h30

  WorkoutItem.create({ name: 'tempo_strength', format: ['3h30'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '4*25min'} },
    warmup2 + '4*(10:00@55%ftp, 25:00@85%ftp60rpm), 45:00@65%ftp, 10:00@60%ftp', 5.5, tempoForceDescriptions(2, 140*60, '85%ftp', '4*25min', '10min', 45*60, 10*60)),

  WorkoutItem.create({ name: 'tempo_strength', format: ['3h30'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '2*55min'} },
    warmup2 + '2*(15:00@55%ftp, 55:00@85%ftp60rpm), 45:00@65%ftp, 10:00@60%ftp', 6, tempoForceDescriptions(2, 140*60, '85%ftp', '2*55min', '15min', 45*60, 10*60)),

  WorkoutItem.create({ name: 'tempo_strength', format: ['3h30'] }, 'tempo', { description: 'tempo_strength', format: {intervals: '2*60min'} },
    warmup2 + '2*(15:00@55%ftp, 60:00@87%ftp60rpm), 35:00@65%ftp, 10:00@60%ftp', 6.5, tempoForceDescriptions(2, 150*60, '85%ftp', '2*60min', '15min', 35*60, 10*60)),

  ],
);



module.exports = {
  tempo,
  tempoForce,
};
