const WorkoutCategory = require('../workoutCategory');
const WorkoutItem = require('../workoutItem');
const rules = require('../rules/rules');
const workouts = require('../workoutslib/workoutsLib');



module.exports = {
  categories: [
    workouts.test20min, workouts.test5min, workouts.test1min, workouts.testSprint, workouts.recovery, workouts.light,
  ],

  rules: [
    rules.placementRule('20minTests', 'AFTER', ['REST', 'recovery', 'light'], 'VERYHIGH'),
    rules.placementRule('20minTests', 'AFTER', 'light', 'HIGH'),
    rules.placementRule('recovery', 'AFTER', '20minTests', 'MEDIUM'),

    rules.placementRule('5minTests', 'AFTER', ['REST', 'recovery', 'light'], 'HIGH'),

    rules.placementRule('1minTests', 'AFTER', ['recovery', 'light'], 'HIGH'),
    rules.placementRule('1minTests', 'AFTER', '5minTests', 'MEDIUM'),
    rules.placementRule('1minTests', 'AFTER', 'REST', 'MEDIUM'),
    rules.placementRule('sprintTests', 'AFTER', ['REST', 'recovery', 'light'], 'HIGH'),
    rules.placementRule('sprintTests', 'AFTER', '5minTests' , 'MEDIUM'),
    rules.placementRule('sprintTests', 'AFTER', '1minTests', 'LOW'),

    rules.orderRule('20minTests', 'BEFORE', ['5minTests', '1minTests', 'sprintTests'], 'VERYHIGH'),
    rules.orderRule('5minTests', 'BEFORE', 'sprintTests', 'MEDIUM'),
    rules.orderRule('5minTests', 'BEFORE', '1minTests', 'HIGH'),
    rules.orderRule('sprintTests', 'BEFORE', '1minTests', 'HIGH'),

    rules.frequencyRule('20minTests', 'EQUAL', 1, 'REQUIRED', 2),
    rules.frequencyRule('5minTests', 'EQUAL', 1, 'REQUIRED'),
    rules.frequencyRule('sprintTests', 'EQUAL', 1, 'HIGH'),
    rules.frequencyRule('sprintTests', 'MAX', 1, 'REQUIRED'),
    rules.frequencyRule('1minTests', 'MAX', 1, 'REQUIRED'),
    rules.frequencyRule('light', 'MIN', 1, 'MEDIUM'),
    rules.frequencyRule('light', 'MIN', 2, 'LOW'),
    rules.frequencyRule('recovery', 'MIN', 1, 'LOW'),
    rules.frequencyRule('recovery', 'MIN', 2, 'LOW'),
    rules.frequencyRule('recovery', 'MIN', 3, 'LOW'),

    rules.weekHoursTargetRule('MIN', 40, '%', 'HIGH', 2),
    rules.weekHoursTargetRule('MAX', 125, '%', 'HIGH', 2),
    rules.weekHoursTargetRule('TARGET', 75, '%', 'MEDIUM'),
    rules.weekLoadTargetRule('TARGET', 60, '*', 'LOW'),

    rules.ifAvailableHours('>=', 14, rules.ifTrainingLevel('>=', 4, rules.frequencyRule('1minTests', 'MIN', 1, 'HIGH'))),
    rules.ifAvailableHours('<', 14, rules.frequencyRule('1minTests', 'EQUAL', 0, 'REQUIRED')),
    rules.ifTrainingLevel('<', 4, rules.frequencyRule('1minTests', 'EQUAL', 0, 'REQUIRED')),


    rules.trainingRideCategory(['easy', 'endurance'], ['recovery', 'light'], 'REQUIRED'),
    rules.trainingRideCategory('endurance', 'light', 'HIGH'),
  ],
};
