/*
 * Training block class. Store start and end date and block name/type
 */
var dayjs = require('dayjs');
dayjs.extend(require('dayjs/plugin/utc'));

class TrainingBlock{
  constructor(type, start, end){
    this.start = start;
    this.end = end;
    this.type = type;
    this.name = type.split('_')[0];
    this.data = {};

    if(type.includes('RECOVERY')){ //If recovery block, store special week number data corresponding to the block number
      this.data.week = parseInt(type.split('_').pop());
    }
    if(type.includes('BASE') || type.includes('BUILD'))
      this.data.weekNumberStart = 3-this.nbWeeks();

    if(type.includes('TAPER'))
      this.data.weekNumberStart = 2-this.nbWeeks();
  }

  static fromData(block){ //Simple block object to class object
    return new TrainingBlock(block.type, block.start, block.end);
  }

  nbWeeks(){
    return Math.round(this.nbDays()/7);
    //return dayjs.utc(this.end).diff(dayjs.utc(this.start), 'week');
  }

  nbDays(){
    return dayjs.utc(this.end).diff(dayjs.utc(this.start), 'day');
  }

  startDay(){ return (dayjs.utc(this.start).day()+6)%7; } //Day of the week for block start
  endDay(){ return (dayjs.utc(this.end).day()+6)%7; } //Day of the week for block end

  weeksLengths(){ //Lengths in day of each week in the block
    var nbDays = this.nbDays();
    var nbWeeks = this.nbWeeks();
    var lengths = new Array(nbWeeks);
    for(var i=0; i<nbWeeks; i++){
      var start = Math.round(i*(nbDays/nbWeeks));
      var end = Math.round((i+1)*(nbDays/nbWeeks));
      lengths[i] = end-start;
    }
    return lengths;
  }

  weeksStarts(){ //Start day of each week in the block
    var starts = [];
    var weeksLengths = this.weeksLengths();
    var start = this.start;
    for(let weekLength of weeksLengths){
      starts.push(start);
      start = dayjs.utc(start).add(weekLength, 'day').toDate();
    }

    return starts;
  }

  weekStart(week){
    return this.weeksStarts()[week];
  }

  weekEnd(week){
    return week+1 > this.nbWeeks() ? dayjs.utc(this.weeksStarts()[week+1]).add(-1, 'day').toDate() : this.end;
  }

  weekOfDate(date){ //Return the week number of the date in the block
    date = dayjs.utc(date);
    var starts = this.weeksStarts();
    for(var i=0; i<starts.length; i++){
      if(starts[i] > date)
        return i-1;
    }
    return starts.length;
  }

  getWeekNumberForWeek(week){
    if(this.data.week)
      return this.data.week-1;
    if(this.data.weekNumberStart)
      return week + this.data.weekNumberStart;
    return week;
  }
}


module.exports = TrainingBlock;
