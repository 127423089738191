const WorkoutCategory = require('../workoutCategory');
const WorkoutItem = require('../workoutItem');
const rules = require('../rules/rules');
const workouts = require('../workoutslib/workoutsLib');







module.exports = {
  categories: [ workouts.recovery, workouts.light, workouts.enduranceF, workouts.enduranceActive, workouts.test5min,
       workouts.test1min, workouts.testSprint,workouts.test20min,
  ],

  rules: [
    rules.frequencyRule('enduranceF', 'MIN', 1, 'HIGH'),
    rules.frequencyRule('enduranceF', 'MIN', 2, 'LOW'),
    rules.frequencyRule('enduranceActive', 'MIN', 1, 'HIGH'),
    rules.frequencyRule('recovery', 'MIN', 1, 'MEDIUM'),
    rules.frequencyRule('light', 'MIN', 1, 'HIGH'),

    rules.frequencyRule(['20minTests', '5minTests', '1minTests', 'sprintTests'], 'MAX', 1, 'REQUIRED'),
    rules.frequencyRule(['enduranceF', 'enduranceActive'], 'MIN', 2, 'HIGH'),
    rules.frequencyRule(['enduranceF', 'enduranceActive'], 'MAX', 5, 'HIGH'),

    rules.placementRule('enduranceF', 'AFTER', ['REST', 'recovery', 'light'], 'HIGH'),
    rules.placementRule('enduranceActive', 'AFTER', 'enduranceF', 'MEDIUM'),
    rules.placementRule('recovery', 'AFTER', 'enduranceActive', 'HIGH'),
    rules.placementRule('1minTests', 'AFTER', ['enduranceActive', 'enduranceF'], 'HIGH'),
    rules.placementRule('sprintTests', 'AFTER', ['enduranceActive', 'enduranceF'], 'HIGH'),
    rules.placementRule(['enduranceF', 'enduranceActive'], 'BEFORE', ['1minTests', 'sprintTests', '20minTests', '5minTests'], 'HIGH'),
    rules.placementRule(['enduranceF', 'enduranceActive', 'REST'], 'AFTER', ['1minTests', 'sprintTests', '20minTests', '5minTests'], 'VERYHIGH'),

    rules.weekPositionRule(['20minTests', '5minTests', '1minTests', 'sprintTests'], 1, 5, 'REQUIRED'),
    rules.weekPositionRule(['20minTests', '5minTests', '1minTests', 'sprintTests'], 3, 5, 'HIGH'),

    rules.ifObjectivesNumber('>=', 1, 'ABC', rules.frequencyRule(['20minTests', '5minTests', '1minTests', 'sprintTests'], 'MAX', 0, 'REQUIRED')),


    rules.trainingRideCategory('endurance', ['enduranceF', 'enduranceActive'], 'REQUIRED'),
    rules.trainingRideCategory('easy', ['recovery', 'light', 'enduranceF', 'enduranceActive'], 'REQUIRED'),
    rules.trainingRideCategory('easy', ['recovery', 'light'], 'VERYHIGH'),

    //BaseBlock1
    rules.forWeek(1, [
      rules.weekHoursTargetRule('TARGET', 60, '%', 'VERYHIGH', 5),
      rules.frequencyRule('1minTests', 'EQUAL', 1, 'VERYHIGH'),

      rules.longestWorkoutDurationTargetRule('TARGET', 1, 15, '%', 'HIGH'),
    ]),

    //BaseBlock2
    rules.forWeek(2, [
      rules.weekHoursTargetRule('TARGET', 65, '%', 'VERYHIGH', 5),

      rules.frequencyRule('20minTests', 'EQUAL', 1, 'VERYHIGH'),

      rules.longestWorkoutDurationTargetRule('TARGET', 1, 20, '%', 'HIGH'),
    ]),

    //BaseBlock3
    rules.forWeek(3, [
      rules.weekHoursTargetRule('TARGET', 65, '%', 'VERYHIGH', 5),

      rules.frequencyRule('5minTests', 'EQUAL', 1, 'VERYHIGH'),

      rules.longestWorkoutDurationTargetRule('TARGET', 1, 20, '%', 'HIGH'),
    ]),

  ],
};
