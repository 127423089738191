const trainingBlocksPlanner = require('@/../api/planner/trainingBlocksPlanner');
const workoutPlanner = require('@/../api/planner/workoutPlanner');
const WorkoutItem = require('@/../api/planner/workoutItem');
const ObjectiveItem = require('@/../api/planner/objectiveItem');
const TrainingRideItem = require('@/../api/planner/trainingRideItem');
const TrainingBlock = require('@/../api/planner/trainingBlock');
const plannerUtil = require('@/../api/planner/plannerUtil');
var dayjs = require('dayjs');
dayjs.extend(require('dayjs/plugin/utc'));
require('dayjs/locale/en-gb');
dayjs.locale('en-gb');
const isEqual = require('fast-deep-equal');
const util = require('@/../api/util');

const WorkoutCategory = require('@/../api/planner/workoutCategory');
const rules = require('@/../api/planner/rules/rules');
const workouts = require('@/../api/planner/workoutslib/workoutsLib');
const workoutUtil = require('@/../shared/workout/workoutUtil');
const otherWorkouts = require('@/../api/planner/workoutslib/otherWorkouts');
const clonedeep = require('lodash.clonedeep');

function generatePlanData(level = 0){
  /*return {
    max_week_available_time: 20*3600,
    week_available_time: 15*3600,
    available_time_days: [3*3600, 3*3600, 3*3600, 3*3600, 3*3600, 4*3600, 5*3600],
  }*/

  var availableHours = 5+Math.round(Math.random()*15);
  var maxAvailableHours = availableHours + Math.round(Math.random()*5);
  do{
    var available_time_days = [];
    for(let i=0; i<7; i++){
      available_time_days[i] = (Math.round(Math.random()*16)-4)*1800;
      if(available_time_days[i] <= 1800)
        available_time_days[i] = 0;
    }
  }while(available_time_days.reduce((acc, value) => acc+value) < maxAvailableHours*3600);

  return {
    week_available_time: availableHours*3600,
    max_week_available_time: maxAvailableHours*3600,

    available_time_days: available_time_days,
    training_level: level || 1+Math.round(Math.random()*5),
    available_time_on_week: {},
  };
}

function generateMainObjective(profile = ''){
  const profiles = ['climber', 'puncher', 'rouleur', 'sprinter', 'grandfondo']
  if(!profile) //If no set profile, pick one at random
    profile = profiles[Math.floor(Math.random() * profiles.length)];

  return { name: 'Target', priority: 'A', profile_type: profile, isObjective: true, isTrainingRide: false, date: dayjs.utc(new Date(Date.now() + 3600*24*30)).startOf('day') };
}

function codeToObject(code){
  var toEval = `
    (${code});
  `;
  return eval(toEval);
}

function generateTrainingTypeDays(nbIndoor){
  const array = [];
  const indoorOutdoor = ['indoor', 'outdoor'];

  for (let i = 0; i < 7; i++) {
    array.push(indoorOutdoor[Math.floor(Math.random() * 2)]);
  }

  const indoorCount = array.filter(element => element === 'indoor').length;
  if(indoorCount > nbIndoor) {
    // There are more 'indoor' elements than desired, replace some with 'outdoor'
    for(let i = 0; i < indoorCount - nbIndoor; i++){
      array[array.indexOf('indoor')] = 'outdoor';
    }
  }else if(indoorCount < nbIndoor){
    // There are fewer 'indoor' elements than desired, replace some with 'indoor'
    for(let i = 0; i < nbIndoor - indoorCount; i++){
      array[array.indexOf('outdoor')] = 'indoor';
    }
  }

  return array;
}

function planWeek(user, code, week = 0, level = 0, profile = '', objectives = [], trainingRides = [], indoorDays = 0){
  var plan = new Array(7);

  var blockType = new workoutPlanner.BlockType(codeToObject(code));

  var startDate = dayjs.utc().startOf('week');
  var plannedItemsMap = objectives.reduce((map, obj) => {
    obj.date = startDate.add(obj.day, 'days').toDate();
    obj.name = 'Objectif',
    obj.estimated_duration = obj.duration;
    obj.isObjective = true;
    obj.isTrainingRide = false;
    map[obj.day] = obj;
    return map;
  }, {});

  for(let trainingRide of trainingRides){
    if(!plannedItemsMap[trainingRide.day]){
      plannedItemsMap[trainingRide.day] = {
        date: startDate.add(trainingRide.day, 'days').toDate(),
        estimated_duration: trainingRide.estimated_duration,
        name: 'Training Ride',
        planned_intensity: trainingRide.planned_intensity,
        isObjective: false,
        isTrainingRide: true,
      }
    }
  }

  var trainingTypeDays = generateTrainingTypeDays(indoorDays);

  var planData = generatePlanData(level);
  planData.training_type_days = trainingTypeDays;

  user.training_plan_data = planData;
  user.objectives = [generateMainObjective(profile)];
  var maxTime = planData.max_week_available_time;
  var timePerDay = planData.available_time_days;

  //Every day where the user can't train is a rest day.
  var nbRestDays = 0;
  for(let i=0; i<plan.length; i++){
    if(timePerDay[(startDate.day()+6+i)%7] == 0){  //Modulo 7 to get a day number between 0 and 6. Add 6 to start day so that monday become 0 and sunday become 6
      plan[i] = 'REST';
    }
    let objectiveNextDay = plannedItemsMap[i+1];
    if(objectiveNextDay && objectiveNextDay.priority === 'A'){ //If there is an objective A next day, then it's an opener
      plan[i] = clonedeep(otherWorkouts.opener.workouts[0]); //clone opener and set it as a set workout
      plan[i].set = true;
    }
    //If objective during the week, add it to the plan
    if(plannedItemsMap[i]){
      if(plannedItemsMap[i].isObjective)
        plan[i] = new ObjectiveItem(plannedItemsMap[i]);
      else
        plan[i] = new TrainingRideItem(plannedItemsMap[i]);
    }
  }



  //Data that may be used by some rules for the workouts planning
  var data = {
    weekStartDate: startDate,
    startDay: 0,
    week: week,
    weekLength: 7,
    plannedItemsMap: plannedItemsMap,
    previousWorkout: null,
    nextWorkout: null,
    trainingTypeDays: trainingTypeDays,
  };

  plan = workoutPlanner.fillDaysGenetic(user, plan, blockType, data);

  for(let i=0; i<plan.length; i++){
    if(plan[i] !== 'REST'){
      var date = startDate.add(i, 'days').toDate();
      if(plan[i].isWorkout()){
        plan[i] = plan[i].toWorkout(user, date);
        plan[i].data = workoutUtil.calcData(plan[i], user.models.length > 0 ? user.models[user.models.length-1] : {}, true);
        plan[i].isWorkout = true;
        plan[i].date = date;
      }else if(plan[i].isObjective()){
        var odata = plan[i].getData();
        plan[i] = plan[i].objective;
        plan[i].data = odata;
        plan[i].isObjective = true;
      }else if(plan[i].isTrainingRide()){
        var tdata = plan[i].getData();
        plan[i] = plan[i].trainingRide;
        plan[i].data = tdata;
        plan[i].isTrainingRide = true;
      }
    }
  }

  plan.data = planData;

  return plan;
}

function planStats(user, plan){
  var stats = {
    plannedDuration: 0,
    plannedLoad: 0,
    plannedZones: [0,0,0,0,0,0,0],
    profile: user.objectives && user.objectives.length > 0 ? user.objectives[0].profile_type : '',
  };

  for(let workout of plan){
    if(workout !== 'REST'){
      stats.plannedDuration += workout.duration;
      stats.plannedLoad += workout.data.load;

      if(workout.data.time_power_zones && workout.data.time_power_zones.length > 0){
        workout.data.time_power_zones.forEach((time, i) => { stats.plannedZones[i] += time; });
      }else if(workout.data.time_hr_zones && workout.data.time_hr_zones.length > 0){
        workout.data.time_hr_zones.forEach((time, i) => { stats.plannedZones[i] += time; });
      }
    }
  }

  return stats;
}

module.exports = function generateWeek(user, code, week = 0, level = 0, profile = '', objectives = [], trainingRides = [], indoorDays = 0){
  var plan = planWeek(user, code, week, level, profile, objectives, trainingRides, indoorDays);
  plan.stats = planStats(user, plan);

  return plan;
};
