/*
 * Add conditions to the application of some rules
 * Rules will be checked only if these conditions are met.
 */

const Rule = require('./rule');
const plannerUtil = require('../plannerUtil');
const compare = plannerUtil.compare;

//Condition class, work somewhat like a rule (but no priority)
class Condition extends Rule{
  constructor(condition, rules){
    super();
    this.condition = condition; //Condition callback to check
    this.rules = Array.isArray(rules) ? rules : [rules]; //Rules need to be an array
  }

  check(user, plan, data){
    var points = 0;
    if(this.condition(user, plan, data)){ //If condition met
      for(let rule of this.rules){
        points += rule.check(user, plan, data); //Check all rules and return the total points
      }
    }
    return points;
  }
}

function condition(cond, rules){
  return new Condition(cond, rules);
}

//Rules will only be applied for a specific week in the block
function forWeek(week, rules){
  return condition((user, plan, data) => data.week+1 == week, rules);
}

//Rules will only be applied if available hours of the week match the comparison
function ifAvailableHours(comp, hours, rules){
  return condition((user, plan, data) => compare(comp, (plannerUtil.getUserAvailableTimeForPlan(user, data.weekStartDate, plan.length)*(plan.length/7))/3600, hours), rules);
}

//Rules will only be applied if the user have one of the required equipment
function ifEquipments(equipments, rules){
  equipments = Array.isArray(equipments) ? equipments : [equipments];
  return condition((user, plan, data) => {
    for(let equipment of equipments){
      if(user.infos.equipment[equipment])
        return true;
    }
    return false;
  }, rules);
}

//Rules will only be applied if the user don't have one of the given equipment
function ifNotEquipments(equipments, rules){
  equipments = Array.isArray(equipments) ? equipments : [equipments];
  return condition((user, plan, data) => {
    for(let equipment of equipments){
      if(user.infos.equipment[equipment])
        return false;
    }
    return true;
  }, rules);
}

//Rules will only be applied if the user training level match the comparison
function ifTrainingLevel(comp, level, rules){
  return condition((user, plan, data) => compare(comp, (user.training_plan_data.training_level || 1), level), rules);
}

//Rules will only be applied if the week have a number of objectives of x priorities that match the comparison
function ifObjectivesNumber(comp, number, priorities, rules){
  if(!priorities || priorities.length === 0)
    priorities = 'ABC';

  return condition((user, plan, data) => {
    var nbObjectives = 0;
    for(var item of plan)
      if(item && item !== 'REST' && item.isObjective() && priorities.includes(item.priority()))
        nbObjectives++;

    return compare(comp, nbObjectives, number);
  }, rules);
}

//Rules will only be applied if the week have at least one objective of a given priority
function ifObjectives(priorities, rules){
  return ifObjectivesNumber('>', 0, priorities, rules);
}

//Rules will only be applied if the profile type of the next A objective of the user match the list
function ifProfileType(profile_types, rules){
  profile_types = Array.isArray(profile_types) ? profile_types : [profile_types];
  return condition((user, plan, data) => {
    let objective;
    for(let obj of user.objectives){ //Find next A objective from current block weekdate
      if(obj.priority === 'A' && obj.date >= data.weekStartDate && (!objective || obj.date < objective.date))
        objective = obj;
    }

    if(objective && objective.profile_type) //if A objective found and a profile type is defined
      return profile_types.includes(objective.profile_type); //Check if it match

    return profile_types.includes('puncher'); //Else by default the plan will be a puncher plan
  }, rules);
}


function ifBaseAccountType(rules){
  return condition((user, plan, data) => {
    return user.account_type === 'BASE';
  }, rules);
}



module.exports = {
  condition,
  forWeek,
  ifAvailableHours,
  ifEquipments,
  ifNotEquipments,
  ifTrainingLevel,
  ifObjectivesNumber,
  ifObjectives,
  ifProfileType,
  ifBaseAccountType,
};
